import { useEffect, useState } from 'react';
import { dataProvider, getStorage, useApp } from 'helpers';
import { getParams } from 'helpers/dataOptions';
import microTrainingApi from 'helpers/apis/microtraining';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useMicroTrainingQuizData = params => {
  const { dispatch } = useApp();

  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [trainingId, setTrainingId] = useState(null);

  const [answerProgress, setAnswerProgress] = useState(0);
  const userId = getStorage('userId', true);

  useEffect(() => {
    if (trainingId !== null) {
      setLoading(true);
      clearTraining();

      // Params are being sent to back-end in order to get correct next and previous id for MT when there is a list and user can navigate through nex and prev buttons (in MT mobile)
      let requestParams = {};
      if (params) {
        const { pagination, ...restParams } = params;
        requestParams = getParams(restParams);
      }

      dataProvider
        .getOne('myDashboard', `microTrainingsQuiz/${userId}`, { training_id: trainingId, ...requestParams })
        .then(({ data }) => {
          setRecord(data);
          setIsCompleted(data && data.score !== null ? true : false);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [trainingId]);

  const handleChangeTraining = (e, index, value) => {
    const mt = JSON.parse(JSON.stringify(record));
    const trainingValue = value ?? e.target.value;
    mt.questions[index].user_answer = trainingValue;
    const answers = mt.questions.filter(e => e.user_answer !== null);
    setRecord(prevMtData => ({ ...prevMtData, questions: mt.questions }));
    setAnswerProgress(Math.floor((answers.length * 100) / record.questions.length));
  };

  const handleSubmitTraining = (channel, callback) => {
    setIsSubmitted(true);
    const user_answers = record.questions.map(q => {
      return { user_answer: q.user_answer, right_answer: q.right_answer, feedback: q.feedback };
    });
    const mt = {
      id: record.id,
      questions: user_answers
    };

    microTrainingApi
      .submit({ app: 'myDashboard', data: channel ? { ...mt, channel } : mt })
      .then(res => {
        const questions = res.data.questions;
        const score = res.data.score;
        const total_correct_answers = res.data.total_correct_answers;
        setRecord(prevState => ({ ...prevState, questions, score, total_correct_answers }));
        setIsCompleted(res && score !== null ? true : false);
        setIsSubmitted(false);

      usersAPI.getUserData(['user_info']).then(res => {
        dispatch.setItem('user', 'profile', 'ess', res.data.latest_ess);
      });

        return callback?.();
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', { props: { severity: 'error' } });
        setIsSubmitted(false);
      });
  };

  const handleChangeTrainingId = id => {
    setTrainingId(id);
  };

  const clearTraining = () => {
    setAnswerProgress(0);
    setRecord(null);
  };

  return {
    record,
    loading,
    answerProgress,
    isCompleted,
    setIsCompleted,
    isSubmitted,
    trainingId,
    handleSubmitTraining,
    handleChangeTraining,
    clearTraining,
    handleChangeTrainingId
  };
};

export default useMicroTrainingQuizData;
