import React, { useState, useMemo } from 'react';
import { LoadingStyled } from 'components';
import { dataProvider } from 'helpers';
import { useDataGet, useId } from 'hooks';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import UserContactUsForm from './UserContactUsForm';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company: '',
  subject: '',
  feedback: ''
};

const UserContactUs = () => {
  const clientId = useId({ key: 'clientId' });
  const item = useId({ key: 'userId' });

  const [data, setData] = useState({ ...initialState, id: item });
  const [submitting, setSubmitting] = useState(false);

  const { data: profile, isLoading: isLoadingProfile } = useDataGet({
    app: 'user',
    tab: 'profile',
    item,
    options: {
      staleTime: 0,
      onSuccess: res => {
        setData(prev => ({
          ...prev,
          first_name: res.data.firstName,
          last_name: res.data.lastName,
          phone: res.data.phoneNumber,
          email: res.data.email
        }));
      }
    }
  });

  const { data: company, isLoading: isLoadingCompany } = useDataGet({
    app: 'clients',
    tab: 'company',
    clientId,
    options: {
      onSuccess: res => {
        setData(prev => ({
          ...prev,
          company: res.data.name
        }));
      }
    }
  });

  const resetForm = () => {
    setData(() => ({
      subject: '',
      feedback: '',
      id: item,
      first_name: profile.firstName,
      last_name: profile.lastName,
      phone: profile.phoneNumber,
      email: profile.email,
      company: company.name
    }));
  };

  const handleFieldChange = e => {
    setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = e => {
    e.preventDefault();

    setSubmitting(true);
    dataProvider
      .post('user', 'contactUs', data.id, data)
      .then(res => {
        enqueueAlertSnackbar(res.data.message, {
          props: { severity: 'success' }
        });
        resetForm();
      })
      .catch(err => {
        enqueueAlertSnackbar(err.response?.data?.description || err.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const isValidForm = useMemo(() => {
    return ['first_name', 'last_name', 'subject', 'feedback'].every(field => Boolean(data[field]?.trim()?.length)) && !data?.phone.startsWith("+0");
  }, [data]);

  if (isLoadingCompany || isLoadingProfile) return <LoadingStyled />;

  return (
    <UserContactUsForm
      onSubmit={onSubmit}
      onFieldChange={handleFieldChange}
      data={data}
      resetForm={resetForm}
      isValidForm={isValidForm}
      submitting={submitting}
    />
  );
};

export default UserContactUs;
