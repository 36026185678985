// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import {
  Button,
  IconButton as MuiIconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel
} from '@material-ui/core';

export const DialogTitleStyled: ComponentType<*> = styled(DialogTitle)`
  && {
    background: var(--backgroundDefault);
    padding: calc(var(--spacing) * 3);
    .modal-title {
      margin: 0 5% 0 0;
    }
  }
`;

export const DialogContentStyled: ComponentType<*> = styled(DialogContent)`
  background: var(--backgroundDefault);
  font-size: var(--fontSize);
`;

export const DialogActionsStyled: ComponentType<*> = styled(DialogActions)`
  background: var(--backgroundDefault);
  display: flex;
  padding: 12px 24px !important;
  && {
    justify-content: space-between;
  }
`;

export const AlignRight: ComponentType<*> = styled.div`
  && {
    justify-content: right;
  }
`;

export const AlignLeft: ComponentType<*> = styled.div`
  && {
    justify-content: left;
  }
`;

export const IconButton: ComponentType<*> = styled(MuiIconButton)`
  right: calc(var(--spacing) * 3);
  top: calc(var(--spacing) * 2);
  white-space: nowrap;
  && {
    position: absolute;
    svg {
      color: var(--colorDefault);
      font-size: var(--fontSize);
    }
  }
`;

export const FormControlLabel: ComponentType<*> = styled(MuiFormControlLabel)`
  padding-left: 24px;
`;

export const Checkbox: ComponentType<*> = styled(MuiCheckbox)`
  transform: scale(1.5);
  && {
    margin: 0 8px;
    padding: 0;
  }
`;
