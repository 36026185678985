import React, { useState } from 'react';
import { Container } from 'components';
import { Button, MailIcon, MobileIcon } from '@trustsecurenow/components-library';
import { usePreassessmentContext } from '../../contexts';
import cognito from '../../../../helpers/apis/cognito';
import PreassessmentPhoneNumber from './PreassessmentPhoneNumber';
import PreassessmentVerificationCode from './PreassessmentVerificationCode';
import { PreassessmentBlueText, PreassessmentLeftBox } from '../../style';

const PreassessmentVerifyStep = () => {
  const [verificationDate, setVerificationDate] = useState(null);

  const [verificationType, setVerificationType] = useState('');

  const [verifyView, setVerifyView] = useState('verifyButtons');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    type,
    client_id,
    user: { email }
  } = usePreassessmentContext();

  const handleSendVerificationCode = async method => {
    setLoading(true);
    setVerificationType(method);
    try {
      const verificationData = await cognito.sendVerificationCode({
        email,
        method,
        client_id
      });
      setVerificationDate(verificationData?.data?.valid_until);
      setVerifyView('verifySms');
    } catch (e) {
      console.log(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickButtons = async method => {
    setVerificationType(method);
    if (method === 'email') {
      await handleSendVerificationCode('email');
    } else {
      setVerifyView('verifyPhoneNumber');
    }
  };

  const maskedPhoneNumber = phoneNumber.replace(/(\d|\w)(?=.{4,}$)/g, 'X');
  const maskedEmail = email.slice(0, 2) + '**@' + email.split(/@/)[1];

  return (
    <div>
      <PreassessmentBlueText>
        {verifyView !== 'verifySms' ? (
          verificationType !== 'sms' ? (
            <span>
              We need to send you a one-time access code to verify your account. <br />
              How would you like to receive your code?
            </span>
          ) : (
            <span>Mobile phones only. Message and data rates may apply</span>
          )
        ) : verificationType === 'email' ? (
          <span>
            We sent an email to {maskedEmail}, please enter your 6 digit code.
            <br />
            Be sure to check your spam folder
          </span>
        ) : (
          `We sent you a text message to ${maskedPhoneNumber}, please enter your 6 digit code`
        )}
      </PreassessmentBlueText>

      {verifyView === 'verifyButtons' && (
        <Container.Grid container>
          <PreassessmentLeftBox sm={5}>
            <Button
              disabled={loading}
              startIcon={<MailIcon />}
              fullWidth
              variant="outlined"
              onClick={() => handleClickButtons('email')}
            >
              {type === 'baseline' && 'Business'} email address
            </Button>
          </PreassessmentLeftBox>
          <Container.Grid sm={3}>
            <Button disabled={loading} startIcon={<MobileIcon />} fullWidth onClick={() => handleClickButtons('sms')}>
              Text message
            </Button>
          </Container.Grid>
        </Container.Grid>
      )}

      {verifyView === 'verifyPhoneNumber' && (
        <PreassessmentPhoneNumber
          setVerifyView={setVerifyView}
          setVerificationDate={setVerificationDate}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}

      {verifyView === 'verifySms' && verificationDate && (
        <PreassessmentVerificationCode
          verificationDate={verificationDate}
          setVerifyView={setVerifyView}
          resendCallBack={handleSendVerificationCode}
          setVerificationType={setVerificationType}
          verificationType={verificationType}
          loading={loading}
        />
      )}
    </div>
  );
};

export default PreassessmentVerifyStep;
