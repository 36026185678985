import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core';
import * as sra from 'helpers/apis/services/sra';
import { LoadingStyled } from 'components';
import Recommendations from './Recommendations';
import RecommendationInfo from './RecommendationInfo';
import { RecommendationsProvider } from '../context/recommendationsContext';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { useSraReports } from '../hooks';
import { useId, useLocation } from 'hooks';
import { SRA_SUB_PAGES } from '../constants';

const useStyles = makeStyles(theme => ({
  workPlan: {
    padding: '30px 0 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  recommendationList: {
    marginRight: 30,
    width: 'calc(70% - 15px)',
    display: 'flex',
    flexDirection: 'column'
  },
  recommendationInfo: {
    width: 'calc(30% - 15px)',
    minWidth: 250
  }
}));

const WorkPlanPage = () => {
  const classes = useStyles();
  const userId = useId();
  const { item } = useLocation();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const clientId = item && !isSraSubPage ? item : userId;
  const {
    data: reports,
    isLoading: reportsLoading,
  } = useSraReports(clientId);
  // this is a hack to get the revision id of the most recent COMPLETED revision
  // where the /revision endpoint will give us the most recent revision,
  // regardless of completion status
  const revisionId = reports?.[0]?.revision_id;
  const [currentRecommendation, setCurrentRecommendation] = useState(null);
  const [workPlanInfo, setWorkPlanInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const getWorkplanInfo = useCallback(
    showLoading => {
      if (revisionId) {
        if (showLoading) setLoading(true);
        sra
          .getWorkplanInfo(revisionId)
          .then(res => {
            setWorkPlanInfo(res?.data);
          })
          .catch(err => {
            enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', { props: { severity: 'error' } });
          })
          .finally(() => {
            if (showLoading) setLoading(false);
          });
      }
    },
    [revisionId]
  );

  useEffect(() => {
    getWorkplanInfo(true);
  }, [getWorkplanInfo]);

  if (loading || reportsLoading || !revisionId) return <LoadingStyled />;

  return (
    <RecommendationsProvider>
      <div className={classes.workPlan}>
        <div className={classes.recommendationList}>
          <Recommendations
            currentRecommendation={currentRecommendation}
            setCurrentRecommendation={setCurrentRecommendation}
            revisionId={revisionId}
            outstandingRecommendations={workPlanInfo?.outstanding_recommendations}
          />
        </div>
        <div className={classes.recommendationInfo}>
          <RecommendationInfo
            currentRecommendation={currentRecommendation}
            setCurrentRecommendation={setCurrentRecommendation}
            aboutVideo={workPlanInfo?.video_url}
            getWorkplanInfo={getWorkplanInfo}
          />
        </div>
      </div>
    </RecommendationsProvider>
  );
};

export default WorkPlanPage;
