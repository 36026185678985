import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/NewsFeed`;

export const checkLicenseAgreement = () => {
    const requestOptions = {
      url: `${BASE_PATH}/LicenseAgreement`,
      headers: getHeaders()
    };
    return apiClient.get(requestOptions);
  };

export const acceptLicenseAgreement = () => {
  const requestOptions = {
    url: `${BASE_PATH}/LicenseAgreement`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const getHashtags = hashtag_name => {
  const requestOptions = {
    url: `${BASE_PATH}/hashtags`,
    params: { hashtag_name },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getPopularHashtags = (limit, offset, audience_name) => {
  const requestOptions = {
    url: `${BASE_PATH}/popular_hashtags`,
    params: { limit, offset, audience_name },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getMentionUsers = first_name => {
  const requestOptions = {
    url: `${BASE_PATH}/mention/users`,
    params: { first_name },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getNewsfeedUsers = name => {
  const requestOptions = {
    url: `${BASE_PATH}/Users`,
    params: { name },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getFlaggingReasons = () => {
  const requestOptions = {
    url: `${BASE_PATH}/FlaggingReasons`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getFeedPicklist = (types, filters) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/PickList`,
    params: {
      types: JSON.stringify(types),
      ...(filters && {filters: {...filters}}),
    },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getUsersPicklist = (types) => {
  const requestOptions = {
    url: `${BASE_PATH}/Users/PickList`,
    params: {
      types: JSON.stringify(types),
    },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

//Feeds

export const getFeed = (userId, offset, orderBy = null, searchParam = null, filter = null, searchFilter = null) => {
  const requestOptions = {
    url: `${BASE_PATH}/${userId}/Feed`,
    params: {
      offset,
      ...(orderBy && {order_by_field: orderBy}),
      ...(searchFilter && {...searchFilter}),
      ...(searchParam && {...searchParam}),
      ...(filter && {...filter})
    },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getFeedSingle = (userId, postId) => {
  const requestOptions = {
    url: `${BASE_PATH}/${userId}/Feed`,
    params: {
      offset: 0,
      post_id: postId
    },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const addPost = (userId, postData) => {
  const requestOptions = {
    url: `${BASE_PATH}/${userId}/Feed`,
    headers: getHeaders(),
    data: postData
  };
  return apiClient.post(requestOptions);
};

export const getPresignedPost = filename => {
  const requestOptions = {
    url: `${BASE_PATH}/GetPresignedPost`,
    params: { filename },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getLinkPreview = url => {
  const requestOptions = {
    url: `${BASE_PATH}/GetLinkPreview`,
    data: { url },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const likePost = (postId, userId) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/Likes`,
    data: { user_id: userId },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const unLikePost = (postId, userId) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/Likes`,
    data: { user_id: userId },
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const getPostsLikers = (postId, offset) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/Likes`,
    params: { offset },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const deletePost = postId => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const updatePost = (postId, postData) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}`,
    data: postData,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const flagPost = (postId, reason) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/flag`,
    data: { reason },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const favoritePost = postId => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/Favorites`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const unFavoritePost = postId => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/Favorites`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

//Comments

export const getComments = (post_id, offset, user_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${post_id}/Comments`,
    params: { offset, user_id },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const addComment = comment => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${comment.post_id}/Comments`,
    headers: getHeaders(),
    data: { comment }
  };
  return apiClient.post(requestOptions);
};

export const deleteComment = (commentId, postId) => {
  const requestOptions = {
    url: `${BASE_PATH}/Comments/${commentId}`,
    headers: getHeaders(),
    data: { post_id: postId }
  };
  return apiClient.delete(requestOptions);
};

export const updateComment = comment => {
  const requestOptions = {
    url: `${BASE_PATH}/Comments/${comment.id}`,
    data: { comment },
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const likeComment = (commentId, userId, postId) => {
  const requestOptions = {
    url: `${BASE_PATH}/Comments/${commentId}/Likes`,
    data: { user_id: userId, post_id: postId },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const unLikeComment = (commentId, userId, postId) => {
  const requestOptions = {
    url: `${BASE_PATH}/Comments/${commentId}/Likes`,
    data: { user_id: userId, post_id: postId },
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const getCommentsLikers = (commentId, offset) => {
  const requestOptions = {
    url: `${BASE_PATH}/Comments/${commentId}/Likes`,
    params: { offset },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const checkAccess = userId => {
  const requestOptions = {
    url: `${BASE_PATH}/${userId}/access`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const flagComment = (commentId, reason) => {
  const requestOptions = {
    url: `${BASE_PATH}/Comments/${commentId}/flag`,
    data: { reason },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

//Notifications

export const getNotifications = (user_id, offset) => {
  const requestOptions = {
    url: `${BASE_PATH}/${user_id}/Notifications`,
    params: { offset, user_id },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const markOpened = (notificationType, notificationId) => {
  const requestOptions = {
    url: `${BASE_PATH}/Notifications/${notificationId}/MarkOpened`,
    data: { type: notificationType },
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const markAllOpened = () => {
  const requestOptions = {
    url: `${BASE_PATH}/Notifications/MarkAllOpened`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const markAllSeen = () => {
  const requestOptions = {
    url: `${BASE_PATH}/Notifications/MarkAllSeen`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const setDevicesToken = (userId, token) => {
  const requestOptions = {
    url: `${BASE_PATH}/${userId}/SetDevicesTokens`,
    data: { device_token: token },
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const mutePostNotifications = postId => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/notification`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const unMutePostNotifications = postId => {
  const requestOptions = {
    url: `${BASE_PATH}/Posts/${postId}/notification`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};
