import React from 'react';
import { DatePicker } from '@trustsecurenow/components-library';
import { ADMIN_MICRO_TRAINING } from '../../constants';
import { isValid } from 'date-fns';

const PublishDatePicker = ({ dispatch, record, page }) => {
  const isMicroTraining = page === ADMIN_MICRO_TRAINING;

  const shouldDisableDate = date => {
    const selectedDate = new Date(date);

    if (selectedDate.getDay() !== 4) return true;
    const nextThursday = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 7);
    const isLastThursdayInMonth = nextThursday.getMonth() !== selectedDate.getMonth();
    return isMicroTraining ? isLastThursdayInMonth : !isLastThursdayInMonth;
  };

  const handleDateChange = value => {
    const date = new Date(value);
    if (!isValid(date)) return;
    dispatch.onChange('publish_date', value ? date.toISOString() : value);
  };

  return (
    <DatePicker
      value={record?.publish_date || null}
      label="Publish Date"
      disablePast
      slotProps={{ textField: { error: false }}}
      shouldDisableDate={shouldDisableDate}
      onChange={handleDateChange}
      sx={{ width: '100%' }}
      format="YYYY-MM-DD"
    />
  );
};

export default PublishDatePicker;
