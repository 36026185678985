/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, {
  type Element,
  type Context,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataProvider, hasKey, useApp, isObjectEmpty } from 'helpers';
import { useConfig, useTableData, useTableProps, useTableRowSelected, useModalConfig } from 'hooks';
import * as dashboard from 'helpers/apis/services/dashboard';
import bsnClientServices from 'helpers/apis/bsnClientServices';
import userPreferences from '../../helpers/apis/UsersAPI/userPreferences';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction';

const TableListContext: Context<{
  dispatch: Object,
  filter: string | boolean,
  settings: Object,
  record: Object | Array<*>,
  order: 'asc' | 'desc',
  selected: Array<*>,
  orderBy: string,
  page: number,
  rowsPerPage: number,
  open: boolean,
  password: string,
  app: string,
  tab: string,
  item: string | number | boolean,
  paramKeyForModal: string,
  toolbar: any,
  toolbarExtra: any,
  topToolbar: any,
  toolbarMobile: any,
  tableActions: Object | null,
  disabeldActions: Array<string>,
  total: number | null,
  isFetching: boolean,
  isPreviousData: boolean,
  isLoading: boolean,
  status: string,
  keyCheckToHideIcon?: string,
  openDialog?: boolean,
  hideColumns?: Array<string>,
  prefilledSearch?: string,
  isDwFilterApplied?: boolean
}> = createContext({
  dispatch: {},
  filter: false,
  settings: {},
  record: null,
  order: 'asc',
  selected: [],
  orderBy: '',
  paramKeyForModal: '',
  keyCheckToHideIcon: '',
  page: 0,
  rowsPerPage: 25,
  open: false,
  password: '',
  app: '',
  tab: '',
  item: 0,
  toolbar: null,
  toolbarExtra: null,
  topToolbar: null,
  toolbarMobile: null,
  tableActions: null,
  disabeldActions: [],
  total: 0,
  isFetching: false,
  isPreviousData: false,
  isLoading: false,
  status: '',
  enableFilters: {},
  hideColumns: [],
  prefilledSearch: '',
  isDwFilterApplied: false
});

export type TableListProviderTypes = {
  tableSettings?: Object,
  paramKeyForModal?: string,
  keyCheckToHideIcon?: string,
  data?: {
    data: Array<*> | Object,
    total: number,
    status: string,
    dispatch: Function,
    isFetching: boolean,
    isPreviousData: boolean,
    isLoading: boolean
  } | null,
  source?: string,
  id?: string | boolean,
  onSearch?: Function,
  cache?: boolean,
  children?: any,
  toolbar?: any,
  toolbarExtra?: any,
  topToolbar?: any,
  toolbarMobile?: any,
  tableActions?: Object,
  disabeldActions?: Array,
  newItemSource?: any,
  refresh?: boolean,
  setApp?: string,
  transform?: Function,
  tableParams?: Object,
  extraParams?: Object,
  tableOptions?: Object,
  resetTable?: string,
  modalTitle?: string,
  noFilter?: boolean,
  showSpinnerOnFetching?: boolean,
  keepSpinner?: Boolean,
  localSelection?: boolean,
  disableTableAction?: boolean,
  additionalRefetch?: Function,
  paramIdVal?: string,
  hideColumns?: Array<string>,
  setLoading?: Function
};

const olddata = {};

export const TableListProvider = ({
  tableSettings,
  paramKeyForModal,
  keyCheckToHideIcon,
  data,
  source: tableTab,
  id,
  onSearch,
  cache = true,
  children,
  toolbar,
  toolbarExtra,
  topToolbar,
  toolbarMobile,
  tableActions,
  disabeldActions,
  newItemSource,
  refresh = false,
  setApp: tableApp,
  transform = e => e,
  tableParams = {},
  extraParams = null,
  tableOptions = {},
  resetTable,
  modalTitle,
  noFilter,
  localSelection,
  disableTableAction,
  additionalRefetch,
  paramIdVal,
  enableFilters,
  hideColumns = [],
  hideColumnsCallback,
  renderNoDataContent,
  prefilledSearch = '',
  onRemovePreSeach = () => { },
  showSpinnerOnFetching,
  keepSpinner = false,
  setLoading: setloadingParent,
  setErr,
  setModalAction,
  customCells,
  isSelectedCallback,
  initialOrderBy,
}: TableListProviderTypes): Element<*> => {
  const dispatchRedux = useDispatch();
  const { app, tab, item, settings: settingsProps } = useTableProps({
    tableApp,
    tableTab,
    tableItem: id,
    tableSettings
  });
  const tables = useSelector(rxState => rxState.bsn.tables);
  const system = useSelector(rxState => rxState.bsn.system);
  const { responsive } = tables?.[app]?.[tab] || {};

  const { dispatch: dispatchApp } = useApp();

  let settings = settingsProps;

  const preferences = userPreferences.getTableSettings(app, tab);
  preferences.order = data?.order || preferences.order;
  preferences.orderBy = data?.orderBy || preferences.orderBy;
  preferences.pagination = data?.prePage || preferences.pagination;
  const defaultOrder = hasKey(settings, 'order') ? settings.order : 'asc';
  const defaultOrderBy = hasKey(settings, 'orderBy') ? settings.orderBy : initialOrderBy === undefined ? 'name' : initialOrderBy;
  const defaultPagination = 25;

  const { getFilterFlat, isSelectedAll } = useTableRowSelected();
  const selectFilters = getFilterFlat();
  const { pagination } = useConfig('system', 'table');
  const initialState = {
    open: false,
    searchTerm: prefilledSearch,
    page: 0,
    params:
      settings && hasKey(settings, 'params')
        ? settings.params
        : {
          pagination: {
            page: 0,
            perPage: defaultPagination
          }
        }
  };
  const [reduce, setState] = useReducer(reducer, initialState);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPermanentDelete, setOpenPermanentDelete] = useState(false);
  const [order, setOrder] = useState(preferences.order || defaultOrder);
  const [orderBy, setOrderBy] = useState(preferences.orderBy || defaultOrderBy);
  const [selected, setSelected] = useState([]);
  const [unselected, setUnselected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(preferences.pagination || pagination.rowsPerPage);
  const [filter, setFilter] = useState(false);
  const [password, setPassword] = useState('none');
  const [, setTotal] = useState(null);
  const [newRefresh, setRefresh] = useState(refresh);

  const UseTableData = () => useTableData({ app, tab, item, transform, params: getParams(), options: tableOptions });
  const {
    data: record,
    page: pageUtd,
    prePage: perPageUtd,
    total,
    status,
    dispatch: dispatchUtd,
    isFetching,
    isPreviousData,
    isLoading,
    error: apiError
  } = (d => {
    if (d && hasKey(d, 'data') && d.data !== null) return d;
    return UseTableData();
  })(data);

  const { setOpen: setOpenModal, open: openModal } = useModalConfig(app, tab);

  useEffect(() => {
    if (preferences.order) {
      setOrder(preferences.order);
    }
    if (preferences.orderBy) {
      setOrderBy(preferences.orderBy);
    }
    return () => {
      setOrder(defaultOrder);
      setOrderBy(defaultOrderBy);
    };
  }, [preferences.order, preferences.orderBy]);

  useEffect(() => {
    if (setloadingParent) setloadingParent(isFetching);
  }, [isFetching]);

  useEffect(() => {
    const err = apiError?.response?.data?.description;
    if (err) setErr(err);
  }, [apiError]);

  useEffect(() => {
    if (refresh) setRefresh(true);
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 300);
    };
  }, [refresh]);

  useEffect(() => {
    if (newRefresh) dispatchUtd.refetch();
  }, [newRefresh]);

  useEffect(() => {
    if (resetTable) {
      setOrder(preferences.order || defaultOrder);
      setOrderBy(preferences.orderBy || defaultOrderBy);
      setPage(0);
      setRowsPerPage(preferences.pagination || defaultPagination);
      dispatchUtd.setPrePage(preferences.pagination || defaultPagination);
      setSelected([]);
      dispatchApp.set('system', 'tableRowSelected', []);
      dispatchApp.set('system', 'tableRowSelectedList', []);
      dispatchApp.set('system', 'tableRowUnselected', []);
      dispatchApp.set('system', 'tableRowSelectedCount', 0);
    }
  }, [resetTable]);

  useEffect(() => {
    if (!isLoading) search(true);
  }, [selectFilters, reduce.searchTerm, orderBy, order, isLoading, extraParams]);

  useEffect(() => {
    setSelected([]);
    setPage(0);
    if (selectFilters) {
      dispatchApp.set('system', 'tableRowSelected', []);
      dispatchApp.set('system', 'tableRowSelectedList', []);
      dispatchApp.set('system', 'tableRowUnselected', []);
      dispatchApp.set('system', 'tableRowSelectedCount', 0);
    }
  }, [selectFilters]);

  useEffect(() => {
    if (pageUtd || perPageUtd) {
      setPage(pageUtd);
      setRowsPerPage(perPageUtd);
    }
  }, [pageUtd, perPageUtd]);

  const getSelected = React.useCallback(
    (name: string, type: 'tableRowSelected' | 'tableRowUnselected' = 'tableRowSelected') => {
      const newSelected = localSelection ? [...selected] : system[type];
      const newName = String(name);
      const selectIndex = newSelected.indexOf(newName);
      if (selectIndex > 0 || selectIndex === 0) return newSelected.filter(s => s !== newName);
      if (selectIndex === -1) return [...newSelected, ...Array(newName)];
      return selectIndex;
    },
    [selected, system]
  );

  if (record) {
    const [rowTable] = hasKey(record, 'data') ? record.data : record;

    const settingsAccessLevelHidden =
      rowTable &&
      settings.cells.map(cl => {
        if (cl.id === 'access_level') {
          const { dir_sync, billing, marketing_material, purchasing, tax } = Object.fromEntries(
            Object.keys(rowTable.access_level).map(key => [key, key])
          );
          const [vMktg, vBuy, vBill, vTax, vDirSync] = cl.subLabel;
          const accessLevelLabels = {
            [marketing_material]: vMktg,
            [purchasing]: vBuy,
            [billing]: vBill,
            [tax]: vTax,
            [dir_sync]: vDirSync
          };

          return {
            ...cl,
            subLabel: Object.keys(accessLevelLabels)
              .filter(al => typeof rowTable.access_level[al] === 'boolean')
              .map(al => accessLevelLabels[al])
          };
        }
        return cl;
      });

    const rowKeys = rowTable && Object.keys(rowTable);
    const keysHidden = rowTable && rowKeys.filter(row => rowTable[row] === 'hidden');
    if (settings) {
      const newCells = rowTable
        ? settingsAccessLevelHidden.filter(row => !keysHidden.includes(row.id))
        : settings.cells;
      settings = { ...settings, cells: newCells };
    }
  }

  const dispatch = {};

  dispatch.handleRequestSort = useCallback(
    (event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      const newOrder = isDesc ? 'asc' : 'desc';
      const newParams = {
        ...reduce.params,
        sort: {
          field: property,
          order: newOrder
        }
      };
      setOrder(newOrder);
      setOrderBy(property);
      setParams(newParams);
      if (dispatchUtd.setOrder) dispatchUtd.setOrder(newOrder);
      if (dispatchUtd.setOrderBy) dispatchUtd.setOrderBy(property);
      if (dispatchUtd.setSort) dispatchUtd.setSort(property, newOrder); // sorting for Micro Training table

      userPreferences.setTableSettings(app, tab, { order: newOrder, orderBy: property });
    },
    [order, orderBy, reduce.params, app, tab, dispatchUtd, userPreferences, setOrder, setOrderBy, setParams]
  );

  dispatch.handleSelectAllClick = useCallback(
    event => {
      if (event.target.checked) {
        let newSelecteds = record.map(n => n.id);
        setSelected(newSelecteds);
        newSelecteds = settings?.selectAll ? newSelecteds : ['all'];
        if (!localSelection) {
          dispatchApp.set('system', 'tableRowSelected', newSelecteds);
          dispatchApp.set('system', 'tableRowSelectedList', record);
        }
        return;
      }
      setSelected([]);
      setUnselected([]);
      if (!localSelection) {
        dispatchApp.set('system', 'tableRowSelected', []);
        dispatchApp.set('system', 'tableRowSelectedList', []);
        dispatchApp.set('system', 'tableRowUnselected', []);
      }
    },
    [record, localSelection]
  );

  dispatch.handleClick = useCallback(
    (event, name, row) => {
      if (system.tableRowSelected[0] === 'all') {
        const newUnselected = getSelected(name, 'tableRowUnselected');
        setUnselected(newUnselected);
        if (!localSelection) dispatchApp.set('system', 'tableRowUnselected', newUnselected);
      } else {
        const newSelected = getSelected(name, 'tableRowSelected');
        setSelected(newSelected);
        if (!localSelection) {
          dispatchApp.set('system', 'tableRowSelected', newSelected);
          const oldList = [...system.tableRowSelectedList];
          const newList = event.target.checked ? oldList.concat([row]) : oldList.filter(el => el.id !== row.id);
          dispatchApp.set('system', 'tableRowSelectedList', newList);
        }
      }
    },
    [dispatchApp, localSelection, getSelected]
  );

  dispatch.handleRadio = useCallback(
    (event, name) => {
      setSelected([name]);
      dispatchApp.set('system', 'tableRowSelected', [name]);
    },
    [dispatchApp]
  );

  dispatch.handleChangeRowsPerPage = useCallback(
    event => {
      dispatchUtd.setPrePage(+event.target.value);
      setRowsPerPage(+event.target.value);
      setPage(0);
      const value = event?.target?.value; // this line for event pooling
      userPreferences.setTableSettings(app, tab, { pagination: value });
    },
    [app, tab]
  );

  dispatch.setNewPage = useCallback((e, p) => {
    dispatchUtd.setPage(Math.floor(p));
    setPage(Math.floor(p));
    dispatchRedux(resetCreateUserMatchingEmailError());
  }, []);

  dispatch.setOpen = useCallback(e => {
    if (!e) dispatchUtd.refetch();
    setState({ type: 'SETOPEN', payload: e });
  }, []);

  dispatch.setOpenDialog = useCallback(e => setOpenDialog(e), []);

  dispatch.setOpenPermanentDelete = useCallback(e => setOpenPermanentDelete(e), []);

  dispatch.setFilter = useCallback(e => setFilter(e), []);

  dispatch.setPassword = useCallback(e => setPassword(e), []);

  dispatch.setTotal = useCallback(e => setTotal(e), []);

  dispatch.setRefresh = setRefresh;

  dispatch.isSelected = useCallback(
    name => {
      if (isSelectedAll) return unselected.indexOf(name) === -1;
      return selected.indexOf(name) !== -1;
    },
    [selected, unselected]
  );

  dispatch.search = useCallback((term: string) => {
    onSearch(term);
    setState({ type: 'SETSEARCH', payload: term });
    if (prefilledSearch && term !== prefilledSearch) onRemovePreSeach();
  }, []);

  function search(reset = true) {
    const newParams = getParams(reset);
    if (!data) dispatchUtd.setFilter(newParams);
    const type = reset ? 'SETSEARCHPARAMS' : 'SETPARAMS';

    const controlledPages = [
      {
        app: 'myDashboard',
        tabs: ['newsletters']
      },
      {
        app: 'myCompany',
        tabs: ['policies', 'otherPolicies', 'sraDocuments', 'workPlan', 'darkWebMonitoring']
      },
      {
        app: 'clients',
        tabs: [
          'policies',
          'otherPolicies',
          'documentsTemplates',
          'disasterRecovery',
          'documentsServiceProvider',
          'otherDocuments',
          'trackSecurityIncidents',
          'trackServerRoomAccess',
          'darkWebMonitoring'
        ]
      },
      {
        app: 'admin',
        tabs: ['dashboard']
      }
    ];
    const page = controlledPages.some(element => {
      return element.app === app && element.tabs.some(subElement => subElement === tab);
    })
      ? pageUtd
      : 0;
    const payload = type === 'SETSEARCHPARAMS' ? { page, newParams } : newParams;
    setState({ type, payload });
  }

  function getParams(reset) {
    return {
      ...(reset ? initialState.params : reduce.params),
      _filter: noFilter ? '' : getFielterFields(),
      _sort: orderBy,
      sort: {
        field: orderBy,
        order
      },
      pagination: {
        page: reset ? 0 : reduce?.params?.pagination?.page || 0,
        perPage: rowsPerPage
      },
      ...extraParams
    };
  }

  function getFielterFields() {
    if (!settings?.searchFields && selectFilters === '' && isObjectEmpty(tableParams)) return '';
    const fields =
      reduce.searchTerm.length > 0 && settings?.searchFields.map(f => `${f}:${reduce.searchTerm}`).join(',');
    const filterFields = [];
    if (fields) filterFields.push(fields);
    if (selectFilters) filterFields.push(selectFilters);
    Object.keys(tableParams).forEach(e => filterFields.push(tableParams[e]));
    return filterFields.join(',');
  }

  const getTabName = React.useCallback(tab => {
    const tabName = { workPlan: 'Work Plan' };
    return Reflect.get(tabName, tab) ?? 'item';
  }, []);

  dispatch.delete = useCallback(() => {
    const rowList = system.tableRowSelected?.length ? system.tableRowSelected : selected;
    const onSuccess = res => {
      setPage(0);
      dispatchUtd.setPage(0);
      setSelected([]);
      dispatchApp.set('system', 'tableRowSelected', []);
      dispatchApp.set('system', 'tableRowSelectedList', []);
      const tabName = getTabName(tab);
      enqueueAlertSnackbar(
        res.data?.status
          ? `${res.data.status}`
          : `${rowList.length} ${rowList.length === 1 ? tabName : `${tabName}s`} deleted`,
        { props: { severity: 'error' } }
      );
    };
    const onError = error => {
      enqueueAlertSnackbar(
        error.response?.data?.message ? `${error.response.data.message}` : `${error.response.data.description}`,
        { props: { severity: 'error' } }
      );
    };
    const onFinished = () => {
      if (dispatchUtd?.refetchOnDeletion) {
        dispatchUtd.refetchOnDeletion();
      }
      dispatchUtd.refetch();
    };
    if (app === 'clients' && tab === 'policies') {
      dashboard
        .deletePolicies({ app, id, data: { id: rowList } })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else if (app === 'clients' && tab === 'otherPolicies') {
      dashboard
        .deleteOtherPolicies({ app, id, data: { id: rowList } })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else if (
      ['clients', 'myCompany'].includes(app) &&
      ['disasterRecovery', 'documentsServiceProvider', 'otherDocuments', 'sraDocuments', 'workPlan'].includes(tab)
    ) {
      dashboard
        .deleteDocuments({ app, documentType: tab, id, data: { id: rowList } })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else if (['clients', 'myCompany'].includes(app) && tab === 'workPlanFinding') {
      dashboard
        .deleteWorkPlanFinding({ app, id, data: { id: rowList } })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else if (app === 'clients' && tab === 'trackSecurityIncidents') {
      bsnClientServices
        .deleteSecurityIncident({ clientID: id, tracksID: rowList })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else if (app === 'clients' && tab === 'trackServerRoomAccess') {
      bsnClientServices
        .deleteServerRoomAccess({ clientID: id, tracksID: rowList })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else {
      dataProvider
        .delete(app, `${String(tab)}/${String(id)}`, null, { id: rowList })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    }
  }, [app, tab, id, getTabName, selected, system.tableRowSelected]);

  dispatch.resetPhishingItem = useCallback(
    currentSelect => {
      const rowList = currentSelect || system.tableRowSelected;
      dataProvider
        .post(app, `phishingResetCampaign/${id}`, null, { ids: rowList })
        .then(res =>
          enqueueAlertSnackbar(
            `Campaign has been reset successfully for ${rowList.length} ${rowList.length === 1 ? 'User' : 'Users'}`,
            { props: { severity: 'error' } }
          )
        )
        .catch(error =>
          enqueueAlertSnackbar(`${error.response.data.code}: ${error.response.data.description}`, {
            props: { severity: 'error' }
          })
        )
        .finally(() => dispatchUtd.refetch());
    },
    [app, tab]
  );

  dispatch.endCampaign = useCallback(() => {
    const rowList = system.tableRowSelected;
    dataProvider
      .post(app, `phishingReportsCampaign/${id}`, null, { type: 'endCampaign', ids: rowList })
      .then(res =>
        enqueueAlertSnackbar(`${rowList.length} ${rowList.length === 1 ? 'item' : 'items'} was ended`, {
          props: { severity: 'error' }
        })
      )
      .catch(error => {
        enqueueAlertSnackbar(`It was not possible end campaign due to: ${error.response.data.description}`);
      })
      .finally(() => dispatchUtd.refetch());
  }, [app, tab]);

  dispatch.setSelected = (selection: Array<*>) => {
    setSelected(selection);
    if (!localSelection) {
      dispatchApp.set('system', 'tableRowSelected', selection);
      dispatchApp.set('system', 'tableRowSelectedList', selection);
    }
  };

  function setPage(p) {
    setState({ type: 'SETPAGE', payload: p });
  }

  function setParams(params) {
    setState({ type: 'SETPARAMS', payload: params });
  }

  return (
    <TableListContext.Provider
      value={{
        app,
        tab,
        item,
        dispatch,
        filter,
        settings,
        record,
        order,
        selected,
        orderBy,
        page: reduce.page,
        rowsPerPage,
        open: reduce.open,
        openDialog,
        openPermanentDelete,
        password,
        toolbar,
        toolbarExtra,
        topToolbar,
        toolbarMobile,
        tableActions,
        disabeldActions,
        total,
        status,
        paramKeyForModal,
        keyCheckToHideIcon,
        isFetching,
        isPreviousData,
        isLoading,
        setOpenModal,
        openModal,
        modalTitle,
        disableTableAction,
        additionalRefetch,
        paramIdVal,
        hideColumns,
        hideColumnsCallback,
        renderNoDataContent,
        enableFilters,
        responsive,
        isSelectedCallback,
        prefilledSearch: reduce.searchTerm,
        showSpinnerOnFetching,
        setModalAction,
        keepSpinner,
        customCells,
        isDwFilterApplied: !!reduce.params?.dw_filter
      }}
    >
      {children}
    </TableListContext.Provider>
  );
};

function reducer(previousState, { type, payload }) {
  switch (type) {
    case 'SETOPEN': {
      return { ...previousState, open: payload };
    }

    case 'SETSEARCH': {
      return { ...previousState, searchTerm: payload };
    }

    case 'SETPAGE': {
      return { ...previousState, page: payload };
    }

    case 'SETPARAMS': {
      return { ...previousState, params: payload };
    }

    case 'SETSEARCHPARAMS': {
      const newState = previousState;
      newState.params = payload.newParams;
      newState.page = payload.page;
      return newState;
    }

    default: {
      return { ...previousState, ...payload };
    }
  }
}

TableListProvider.defaultProps = {
  data: null,
  source: null,
  id: null,
  tableSettings: null,
  onSearch: e => e,
  cache: true,
  children: null,
  toolbar: null,
  toolbarExtra: null,
  topToolbar: null,
  toolbarMobile: null,
  tableActions: null,
  disabeldActions: [],
  newItemSource: null,
  refresh: false,
  paramKeyForModal: '',
  keyCheckToHideIcon: '',
  setApp: null,
  transform: e => e,
  tableParams: {},
  tableOptions: {},
  resetTable: null,
  modalTitle: '',
  noFilter: false,
  localSelection: false,
  disableTableAction: false,
  additionalRefetch: null,
  paramIdVal: '',
  hideColumns: []
};

export const useTableList = () => useContext(TableListContext);
