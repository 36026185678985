// @flow

import React, { memo } from 'react';
import styled from 'styled-components';
import { Typography } from '@trustsecurenow/components-library';
import { LoadingStyled } from '../../types';

const Loading = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: ${({ fetched }) => (fetched ? '215px' : '260px')};
  height: ${({ fetched }) => (fetched ? 'auto' : 'calc(100vh - 300px)')};
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin: auto;
  }
`;

type TableListNoResultstypes = {
  hasRecords: boolean,
  fetched: boolean,
  colNumber: Number
};

const TableListNoResults = ({ fetched, colNumber, emptyView, isSearchApplied, isDwFilterApplied }: TableListNoResultstypes) => {
  return (
    <tr style={{ opacity: 1, background: 'inherit' }}>
      <td colSpan={colNumber}>
        <Loading fetched={fetched && !emptyView}>
        {fetched ? (
            ((!isSearchApplied && !isDwFilterApplied) && emptyView) || <h2>No results.</h2>
        ) : (
            <LoadingStyled centerAlign />
        )}
        </Loading>
      </td>
    </tr>
  );
};

export default memo<TableListNoResultstypes>(TableListNoResults);
