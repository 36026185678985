// @flow
import React from 'react';
import { Dialog, Container, LazyIcon } from 'components';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { Button, Link, Typography } from '@trustsecurenow/components-library';

type Props = {
  onClose: Function,
  onSubmit: Function,
  config: {
    open: boolean,
    title: string,
    message: string,
  },
};

const ConfirmModal = ({ onClose, config, onSubmit, ...rest }: Props) => {
  return (
    <Dialog
      open={config?.open || false}
      setOpen={onClose}
      maxWidth="sm"
      onlyOk
      closeButton
      variant="standard"
      {...rest}
    >
      <Container.Grid container direction="column" alignItems="center" justify="center" p={3}>
        <Container.Grid item>
          <Typography variant='h2'>{config?.title}</Typography>
        </Container.Grid>

        <Container.Grid item alignItems="center" pb={2} pl={2} pr={2}>
          <Typography variant='subtitle1' component='p' textAlign='center'>{config?.message}</Typography>
        </Container.Grid>

        <Container.Grid item pb={1} pt={2}>
          <Button sx={{ width: 170 }} color="success" onClick={onSubmit} disabled={!config?.open}>
            Yes, continue
          </Button>
        </Container.Grid>

        <Link component="button" onClick={onClose}>
          Cancel
        </Link>
      </Container.Grid>
    </Dialog>
  );
};

export default React.memo(ConfirmModal);