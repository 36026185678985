import React, { useState } from 'react';
import clients from 'helpers/apis/clients';
import { enqueueAlertSnackbar, Button, ArrowDownloadIcon } from '@trustsecurenow/components-library';
import { useDispatch } from 'react-redux';
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction';

type ClientsUsersDownloadReportButtonType = {
  client_id: string
};

const ClientsUsersDownloadReportButton = ({ client_id, color }: ClientsUsersDownloadReportButtonType) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const printClientReport = () => {
    setLoading(true);
    clients
      .getClientReport(client_id)
      .then(res => window.open(res.data.url))
      .catch(error => enqueueAlertSnackbar(`${error.response}`, { props: { severity: 'error' } }))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        printClientReport();
        dispatch(resetCreateUserMatchingEmailError());
      }}
      sx={{ mr: 1 }}
      loading={loading}
      color={color}
      startIcon={<ArrowDownloadIcon />}
    >
      {!loading ? 'Download Client Report' : 'Loading...'}
    </Button>
  );
};

export default ClientsUsersDownloadReportButton;
