// @flow
import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { useLocation, useAccess } from 'hooks';
import { Button, enqueueAlertSnackbar, CircularProgress } from '@trustsecurenow/components-library';
import TeamsAppDeploymentAlert from 'components/layout/TeamsAppDeploymentAlert';
import { Typography } from 'components/types';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { useDashboard } from './DashboardContext';
import { LazyIcon } from '../icons';
import DashboardToDoList from './DashboardToDoList';
import DashboardChartBarGroupWidget from './DashboardChartBarGroupWidget';
import CircleCharts from './DashboardCircleChartWidget';
import DashboardBoxTitle from './DashboardBoxTitle';
import HeadTitle from '../mobile/HeadTitle';
import CheckboxesTagsGeneric from '../forms/CheckboxesTagsGeneric';
import clients from '../../helpers/apis/clients';

import { useId } from 'hooks';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import TeamsAppUserNotifiedDialog from 'apps/clients/presets/Modals/TeamsAppUserNotifiedDialog';
import TeamsAppDeploymentInstructionsDialog from 'apps/clients/presets/Modals/TeamsAppDeploymentInstructionsDialog';
import TeamsAppEngagementReminderDialog from 'apps/clients/presets/Modals/TeamsAppEngagementReminderDialog';
import downloadFile from 'utils/downloadFile';
import { useSelector } from 'react-redux';

const Frame = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
  min-height: 480px;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }

  ${({ isFetching }) =>
    isFetching &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
`;

const Row1 = styled(Row)`
  flex: 1 64%;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-flow: column;
    width: 100%;
  }
`;

const Row2 = styled(Row)`
  flex: 1 36%;
  flex-flow: column;
  flex-direction: column;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: 100px;
  flex: 1 ${({ shrink }) => shrink}%;
  padding: 10px;

  ${props => props.theme.breakpoints.down('sm')} {
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
    &.dashboard-table {
      padding: 0 10px 10px;
    }
  }

  @media only screen and (min-width: 1235px) {
    flex: 1 ${({ shrinkLg }) => shrinkLg}%;
  }
  ${({ opacity }) => opacity && `opacity: 0.1`};
`;

const Content = styled.div`
  flex: 1 100%;
  background-color: var(--backgroundPaper);
  padding: 20px;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    box-sizing: border-box;

    &.group-widget {
      padding: 20px 0;
    }
  }
`;

const TopFrame = styled.div`
  display: flex;
  flex-flow: row;
  margin: calc(var(--spacing) * 1.2);

  ${({ isFetching }) =>
    isFetching &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const FlexRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LeftColumn = styled(FlexRow)`
  flex: 1 63.5%;
`;

const MiddleColumn = styled(FlexRow)`
  flex: 1 1.5%;
`;

const RightColumn = styled(FlexRow)`
  flex: 1 35%;
  background: var(--backgroundPaper);
  justify-content: flex-end;
  ${({ opacity }) => opacity && `opacity: 0.1`};
`;


const Dashboard = ({
  clientConfig,
  chartLine,
  externalDataBreaches,
  pieChartGrid,
  toDoList,
  essChart,
  rankTable,
  hasData,
  resource,
  hidden,
  tags,
  isFetching
}) => {
  const { dispatch } = useDashboard();
  const muiTheme = useTheme();
  const mobileView = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { open, box } = dispatch.getBox();
  const { item } = useLocation('clientId');
  const { app } = useLocation();
  const [loading, setLoading] = useState(false);
  const [userConfig, setUserConfig] = useState(null);
  const isClientsApp = app === 'clients';
  const { overall, leaderboard } = isClientsApp ? useSelector(state=> state?.bsn?.clients) : useAccess({ type: 'feature' });
  // TODO: remove When the other Dashboards are returning the hidden key
  const isDashboard = {
    avg_micro_quiz: !hidden?.avg_micro_quiz,
    ess: !hidden?.ess,
    ess_trend: !hidden?.ess_trend,
    external_data_breaches: !hidden?.external_data_breaches,
    improve_your_ESS: !hidden?.improve_your_ESS,
    leaderboard: !hidden?.leaderboard,
    micro_quiz_taken: !hidden?.micro_quiz_taken,
    overall: !hidden?.overall,
    phishing_attack_fail_rate: !hidden?.phishing_attack_fail_rate,
    security_training: !hidden?.security_training
  };

  const showBox = (checkOpen, label, compareLabel) => !(checkOpen && replaceAll(label) === replaceAll(compareLabel));

  const partnerId = useId({ key: 'partnerId' });
  const [modalState, setModalState] = useState({
    submitting: false,
    open: false,
    clientId: item,
    clientName: '',
    titleText: '',
    usersList: [],
  });

  const handleCloseModal = () => {
    setModalState(prevModalState => ({ ...prevModalState, open: false, titleText: '' }));
  };

  const handleOnTeamsAlertClickHere = () => {
    setModalState(prevModalState => ({ ...prevModalState, open: true }));
  };

  const resetModal = () => {
    setModalState(prevState => ({
      ...prevState,
      open: false,
      loading: false,
      titleText: ''
    }));
  };

  const handleSubmitTeamsAppDeploymentInstructions = async () => {
    const { clientId } = modalState;
    setModalState(prev => ({ ...prev, loading: true }));

    try {
      await bsnclientservices.sendTeamsAppAnnouncement(clientId);
      resetModal();
      setModalState(prev => ({ ...prev, open: true, titleText: 'Users Notified' }));
    } catch (error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal();
    }
  };

  const handleSubmitSendTeamsAppEngagementReminder = async filteredUserIds => {
    const { clientId } = modalState;
    setModalState(prev => ({ ...prev, loading: true }));

    try {
      await bsnclientservices.sendTeamsAppAnnouncement(clientId, filteredUserIds);
      resetModal();
      setModalState(prev => ({ ...prev, open: true, titleText: 'Reminder Was Sent' }));
    } catch (error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal();
    }
  };

  const getTeamsAppUnregisteredUsers = async () => {
    const { clientId } = modalState;
    try {
      const unregisteredUsers = await bsnclientservices.getTeamsAppUnregisteredUsers(clientId);
      setModalState(prev => ({
        ...prev,
        loading: false,
        usersList: unregisteredUsers?.data?.users
      }));
    } catch (error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal();
    }
  };

  const onClick = () => {
    const tagsIDs = tags.selectedTags.map(tag => {
      let key = tag.key;
      if (key === 'None') {
        // '0' is the id of the empty tag in the database
        key = '0';
      } else {
        /** decoding the base64 tags keys into database ids */
        /** It have to be decode base64 strings three times to get the real database IDs */
        key = window.atob(key);
        key = window.atob(key);
        key = window.atob(key);
      }
      return key;
    });
    setLoading(true);
    clients
      .getESSReport(item, tagsIDs.join(','))
      .then(res => {
        setLoading(false);
        console.log({ res });
        setTimeout(() => {
          downloadFile(res.data.url);
        }, 1000);
      })
      .catch(error => {
        enqueueAlertSnackbar(error.response?.data?.description || error.message, {
          props: { severity: 'error' }
        });
        setLoading(false);
      });
  };

  // Get user config
  const getUserConfig = useCallback(async () => {
    try {
      const userConfigData = await usersAPI.getUserConfig();
      setUserConfig(userConfigData.data)
    } catch (error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal();
    }
  }, []);

  // Get User configs
  useEffect(() => {
    if (resource === 'myDashboard') {
      getUserConfig();
    }
  }, [resource, getUserConfig]);

  return (
    <>
      <Hidden mdUp>
        <HeadTitle title={'Dashboard'} />
      </Hidden>

      {resource === 'myDashboard' && Boolean(userConfig?.show_teams_user_notification_banner) && (
        <TopFrame>
          <TeamsAppDeploymentAlert variant="user" userRedirectionUrl={userConfig?.teams_app_url} isHSN={Boolean(userConfig?.is_hsn)} />
        </TopFrame>
      )}

      {(resource === 'myCompany' || resource === 'clients') && (
          <TopFrame>
            {resource === 'clients' &&
            clientConfig.teams_app_deployment_percent !== undefined &&
              clientConfig.teams_app_deployment_percent !== null &&
              clientConfig.teams_app_deployment_percent !== 100 && (
                <>
                  <LeftColumn>
                    <TeamsAppDeploymentAlert
                      deploymentPercent={clientConfig.teams_app_deployment_percent}
                      variant="client"
                      onClientClickHere={handleOnTeamsAlertClickHere}
                      isHSN={Boolean(clientConfig?.is_hsn)}
                    />

                    <TeamsAppDeploymentInstructionsDialog
                      state={{
                        ...modalState,
                        isHSN: Boolean(clientConfig?.is_hsn),
                        open:
                          modalState.open && !modalState.titleText && clientConfig.teams_app_deployment_percent === 0
                      }}
                      resetModal={handleCloseModal}
                      onSubmit={handleSubmitTeamsAppDeploymentInstructions}
                    />

                    <TeamsAppUserNotifiedDialog
                      state={{ ...modalState, open: modalState.open && modalState.titleText === 'Users Notified', isHSN: Boolean(clientConfig?.is_hsn) }}
                      resetModal={handleCloseModal}
                    />

                    <TeamsAppEngagementReminderDialog
                      state={{
                        ...modalState,
                        isHSN: Boolean(clientConfig?.is_hsn),
                        open:
                          modalState.open && !modalState.titleText && clientConfig.teams_app_deployment_percent !== 0
                      }}
                      resetModal={handleCloseModal}
                      onSubmit={handleSubmitSendTeamsAppEngagementReminder}
                      getTeamsAppUnregisteredUsers={getTeamsAppUnregisteredUsers}
                    />

                    <TeamsAppUserNotifiedDialog
                      state={{ ...modalState, open: modalState.open && modalState.titleText === 'Reminder Was Sent', isHSN: Boolean(clientConfig?.is_hsn) }}
                      resetModal={handleCloseModal}
                    />
                  </LeftColumn>
                  {!mobileView && (<MiddleColumn /> )}
                </>
              )}
            {!mobileView && (
          <RightColumn>
            <div
              style={{
                minWidth: 200,
                marginRight: 30,
                minHeight: 40,
                marginTop: 10
              }}
            >
              {tags && <CheckboxesTagsGeneric options={tags.options} onChange={tags.onChange} selectedTags={tags.selectedTags} />}
            </div>
            <Button height={40} pr={3} mr={3} mt={1} disabled={loading} onClick={onClick}>
              {loading ? (
                <>
                  <CircularProgress size={18} thickness={2} />
                  &nbsp;&nbsp;Loading...
                </>
              ) : (
                <>
                  <LazyIcon color="colorCommonWhite" component="Download" mr={1} />
                  Download ESS Report
                </>
              )}
            </Button>
          </RightColumn>
        )}
        </TopFrame>
      )}

      <Frame isFetching={isFetching}>
        {isDashboard.ess && isDashboard.ess_trend && isDashboard.external_data_breaches && (
          <Row1>
            <CircleCharts
              essChart={essChart}
              isDashboard={isDashboard}
              showBox={showBox}
              chartLine={chartLine}
              externalDataBreaches={externalDataBreaches}
              box={box}
              open={open}
              size={['clients', 'myCompany'].includes(app) ? 'medium' : 'default'}
            />
            {isDashboard.external_data_breaches && (
              <Box shrink="60" opacity={open && showBox(open, "Training Statistics", box)}>
                <Content className="group-widget">
                  {pieChartGrid && (
                    <DashboardChartBarGroupWidget
                      chart={pieChartGrid}
                      description={

                        <Typography.p style={{width: 400}}>
                          <Typography.Base component="span">
                            <b>Phishing Fail Rate:</b>
                            {app === 'myCompany' || app === 'clients'
                              ? ' The Phishing Fail Rate is the percentage of failed phishing simulations across all active users. You want this number to be as low as possible.'
                              : ' The Phishing Fail Rate is the percentage of failed phishing simulations. You want this number to be as low as possible.'}
                          </Typography.Base>
                          <br />
                          <Typography.Base component="span">
                            <b>Average Security Training Score:</b>
                            {app === 'myCompany' || app === 'clients'
                              ? ' The average score from all active annual training courses that impact ESS across all active users.'
                              : ' The average score from all active annual training courses that impact ESS.'}
                          </Typography.Base>
                          <br />
                          <Typography.Base component="span">
                            <b>Average Micro Training Score:</b>
                            {app === 'myCompany' || app === 'clients'
                              ? ' The average score of all Micro Training quizzes taken across all active users.'
                              : ' The average score of all Micro Training quizzes taken.'}
                          </Typography.Base>
                          <br />
                          <Typography.Base component="span">
                            <b>
                              {app === 'myCompany' || app === 'clients'
                                ? 'Average Micro Training Completion'
                                : 'Micro Training Completion'}
                              :
                            </b>
                            {app === 'myCompany' || app === 'clients'
                              ? ' The average percentage of active Micro Training quizzes that have been completed across all active users.'
                              : ' The total number of active Micro Training quizzes that have been completed out of the active 80.'}
                          </Typography.Base>
                        </Typography.p>
                      }
                      title="Training Statistics"
                    />
                  )}
                </Content>
              </Box>
            )}
          </Row1>
        )}
        {Boolean(toDoList || overall || leaderboard) && (
          <Row2>
            {resource === 'myDashboard' && isDashboard.improve_your_ESS && (
              <Hidden smDown>
                <Box shrink="40" shrinkLg="50" opacity={open}>
                  <Content>{toDoList && <DashboardToDoList record={toDoList} />}</Content>
                </Box>
              </Hidden>
            )}

            {rankTable && (isDashboard.leaderboard || isDashboard.overall) && (
              <Box shrink="60" shrinkLg="50" opacity={open} className={'dashboard-table'}>
                <Content>
                  <DashboardBoxTitle label={rankTable.title} fontSize={13} />
                  {rankTable.component}
                </Content>
              </Box>
            )}
          </Row2>
        )}
      </Frame>
    </>
  );
};

function replaceAll(str) {
  if (str.replaceAll) {
    return str.replaceAll(' ', '_').toLowerCase();
  }

  return str.replace(/\s/g, '-').toLowerCase();
}

Dashboard.defaultProps = {
  clientConfig: null,
  chartLine: null,
  pieChartGrid: null,
  toDoList: null,
  essChart: null,
  essDescription: null,
  rankTable: null,
  externalDataBreaches: null,
  hidden: null,
  tags: null
};

export default Dashboard;
