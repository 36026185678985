// @flow
import React, { type ComponentType, useState } from 'react';
import styled from 'styled-components';
import { Link as LinkMui } from 'react-router-dom';
import { useRecord } from 'hooks';
import { useApp, getActiveTab } from 'helpers';
import { useSelector } from 'react-redux';
import { hasKey } from 'helpers/utils';
import Typography from '../../types/Typography';
import ChartHalfCircle from '../../charts/ChartHalfCircle';
import MiniHalfChartPopover from './MiniHalfChartPopover';
import { useLayoutContext } from '../LayoutContext';
import { useMediaQuery, useTheme } from '@material-ui/core';

export const MenuProfile: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--colorCommonWhite);

  ${props => props.theme.breakpoints.down('sm')} {
    margin-bottom: 25px;
  }
`;

export const MenuProfileInformation: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const MenuProfileName: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  > p,
  h3 {
    color: var(--colorCommonWhite);
    padding: 2px 0;
    margin: 0;
    line-height: 18px;
    word-break: break-word;
    max-height: 400px;

    ${props => props.theme.breakpoints.down('sm')} {
      margin: 0;
    }
  }
`;

export const MiniHalfChart: ComponentType<*> = styled.div`
  width: 20%;
  padding-top: calc(var(--spacing) * 1);
  cursor: pointer;

  path {
    stroke-width: calc(var(--spacing) * 1.4);
  }

  .menu-profile-half-circle {
    ${props => props.theme.breakpoints.up('md')} {
      svg {
        &:hover {
          filter: drop-shadow(0 0 8px #6392b1);
        }
      }
    }
  }
`;

export const Link: ComponentType<*> = styled(LinkMui)`
  font-size: 9px;
  && {
    color: var(--colorCommonWhite);
    text-decoration: none;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      display: none;
      letter-spacing: 0 !important;

      & > p {
        font-size: 12px !important;
      }
    }
  }
`;

// export const MenuProfileSearch: ComponentType<*> = styled(InputBase)`
//   color: var(--colorCommonWhite);
//   background: ${({ background }) =>
//     background
//       ? 'var(--colorPrimaryDark2)'
//       : '#00000052'};
//   padding: 8px 15px;
//   margin: 8px 0;

//   svg {
//     font-size: 20px;
//     margin-right: 8px;
//   }
// `;

const { h3: H3, p: P } = Typography;

const MenuProfileInfo = () => {
  const profile = useRecord('user', 'profile');
  const { dispatch } = useApp();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { setPopoverOpen, chartQuery } = useLayoutContext();
  const [anchorEl, setAnchorEl] = useState();

  const {
    tabs,
    user: { access },
    system: { tabCurrent, item, location: locationCurrent }
  } = useSelector(rxState => rxState.bsn);
  const location = 'user';

  const onClick = e => {
    dispatch.changeApp(location, locationCurrent, getActiveTab(tabs[location]), tabCurrent, item);
  };

  const handleShowPopover = event => {
    if (mobileView) return;
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverOpen(false);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'ess-state-popover' : undefined;

  const essChart = chartQuery?.data?.data?.ess;

  return (
    <MenuProfile>
      <MenuProfileInformation>
        {profile && profile?.first_name && (
          <>
            <MenuProfileName>
              <H3 fontSize={16}>{`${profile.first_name} ${profile.last_name}`}</H3>
              <Link to="/user/profile" onClick={() => onClick()}>
                <P color="colorCommonWhite" fontSize={9}>
                  Edit profile
                </P>
              </Link>
            </MenuProfileName>
            {hasKey(profile, 'hidden') && profile.hidden.ess_chart ? (
              ''
            ) : (
              <>
                <MiniHalfChart onClick={handleShowPopover} aria-describedby={id}>
                  {/* TODO: Change essChart API for a Object */}
                  {
                    essChart && (
                      <ChartHalfCircle
                        title="Employee Secure Score (ESS)"
                        chart={{
                          chartData: [{ name: essChart.display_value, value: (Number(essChart.value) / 800) * 100 }]
                        }}
                        isActive={openPopover}
                        className={'menu-profile-half-circle'}
                      />
                    )
                  }
                </MiniHalfChart>
                {!mobileView && (
                  <MiniHalfChartPopover id={id} open={openPopover} anchorEl={anchorEl} onClose={handleClosePopover} />
                )}
              </>
            )}
          </>
        )}
      </MenuProfileInformation>
      {/* Next Level Search */}
      {/* <MenuProfileSearch
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon /> Search anything
          </InputAdornment>
        }
      /> */}
    </MenuProfile>
  );
};

export default MenuProfileInfo;
