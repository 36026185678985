import {
  CompanyIcon,
  ContentAdminIcon,
  DashboardIcon,
  DocSearchIcon,
  FlagIcon,
  NewsPaperIcon,
  PeopleIcon,
  SettingsIcon,
  SliderIcon,
  UserSecretIcon
} from '@trustsecurenow/components-library';

export const RESOURCES = [
  {
    name: 'myDashboard',
    icon: DashboardIcon,
    label: 'My Dashboard'
  },
  {
    name: 'newsfeed',
    icon: NewsPaperIcon,
    label: 'Newsfeed',
    hasDot: true,
    hasMobileSublist: false
  },
  {
    name: 'myCompany',
    icon: CompanyIcon,
    label: 'My Company'
  },
  {
    name: 'clients',
    icon: SliderIcon,
    label: 'Manage Clients',
    hasMaskMode: true
  },
  {
    name: 'partnerProfile',
    icon: PeopleIcon,
    label: 'Partner Profile'
  },
  {
    name: 'reports',
    icon: ContentAdminIcon,
    label: 'Reports',
    hasMobileSublist: false
  },
  {
    name: 'getStarted',
    icon: FlagIcon,
    label: 'Getting Started',
    hasMobileSublist: false
  },
  {
    name: 'admin',
    icon: SettingsIcon,
    label: 'Admin',
    hasMobileSublist: false
  },
  {
    name: 'contentadmin',
    icon: ContentAdminIcon,
    label: 'Content Admin',
    hasMobileSublist: false
  },
  {
    name: 'auditlog',
    icon: DocSearchIcon,
    label: 'Audit Log',
    options: {
      label: 'Audit Log',
      hasMobileSublist: false
    }
  },
  {
    name: 'instantScan',
    icon: UserSecretIcon,
    label: 'DW Instant Scan',
    tooltip: 'Dark Web Instant Scan',
    hasMaskMode: true,
    hasMobileSublist: false
  }
];
