import React, { useEffect, useRef } from 'react';
import { LazyIcon } from '../../../icons';
import {
  ReferralCodeModalIconWrapper,
  ReferralCodeModalText,
  ReferralCodeModalTitle,
  ReferralCodeModalWrapper
} from './style';
import { useReferralCodeContext } from './ReferralCodeContext';

const ReferralCodeSubmissionResult = () => {

  const { getReferralCodeSubmissionMsg, record } = useReferralCodeContext();

  const isSuccess = record.modalData?.isSuccess;

  const referralCodeTextRef = useRef(null)

  useEffect(() => {
    if (!record.modalData?.msg || !referralCodeTextRef?.current) return

    const htmlData = getReferralCodeSubmissionMsg(record.modalData)
    const parser = new DOMParser()
    const html = parser.parseFromString(htmlData, "text/html");
    const element = html.querySelector('body p');
    if (!element) {
      return;
    }

    referralCodeTextRef.current.appendChild(element)

  }, [record.modalData, referralCodeTextRef])

  return (
    <ReferralCodeModalWrapper alignItems={"center"}>
      <ReferralCodeModalIconWrapper isSuccess={isSuccess}>
        {
          isSuccess ? (
            <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
          ) : (
            <LazyIcon component="CloseCircle" color="colorSystemDanger" strokeColor="colorSystemDanger" />
          )
        }
      </ReferralCodeModalIconWrapper>
      <ReferralCodeModalTitle isSuccess={isSuccess}>
        {isSuccess ? "Code Validated" : "Sorry!"}
      </ReferralCodeModalTitle>
      <ReferralCodeModalText ref={referralCodeTextRef} />
    </ReferralCodeModalWrapper>
  )
}

export default ReferralCodeSubmissionResult
