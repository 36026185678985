import React from 'react';
import {
  Button,
  Divider,
  Link,
  Paper,
  PhoneNumberInput,
  SendEmailIcon,
  Stack,
  TextField
} from '@trustsecurenow/components-library';

const UserContactUsForm = ({ onSubmit, onFieldChange, data, resetForm, isValidForm, submitting }) => {
  return (
    <Paper sx={{ mt: 2, borderRadius: 1 }}>
      <Stack spacing={3}>
        <Stack spacing={4} maxWidth={600} p={2} pt={4}>
          <Stack direction="row" gap={3}>
            <TextField
              required
              value={data.first_name}
              name="first_name"
              label="First name"
              onChange={onFieldChange}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
            <TextField
              value={data.last_name}
              name="last_name"
              label="Last name"
              onChange={onFieldChange}
              inputProps={{ maxLength: 50 }}
              required
              fullWidth
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <TextField value={data.company} name="company" label="Company" fullWidth disabled />
            <TextField value={data.email} name="email" label="Email" fullWidth disabled />
          </Stack>
          <Stack direction="row" gap={3}>
            <PhoneNumberInput
              variant="outlined"
              value={data?.phone?.includes('+') ? data?.phone : `+1 ${data?.phone}`}
              name="phone"
              label="Phone Number"
              defaultCountry="us"
              countryCodeEditable={false}
              fullWidth
              onChange={value => {
                onFieldChange({ target: { name: 'phone', value } });
              }}
            />
            <TextField
              value={data.subject}
              name="subject"
              label="Subject"
              inputProps={{ maxLength: 50 }}
              onChange={onFieldChange}
              fullWidth
              required
            />
          </Stack>
          <Stack direction="row">
            <TextField
              id="feedback"
              name="feedback"
              label="Feedback"
              value={data.feedback}
              inputProps={{ maxLength: 2000 }}
              onChange={onFieldChange}
              fullWidth
              required
              rows={4}
              multiline
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3} p={2} pt={0}>
          <Link component="button" onClick={resetForm}>
            Cancel
          </Link>
          <Button
            color="success"
            startIcon={<SendEmailIcon />}
            disabled={!isValidForm}
            loading={submitting}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default UserContactUsForm;
