import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styled from "styled-components";
import { IconButton } from '@material-ui/core';
import { useLocation } from 'hooks';
import { getStorage, setStorage, trackingUtils } from 'helpers';
import { Bar, NotificationAlert, Warning } from 'components';
import { NotificationsMenu } from 'apps/newsfeed/components';
import { DarkMode as DarkModeIcon, WhiteMode } from 'components/icons';
import TermsModal from 'apps/newsfeed/components/license/TermsModal';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { BSN_SET_USER_ALL_NOTIFICATIONS_SEEN, BSN_UPDATE_DARK_MODE } from 'conf';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { Button, useThemeContext, enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const MobileHeaderContainer = styled.div`
  padding: 0 8px 0 14px;
  min-height: 56px;
  background-color: var(--colorBaseBase);
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MobileHeaderBlackWindow = styled.div`
  display: ${({open}) => !open ? "none" : "block"};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
`
const NotificationDot: ComponentType<*> = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #FF5A61;
  border-radius: 50%;
  top: 9px;
  right: 9px;
`;

const MobileHeader = ({ setOpen, open, access, isNotificationsOpen, toggleNotificationDrawer, isTermsOfUseDialogOpen, onToggleOpenTermsDialog }) => {
  const isDarkMode = getStorage('darkMode') === 'true';
  const dispatch = useDispatch();
  const { app } = useLocation();
  const { setThemeMode } = useThemeContext();
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const [darkModeChanged, setDarkModeChanged] = useState(false);
  const userDarkMode = useSelector(({ bsn }) => bsn?.user?.profile?.dark_mode);
  const showDarkModeToggle = useSelector(({ bsn }) => bsn?.user?.access?.features?.show_dark_mode_switch);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const allNewsfeedNotificationsSeen = useSelector(({ bsn }) => bsn?.user?.profile?.all_newsfeed_notifications_seen);
  const newsfeedLicense = useSelector(state => state.newsfeed.license);
  const showNewsfeedIcons = newsfeedAccess && app === 'newsfeed';

  useEffect(() => {
    if (typeof userDarkMode === 'boolean' && !darkModeChanged) {
      setDarkMode(userDarkMode);
      setThemeMode(userDarkMode ? 'dark' : 'light');
      setStorage('darkMode', userDarkMode, true);
    }
  }, [darkModeChanged, userDarkMode]);

  useEffect(() => {
    if (document.body) {
      document.body.setAttribute('data-theme', darkMode ? 'dark' : 'light');
      document.querySelector("meta[name='theme-color']").setAttribute('content', darkMode ? '#191a20' : '#f3f5f6');
    }
  }, [darkMode]);

  if (!access) return null;

  const handleOpen = () => {
    setOpen(open => !open)
  };

  const handleClickIcon = () => {
    toggleNotificationDrawer(true);
    if (!allNewsfeedNotificationsSeen) {
      newsFeed
        .markAllSeen()
        .then(res => {
          dispatch({
            type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
            payload: 1
          });
        });
    }
  };

  const handleClickTermsIcon = () => {
    onToggleOpenTermsDialog(true)
  };

  const onCloseTermsModal = () => {
    onToggleOpenTermsDialog(false)
  };

  const onChangeTheme = () => {
    setDarkMode(!darkMode);
    setThemeMode(darkMode ? 'light' : 'dark');
    setStorage('darkMode', !darkMode, true);
    setDarkModeChanged(true);
    usersAPI
      .manageUserDarkModeData(!darkMode)
      .then(res => {
        dispatch({ type: BSN_UPDATE_DARK_MODE, payload: !darkMode });
      })
      .catch(err => {
        let errorMsg = err?.response?.data?.message;
        if (errorMsg) enqueueAlertSnackbar(errorMsg, { props: { severity: 'error' } });
      });
    trackingUtils.clickButton(`${darkMode ? 'Disable' : 'Enable'} Dark Mode`);
  };

  return (
    <MobileHeaderContainer>
      <MobileHeaderBlackWindow open={open} onClick={handleOpen}/>
      <Bar style={{width: 24, height: 24}} onClick={handleOpen} />
        <div>
          {showNewsfeedIcons ? (
            <>
              {newsfeedLicense?.newsfeed_license_agreed && newsfeedLicense?.show_license_agreement_dialog ? (
                <>
                  <IconButton style={{ padding: 8, marginRight: 8 }} onClick={handleClickTermsIcon}>
                    <Warning size={1.715} color="colorCommonWhite" />
                  </IconButton>
                  <TermsModal open={isTermsOfUseDialogOpen} close={onCloseTermsModal} />
                </>
              ) : null}
              <IconButton style={{ padding: 8 }} onClick={handleClickIcon}>
                <NotificationAlert size={1.715} color="colorCommonWhite" />
                {allNewsfeedNotificationsSeen ? null : <NotificationDot />}
              </IconButton>
            <NotificationsMenu isOpen={isNotificationsOpen} onClose={() => toggleNotificationDrawer(false)} />
            </>
          ) : null}
          {showDarkModeToggle && (
            <IconButton style={{ padding: 8, marginLeft: 8 }} onClick={onChangeTheme}>
              {
                darkMode ? (
                  <WhiteMode size={1.715} color="colorCommonWhite" />
                ) : (
                  <DarkModeIcon size={1.715} color="colorCommonWhite" />
                )
              }
            </IconButton>
          )}
        </div>
    </MobileHeaderContainer>
  )
}

export default MobileHeader;
