import React from 'react';
import { Quiz } from 'components';
import { QuizViewer } from 'components/QuizViewer';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { PreassessmentModal } from '../components';
import { usePreassessmentContext } from '../contexts';
import { PreassessmentQuizLayout } from '../style';

const PreassessmentQuiz = () => {
  const { quiz_token, quiz_id, gotoStartPage } = usePreassessmentContext();
  const [modalOpen, setModalOpen] = React.useState();

  return (
    <PreassessmentQuizLayout container alignItems={'center'} justifyContent={'center'}>
      <QuizViewer
        isPre={true}
        quizId={quiz_id}
        quizToken={quiz_token}
        onQuizEnd={() => setModalOpen(true)}
        onError={() => {
          // gotoStartPage();
          enqueueAlertSnackbar('Something went wrong. Please try again', {
            props: { severity: 'error' }
          });
        }}
        containerStyle={{
          width: '60rem'
        }}
      />
      <PreassessmentModal open={modalOpen} />
    </PreassessmentQuizLayout>
  );
};

export default PreassessmentQuiz;
