// @flow

import React, { memo, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import { Modal3 } from 'components';
import { AddIcon, Button, TagIcon } from '@trustsecurenow/components-library';

type ClientsUsersAddButtonType = {
  tab: string,
  label: string,
  setRefetch: Function
};

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const ClientsUsersAddButton = ({
  tab,
  label,
  setRefetch,
  RenderedButton = null,
  transformBeforeSubmit = null,
  startIcon
}: ClientsUsersAddButtonType) => {
  const app = 'clients';
  const [open, dispatchOpen] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const setOpen = (value: boolean) => {
    dispatchOpen(value);
  };

  const refetch = () => {
    setRefetch(true);
    setRefetch(false);
  };

  return (
    <>
      {!RenderedButton ? (
        <Button
          startIcon={tab === 'tags' ? <TagIcon /> : <AddIcon />}
          onClick={() => setOpen(true)}
          sx={{ mr: 1, width: tab === 'tags' ? 128 : 160 }}
        >
          {label}
        </Button>
      ) : (
        <RenderedButton onClick={() => setOpen(true)}>{label}</RenderedButton>
      )}
      {open && (
        <Modal3
          open={open}
          setOpen={setOpen}
          app={app}
          tab={tab}
          createNew
          TransitionComponent={Transition}
          transformBeforeSubmit={transformBeforeSubmit}
          refetchOnCreate={refetch}
        />
      )}
    </>
  );
};

export default memo<ClientsUsersAddButtonType>(ClientsUsersAddButton);
