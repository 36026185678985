import { Container, TextField, Typography } from 'components';
import styled, { css } from 'styled-components';
import { Card, InputLabel } from '@material-ui/core';
import { SelectField } from 'components';

export const CardPageLayoutContainer = styled(Container.Grid)`
  && {
    flex-direction: column;
    height: 100%;
    padding: 20px 0;
    flex-wrap: nowrap;
  }
`;

export const CardPageLayoutHeaderContainer = styled(Container.Grid)`
  && {
    margin-bottom: 20px;
  }
`;

export const CardPageLayoutBody = styled(Container.Grid)`
  flex-grow: 1;
  overflow-y: auto;
`;

export const CardPageLayoutFooterContainer = styled(Container.Grid)``;

export const CardPageLayoutHeaderBox = styled(Container.Grid)``;

export const SearchField = styled(TextField)`
  && {
    max-width: 500px;
    width: 100%;
    background-color: var(--backgroundPaper);
  }
`;

export const SelectLabel = styled(InputLabel)`
  margin-right: 5px;
`;

export const CardPageLayoutBoxContainer = styled(Container.Grid)`
  margin-bottom: 30px;
`;

export const CardPageLayoutBoxCard = styled(Card)`
  width: 100%;
  padding: 20px 20px 0;
  box-sizing: border-box;
  position: relative;
  ${({ isFetching }) =>
    isFetching &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;

export const CardPageLayoutGrid = styled(Container.Grid)``;

export const CardTitleAndTextContainer = styled.div`
  margin-bottom: 20px;
`;

export const CardPageLayoutTitle = styled(Typography.h3)`
  font-size: 16px;
  line-height: 19px;
  color: var(--colorDefault);
  margin: 0;
`;

export const CardPageLayoutText = styled(Typography.p)`
  font-size: 14px;
  line-height: 25px;
  color: var(--colorDefault);
  margin: 0;
  text-align: left;
`;

export const SelectFieldStyled = styled(SelectField)`
  && {
    background-color: var(--backgroundPaper) !important;
  }
`;

export const TemplatePreviewLogoContainer = styled.div`
  width: 80px;
  height: 50px;
  position: relative;
  overflow: hidden;
`;

export const TemplatePreviewLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

export const CardPageNoResult = styled.div`
  width: 100%;
  height: 120px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  > h2 {
    margin: 0;
    text-align: center;
  }
`;
