import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import * as dashboard from 'helpers/apis/services/dashboard';
import { LoadingStyled, Modal3 } from 'components';
import { useId, useLocation } from 'hooks';
import { useApp } from 'helpers';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import useHasInternet from 'hooks/useHasInternet';
import { AddIcon, Button } from '@trustsecurenow/components-library';
import { SelectField } from '../forms';
import TableListModal from '../tablelist/TableListModal';

const PageDocuments = () => {
  const [record, setRecord] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const activeTabType = useSelector(rxState => rxState.bsn.system.activeTabType);
  const initialState = {
    value: activeTabType || '',
    templateValue: 'Show All',
    open: false,
    presignedUrlKey: ''
  };
  const [{ value, templateValue, open, presignedUrlKey }, setState] = useReducer(reducer, initialState);
  const app = 'clients';
  const settings = useSelector(state => state.bsn.tables[app][value]);
  const { app: appLoc, item: itemLoc } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const { dispatch: dispatchApp } = useApp();
  const item = itemLoc || clientId;
  const hasInternet = useHasInternet();
  const cancelTokenSource = useRef();
  const preferences = useMemo(
    () =>
      userPreferences.getTableSettings(
        app,
        value === 'documentsBusinessAssociateAgreement' ? 'documentsServiceProvider' : value
      ),
    [value]
  );
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState({
    exists: false,
    msg: ''
  });
  const [tableControls, setTableControls] = useState({
    page: 0,
    perPage: preferences.pagination || 25,
    total: 0,
    order: preferences.order || 'asc',
    orderBy: preferences.orderBy || (value === 'documentsServiceProvider' ? 'contact' : 'name'),
    isLoading: false
  });

  useEffect(() => {
    const documentType = localStorage.getItem('documentType');
    const templateType = localStorage.getItem('templateType');

    if (documentType) {
      setState({ type: 'SETVALUE', payload: documentType });
      localStorage.removeItem('documentType');
    } else if (templateType) {
      dispatchApp.set('system', 'tableFilter', { category_id: templateType });
      setState({ type: 'SETTEMPLATE', payload: templateType });
      localStorage.removeItem('templateType');
    }
  }, [dispatchApp]);

  useEffect(() => {
    // getting new pereferences when user changes the tab
    // which is equal to the value
    setTableControls(prev => {
      return {
        ...prev,
        page: 0,
        perPage: preferences.pagination || 25,
        total: 0,
        order: preferences.order || 'asc',
        orderBy: preferences.orderBy || (value === 'documentsServiceProvider' ? 'contact' : 'name')
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  // eslint-disable-next-line no-shadow
  const fetchDocumentsTypes = useCallback(async ({ app, clientID, onSuccess, onError, cancelToken }) => {
    dashboard
      .getDocumentsTypes({ app, clientID, cancelToken })
      .then(onSuccess)
      .catch(onError);
  }, []);

  /**
   * fetches documents types and
   * handles the related  internal state of the component
   */
  const fetchDocumentsTypesEffect = useCallback(async () => {
    const onSuccess = response => {
      setRecord(response.data);
    };
    // eslint-disable-next-line no-shadow
    const onError = error => {
      if (axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.log('Request canceled', error.message);
      } else {
        setRecord(null);
        enqueueAlertSnackbar(error?.response?.data?.description || 'Something went wrong', {
          props: { severity: 'error' }
        });
      }
    };
    fetchDocumentsTypes({ app: appLoc, clientID: item, onSuccess, onError });
  }, [fetchDocumentsTypes, appLoc, item]);

  useEffect(() => {
    // fetching documents types first time the component renders
    // and each time the item (i.e client id) changes
    fetchDocumentsTypesEffect();
    return () => {
      setRecord(null);
    };
  }, [fetchDocumentsTypesEffect]);

  const fetchDocuments = useCallback(
    // eslint-disable-next-line no-shadow
    async ({ documentType, app, id, filters, sort, pagination, onSuccess, onError, cancelToken }) => {
      dashboard
        .getDocuments({ app, documentType, id, filters, sort, pagination, cancelToken })
        .then(onSuccess)
        .catch(onError);
    },
    []
  );

  const fetchDocumentsEffects = useCallback(() => {
    if (value !== null && value && value.length > 0) {
      setTableControls(prev => {
        return { ...prev, isLoading: true };
      });
      setError({
        exists: false,
        msg: ''
      });
      const onSuccess = (response, policyType) => {
        hasInternet(() => {
          setError({
            exists: false,
            msg: ''
          });
          setTableControls(old => {
            return { ...old, total: response.headers['x-total-count'], isLoading: false };
          });
          if (response.data.length === 0) {
            setDocuments([]);
          } else {
            setDocuments(response.data);
          }
        });
      };
      // eslint-disable-next-line no-shadow
      const onError = error => {
        if (axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log('Request canceled', error.message);
        } else {
          setTableControls(prev => {
            return { ...prev, isLoading: false };
          });
          setError({
            exists: true,
            msg: error?.response?.data?.description || error?.response?.data?.message || 'Fetching documents went wrong'
          });
          enqueueAlertSnackbar(
            error?.response?.data?.description || error?.response?.data?.message || 'Fetching documents went wrong',
            { props: { severity: 'error' } }
          );
        }
      };

      /** cancelling previous not answered requests */
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('cancelling an old api call');
      }

      cancelTokenSource.current = axios.CancelToken.source();
      fetchDocuments({
        documentType: value === 'documentsBusinessAssociateAgreement' ? 'documentsServiceProvider' : value,
        app,
        id: item,
        filters: { categoryID: templateValue === 'Show All' ? '' : templateValue },
        sort: { field: tableControls.orderBy, order: tableControls.order },
        pagination: {
          page: tableControls.page * tableControls.perPage + 1,
          perPage: (tableControls.page + 1) * tableControls.perPage + 1
        },
        onSuccess,
        onError,
        cancelToken: cancelTokenSource.current.token
      });
    }
  }, [
    value,
    fetchDocuments,
    item,
    templateValue,
    tableControls.orderBy,
    tableControls.order,
    tableControls.page,
    tableControls.perPage,
    hasInternet
  ]);

  useEffect(() => {
    fetchDocumentsEffects();
  }, [fetchDocumentsEffects]);

  useEffect(() => {
    if (!value && record && record.length > 0) {
      const indexTemplates = record.findIndex(r => r.value === 'documentsTemplates');
      const index = indexTemplates !== -1 ? indexTemplates : 0;
      setState({ type: 'SETVALUE', payload: record[index].value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, value]);

  useEffect(() => {
    return () => {
      dispatchApp.set('system', 'tableFilter', {});
      dispatchApp.set('system', 'activeTabType', '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // I need document type.. to pass on the modal, and to show on the button.. I will get from the select box.
  useEffect(() => {
    if (value === 'disasterRecovery') setState({ type: 'SETPRESIGNEDURLKEY', payload: 'disaster_recovery' });
    if (value === 'documentsServiceProvider' || value === 'documentsBusinessAssociateAgreement')
      setState({ type: 'SETPRESIGNEDURLKEY', payload: 'documents' });
    if (value === 'otherDocuments') setState({ type: 'SETPRESIGNEDURLKEY', payload: 'other_documents' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Table = useCallback(props => <TableListModal {...props} />, [value]);

  if (!record || !value) return <LoadingStyled />;

  const docTypeChoices =
    record &&
    record.map((v, l) => {
      return { value: v.value, label: v.label };
    });

  const templateChoice = record && record.find(r => r.field_name === 'template');
  const typeChoices = templateChoice?.filters
    ? templateChoice.filters.map((v, l) => {
        return { value: v.id, label: v.label };
      })
    : [];

  const temTypeChoices = [{ value: 'Show All', label: 'Show All' }, ...typeChoices];

  const onClick = e => {
    setRefresh(!open);
    setState({ type: 'SETOPEN', payload: !open });
  };

  const onChange = e => {
    dispatchApp.set('system', 'tableFilter', { category_id: e.target.value === 'Show All' ? '' : e.target.value });
    setState({ type: 'SETTEMPLATE', payload: e.target.value });
    setTableControls(prev => ({ ...prev, page: 0 }));
  };

  const Toolbar = () => (
    <>
      {record && (
        <SelectField
          name="documentTypes"
          value={value}
          emptyValue="Document Type"
          onChange={e => setState({ type: 'SETVALUE', payload: e.target.value })}
          choices={docTypeChoices}
          mr={2}
          variant
          GAclick
        />
      )}
      {record && value === 'documentsTemplates' && (
        <SelectField
          name="templates"
          value={templateValue}
          onChange={onChange}
          choices={temTypeChoices}
          emptyValue="Templates Category"
          mr={2}
          variant
        />
      )}
      {value === 'disasterRecovery' && (
        <Button onClick={onClick} startIcon={<AddIcon />}>
          New Disaster Recovery Plan
        </Button>
      )}
      {value === 'documentsServiceProvider' && (
        <Button onClick={onClick} startIcon={<AddIcon />}>
          New Service Provider Document
        </Button>
      )}
      {value === 'documentsBusinessAssociateAgreement' && (
        <Button onClick={onClick} startIcon={<AddIcon />}>
          New Business Associate Agreement
        </Button>
      )}
      {value === 'otherDocuments' && (
        <Button onClick={onClick} startIcon={<AddIcon />}>
          New Other Document
        </Button>
      )}
    </>
  );

  const tableOptions = {
    refetchOnWindowFocus: false
  };

  return (
    <>
      <Table
        tab={value}
        modalTitle={value === 'documentsBusinessAssociateAgreement' ? 'Business Associate Agreement Information' : ''}
        app={app}
        refetchOnUpdate={() => fetchDocumentsEffects()}
        tableOptions={tableOptions}
        tableSettings={settings}
        toolbar={<Toolbar />}
        id={item}
        paramIdVal={item}
        resetTable={value}
        showSpinnerOnFetching
        data={{
          data: documents,
          page: tableControls.page,
          prePage: tableControls.perPage,
          total: tableControls.total,
          isError: error.exists,
          isFetching: tableControls.isLoading,
          isLoading: tableControls.isLoading,
          // isPreviousData: false,
          order: tableControls.order,
          orderBy: tableControls.orderBy,
          status: error.exists ? 'error' : 'success',
          dispatch: {
            // empty methods for the table
            // to use when when some actions triggered
            // just to prevent errors
            refetch: () => {},
            // if provided, will be fired only when user deletes items from the table
            refetchOnDeletion: () => {
              fetchDocumentsEffects();
            },
            setOrder: order => {
              setTableControls(old => {
                return { ...old, order };
              });
            },
            setOrderBy: orderBy => {
              setTableControls(old => {
                return { ...old, orderBy };
              });
            },
            setPage: page => {
              setTableControls(old => {
                return { ...old, page };
              });
            },
            setPrePage: perPage => {
              setTableControls(old => {
                return { ...old, perPage, page: 0 };
              });
            }
          }
        }}
      />
      {open && (
        <Modal3
          open={open}
          setOpen={onClick}
          app={app}
          type={presignedUrlKey}
          tab={value}
          createNew
          refetchOnCreate={() => fetchDocumentsEffects()}
        />
      )}
    </>
  );
};

PageDocuments.propTypes = {
  tab: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  record: PropTypes.oneOfType([PropTypes.object]).isRequired
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'SETVALUE': {
      return { ...prevState, value: payload };
    }

    case 'SETTEMPLATE': {
      return { ...prevState, templateValue: payload };
    }

    case 'SETOPEN': {
      return { ...prevState, open: payload };
    }
    case 'SETPRESIGNEDURLKEY': {
      return { ...prevState, presignedUrlKey: payload };
    }
    default: {
      return { ...prevState, ...payload };
    }
  }
}

export default PageDocuments;
