import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/TestAuthoringSystem`;

export const getMTNL = type => {
  const requestOptions = {
    url: `${BASE_PATH}/test_mt_nl/get_mt_nl?quiz_type=${type}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getMultipleTrainings = () => {
  const requestOptions = {
    url: `${BASE_PATH}/myDashboard/trainingCourses`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const isQuizSubmitted = courseId => {
  const requestOptions = {
    url: `${BASE_PATH}/myDashboard/isQuizSubmitted/${courseId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getQuizStatus = courseId => {
  const requestOptions = {
    url: `${BASE_PATH}/myDashboard/DashboardQuizModal_v2/${courseId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const deleteQuizStatus = courseId => {
  const requestOptions = {
    url: `${BASE_PATH}/myDashboard/DashboardQuizModal_v2/${courseId}`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const addQuizMark = payload => {
  const requestOptions = {
    url: `${BASE_PATH}/myDashboard/DashboardQuizModalAddMark/1`,
    headers: getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

export const addQuizStatus = payload => {
  const requestOptions = {
    url: `${BASE_PATH}/myDashboard/DashboardQuizModal/1`,
    headers: getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

export const setCompletedCourse = courseId => {
  const requestOptions = {
    url: `${BASE_PATH}/training/markCompleted/${courseId}`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const getCertificate = (courseId, user_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/getCertificate/${courseId}?recipe=chrome-pdf${user_id ? `&user_id=${user_id}` : ``}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const sendCertificate = courseId => {
  const requestOptions = {
    url: `${BASE_PATH}/sendCertificate/${courseId}`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const configurations = (partnerId, clientId = null) => {
  const requestOptions = {
    url: `${BASE_PATH}/training/${clientId ? 'clients' : 'partners'}/${clientId ? clientId : partnerId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getClientTrainingConfigurations = (clientId, tab, filters = {}) => {
  const url = `${BASE_PATH}/training/v2/clients/${clientId}/${tab}`;
  const requestOptions = {
    url,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getPartnerTrainingConfigurations = (partnerId, tab, filters = {}) => {
  const url = `${BASE_PATH}/training/partners/${partnerId}/${tab}`;
  const requestOptions = {
    url,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setReleaseDate = (
  package_id,
  release_date,
  partner_id,
  adjust_future_publication = false,
  client_id = null
) => {
  const requestOptions = {
    url: `${BASE_PATH}/training/set-release-date`,
    headers: getHeaders(),
    data: {
      partner_id: partner_id,
      ...(client_id !== null && { client_id: client_id }),
      release_date: release_date,
      package_id: package_id,
      adjust_future_publication: adjust_future_publication ? 1 : 0
    }
  };
  return apiClient.post(requestOptions);
};

export const setReleaseDateV2 = (tab, payload) => {
  const requestOptions = {
    url: `${BASE_PATH}/training/v2/set-release-date/${tab}`,
    headers: getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

export const unpublish = (package_id, partner_id, client_id = null) => {
  const requestOptions = {
    url: `${BASE_PATH}/training/unpublish`,
    headers: getHeaders(),
    data: {
      partner_id: partner_id,
      ...(client_id !== null && { client_id: client_id }),
      package_id: package_id
    }
  };
  return apiClient.put(requestOptions);
};

export const unpublishV2 = (partner_id, clients_ids, trainings, tab) => {
  const requestOptions = {
    url: `${BASE_PATH}/training/v2/unpublish/${tab}`,
    headers: getHeaders(),
    data: {
      partner_id,
      clients_ids,
      trainings: trainings.map(({ trainingId, packageId }) => ({ training_id: trainingId, package_id: packageId }))
    }
  };
  return apiClient.put(requestOptions);
};

export const getPackageClients = packageId => {
  const requestOptions = {
    url: `${BASE_PATH}/training/configure?package_id=${packageId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const addExcludedPackageClients = ({ selectedClients, packageId }) => {
  const requestOptions = {
    url: `${BASE_PATH}/training/configure`,
    headers: getHeaders(),
    data: {
      client_ids: selectedClients,
      package_id: packageId
    }
  };
  return apiClient.post(requestOptions);
};
