/* eslint-disable react/prop-types */
import { Dialog, DialogContentText, Slide } from '@material-ui/core';
import { LazyApp } from 'apps';
import {
  dataProvider,
  getPreContent,
  hasKey,
  isObjectEmpty,
  useApp,
  strToBase64,
  trackingUtils,
  getIdByResource
} from 'helpers';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import * as dashboard from 'helpers/apis/services/dashboard';
import { useConfig, useFormData, useId, useLocation } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link } from '@trustsecurenow/components-library';
import useHasInternet from '../../hooks/useHasInternet';
import { LazyIcon } from '../icons';
import LoadingStyled from '../types/Loading';
import {
  AlignLeft,
  AlignRight,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
  IconButton
} from './ComponentTypes';
import bsnClientServices from 'helpers/apis/bsnClientServices';
import WarningAddUserModal from 'apps/shared/directorySync/modals/WarningAddUserModal';
import { enqueueAlertSnackbar, Typography } from '@trustsecurenow/components-library';
import { CREATE_USER_MATCH_EMAIL_ERR } from 'conf';
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction';
import { useNotify } from 'ra-core';

const preStyles = {
  padding: 0,
  margin: 0,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word'
};

const showMatchingEmailErrAlert = (errorMsg, dispatchRx) => {
  dispatchRx({
    type: CREATE_USER_MATCH_EMAIL_ERR,
    payload: errorMsg
  });
}

const Modal3 = props => {
  const {
    open = false,
    setOpen,
    app,
    tab,
    parentTab = null,
    rowId = '',
    parentId = '',
    rowIndex,
    createNew = false,
    paramId = '',
    data,
    type = null,
    apiApp = null,
    apiTab = null,
    row,
    modalTitle,
    paramIdVal,
    maxWidth = 'md',
    trackingEvent,
    refetchOnUpdate = null,
    refetchOnCreate = null,
    ...rest
  } = props;
  const notify = useNotify();
  const form = useRef(null);
  const saveButton = useRef(null);
  const { dispatch: dispatchApp } = useApp();
  const dispatchRx = useDispatch();
  const { item } = useLocation();
  const config: Object = useConfig('modals', app, tab);
  const [state, setStateFunction]: [any, Function] = useState(null);
  const [url, setUrl] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [isSubmitOnClick, setIsSubmitOnClick] = useState(true);
  const [initialFields, setInitialFields] = useState({});
  const [loading, setLoading] = useState(false);
  const clientId = useId({ key: 'clientId' });
  const partnerId = useId({ key: 'partnerId' });
  const setState = s => {
    setTimeout(() => setStateFunction(s), 300);
  };
  const email = localStorage.getItem('email');
  const [id, setId] = useState(rowId || item || '');
  const [disabled, setDisabled] = useState(false);
  const [layout, setLayout] = useState({
    footer: {
      right: null,
      left: null
    }
  });
  const hasInternet = useHasInternet();
  const { setFormData } = useFormData();
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);
  let formData = null;
  let changedFile = null;
  const {
    buttons,
    title,
    requireRowId,
    idRequired,
    paramKey,
    otherId,
    userIdrequired,
    initialState,
    noState,
    noQuery,
    newTabSource,
    newItemSource,
    requireRowData,
    invalidFormMessage,
    ...settings
  } = config;
  const [record, setRecord] = useState(
    useSelector(({ bsn }): Object | null => {
      if (noState || !hasKey(bsn[app], tab)) return null;
      const init = initialState;
      // If modal is for new item, return with initial state.
      if (createNew) return Object.keys(initialFields).length ? setState(initialFields) : setState(init);
      const rxData = bsn[app][tab];

      if (requireRowData) {
        return { ...initialState, data: row };
      }

      if (rxData === null) return null;

      // if call require `item`, add it to the request, otherwise get regular rxData.
      if (idRequired) {
        if (Object.keys(initialFields).length) return '';
        const newRow = rxData[item] && rxData[item].filter(d => d.id === rowId)[0];
        setState(newRow);
        return newRow;
      }
      if (requireRowId) {
        if (hasKey(rxData, rowId)) {
          if (Object.keys(initialFields).length) return '';
          setState(rxData[rowId]);
          return rxData[rowId];
        }
        if (typeof rxData === 'string') return rxData;
        return setState(rxData);
      }
      if (rxData && !state) {
        setState(rxData);
        return rxData;
      }
      // if no initial rxData or regular rxData, return null.
      if (rxData === null && init === null) return null;
      // return rxData, overwriting initial state.
      return rxData;
    })
  );
  const [showWarning, setShowWarning] = useState(false);

  // This useEffect should clear the state on unmount of the modal component - It is not cleaning
  useEffect(
    () => {
      if (requireRowData) {
        setStateFunction({ ...initialState, data: row });
        setRecord({ ...initialState, data: row });
      }
      return () => {
        setStateFunction(null);
        dispatchApp.set(apiApp || app, apiTab || tab, null);
        dispatchApp.set('system', 'tableRowModal', false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requireRowData, row, app, apiTab, tab]
  );
  useEffect(() => {
    if (parentId && paramKey) {
      const onSuccess = res => {
        setStateFunction(res.data);
      };
      if (apiApp === 'clients' && apiTab === 'customMessage') {
        bsnClientServices.getWelcomeMsg({ clientID: parentId, messageType: paramId }).then(onSuccess);
      } else if (apiApp === 'partnerProfile' && apiTab === 'customMessages') {
        bsnClientServices.getPartnerWelcomeMsg({ clientID: parentId, messageType: paramId }).then(onSuccess);
      } else {
        dispatchApp.getOne(apiApp, apiTab, parentId, { [paramKey]: paramId }).then(onSuccess);
      }
    } else if (otherId && !noQuery && !paramKey) {
      dispatchApp.getOne(app, tab, otherId ? rowId : null);
    } else if (!state && !noQuery) {
      if (app === 'myDashboard' && tab === 'securityPolicy') {
        dashboard.getSecurityPolicy({ app, policyID: paramIdVal || paramId || rowId }).then(response => {
          setStateFunction(response.data);
        });
      } else if (app === 'myDashboard' && tab === 'otherPolicy') {
        dashboard.getOtherPolicy({ app, policyID: paramIdVal || paramId || rowId }).then(response => {
          setStateFunction(response.data);
        });
      } else if (app === 'clients' && tab === 'policyInfo') {
        dashboard
          .getPolicyInfo({
            app,
            policyID: paramIdVal || paramId || rowId
          })
          .then(response => {
            setStateFunction(response.data);
          });
      } else if (app === 'clients' && tab === 'otherPolicyInfo') {
        dashboard
          .getOtherPolicyInfo({
            app,
            policyID: paramIdVal || paramId || rowId
          })
          .then(response => {
            setStateFunction(response.data);
          });
      } else if (
        ['clients', 'myCompany'].includes(app) &&
        [
          'disasterRecoveryInfo',
          'documentsServiceProviderInfo',
          'otherDocumentsInfo',
          'sraDocumentsInfo',
          'workPlanFindingInfo'
        ].includes(tab)
      ) {
        dashboard.getDocumentInfo({ app, documentType: tab, id: rowId }).then(response => {
          setStateFunction(response.data);
        });
      } else {
        dispatchApp
          .getOne(app, tab, requireRowId ? rowId : null, {
            [paramKey]: paramIdVal || paramId || rowId
          })
          .then(res => {
            setStateFunction(res.data);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramKey, noQuery]);

  useEffect(() => {
    if (!state && (state !== null || typeof state !== 'number') && record) {
      setStateFunction(prevState => record);
    }
  }, [state, record]);

  useEffect(() => {
    if (!state && data) {
      setStateFunction(prevState => data);
    }
    if (!state && createNew) {
      setStateFunction(prevState => config.initialState);
    }
  }, [state, data, createNew, config.initialState]);

  const dispatch = {};

  dispatch.onClose = (closeType: string): void => {
    setClose();
  };

  dispatch.onClick = (
    clickType: 'download' | 'certificate' | 'refresh' | 'resetPassword' | 'resetMFAToken' | 'customMessageDraft',
    link?: string | null,
    ev?: Object
  ) => {
    switch (clickType) {
      case 'download': {
        if (link !== null) {
          window.open(link);
        } else {
          enqueueAlertSnackbar('There is no file attached', { props: { severity: 'error' } });
        }
        break;
      }
      case 'certificate': {
        if (url !== null) window.open(url.replace('chrome-image', 'chrome-pdf'));
        break;
      }
      case 'refresh': {
        setRefresh(true);
        setTimeout(() => setRefresh(false), 3000);
        break;
      }
      case 'resetPassword': {
        usersAPI.resetPassword(rowId);
        break;
      }
      case 'resetMFAToken': {
        usersAPI.resetMFA(rowId);
        break;
      }
      case 'customMessageDraft': {
        const fData = setFormData(document.getElementById('modalForm'));
        customMessage(fData, paramId, id, apiTab);

        break;
      }
      default: {
        break;
      }
    }
  };

  const customMessage = (d: Object, messType: number | string, clntId: string, messTab: string) => {
    console.log('onSaveClicked');
    setLoading(true);
    const messageApp = apiApp || app;
    const messageTab = apiTab || messTab;
    const messageItem = messageApp === 'clients' ? item || clientId : partnerId;
    const objectName: string = messageApp === 'clients' ? 'client_messages' : 'partner_messages';

    const { welMessHowMany, welMessageValue, first_text, second_text, deferWelcome } = d;

    const hours = welMessageValue === 'days' ? Number(welMessHowMany) * 24 : welMessHowMany;

    const cleanData = {
      deferred_sending: messType === 1 || messType === 1001 ? deferWelcome : false,
      first_text,
      second_text,
      hours,
      message_type: messType,
      add_notification_email: email
    };

    if (messType === 1 || messType === 2) {
      cleanData.delete_draft = messType;
    }

    const preparedPayload = {
      ...cleanData,
      first_text: strToBase64(first_text),
      second_text: strToBase64(second_text)
    };
    const onSuccess = response => {
      // 1. setStateFunction(null) was used to just display loading which is wrong as we have the state [loading]
      // 2. in the case of customMessages we can be 100% sure that we want to keep the prevState + update specific key
      if (trackingEvent) {
        trackingUtils.customEvent(trackingEvent.eventName, { ...trackingEvent.params });
      }
      setStateFunction(prevState => {
        return {
          ...prevState,
          [`${objectName}`]: {
            ...prevState[`${objectName}`],
            ...cleanData
          }
        };
      });
      enqueueAlertSnackbar(`${response.data.description}`);
    };
    const onError = err => {
      console.log('onSaveClickedError', err);
      // in case of error we empty the object
      setStateFunction(prevState => null);
      if (err.response?.status && err.response.status === 500) {
        enqueueAlertSnackbar('Something went wrong', { props: { severity: 'error' } });
      } else if (err.response && err.response.data) {
        enqueueAlertSnackbar(` ${err.response.data.description}`, { props: { severity: 'error' } });
      } else {
        setClose();
        enqueueAlertSnackbar(`${err.response}`, { props: { severity: 'error' } });
      }
    };
    const onFinished = () => {
      setLoading(false);
    };
    if (messageApp === 'clients' && messageTab === 'customMessage') {
      if (preparedPayload.delete_draft) {
        bsnClientServices
          .publishWelcomeMsg({
            clientID: messageItem,
            message: preparedPayload
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      } else {
        bsnClientServices
          .updateWelcomeMsgDraft({
            clientID: messageItem,
            message: preparedPayload
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      }
    } else if (messageApp === 'partnerProfile' && messageTab === 'customMessages') {
      if (preparedPayload.delete_draft) {
        bsnClientServices
          .publishParnterWelcomeMsg({
            clientID: messageItem,
            message: preparedPayload
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      } else {
        bsnClientServices
          .updatePartnerWelcomeMsgDraft({
            clientID: messageItem,
            message: preparedPayload
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      }
    } else {
      dataProvider
        .post(messageApp, `${messageTab}/${String(messageItem)}`, null, preparedPayload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    }
  };

  dispatch.onStart = () => {
    if (isObjectEmpty(initialFields)) {
      setRecord(initialState);
      setInitialFields(initialState);
    }
  };

  dispatch.onChange = (key, value) => {
    setRecord({ ...record, [key]: value });
  };

  dispatch.onCheckValidity = () => {
    const refForm = form.current;
    return refForm.checkValidity();
  };

  dispatch.getRecord = () => record;

  dispatch.onUpdate = () => {
    if (createNew) dataProvider.post(app, tab, null, record);
    else dispatchApp.update(app, tab, null, record);
  };

  dispatch.setId = newId => setId(newId);

  dispatch.setModalState = newState => {
    setStateFunction(newState);
  };

  dispatch.setDownloadUrl = newUrl => {
    setUrl(newUrl);
  };

  dispatch.presignedUpload = file => {
    setLoading(true);
    const formDataType = new FormData();
    dataProvider
      .post(app, `presignedUpload/${rowId}`, null, {
        filename: file.name,
        type: buttons.submit.uploadType
      })
      .then(res => {
        formDataType.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
        formDataType.append('key', res.data.fields.key);
        formDataType.append('policy', res.data.fields.policy);
        formDataType.append('signature', res.data.fields.signature);
        formDataType.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
        formDataType.append('file', file);
        dataProvider
          .postUrl(res.data.url, formDataType, 'multipart/form-data')
          .then(resPostUrl => console.log({ resPostUrl }));
      })
      .catch(err => console.log(err));
  };

  dispatch.fileChanged = file => {
    changedFile = file;
  };

  function setClose() {
    changedFile = null;
    setOpen(false);
  }

  function updateAttachedFile(res) {
    const file = changedFile;
    const formDataType = new FormData();
    formDataType.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
    formDataType.append('key', res.data.fields.key);
    formDataType.append('policy', res.data.fields.policy);
    formDataType.append('signature', res.data.fields.signature);
    formDataType.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
    formDataType.append('file', file);

    dataProvider.postUrl(res.data.url, formDataType, 'multipart/form-data').then(resPostUrl => {
      const onSuccess = response => {
        enqueueAlertSnackbar(
          response.data?.status ||
            response.data?.description ||
            response.description ||
            'The information was successfully updated',
          { props: { severity: 'success' } }
        );
        refetchOnUpdate && refetchOnUpdate();
      };
      const onError = err => {
        if (err?.response?.data) {
          enqueueAlertSnackbar(` ${err.response.data.description}`, { props: { severity: 'error' } });
        } else {
          enqueueAlertSnackbar(`${err?.response?.status}`, { props: { severity: 'error' } });
        }
      };
      const onFinished = () => {
        setClose();
        setLoading(false);
      };
      if (app === 'clients' && tab === 'policyInfo') {
        dashboard
          .updatePolicyInfo({
            app,
            policyID: rowId,
            data: formData
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      } else if (app === 'clients' && tab === 'otherPolicyInfo') {
        dashboard
          .updateOtherPolicyInfo({
            app,
            policyID: rowId,
            data: formData
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      } else if (
        ['clients', 'myCompany'].includes(app) &&
        ['disasterRecoveryInfo', 'documentsServiceProviderInfo', 'otherDocumentsInfo', 'sraDocumentsInfo'].includes(tab)
      ) {
        dashboard
          .updateDocument({
            app,
            documentType: tab,
            id: rowId,
            data: { ...formData }
          })
          .then(onSuccess)
          .catch(onError)
          .finally(onFinished);
      } else {
        dataProvider
          .update(app, `${tab}/${rowId}`, formData)
          .then(onSuccess)
          .finally(onFinished);
      }
    });
  }


  const checkInvalidInput = (inputValue, inputValueLength) => {
    return (inputValue?.startsWith('+') && (inputValueLength > 2 && (inputValueLength < 10 || inputValueLength > 18))) ||
    (!inputValue?.startsWith('+') && (inputValueLength < 10 || inputValueLength > 18))
  }

  const onSubmit = (e, proceed = false) => {
    e.preventDefault();
    const refForm = form.current;
    const elem = refForm.elements;
    const elemFormData = {};
    elem.forEach(el => {
      if (hasKey(el, 'value')) {
        elemFormData[el.name] = el.value;
      }
    });
    dispatchRx(resetCreateUserMatchingEmailError())
    const phoneNumberLength = elemFormData?.phone_number?.length;
    const mobileNumberLength = elemFormData?.mobile_number?.length;
    const cellNumberLength = elemFormData?.cell_number?.length;

    if (
      checkInvalidInput(elemFormData?.phone_number, phoneNumberLength) ||
      checkInvalidInput(elemFormData?.mobile_number, mobileNumberLength) ||
      checkInvalidInput(elemFormData?.cell_number, cellNumberLength)
    ) {
      return;
    }

    if (
      buttonsState?.directory_sync_enabled &&
      !proceed &&
      refForm.reportValidity() &&
      props.createNew &&
      newTabSource === 'addUser'
    ) {
      setShowWarning(true);
    } else if (refForm && refForm.reportValidity()) {
      const param = parseInt(paramId, 10);
      formData = setFormData(refForm);
      if (param === 1001 || param === 1002) {
        const mType = param === 1001 ? 1 : 2;
        customMessage(formData, mType, id, apiTab);
        setClose();
        return;
      }
      setLoading(true);
      console.log('propss', props);
      if (typeof props.transformBeforeSubmit === 'function') {
        formData = props.transformBeforeSubmit(formData);
      }

      if (createNew) {
        if (app === 'myCompany' && tab === 'workPlan') {
          dashboard
            .createDocument({
              app,
              documentType: tab,
              id: item || getIdByResource(app, tab),
              data: formData
            })
            .then(d => {
              if (settings?.successfullyCreated) {
                enqueueAlertSnackbar(settings.successfullyCreated);
              } else {
                enqueueAlertSnackbar('Successfully Created!');
              }
              refetchOnCreate && refetchOnCreate();
            })
            .catch(err => {
              const statusError = err.response.status === 500 ? 'Server Error' : err.response.status;
              const errorMessage =
                err.response.data.description ||
                err.response.data?.message ||
                err.response.data?.status ||
                statusError ||
                'Something went wrong';
              enqueueAlertSnackbar(` ${errorMessage}`, { props: { severity: 'error' } });
            })
            .finally(() => {
              setClose();
              setLoading(false);
            });
        } else {
          if (formData?.password != formData?.check_password) {
            enqueueAlertSnackbar(`Passwords do not match`, { props: { severity: 'error' } });
            setLoading(false);
            return;
          }
          if (typeof formData.send_welcome_message === 'string') {
            formData = { ...formData, send_welcome_message: Number(formData.send_welcome_message) }
          }
          dataProvider
            .post(app, tab, id, formData)
            .then(d => {
              if (tab === newTabSource) {
                dataProvider
                  .getList(app, `${newItemSource}/${id}`, {
                    filter: { id: d.data.data.id }
                  })
                  .then(res => hasInternet(() => dispatchApp.set(app, tab, res.data[0], null, true)));
              }

              if (settings?.successfullyCreated) {
                enqueueAlertSnackbar(settings.successfullyCreated, { props: { severity: 'success' } });
              } else {
                enqueueAlertSnackbar('Successfully Created!');
              }
              refetchOnCreate && refetchOnCreate();
            })
            .catch(err => {
                if (err?.response?.data?.description?.includes("matching email")) {
                  showMatchingEmailErrAlert(err?.response?.data?.description, dispatchRx)
                } else {
                const statusError = err.response.status === 500 ? 'Server Error' : err.response.status;
                const errorMessage =
                  err.response.data.description ||
                  err.response.data?.message ||
                  err.response.data?.status ||
                  statusError ||
                  'Something went wrong';
                enqueueAlertSnackbar(` ${errorMessage}`, { props: { severity: 'error' } });
                }
            })
            .finally(() => {
              setClose();
              setLoading(false);
            });
        }
      } else {
        const onSuccess = res => {
          enqueueAlertSnackbar(
            res.data?.status ||
            res.data?.description ||
            res.description ||
            res.status ||
            'The information was successfully updated',
            {
              props: {
                severity:
                  res.data?.status >= 300 || res.data?.code >= 300 || res.code >= 300
                    ? 'warning'
                    : 'success'
              }
            }
          );
          refetchOnUpdate && refetchOnUpdate();
        };

        const onError = err => {
          if (err?.response?.data?.description?.includes("matching email")) {
            showMatchingEmailErrAlert(err?.response?.data?.description, dispatchRx);
          }
          if(err?.response?.data?.description?.includes("Edit is not allowed")) {
            enqueueAlertSnackbar(
              err?.response?.data?.description || 'Something went wrong',
              { props: { severity: 'error' } }
            );
          }
          if (app === 'clients' && tab === 'user') return;
          if (err?.response?.data) {
            enqueueAlertSnackbar(
              err?.response?.data?.description || err?.response?.data?.message || 'Something went wrong',
              { props: { severity: 'error' } }
            );
          } else {
            enqueueAlertSnackbar(`${err?.response?.status || err.message}`, { props: { severity: 'error' } });
          }
        };

        const onFinished = () => {
          setClose();
          setLoading(false);
        };

        if (changedFile) {
          const file = changedFile;

          const onError = err => {
            if (app === 'clients' && tab === 'user') return;
            if (err?.response?.data) {
              notify(
                err?.response?.data?.description ||
                  err?.response?.data?.message ||
                  err?.response?.data?.status ||
                  'Something went wrong',
                'warning'
              );
            } else {
              notify(`${err?.response?.status || err.message}`, 'warning');
            }
          };
          const onFinished = () => {
            setClose();
            setLoading(false);
          };

          // NO changedFile
          if (app === 'clients' && tab === 'policyInfo') {
            dashboard
              .updatePolicyInfo({
                app,
                policyID: rowId,
                data: formData
              })
              .then(onSuccess)
              .catch(onError)
              .finally(onFinished);
          } else if (app === 'clients' && tab === 'otherPolicyInfo') {
            dashboard
              .updateOtherPolicyInfo({
                app,
                policyID: rowId,
                data: formData
              })
              .then(onSuccess)
              .catch(onError)
              .finally(onFinished);
          } else if (
            ['clients', 'myCompany'].includes(app) &&
            [
              'disasterRecoveryInfo',
              'documentsServiceProviderInfo',
              'otherDocumentsInfo',
              'sraDocumentsInfo',
              'workPlanFindingInfo'
            ].includes(tab)
          ) {
            dashboard
              .presignedUpload({
                app,
                id: rowId,
                data: {
                  filename: file.name,
                  type: buttons.submit.uploadType
                }
              })
              .then(res => {
                updateAttachedFile(res);
              })
              .catch(err => {
                setLoading(false);
                enqueueAlertSnackbar(err.response.data?.message, { props: { severity: 'error' } });
              });
          } else {
            dataProvider
              .post(app, `presignedUpload/${rowId}`, null, {
                filename: file.name,
                type: buttons.submit.uploadType
              })
              .then(res => {
                updateAttachedFile(res);
              })
              .catch(err => {
                setLoading(false);
                enqueueAlertSnackbar(err.response.data?.message, { props: { severity: 'error' } });
              });
          }
          return;
        }

        // NO changedFile
        if (app === 'clients' && tab === 'policyInfo') {
          dashboard
            .updatePolicyInfo({
              app,
              policyID: rowId,
              data: formData
            })
            .then(onSuccess)
            .finally(onFinished);
        } else if (app === 'clients' && tab === 'otherPolicyInfo') {
          dashboard
            .updateOtherPolicyInfo({
              app,
              policyID: rowId,
              data: formData
            })
            .then(onSuccess)
            .finally(onFinished);
        } else if (
          ['clients', 'myCompany'].includes(app) &&
          [
            'disasterRecoveryInfo',
            'documentsServiceProviderInfo',
            'otherDocumentsInfo',
            'sraDocumentsInfo',
            'workPlanFindingInfo'
          ].includes(tab)
        ) {
          dashboard
            .updateDocument({
              app,
              documentType: tab,
              id: rowId,
              data: { ...formData }
            })
            .then(onSuccess)
            .catch(onError)
            .finally(onFinished);
        } else {
          const data = {...formData}
          if(title === 'Edit User') data.user_id = rowId
          dataProvider
            .update(app, `${tab}/${rowId}`, data)
            .then(onSuccess)
            .catch(onError)
            .finally(onFinished);
        }
      }
    }
  };

  const isLoading = ((state === null || typeof state === 'number') && !createNew && !noQuery) || loading;

  const lazyApp = useMemo(() => {
    return (
      <LazyApp
        component={settings.component}
        app={app}
        tab={tab}
        record={state}
        id={rowId || open}
        dispatch={dispatch}
        setLayout={setLayout}
        layout={layout}
        paramId={paramId}
        type={type}
        apiTab={apiTab}
        apiApp={apiApp}
        refresh={refresh}
        setDisabled={setDisabled}
        setClose={setClose}
        saveButton={saveButton}
        row={row}
        refetchOnCreate={refetchOnCreate}
        onSubmit={onSubmit}
        setIsSubmitOnClick={setIsSubmitOnClick}
        {...rest}
      />
    );
  }, [state, refresh]);

  const hasFooter = Object.keys(buttons).length > 0 || layout?.footer?.right || layout?.footer.left;
  return (
    <Dialog
      {...rest}
      open={open}
      onClose={() => setClose()}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
    >
      <form id="modalForm" ref={form}>
        {title && (
          <DialogTitleStyled id="dialog-title">
            <Typography variant="button">{modalTitle || title}</Typography>
            <IconButton onClick={() => dispatch.onClose('clear')}>
              <LazyIcon component="Close" />
            </IconButton>
          </DialogTitleStyled>
        )}
        <DialogContentStyled dividers>
          {isLoading ? (
            <LoadingStyled />
          ) : (
            <>
              {hasKey(settings, 'component') ? (
                <DialogContentText id="dialog-component">
                  {lazyApp}
                  {showWarning && (
                    <WarningAddUserModal
                      showWarning={showWarning}
                      setShowWarning={setShowWarning}
                      onConfirm={e => onSubmit(e, true)}
                    />
                  )}
                </DialogContentText>
              ) : (
                <>
                  {!noQuery && (
                    <>
                      {!state ? (
                        <LoadingStyled />
                      ) : (
                        <>
                          {state.title && (
                            <>
                              <Typography variant="h3" my={2.25}>
                                Name
                              </Typography>
                              <Typography variant="body2" my={2}>
                                {state.title}
                              </Typography>
                            </>
                          )}
                          {state.description && (
                            <>
                              <Typography variant="h3" my={2.25}>
                                Description
                              </Typography>
                              <Typography>
                                <pre
                                  style={preStyles}
                                  dangerouslySetInnerHTML={{ __html: getPreContent(state.description) }}
                                />
                              </Typography>
                            </>
                          )}
                          {state.content && (
                            <DialogContentText id="dialog-content">
                              <Typography variant="h3" my={2.25}>
                                Details
                              </Typography>
                              <div style={{ margin: 20 }}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <pre
                                  dangerouslySetInnerHTML={{ __html: getPreContent(state.content) }}
                                  style={preStyles}
                                />
                              </div>
                            </DialogContentText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogContentStyled>
        {!isLoading && hasFooter && (
          <DialogActionsStyled>
            {layout.footer.left || (
              <AlignLeft>
                {buttons?.other &&
                  buttons.other.map(button => {
                    if ((button.key && row[button.key]) || !button.key) {
                      return (
                        <Button
                          color="info"
                          onClick={e => dispatch.onClick(button.type, state ? state[button.key] : null, e)}
                          disabled={loading}
                        >
                          {hasKey(button, 'icon') && (
                            <>
                              <LazyIcon
                                style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }}
                                component={button.icon}
                              />
                              &nbsp;&nbsp;
                            </>
                          )}
                          {button.label}
                        </Button>
                      );
                    }
                  })}
              </AlignLeft>
            )}
            {layout.footer.right || (
              <AlignRight>
                {buttons?.cancel && (
                  <Link
                    component="button"
                    sx={{ mr: 2.5, display: 'inline-flex' }}
                    onClick={() => (buttons.type ? dispatch.onClose(`${buttons.type}`) : dispatch.onClose('clear'))}
                  >
                    {buttons.cancel.label}
                  </Link>
                )}
                {buttons?.submit && (
                  <Button
                    style={buttons?.submit?.style}
                    color="success"
                    type="submit"
                    onClick={isSubmitOnClick && onSubmit}
                    disabled={disabled || isLoading}
                    ref={saveButton}
                  >
                    <>
                      {hasKey(buttons.submit, 'icon') && (
                        <>
                          <LazyIcon
                            style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }}
                            component={buttons.submit.icon}
                          />
                          &nbsp;&nbsp;
                        </>
                      )}
                      {buttons.submit.label}
                    </>
                  </Button>
                )}
              </AlignRight>
            )}
          </DialogActionsStyled>
        )}
      </form>
    </Dialog>
  );
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

Modal3.defaultProps = {
  data: null,
  createNew: false,
  paramId: '',
  rowId: '',
  parentId: '',
  rowIndex: 0,
  parentTab: null,
  type: null,
  apiApp: null,
  apiTab: null,
  row: {},
  modalTitle: '',
  paramIdVal: '',
  trackingEvent: null
};

export default Modal3;
