import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAdminNavigate from './useAdminNavigate';
import { admin, dateToISO, emojiToUnicode, unicodeToEmoji } from 'helpers';
import { updateNestedMutable } from 'utils/update';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { addHours, format } from 'date-fns';
import getNextThursday from 'utils/getNextThursday';

const getNextThursdayNotLastInMonth = () => {
  const nextThursday = getNextThursday();
  const secondNextThursday = new Date(nextThursday);
  secondNextThursday.setDate(secondNextThursday.getDate() + 7);
  return nextThursday.getMonth() === secondNextThursday.getMonth() ? nextThursday : secondNextThursday;
};

const initialData = {
  name: '',
  video_number: '',
  description: '',
  publicly_accessible: 0,
  question_1: '',
  answer_1: false,
  feedback_true_1: '',
  feedback_false_1: '',
  question_2: '',
  answer_2: false,
  feedback_true_2: '',
  feedback_false_2: '',
  question_3: '',
  answer_3: false,
  feedback_true_3: '',
  feedback_false_3: '',
  question_4: '',
  answer_4: false,
  feedback_true_4: '',
  feedback_false_4: '',
  question_5: '',
  answer_5: false,
  feedback_true_5: '',
  feedback_false_5: '',
  email_text: '',
  quick_tips: '',
  is_publish: '',
  hash_tags: '',
  created_date: dateToISO(new Date()),
  publish_date: getNextThursdayNotLastInMonth().toISOString()
}

const useMicroTrainingAddEdit = ({ steps, stepsConfig }) => {
  const { page, method, id } = useParams();
  const { navigateToList, navigateGoBack } = useAdminNavigate();
  const { nextStep, onBack, activeStep } = stepsConfig;
  
  const [mtData, setMtData] = useState(initialData)
  const [mtSuccessModalOpen, setMtSuccessModalOpen] = useState(false)
  const [information, setInformation] = useState({});
  const [loading, setLoading] = useState(false);
  
  const createMode = method === 'create' && !id;
  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;

  useEffect(() => {
    const fetchMicroTraining = async () => {
      try {
        setLoading(true);
        const resp = await admin[page].information(id);
        const data = {
          ...resp.data,
          email_text: resp.data.email_body && unicodeToEmoji(resp.data.email_body),
          quick_tips: resp.data.quick_tips_body && unicodeToEmoji(resp.data.quick_tips_body)
        };

        setInformation(data);
        setMtData({ ...data, publish_date: addHours(new Date(data.publish_date), 12) });
      } catch (e) {
        enqueueAlertSnackbar(
          e?.response?.data?.status || 'Failed on load information',
          { props: { severity: 'error' } }
        );
        navigateToList();
      } finally {
        setLoading(false);
      }
    }
    if (id) fetchMicroTraining();
  }, []);

  const onSuccess = (resp) => { 
    const data = {
      ...resp.data,
      email_text: resp.data.email_body && unicodeToEmoji(resp.data.email_body),
      quick_tips: resp.data.quick_tips_body && unicodeToEmoji(resp.data.quick_tips_body)
    };    
    setInformation(data);
    // if last step then display success message on modal
    if (isLastStep) setMtSuccessModalOpen(true);
    else nextStep();
  }

  const create = async () => {
    try {
      const newData = {
        ...mtData,
        email_text: emojiToUnicode(mtData.email_text),
        quick_tips: emojiToUnicode(mtData.quick_tips),
        publish_date: format(new Date(mtData.publish_date), 'yyyy-MM-dd')
      }

      const resp = await admin[page].create(newData);
      onSuccess(resp?.data);
    } catch (e) {
      enqueueAlertSnackbar(
        e?.response?.data?.status || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  };

  const getNewDataOnUpdate = () => {
    switch (activeStep) {
      case 0:
        return {
          ...information,
          name: mtData.name,
          video_number: mtData.video_number,
          publicly_accessible: mtData.publicly_accessible,
          description: mtData.description,
          hash_tags: mtData.hash_tags,
          email_text: information.email_body && emojiToUnicode(information.email_body),
          quick_tips: information.quick_tips_body && emojiToUnicode(information.quick_tips_body),
          publish_date: format(new Date(mtData.publish_date), 'yyyy-MM-dd')
        }
      case 1:
        return {
          ...information,
          question_1: mtData.question_1,
          answer_1: mtData.answer_1,
          feedback_true_1: mtData.feedback_true_1,
          feedback_false_1: mtData.feedback_false_1,
          question_2: mtData.question_2,
          answer_2: mtData.answer_2,
          feedback_true_2: mtData.feedback_true_2,
          feedback_false_2: mtData.feedback_false_2,
          question_3: mtData.question_3,
          answer_3: mtData.answer_3,
          feedback_true_3: mtData.feedback_true_3,
          feedback_false_3: mtData.feedback_false_3,
          question_4: mtData.question_4,
          answer_4: mtData.answer_4,
          feedback_true_4: mtData.feedback_true_4,
          feedback_false_4: mtData.feedback_false_4,
          email_text: information.email_body && emojiToUnicode(information.email_body),
          quick_tips: information.quick_tips_body && emojiToUnicode(information.quick_tips_body)
        }
      case 2: 
        return {
          ...information,
          email_text: mtData.email_text && emojiToUnicode(mtData.email_text),
          quick_tips: mtData.quick_tips && emojiToUnicode(mtData.quick_tips)
        }  
    }
  };

  const hasDataBeenUpdated = () => {
    switch (activeStep) {
      case 0:
        return information.name !== mtData.name
          || information.video_number !== mtData.video_number
          || information.publicly_accessible !== mtData.publicly_accessible
          || information.description !== mtData.description
          || information.hash_tags !== mtData.hash_tags
          || information.publish_date !== (mtData.publish_date && format(new Date(mtData.publish_date), 'yyyy-MM-dd'))
      case 1:
        return information.question_1 !== mtData.question_1
          || information.answer_1 !== mtData.answer_1
          || information.feedback_true_1 !== mtData.feedback_true_1
          || information.feedback_false_1 !== mtData.feedback_false_1
          || information.question_2 !== mtData.question_2
          || information.answer_2 !== mtData.answer_2
          || information.feedback_true_2 !== mtData.feedback_true_2
          || information.feedback_false_2 !== mtData.feedback_false_2
          || information.question_3 !== mtData.question_3
          || information.answer_3 !== mtData.answer_3
          || information.feedback_true_3 !== mtData.feedback_true_3
          || information.feedback_false_3 !== mtData.feedback_false_3
          || information.question_4 !== mtData.question_4
          || information.answer_4 !== mtData.answer_4
          || information.feedback_true_4 !== mtData.feedback_true_4
          || information.feedback_false_4 !== mtData.feedback_false_4;
      case 2: 
        return information.email_text !== mtData.email_text 
          || information.quick_tips !== mtData.quick_tips;
      default:
        return false;
    }
  };

  const update = async () => {
    try {
      const newData = getNewDataOnUpdate();
      const resp = await admin[page].update(mtData.id, newData);
      onSuccess(resp?.data);
    } catch (e) {
      enqueueAlertSnackbar(
        e?.response?.data?.status || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  };

  const checkPublishedDate = async () => {
    try {
      setLoading(true);
      const data = {
        publish_date: format(new Date(mtData.publish_date), 'yyyy-MM-dd')
      }

      await admin[page].checkPublishedDate(data);
      nextStep();
    } catch (e) {
      enqueueAlertSnackbar(
        e?.response?.data?.status || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  }

  const save = () => {
    setLoading(true);
    
    const isNew = createMode && !mtData.id;
    
    if (isNew) create()
    else update();
  };

  const dispatch = {};

  dispatch.onChange = (name, value) => {
    const emojiRegex = /\p{Extended_Pictographic}/u;
    //if there're emoji do not update simple text fields and allowing add emojis only on rich text fields
    if (!emojiRegex.test(value) || ['quick_tips', 'email_text'].includes(name)) {
      setMtData(old => updateNestedMutable(old, { [name]: value }));
    }
  };

  dispatch.onCloseModal = () => {
    setMtSuccessModalOpen(false)
    navigateGoBack();
  };

  dispatch.onNext = () => {
    if (isLastStep || (id && hasDataBeenUpdated())) save();
    else if (createMode && isFirstStep) checkPublishedDate();
    else nextStep()
  };

  dispatch.onCancel = () => {
    setMtData(id ? information : initialData)
  };

  dispatch.onBack = () => onBack();

  return {
    createMode,
    dispatch,
    loading,
    mtSuccessModalOpen,
    page,
    record: mtData,
  };
};

export default useMicroTrainingAddEdit;