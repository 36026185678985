// @flow
import { useBreakpoint } from 'hooks';
import React, { type Element } from 'react';
import { TableListProvider, type TableListProviderTypes } from './TableListContext';
import TableListTable from './TableListTable';

const TableList = (props: TableListProviderTypes): Element<*> => {
  const mobileView = useBreakpoint('sm');
  const hideColumns =
    mobileView && props.setApp === 'clients' && props.source === 'usersList'
      ? [...props.hideColumns, 'data_breaches', 'policies', 'access_level']
      : [...props.hideColumns];

  return (
    <TableListProvider {...props} hideColumns={hideColumns}>
      <TableListTable centerToolbarItems={props.centerToolbarItems}tableWrapperStyle={props.tableWrapperStyle} tableHeadStyle={props.tableHeadStyle} />
    </TableListProvider>
  );
};

TableList.defaultProps = {
  data: null,
  source: '',
  id: '',
  tableSettings: null,
  onSearch: e => e,
  cache: true,
  hideColumns: [],
  tableWrapperStyle: React.CSSProperties,
  tableHeadStyle: React.CSSProperties
};

export default TableList;
