import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Container } from 'components';
import { Button, Link, PlayIcon } from '@trustsecurenow/components-library';
import { PARTNER_SETUP_BASE_PATH } from '../constants';

export const MainContainer = styled(Container.Paper)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
  }
`;

const ActionButtons = ({ onSave, hasCancel, hasSave, isLoading, isDisabled }) => {
  const { page } = useParams();
  const history = useHistory();

  const goBack = () => {
    history.push(PARTNER_SETUP_BASE_PATH);
  };

  const handleCancel = () => {
    sessionStorage.setItem('configureActiveTab', page);
    goBack();
  };

  const handleSave = () => {
    sessionStorage.setItem('configureActiveTab', page);
    if (hasSave) onSave(goBack);
    else goBack();
  };

  return (
    <MainContainer mt={1.2} radius={1}>
      {hasCancel && (
        <Link sx={{ mr: 2.5 }} component="button" onClick={handleCancel}>
          Cancel
        </Link>
      )}
      <Button
        disabled={isLoading || isDisabled}
        onClick={handleSave}
        color="info"
        startIcon={hasSave && <PlayIcon />}
        sx={{ minWidth: 140 }}
      >
        {hasSave ? 'Save & Exit' : 'Exit'}
      </Button>
    </MainContainer>
  );
};

ActionButtons.defaultProps = {
  onSave: () => {},
  hasCancel: false,
  hasSave: false,
  isLoading: false,
  isDisabled: false
};

ActionButtons.propTypes = {
  onSave: PropTypes.func,
  hasCancel: PropTypes.bool,
  hasSave: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default ActionButtons;
