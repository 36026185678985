// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import {
  Button as MuiButton,
  TextField as MuiTextField,
  InputBase as MuiInputBase,
  DialogContentText as MuiDialogContentText
} from '@material-ui/core';
import { TimePicker as MuiTimePicker, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { Search } from 'components/icons';
// import AppTextField from './TextField';

export const CardContentInner: ComponentType<*> = styled.div`
  flex: 1 1 auto;
  overflow: hidden;

  button {
    width: 100%;
  }
`;

export const TextFieldStyled: ComponentType<*> = styled(MuiTextField)`
  label {
    font-size: calc(var(--fontSize) * 1.1);
    line-height: calc(var(--fontSize) * 1.5);
  }
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear {
    display: none;
  }
  &.variant-outlined {
    margin: 8px 0;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border-radius: 0;
      -webkit-text-fill-color: var(--colorDarkGrey);
      box-shadow: 0 0 0px 1000px var(--backgroundDefault) inset;
    }
    & .Mui-disabled {
      cursor: not-allowed;
    }
  }

  &.variant-standard {
    margin: 8px 15px 8px 0;
    background-color: var(--backgroundDefault);
    font-size: var(--fontSize);
    border: none;
    height: 35px;
    width: 100%;
    max-width: 250px;
    border-radius: 4px;
  }
  &.variant-standard > div {
    padding: 4px 0 0 14px;
    color: var(--colorDefalut);
    &:before {
      border: none;
    }
    &:after {
      border: none;
    }
    &:hover::before {
      border: none;
    }
  }
`;

export const Link: ComponentType<*> = styled(MuiButton)`
  text-transform: capitalize;
  font-size: var(--fontSize);
  margin: 10px 0;
  display: block;
`;

export const DatePicker: ComponentType<*> = styled(MuiDatePicker)`
  margin: 8px 0;
  font-size: var(--fontSize);
  label {
    color: var(--colorGreyLight2);
  }
`;

export const TimePicker: ComponentType<*> = styled(MuiTimePicker)`
  margin: 8px 0;
  font-size: var(--fontSize);
  label {
    color: var(--colorGreyLight2);
  }
`;

export const InputBase: ComponentType<*> = styled(MuiInputBase)`
  && {
    background-color: var(--backgroundDefault);
    && {
      color: var(--colorDefault);
    }
    font-size: var(--fontSize);
    margin: 8px 0;
    border: none;
    height: 35px;
    width: 220px;
    border-radius: 4px;
    padding-left: 14px;
    margin-right: 27px;
  }
`;

export const SearchIcon: ComponentType<*> = styled(Search)`
  && {
    height: 17px;
    width: 17px;
    color: var(--iconDefaultcolor);
  }
`;


export const DialogContentText: ComponentType<*> = styled(MuiDialogContentText)`
  && {
    justify-content: center;
    font-size: var(--fontSize);
  }
`;

/*
export const TextField: ComponentType<*> = styled(AppTextField)`
  p {
    position: absolute;
    bottom: -6px;
    right: 10px;
    transform: scale(0.75);
    padding: 0 4px;
    background-color: #fff;
    font-size: 1.4rem;
    line-height: 1;
    margin: 0;
    display: inline-block;
    text-align: center;
    align-self: flex-end;
  }
`;
*/
