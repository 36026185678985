// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Indicator from 'components/charts/Indicator';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      background: 'var(--backgroundPaper)',
      borderRadius: 'var(--borderRadius)',
      padding: '25px 15px',
      marginTop: 10
    }
  },
  resultIcon: {
    width: 105,
    height: 105,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 186,
      height: 186,
      marginTop: 0
    }
  },
}));

const Result = ({ score, rightAnswers, questionsLength, isCompleted, isSubmitted }) => {
  const classes = useStyles({ rightAnswers });

  return (
    isCompleted ? (
      <div className={classes.container}>
        <Indicator title={score ? `${score}%` : ''} value={Number(score)} className={classes.resultIcon} />
        <Typography variant="h3" textAlign="center" my = {0.75} color={rightAnswers <= 2 ? 'error.main' : 'success.main'}>
          {
            rightAnswers === 4
            ? "Perfect!"
            : rightAnswers === 3
            ? "Good Job!"
            : "Nice Try!"
          }
        </Typography>
        <Typography variant='body2'>
          You answered {rightAnswers} out of {questionsLength} questions correct
        </Typography>
      </div>
    ) : (
      isSubmitted && (
        <div className={classes.container}>
          <Typography variant='body2' textAlign="center">
           Loading Score ...
          </Typography>
        </div>
      )
    )
  );
};

export default React.memo(Result);
