import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { LazyIcon } from 'components';
import { copyTextToClipboard } from 'helpers';
import debounce from 'lodash/debounce';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

function CopyButton({ text = '', message = 'Copied!', onClick, iconProps = {} }) {

  const copy = e => {
    e.stopPropagation();

    onClick && onClick();

    copyTextToClipboard(text);
    enqueueAlertSnackbar(message);
  };

  const debouncedCopy = debounce(copy, 1500, { leading: true, trailing: false });

  return (
    <>
      <Tooltip title="Copy" placement="top">
        <IconButton onClick={debouncedCopy}>
          <LazyIcon color="grey" component="Copy" size={1.6} {...iconProps} />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default CopyButton;
