import { InputAdornment } from '@material-ui/core';
import { DoubtIcon, Typography } from '@trustsecurenow/components-library';
import {
  RHFAutoComplete as AutoComplete,
  RHFAutoCompleteAsync as AutoCompleteAsync,
  Info,
  InfoButtonWithPopover,
  RHFSelectField as SelectField,
  RHFSwitch as Switch,
  RHFTextField as TextField
} from 'components';
import React from 'react';
import { isValidEmailLocalPart } from 'helpers';
import contentAdmin from 'helpers/apis/contentAdmin';
import {
  CONTENT_ADMIN_PICKLIST_COUNTRIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_PICKLIST_DOMAINS,
  CONTENT_ADMIN_PICKLIST_TAGS,
  EMAIL_LOCAL_PART,
  FROM_NAME
} from '../../constants';
import { useContentAdminFormContext } from '../../context';
import { ContentAdminFormGrid, ContentAdminFormRow, EmailPopoverContainer } from '../../style';

const EmailPopover = ({ variant, title }) => {
  console.log('variant', variant);
  return (
    <EmailPopoverContainer>
      <Typography variant="link1" sx={{ margin: '0 0 14px' }}>
        {title}
      </Typography>

      <Typography
        variant="link1"
        pb={1}
        mb={2}
        mt={1}
        sx={{ borderBottom: '1px solid', borderBottomColor: 'grey.100', display: 'block' }}
      >
        <Typography component="span" mr={1}>
          JS
        </Typography>
        <Typography
          component="span"
          color={variant === FROM_NAME && 'info.main'}
          sx={{ opacity: variant === FROM_NAME ? 1 : 0.2 }}
        >
          John Smith
        </Typography>
      </Typography>

      <Typography variant="link1" sx={{ opacity: variant === EMAIL_LOCAL_PART ? 1 : 0.2 }}>
        <Typography component="span" color={variant === EMAIL_LOCAL_PART && 'info.main'}>
          john.smith
        </Typography>
        <Typography component="span">@domain.com</Typography>
      </Typography>
    </EmailPopoverContainer>
  );
};

// using this immutable object of callbacks to preserve refrences to functions and as dependency api to `AutoCompleteAsync` useEffect preventing unessary rendrers
// alternativly you can prevent functions from taking a new refrence by addding useMemo in component
const AUTOCOMPLETE_API_CB_LIST = Object.freeze({
  templates: params => contentAdmin.getList(`templates`, params),
  landingPages: params => contentAdmin.getList('landing-pages', params)
});

const ScenarioForm = () => {
  const { pickLists } = useContentAdminFormContext();

  return (
    <>
      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid md={4}>
          <TextField label="Name" name="name" fullWidth required />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <SelectField
            fullWidth
            allowEmpty={false}
            id="selectDifficulty"
            label="Difficulty Level"
            choices={pickLists[CONTENT_ADMIN_PICKLIST_DIFFICULTIES] || []}
            name="difficulty_level_id"
            required
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid md={4}>
          <AutoCompleteAsync
            fullWidth
            disableCheckbox
            dataPath="data.result"
            id="selectTemplates"
            label="Template "
            labelName="name"
            name="template_id"
            api={AUTOCOMPLETE_API_CB_LIST.templates}
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <AutoCompleteAsync
            dataPath="data.result"
            disableCheckbox
            fullWidth
            id="selectLandingPage"
            label="Landing page"
            name="landing_page_id"
            api={AUTOCOMPLETE_API_CB_LIST.landingPages}
            labelName="name"
            required
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid md={4}>
          <SelectField
            fullWidth
            allowEmpty={false}
            id="selectCountry"
            label="Country"
            choices={pickLists[CONTENT_ADMIN_PICKLIST_COUNTRIES] || []}
            name="country_id"
            required
          />
        </ContentAdminFormGrid>
        <ContentAdminFormGrid md={4}>
          <Switch label="HIPAA" labelPlacement="start" id="switchPublished" name={'is_hipaa'} />
          <InfoButtonWithPopover
            name="hipaa-info"
            tooltip="If turned on, only HIPAA clients will have access to this scenario."
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid md={2}>
          <Switch label="Published" labelPlacement="start" name="is_published" />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid md={4}>
          <TextField
            label="From Name"
            name="from_name"
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InfoButtonWithPopover
                    name="from-name"
                    tooltip={<EmailPopover variant={FROM_NAME} title="Describes who sent the email" />}
                    Icon={DoubtIcon}
                  />
                </InputAdornment>
              )
            }}
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <TextField
            label="Email Local Part"
            name="email_local_part"
            fullWidth
            required
            rules={{
              validate: value => (!isValidEmailLocalPart(value) ? `"${value}" is not a valid local part.` : null)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InfoButtonWithPopover
                    name="from-name"
                    tooltip={<EmailPopover variant={EMAIL_LOCAL_PART} title="Describes the text before the @ symbol" />}
                    Icon={DoubtIcon}
                  />
                </InputAdornment>
              )
            }}
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid sm={12}>
          <Typography variant="h4" mb={3.75}>
            Domains
          </Typography>
        </ContentAdminFormGrid>
        <ContentAdminFormGrid md={4}>
          <AutoComplete
            label="Phishing Domains"
            disableCheckbox
            fullWidth
            options={pickLists[CONTENT_ADMIN_PICKLIST_DOMAINS] || []}
            name="phishing_domain_id"
            required
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid sm={12}>
          <Typography variant="h4" mb={3.75}>
            Scenario tags <Info style={{ verticalAlign: 'middle' }} />
          </Typography>
        </ContentAdminFormGrid>
        <ContentAdminFormGrid item lg={4} md={6} sm={12}>
          <AutoComplete
            creatable
            forcePopupIcon
            labelName="label"
            label="Scenario tags"
            fullWidth
            multiple
            id="templateTags"
            name="scenario_tags"
            options={pickLists[CONTENT_ADMIN_PICKLIST_TAGS] || []}
            required
            getOptionSelected={(option, value) => {
              const optionType = typeof option === 'string';
              const valueType = typeof value === 'string';
              const currentValue = valueType ? value : value.label;
              const currentOption = optionType ? option : option.label;
              return currentValue === currentOption;
            }}
            // getValue={(options, value) => {
            //   return value ? options.filter(({value: optionId}) => value.find(({value}) => value === optionId)) : value
            // }}
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>
    </>
  );
};

export default ScenarioForm;
