import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';
import { Link as RouterLink } from 'react-router-dom';

const UsersReceivingMTNLCell = ({ receiversPercent = 0, onClick, totalUsersCount, sysDisableEmails }) => {
  if (isNA(receiversPercent))
    return (
      <Tooltip placement="top" title="This account is inactive or does not have Micro Trainings/Newsletters">
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );

  if (receiversPercent === 100) return <Typography variant="body2">{`${receiversPercent}%`}</Typography>;
  if (!totalUsersCount)
    return (
      <Tooltip placement="top" title="There are no users in this account">
        <Typography variant="body2">{`${receiversPercent}%`}</Typography>
      </Tooltip>
    );

  if (sysDisableEmails)
    return (
      <Tooltip placement="top" title="System Emails are disabled, enable system emails to ensure users receive emails in the future.">
        <Typography variant="body2">{receiversPercent}%</Typography>
      </Tooltip>
    );
  const color = receiversPercent < 75 ? 'error' : 'success';

  return (
    <Tooltip
      title="Click here to see what may be contributing to users not receiving Micro Training/Newsletters"
      placement="top"
    >
      <Link
        color={`${color}.main`}
        underline="hover"
        hoverColor="info.main"
        onClick={onClick}
        variant="body2"
        component="button"
      >
        {`${receiversPercent}%`}
      </Link>
    </Tooltip>
  );
};

UsersReceivingMTNLCell.propTypes = {
  receiversPercent: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default UsersReceivingMTNLCell;
