import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders as getRequestHeaders } from '../utils/utils';

const getExternalHeaders = (itemKey = 'quizToken') => ({
  'Content-Type': 'application/json',
  'external-authorization': sessionStorage.getItem(itemKey)
});

const checkExternalPage = () => {
  // List of external hostnames
  const externalHostnames = ['yourcyberassessment'];
  // List of external pathnames
  const externalPathnames = ['/PreAssessment', '/AIAssessment'];

  // Get the URL object from the current window location
  const url = new URL(window.location.href.replace('/#', ''));

  // Check if the hostname is localhost
  if (url.hostname === 'localhost') {
    // Check if the pathname matches any external pathnames
    return externalPathnames.includes(url.pathname);
  }
  // Check if any of the external hostnames are included in the current hostname
  return externalHostnames.some(name => url.hostname.includes(name));
};

const BASE_PATH = `${DOMAIN_URL}/QuizManagementSystem/`;
const isExternalPage = checkExternalPage();
const getHeaders = () => (isExternalPage ? getExternalHeaders('quizToken') : getRequestHeaders());

const requestOptions = (quizId, url = '', data, cancelToken = null) => {
  return {
    url: `${BASE_PATH}quiz/${quizId}/${url}`,
    headers: getHeaders(),
    cancelToken,
    data
  };
};

export const getQuizInfo = quizId => {
  return apiClient.get(requestOptions(quizId));
};

export const endQuiz = quizId => {
  return apiClient.post(requestOptions(quizId, 'end-quiz/'));
};

export const setNextQuestion = quizId => {
  return apiClient.post(requestOptions(quizId, 'next-question/'));
};

export const getQuestion = quizId => {
  return apiClient.get(requestOptions(quizId, 'questions/get-current-question/'));
};

export const getQuizUserState = (quizId, clientId = '') => {
  const url = new URL(`${BASE_PATH}quiz-user-state/quiz/${quizId}`);
  if (clientId) {
    url.searchParams.append('client_id', clientId);
  }
  return apiClient.get({
    url: url.href,
    headers: getHeaders()
  });
};

export const createQuizRevision = (quiz_id, clientId = '') => {
  const url = new URL(`${BASE_PATH}quiz-user-revisions/create`);
  if (clientId) {
    url.searchParams.append('client_id', clientId);
  }
  return apiClient.post({
    url: url.href,
    headers: getHeaders(),
    data: { quiz_id }
  });
};

export const resumeQuizRevision = revision_id => {
  return apiClient.post({
    url: `${BASE_PATH}quiz-user-revisions/resume`,
    headers: getHeaders(),
    data: { revision_id }
  });
};

export const retakeQuizRevision = quiz_id => {
  return apiClient.post({
    url: `${BASE_PATH}quiz-user-revisions/retake`,
    headers: getHeaders(),
    data: { quiz_id }
  });
};

export const getCurrentQuestion = revisionId => {
  return apiClient.get({
    url: `${BASE_PATH}quizzes-questions/revision/${revisionId}/current-question`,
    headers: getHeaders()
  });
};

export const setCurrentQuestion = (revisionId, question_id) => {
  return apiClient.put({
    url: `${BASE_PATH}quizzes-questions/revision/${revisionId}/set-question`,
    headers: getHeaders(),
    data: { question_id }
  });
};

export const nextQuestion = revisionId => {
  return apiClient.put({
    url: `${BASE_PATH}quizzes-questions/revision/${revisionId}/next-question`,
    headers: getHeaders()
  });
};

export const answerQuestion = (revisionId, questionId, answer_id, cancelToken) => {
  return apiClient.post({
    url: `${BASE_PATH}quiz-user-answer/answer-question/${revisionId}/${questionId}`,
    headers: getHeaders(),
    data: { answer_id },
    cancelToken
  });
};

export const getAttachmentUploadPost = (revisionId, filename) => {
  return apiClient.get({
    url: `${BASE_PATH}quiz-attachments/get-attachment-upload-post/revision/${revisionId}?filename=${filename}`,
    headers: getHeaders()
  });
};

export const saveAttachment = (revisionId, questionId, uploaded_filename, display_name, description) => {
  return apiClient.post({
    url: `${BASE_PATH}quiz-attachments/question/${revisionId}/${questionId}`,
    headers: getHeaders(),
    data: {
      uploaded_filename,
      display_name,
      description: description || null
    }
  });
};

export const deleteAttachment = (revisionId, questionId, attachment_id) => {
  return apiClient.delete({
    url: `${BASE_PATH}quiz-attachments/question/${revisionId}/${questionId}`,
    headers: getHeaders(),
    data: { attachment_id }
  });
};

export const endSRAQuiz = revision_id => {
  return apiClient.post({
    url: `${BASE_PATH}quiz-user-revisions/end-quiz`,
    headers: getHeaders(),
    data: { revision_id }
  });
};
