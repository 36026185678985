// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, makeStyles, Link, Box } from '@material-ui/core';
import { Coin, PartnerBSN, ShoppingCart, PlaySquare, PasswordSecurity, Newspaper, Cybersecurity } from 'components/icons';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  borderBottom: {
    borderBottom: '1px solid var(--borderDefault)'
  },
  link: {
    color: 'var(--colorDark)',
    fontWeight: 500
  },
  img: {
    width: 70,
    minWidth: 70,
    height: 70,
    background: '#0A4D78',
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: 35,
      color: '#429DD7'
    }
  }
}));

const partnerAdminContent = [
  {
    title: "Service Overview & Pricing",
    link: "https://www.breachsecurenow.com/bsn-overview-pricing/",
    description: "Review resale product descriptions and partner pricing",
    icon: <Coin />
  },
  {
    title: "Partner Community Call",
    link: "https://attendee.gotowebinar.com/register/1697363805150263819",
    description: "Join us on the third Thursday of every month at 11 AM EST to discuss what's new and where we're heading next",
    icon: <PartnerBSN />
  },
  {
    title: "Go-to-Market Toolkit: Marketing, Sales, Onboarding",
    link:
      "https://www.breachsecurenow.com/wp-content/uploads/2021/03/Instruction-Document-Onboarding-Marketing-Toolkit.pdf",
    description: "Resources for rolling out training to your customers",
    icon: <ShoppingCart />
  }
];

const managerContent = [
  {
    title: "What is the Newsfeed?",
    link: "/media/newsfeed/Newsfeed Breakdown Manager.png",
    description: "Quick tips on how to engage with the Newsfeed",
    icon: <Newspaper />
  },
  {
    title: "Portal Walkthrough Video  ",
    link: "https://www.breachsecurenow.com/wp-content/uploads/2021/03/Manager-Portal-Tour.mp4",
    description: "Explore the portal, and all the elements it has to offer",
    icon: <PlaySquare />
  },
  {
    title: "Fostering Cybersecurity Culture",
    link: "https://www.dropbox.com/s/v76la5ednmi2k2z/Fostering%20Cybersecurity%20Culture.pdf?dl=0",
    description: "Guide to fostering an office culture around cybersecurity",
    icon: <Cybersecurity />
  }
];

const employeeContent = [
  {
    title: "What is the Newsfeed?",
    link: "/media/newsfeed/Newsfeed Breakdown Employee.png",
    description: "Quick tips on how to engage with the Newsfeed",
    icon: <Newspaper />
  },
  {
    title: "Portal Walkthrough Video",
    link: "https://www.breachsecurenow.com/wp-content/uploads/2021/03/Employee-Portal-Tour.mp4",
    description: "Explore the portal, and all the elements it has to offer",
    icon: <PlaySquare />
  },
  {
    title: "Top 5 Tips to Up Your Password Security Game",
    link: "https://www.dropbox.com/s/ru33ysb1vtnws82/Top%205%20Tips%20to%20Up%20Your%20Password%20Security%20Game.pdf?dl=0",
    description: "Review the do’s and don'ts of password security",
    icon: <PasswordSecurity />
  }
];

const suggestedList = {
  'Partner Administrator': partnerAdminContent,
  'Manager Admin': managerContent,
  'Manager': managerContent,
  'User': employeeContent
};

const SuggestedContent = () => {
  const classes = useStyles();
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);

  return (
    <Paper elevation={0}>
      <Box px={2.5}>
        <Box py={2} className={classes.borderBottom}>
          <Typography variant='subtitle2'>Suggested Content</Typography>
        </Box>

        <Box pb={1.5}>
          {suggestedList[userRole]?.map(item => (
            <Box key={item.title} className={classes.borderBottom} display="flex" alignItems="center" py={1.5}>
              <div className={classes.img}>
                {item.icon}
              </div>
              <Box pl={2}>
                <Link target="_blank" href={item.link} className={classes.link}>
                  {item.title}
                </Link>
                <Typography variant='link1' color='text.secondary' mt={0.5}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default SuggestedContent;
