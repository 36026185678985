import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';
import { bsnClientServicesBaseURL } from './bsnClientServices';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const baseURL = bsnClientServicesBaseURL;
const userAddApi = process.env.REACT_APP_BASE_URL_20;
const userAddBaseURL = `https://${userAddApi}.${base}/${env}`;
const baseURLUser = bsnClientServicesBaseURL;

const clients = {};
const DEFAULT_CONTENT_TYPE = 'application/json';

clients.getRequestHeaders = (includeAuthorization = false, contentType = DEFAULT_CONTENT_TYPE) => {
  return {
    'Content-Type': contentType,
    ...(includeAuthorization ? { Authorization: getStorage('idToken', true) } : {})
  };
};

clients.getClientInfo = async clientId => {
  const requestOptions = {
    url: `${baseURL}/clients/information/${clientId}`,
    headers: clients.getRequestHeaders(true)
  };
  const { data: clientInfo } = await apiClient.get(requestOptions);
  return clientInfo;
};

clients.getPresignedPost = (mimeType, clientId) => {
  const requestOptions = {
    url: `${baseURL}/clients/getPreSignedPost/${clientId}`,
    params: { mimeType },
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.uploadLogo = (url, formData) => {
  const requestOptions = {
    url,
    headers: clients.getRequestHeaders(false, 'multipart/form-data'),
    data: formData
  };
  return apiClient.post(requestOptions);
};

clients.updateClientAccess = (clientId, accessData) => {
  const requestOptions = {
    url: `${baseURL}/clients/access/${clientId}`,
    data: accessData,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.put(requestOptions);
};

clients.addClient = (client_id, data, params) => {
  // https://v11mklrp1c.execute-api.us-east-1.amazonaws.com/dev/clients/add/VFdjOVBRPT0=?_start=0&_end=25&_sort=name&_order=ASC&timestamp_cache=1667469051176
  const requestOptions = {
    url: `${userAddBaseURL}/clients/add/${client_id}`,
    data,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.post(requestOptions);
};

clients.getInformation = (clientId, section) => {
  const requestOptions = {
    url: `${baseURL}/clients/information/${section}/${clientId}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.putInformation = (clientId, section, data) => {
  const requestOptions = {
    url: `${baseURL}/clients/information/${section}/${clientId}`,
    data,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.patch(requestOptions);
};

clients.getInformationSettings = clientId => {
  const requestOptions = {
    url: `${baseURL}/clients/information/settings/${clientId}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.deleteInformationLogo = clientId => {
  const requestOptions = {
    url: `${baseURL}/clients/information/logo/${clientId}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.delete(requestOptions);
};

clients.getPickList = params => {
  const requestOptions = {
    url: `${baseURLUser}/clients/pickList`,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.get(requestOptions);
};

clients.setIndustryToClient = (client_id, data) => {
  const requestOptions = {
    url: `${baseURLUser}/clients/information/${client_id}`,
    headers: clients.getRequestHeaders(true),
    data
  };
  return apiClient.put(requestOptions);
};

clients.getClientReport = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/client_report`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.testCustomMessage = (client_id, data) => {
  const requestOptions = {
    url: `${baseURLUser}/clients/testcustomMessage/${client_id}`,
    headers: clients.getRequestHeaders(true),
    data
  };
  return apiClient.post(requestOptions);
};

clients.getCutomMessages = (client_id, params) => {
  const requestOptions = {
    url: `${baseURLUser}/clients/customMessage/${client_id}`,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.get(requestOptions);
};

clients.setCustomMessage = (client_id, params, data) => {
  const requestOptions = {
    url: `${baseURLUser}/clients/customMessage/${client_id}`,
    headers: clients.getRequestHeaders(true),
    params,
    data
  };
  return apiClient.post(requestOptions);
};

clients.revertCustomMessage = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/clients/customMessage/revert/${client_id}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.post(requestOptions);
};

/**
 * gets operation buttons status
 * @param clientId : client id
 * @returns buttons status
 */
clients.getOperationButtonsStatus = async clientId => {
  const requestOptions = {
    url: `${baseURL}/clients/usersOperations/${clientId}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.setSraAnnualLimit = (client_id, data) => {
  const requestOptions = {
    url: `${baseURL}/clients/sra-retakes/${client_id}/`,
    headers: clients.getRequestHeaders(true),
    data
  };
  return apiClient.patch(requestOptions);
};

clients.getClientReport = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/client_report`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getESSReport = (client_id, tags) => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/ess_report${tags?.length ? '?tags='.concat(tags) : ''}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getAssignedTrainingsReport = ({ clientId, selected }) => {
  const params = {};
  if (selected?.length) {
    params.user_ids = `${selected.join(',')}`;
  }
  const queryParams = new URLSearchParams(params).toString();
  const requestOptions = {
    url: `${baseURLUser}/${clientId}/assigned_training_report${queryParams.length ? `?${queryParams}` : ''}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getSelectedTrainingReport = ({ clientId, trainingId, selected }) => {
  const params = {};
  if (trainingId) {
    params.training_id = trainingId;
  }
  if (selected?.length) {
    params.user_ids = `${selected.join(',')}`;
  }
  const queryParams = new URLSearchParams(params).toString();
  const requestOptions = {
    url: `${baseURLUser}/${clientId}/selected_training_report${queryParams.length ? `?${queryParams}` : ''}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getWorkplanReport = (workplan_id, params = {}) => {
  const requestOptions = {
    url: `${baseURLUser}/workplan_report/${workplan_id}`,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.get(requestOptions);
};

clients.getLastWorkplan = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/clients/workplan-info/${client_id}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getInterviewQuestions = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/interview_questions`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getOrgProfileCSV = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/organization_profile`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getSraAnswers = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/sra_answers`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getSRAppendixReport = client_id => {
  const requestOptions = {
    url: `${baseURLUser}/${client_id}/sra_appendix`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.getSraAnnualLimit = client_id => {
  const requestOptions = {
    url: `${baseURL}/clients/sra-retakes/${client_id}/`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

clients.setSraAnnualLimit = (client_id, data) => {
  const requestOptions = {
    url: `${baseURL}/clients/sra-retakes/${client_id}/`,
    headers: clients.getRequestHeaders(true),
    data
  };
  return apiClient.patch(requestOptions);
};

clients.getWelcomeMessageVideo = (partner_id) => {
  const requestOptions = {
    url: `${baseURL}/clients/welcome-msg-video/${partner_id}`,
    headers: clients.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

export default clients;
