// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import grey from '@material-ui/core/colors/grey';
import { Grid, Typography, TableCell, Stepper as StepperMui } from '@material-ui/core';

export const GridContainer: ComponentType<*> = styled(Grid).attrs({
  container: true,
  spacing: 24
})`
  padding: 0;
`;

export const GridItem: ComponentType<*> = styled(Grid).attrs({
  item: true
})`
  margin-bottom: 10px;
`;

export const CampaignFooter: ComponentType<*> = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CampaignDescription: ComponentType<*> = styled(Typography)`
  font-size: 12px;
`;

export const CampaignIntroductionDescription: ComponentType<*> = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 15px;

  > strong {
    margin-right: 5px;
    margin-left: 5px;
  }

  > span {
    margin-right: 20px;
  }

  > svg {
    margin-right: 20px;
    font-size: 20px;
  }
`;

export const NumberLabel: ComponentType<*> = styled.strong`
  font-size: 18px;
  margin-right: 5px;
`;

export const CampaignReviewLabel: ComponentType<*> = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
`;

export const CircleReviewLabel: ComponentType<*> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 30px;
  margin: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${grey[400]};
  opacity: ${props => (props.active ? '1' : '0.4')};
`;

export const GridItemReview: ComponentType<*> = styled(Grid).attrs({
  item: true
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const CollapseLabel: ComponentType<*> = styled(Typography)`
  margin: 10px 0;
  font-weight: bold;
`;

export const CollapseDescription: ComponentType<*> = styled(Typography)`
  margin: 0;
`;

export const GridItemSummary: ComponentType<*> = styled(Grid).attrs({
  item: true
})`
  margin: 0;
  padding: 6px 12px !important;
`;

export const TableWrapper: ComponentType<*> = styled.div`
  margin: 20px 0;
  max-height: 400px;
  overflow: auto;
`;

export const TableHeadCellPadding: ComponentType<*> = styled(TableCell)`
  color: #fff;
  padding: 0 6px !important;
`;

export const TableBodyCellPadding: ComponentType<*> = styled(TableCell)`
  padding: 0 6px !important;
`;

export const Stepper: ComponentType<*> = styled(({ color, ...props }) => (
  <StepperMui
    {...props}
    classes={{
      root: `steperRoot`
    }}
  />
))`
  &.steperRoot {
    background-color: var(--backgroundDefault);
    padding: 24px 12px 12px 12px;
  }
`;
