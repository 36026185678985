/* eslint-disable no-nested-ternary */
// @flow
import React, { memo, useState } from 'react';
import styled from 'styled-components';
import {
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  ListItemText as MuiListItemText
} from '@material-ui/core';
import { SearchField, Modal3, Dialog, CustomTooltip, PermanentDeleteModal } from 'components';
import { Button, AddIcon, Tooltip, Typography, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { dataProvider, getStorage } from 'helpers';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { LazyIcon } from '../icons';
import { ActionButton, ToolbarContent, ToolbarRow, TableCellAlign, TableCellAlignRight } from './ComponentTypes';
import { useTableList } from './TableListContext';
import { useDispatch } from 'react-redux';
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction';

const fontSize = 14;

const ContainerGrow = styled(Popper)`
  && {
    z-index: 1000;
    top: inherit !important;
    left: inherit !important;
    right: 0px !important;
    transform: inherit !important;
  }
`;

const SearchWrap = styled.div`
  width: 100%;
  max-width: 230px;
  .variant-standard {
    max-width: 100%;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: auto;
    flex-grow: 1;
  }
`;

export const ListItemText = styled(MuiListItemText)`
  color: ${({ color }) => (color ? `var(--${color})` : 'var(--colorDefault)')};
`;

const TableToolbar = ({ setToolbar, tableActions, toolbarExtra, topToolbar, centerToolbarItems }) => {
  const dispatch = useDispatch()
  const {
    app,
    tab,
    item,
    open,
    openDialog,
    openPermanentDelete,
    settings: { selecting, search, searchFields, searchLabel, actions, button },
    selected,
    dispatch: { setOpen, setOpenDialog, setOpenPermanentDelete },
    dispatch: dispatchTable,
    disableTableAction,
    additionalRefetch,
    disabeldActions,
    prefilledSearch,
    setModalAction,
    record
  } = useTableList();
  const numSelected = selected.length;
  const [anchorEl, setAnchorEl] = useState(null);
  let newActions = actions;

  if (tableActions) {
    const actionsKeys = Object.keys(tableActions);
    const newKeys = actionsKeys.filter(row => tableActions[row] === true);
    newActions = actions.filter(row => {
      const filteredAction = newKeys.filter(key => key === row.name);

      return row.name === filteredAction[0];
    });
  }

  if (!search && !setToolbar && !button && !newActions) return null;

  const isActions = newActions && Array.isArray(newActions) && newActions.length > 0;

  const isCurrentUser = getStorage('userId');

  const onClick = method => {
    setOpenDialog(false);
    setOpenPermanentDelete(false);
    dispatch(resetCreateUserMatchingEmailError())

    if (app === `clients` && tab === `usersList` && method === `deleteUsers`) {
      usersAPI
        .deleteClientUsersActions(String(item), { ids: selected })
        .then(res => {
          dispatchTable.setSelected([]);
          enqueueAlertSnackbar(`${res.data?.description}`, { props: { severity: 'error' } });
        })
        .catch(error => enqueueAlertSnackbar(`${error.response.status}`, { props: { severity: 'error' } }))
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === `clients` && tab === `usersList` && method === `permDeleteUser`) {
      usersAPI
        .deleteClientUsersActions(String(item), { type: method, ids: selected })
        .then(res => {
          dispatchTable.setSelected([]);
          enqueueAlertSnackbar(`${res.data?.description}`, { props: { severity: 'error' } });
        })
        .catch(error => enqueueAlertSnackbar(`${error.response.status}`, { props: { severity: 'error' } }))
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === `clients` && tab === `usersList` && method !== `deleteUsers`) {
      usersAPI
        .postClientUsersActions(String(item), { type: method, ids: selected })
        .then(res => {
          if (method === 'delete') dispatchTable.setSelected([]);
          if (method === 'deactivate' || method === 'delete') {
            enqueueAlertSnackbar(`${res.data?.description}`, { props: { severity: 'error' } });
          }  else if (method === "activate") {
            const isAllClientActivated = selected.every(id => {
              const client = record.find(client => client.id === id);
              return client?.active
            });
            const severity = isAllClientActivated ? 'error' : 'info'
            enqueueAlertSnackbar(`${res.data?.description}`, {
              props: {severity}
            });
          } else {
            enqueueAlertSnackbar(res.data?.description, { props: { severity: 'success' } });
          }
        })
        .catch(error => {
          enqueueAlertSnackbar(`${error?.response?.data?.description}`, { props: { severity: 'error' } });
        })
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === `clients` && tab === 'tags' && method === 'delete') {
      dataProvider
        .delete(app, `${String(tab)}/${String(item)}`, null, { id: selected })
        .then(res => {
          enqueueAlertSnackbar(`${res.data}`, { props: { severity: 'error' } });
          dispatchTable.setSelected([]);
        })
        .catch(error =>
          enqueueAlertSnackbar(
            error.response?.data?.message ? `${error.response.data.message}` : `${error.response.data.description}`,
            { props: { severity: 'error' } }
          )
        )
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === 'clients' && tab === 'phishingReportsRecipients' && method === 'resetPhishingItem') {
      dispatchTable[method](selected);
      if (additionalRefetch) additionalRefetch();
    } else {
      dispatchTable[method]();
    }
  };

  return (
    <>
      {/* {topToolbar && <ToolbarRow>{topToolbar}</ToolbarRow>} */}
      <ToolbarContent variant="dense">
        <ToolbarRow>
          <TableCellAlign style={{ alignItems: centerToolbarItems && 'center' }}>
            {search && (
              <SearchWrap style={{ marginLeft: '1.5rem', marginTop: '6px' }}>
                <SearchField
                  label={searchLabel}
                  fullWidth
                  location={app}
                  tab={tab}
                  id={item}
                  fields={searchFields}
                  prefilledSearch={prefilledSearch}
                />
              </SearchWrap>
            )}
            {setToolbar}

            {button && button.show && (
              <>
                <Button sx={{ ml: 2 }} onClick={() => dispatchTable[button.onClick](true)}>
                  {button.label}
                </Button>
                <Modal3 open={open} setOpen={setOpen} app="clients" tab={tab} createNew />
              </>
            )}
          <TableCellAlignRight>
            {selecting && numSelected > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                <small>{numSelected} selected&nbsp;&nbsp;</small>
              </Typography>
            ) : (
              ''
            )}
            {isActions ? (
              newActions.length > 3 ? (
                <div>
                  <Tooltip
                  placement="top"
                  title={
                    <Typography variant='caption'>
                      Prior to performing any available Actions, you must first select the users' credentials by clicking on the checkbox (on the left) either individually or by using the batch select checkbox on top.
                    </Typography>
                  }>
                    <Button
                      onClick={e => setAnchorEl(e.currentTarget)}
                      startIcon={<AddIcon />}
                    >
                      Actions
                    </Button>
                  </Tooltip>
                  <ContainerGrow open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <MenuList autoFocusItem={Boolean(anchorEl)} id="simple-menu-action">
                              {newActions.map(({ activateOnSelect = true, ...a }, index) => (
                                <MenuItem
                                  //  eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  disabled={
                                    (activateOnSelect && selected.length === 0) ||
                                    disabeldActions.includes(a.name) ||
                                    (selected.includes(isCurrentUser) &&
                                      (a.name === 'inactive' || a.name === 'delete'))
                                  }
                                  onClick={e => {
                                    if (!e.key || (e.key && e.key !== 'Enter' && e.keyCode !== 32)) {
                                      if (a.method === 'delete') {
                                        setOpenDialog(true);
                                      } else if (a.method === 'permDeleteUser') {
                                        setOpenPermanentDelete(true);
                                      } else if (a.method === 'moveUser') {
                                        setModalAction({ open: true, action: a.method, item, dispatchTable });
                                      } else {
                                        dispatchTable.setSelected([]);
                                        onClick(a.method);
                                      }
                                      setAnchorEl(null);
                                    }
                                  }}
                                >
                                  <LazyIcon
                                    component={a.icon}
                                    color={a.color || 'colorDefault'}
                                    strokeColor={a.icon === 'Enable' && 'colorDefault'}
                                    ml={a.icon !== 'WelcomeMessage' && 0.6}
                                    mr={1}
                                    size={a.icon === 'WelcomeMessage' ? 1.5 : a.icon === 'UserChecked' ? 1.5 : 1}
                                  />
                                  <ListItemText color={a.color}>{a.title}</ListItemText>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </ContainerGrow>
                </div>
              ) : (
                newActions.map(({ activateOnSelect = true, ...a }, index) => {
                  return (
                    <>
                      <CustomTooltip title={a.title}>
                        <ActionButton
                          //  eslint-disable-next-line react/no-array-index-key
                          key={index}
                          aria-label={a.title}
                          disabled={(activateOnSelect && selected.length === 0) || disableTableAction}
                          onClick={() => {
                            if (a.method === 'delete') {
                              setOpenDialog(true);
                            } else {
                              dispatchTable.setSelected([]);
                              onClick(a.method);
                            }
                          }}
                        >
                          <LazyIcon
                            component={a.icon}
                            fontSize="small"
                            style={{ color: 'var(--iconDefaultcolor)', fontSize }}
                          />
                        </ActionButton>
                      </CustomTooltip>
                    </>
                  );
                })
              )
            ) : (
              ''
            )}
            {/* {selecting && numSelected > 0 ? (
            <div>
              {filter && (
                <Tooltip title="Filter">
                  <IconButton aria-label="filter" onClick={onFilter(!filter)}>
                    <FilterListIcon fontSize="medium" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {reset ? (
                <Tooltip title="Reset">
                  <IconButton aria-label="reset">
                    <ReplayIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete">
                    <DeleteIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {type === 'clients' && (
                <>
                  <Tooltip title="Active">
                    <IconButton aria-label="active">
                      <DoneIcon fontSize="small" style={{ fontSize }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="lock">
                    <IconButton aria-label="lock">
                      <LockIcon fontSize="small" style={{ fontSize }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          ) : (
            <div>
              {filter && (
                <Tooltip title="Filter">
                  <IconButton aria-label="filter" onClick={onFilter(!filter)}>
                    <FilterListIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {reset ? (
                <Tooltip title="Reset">
                  <IconButton aria-label="reset">
                    <ReplayIcon fontSize="small" style={{ color: '#ff00007a', fontSize }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete">
                    <DeleteIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {type === 'clients' && (
                <>
                  <IconButton aria-label="active" disabled>
                    <DoneIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                  <IconButton aria-label="lock" disabled>
                    <LockIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </>
              )}
            </div>
          )} */}
          </TableCellAlignRight>
          </TableCellAlign>
        </ToolbarRow>
        {toolbarExtra && <ToolbarRow>{toolbarExtra}</ToolbarRow>}
      </ToolbarContent>
      <Dialog
        title="Delete"
        message="This information will be deleted. Please confirm if you would like to delete?"
        buttonSubmit="Yes, I want to delete"
        open={openDialog || false}
        setOpen={setOpenDialog}
        maxWidth="xs"
        onSubmit={() => {
          setOpenDialog(isOpen => {
            // if delete confirmation clicked multiple times only execute first time
            // (when previous state of openDialog is true).
            isOpen && onClick('delete');
            // close the dialog
            return false;
          });
        }}
      />

      <PermanentDeleteModal
        open={openPermanentDelete || false}
        close={setOpenPermanentDelete}
        onDelete={() => {
          setOpenPermanentDelete(isOpen => {
            isOpen && onClick('permDeleteUser');
            return false;
          });
        }}
        title="Permanent delete item?"
        description={`Are you sure you want to permanently delete
        ${numSelected} user(s)?`}
      />
    </>
  );
};

export default memo<TableToolbarTypes>(TableToolbar);
