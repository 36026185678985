import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import contentAdmin from 'helpers/apis/contentAdmin';
import styled from 'styled-components';
import { LoadingStyled } from 'components';
import { enqueueAlertSnackbar, Typography, MailIcon, MenuIcon, Stack, Box } from '@trustsecurenow/components-library';
import CustomDrawer from '../components/inboxPage/drawer';
import EmailViewer from '../components/inboxPage/emailViewer';
import FiltersBar from '../components/inboxPage/FiltersBar';
import { CONTENT_ADMIN } from '../constants';
import { useCheckAccess } from 'helpers';

const Container = styled.div`
  height: 80vh;
  margin: 40px 5px 5px 5px;
  background-color: var(--backgroundDefault);
  display: flex;
  flex-direction: column;
`;

const DrawerContainer = styled.div`
  height: 100%;
  flex-basis: ${props => props.flex || 30}%;
`;
const EmailViewerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: ${props => props.flex || 70}%;
`;

const ContentAdminInboxPage = () => {
  /**
   * mostly using Refs for window eventlisteners,
   * using react State for react components.
   * as Refs won't trigger UI update when changes
   * and window eventlisteners won't pickup changes in react State.
   */
  const [open, setOpen] = useState(true);
  const emailsRef = useRef();
  const [emails, setEmails] = useState(null);
  emailsRef.current = emails;
  const [selectedEmail, setSelectedEmail] = useState(null);
  const loadingRef = useRef(false);
  const [loadingState, setLoadingState] = useState(false);
  loadingRef.current = loadingState;
  const itemsPerPage = useRef(10);
  const currentPage = useRef(0);
  const [filters, setFilters] = useState({
    partner_name: '',
    client_name: '',
    partner_id: '',
    client_id: '',
    email_template: '',
    // it's null instead of empty string
    // so MUI displays the initial value as an empty date
    delivery_date: null,
    sortingField: 'delivery_date',
    sortingDirection: 'DESC'
  });
  const filtersRef = useRef();
  filtersRef.current = { ...filters };

  const hasAccess = useCheckAccess('app', CONTENT_ADMIN);

  const toggleDrawer = () => {
    setOpen(v => !v);
  };

  const getInboxEmailsList = async (filtersParam, reset = false) => {
    try {
      const start = currentPage.current * itemsPerPage.current;
      const end = itemsPerPage.current;
      setLoadingState(true);
      const data = await contentAdmin.getInboxEmailsList(start, end, filtersParam);
      if (emailsRef.current === null || reset) {
        setEmails([...data.data]);
      } else {
        setEmails([...emailsRef.current, ...data.data]);
      }
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      enqueueAlertSnackbar(error.message, { props: { severity: 'error' } });
    }
  };

  const applyFilters = useCallback(newFilters => {
    setFilters(oldFilters => {
      return { ...oldFilters, ...newFilters };
    });
    setSelectedEmail(null);
    currentPage.current = 0;
    getInboxEmailsList({ ...newFilters }, true);
  }, []);

  const onReachingScrollEnd = () => {
    if (loadingRef.current) return;
    currentPage.current += 1;
    getInboxEmailsList(filtersRef.current, false);
  };

  useEffect(() => {
    getInboxEmailsList(filters, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (emails === null) return <LoadingStyled />;

  if (!hasAccess && hasAccess !== null) return <div>You don't have access for this page</div>;

  return (
    <Container className="ContentAdminInboxPage_container">
      <Box>
        <FiltersBar filters={filters} applyFilters={applyFilters} />
      </Box>
      <Stack direction="row">
        <DrawerContainer className="ContentAdminInboxPage_drawerContainer" flex={open ? 25 : 5}>
          {open ? (
            <CustomDrawer
              open={open}
              onClose={() => setOpen(false)}
              onRefresh={() => {
                currentPage.current = 0;
                getInboxEmailsList(filters, true);
              }}
              onReachingScrollEnd={onReachingScrollEnd}
            >
              {emails.map((email, index) => {
                return (
                  <ListItem
                    disabled={loadingState}
                    button
                    key={email.id}
                    onClick={() => {
                      setSelectedEmail({ ...email });
                    }}
                    selected={selectedEmail !== null && email.id === selectedEmail.id}
                    style={{ borderBottom: '2px solid #eee' }}
                  >
                    <ListItemIcon style={{ fontSize: 25 }}>
                      <MailIcon sx={{ fontSize: 24 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="button2" my={2} component="p">
                          {email.from_name}
                        </Typography>
                      }
                      secondary={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px'
                          }}
                        >
                          <Typography my={2} color="info.main" textOverflow="ellipsis" flexBasis="70%" overflow="hidden">
                            {email.subject.slice(0, 25)}...
                          </Typography>
                          <Typography variant="link1" color="info.main" flexBasis="25%" my={2}>
                            {email.delivery_date}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                );
              })}
            </CustomDrawer>
          ) : (
            <IconButton style={{ width: 30, height: 30 }} onClick={toggleDrawer} color="primary">
              <MenuIcon sx={{ fontSize: 40 }} />
            </IconButton>
          )}
        </DrawerContainer>
        <EmailViewerContainer className="ContentAdminInboxPage_emailViewerContainer" flex={open ? 75 : 95}>
          <EmailViewer email={selectedEmail} />
        </EmailViewerContainer>
      </Stack>
    </Container>
  );
};

export default ContentAdminInboxPage;
