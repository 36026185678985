// @flow

import React, { type ComponentType, useCallback } from 'react';
import { connect } from 'react-redux';
import type { Element } from 'react';
import { Badge, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { transitions } from 'conf';
import { useApp } from 'helpers';
import { useLocation } from 'hooks';
import { CustomTooltip } from 'components';
import { Link } from 'react-router-dom';
import { Box } from '@trustsecurenow/components-library';
import { LazyIcon } from '../../icons';
import { layoutToolbarBottomData /* , layoutToolbarTopData */ } from '../helper';

export const ToolbarMenu: ComponentType<*> = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background-color: var(--colorBaseDark2);
  color: var(--colorCommonWhite);
  /* TODO: Change CSS Variables */
  transition: all all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const ToolbarMenuContent: ComponentType<*> = styled.div`
  display: flex;
  height: 175px;
  flex-direction: column;
  justify-content: ${({ align }) => (align ? `flex-${align}` : `initial`)};

  svg {
    font-size: calc(var(--fontSize) * 1.2);
    color: var(--colorCommonWhite);
  }
`;

export const ToolbarMenuBadge: ComponentType<*> = styled(Badge)`
  span {
    background: var(--colorSystemDanger);
    right: 4px;
  }
`;

const Toolbar = (): Element<*> => {
  const { app, item } = useLocation('clientId');
  const { dispatch } = useApp();

  const handleEmptyTabs = () => {
    dispatch.changeTab(app, null, null, item);
  };

  const actionClick = useCallback(
    link => {
      const [, appLink, tabLink] = link.split('/');

      dispatch.changeTab(appLink, tabLink, 'dashboard', null, false);
    },
    [dispatch]
  );

  return (
    <ToolbarMenu>
      <ToolbarMenuContent />
      <ToolbarMenuContent align="end">
        {layoutToolbarBottomData.map(({ color, size, icon, id, badge, link, label }) => (
          <CustomTooltip title={label} placement="right" key={id}>
            <Box px={1.125} py={0.5}>
              <IconButton
                color={color}
                size={size}
                to={link}
                component={Link}
                onClick={() => (link === '/logout' ? handleEmptyTabs() : actionClick(link))}
              >
                {badge ? (
                  <ToolbarMenuBadge color="secondary" variant="dot">
                    <LazyIcon component={icon} />
                  </ToolbarMenuBadge>
                ) : (
                  <LazyIcon component={icon} />
                )}
              </IconButton>
            </Box>
          </CustomTooltip>
        ))}
      </ToolbarMenuContent>
    </ToolbarMenu>
  );
};

export default connect(() => ({}))(Toolbar);
