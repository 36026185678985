/* eslint-disable react/prop-types */
// @flow
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useConfig, useTableRowSelected } from 'hooks';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { hasKey } from 'helpers';
import UsersListActionsMobile from 'apps/shared/users/mobile/UsersListActionsMobile';
import UsersListNewUserMobile from 'apps/shared/users/mobile/UsersListNewUserMobile';

import { transformUserPhoneNumberData } from 'apps/shared/users/ClientsUsers';
import { TableHead, TableToolbar } from '.';
import {
  PaperContent,
  TableResponsive,
  TableContent,
  TableBodyContent,
  TableRowContent,
  TableCellContent,
  ToolbarRow
} from './ComponentTypes';
import { useTableList } from './TableListContext';
import { TableListNoResults, TableListSelecting, TableListCell } from './containers';
import CustomTablePagination from '../table/pagination/CustomTablePagination';
import UsersListToolbarMobile from '../../apps/shared/users/mobile/UsersListToolbarMobile';

const TableRows = ({
  tableList,
  tableRowSelected,
  system,
  settings,
  hideColumns,
  hiddenCell,
  responsive,
  isSelected,
  openNewModal,
  isSelectedCallback,
  customCells
}) => {
  const [collapsedRowIdx, setCollapsedRowIdx] = useState(null);

  useEffect(() => {
    setCollapsedRowIdx(null);
  }, [tableList]);

  const handleCollapseClick = clickedIndex => {
    setCollapsedRowIdx(idx => {
      const isCollapsed = idx === clickedIndex;
      return isCollapsed ? null : clickedIndex;
    });
  };

  return (
    <>
      {tableList.map((row, index) => {
        const rowId = String(row.id);

        let isItemSelected = isSelected(row.id) || tableRowSelected.filter(t => String(t) === rowId)[0] === rowId;
        if (tableRowSelected[0] === 'all') {
          isItemSelected = !system.tableRowUnselected.includes(rowId);
        }

        const isCollapsed = collapsedRowIdx === index;
        const is_selected_cb = isSelectedCallback?.(row);
        return (
          <>
            <TableRowContent
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={is_selected_cb || isItemSelected}
              data-ga-button={settings?.row?.GAbutton}
            >
              {!hiddenCell && responsive && <TableCellContent />}
              <TableListSelecting row={row} index={index} isItemSelected={isItemSelected} />
              <TableListCell
                {...{
                  index,
                  row,
                  openNewModal,
                  isCollapsed,
                  handleCollapseClick,
                  isSelected: isItemSelected || is_selected_cb,
                  customCells
                }}
                hideColumns={hideColumns}
              />
              {!hiddenCell && responsive && <TableCellContent />}
            </TableRowContent>
            {isCollapsed && (
              <TableRowContent>
                <TableCellContent colSpan={settings.cells.length} collapse>
                  <div style={{ padding: '5px 15px' }}>
                    <b>{settings?.row?.collapseContent.label}: </b> {row[settings?.row?.collapseContent.field]}
                  </div>
                </TableCellContent>
              </TableRowContent>
            )}
          </>
        );
      })}
    </>
  );
};

type TableListTableType = {
  tableSettings?: Object,
  data?: Object,
  source?: string,
  id?: string,
  onSearch?: Function,
  cache?: boolean,
  tableWrapperStyle: React.CSSProperties,
  tableHeadStyle: React.CSSProperties
};

const TableListTable = ({ tableSettings, data, source, id, onSearch, cache = true, centerToolbarItems = null, tableWrapperStyle, tableHeadStyle }: TableListTableType) => {
  const muiTheme = useTheme();
  const mobileView = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const {
    app,
    tab,
    settings,
    record,
    order,
    orderBy,
    selected,
    page,
    open,
    setOpenModal, // setOpen from useModalConfig.js
    openModal,
    responsive = true,
    dispatch: {
      handleRequestSort,
      handleSelectAllClick,
      handleChangeRowsPerPage,
      setNewPage,
      setOpen,
      setPassword,
      isSelected,
      setSelected,
      setRefresh
    },
    toolbar,
    toolbarExtra,
    topToolbar,
    toolbarMobile,
    tableActions,
    total,
    status,
    isFetching,
    modalTitle,
    rowsPerPage,
    hideColumns,
    isSelectedCallback,
    hideColumnsCallback,
    renderNoDataContent,
    showSpinnerOnFetching = false,
    keepSpinner = false,
    prefilledSearch = '',
    isDwFilterApplied = false,
  } = useTableList();

  const dispatchRx = useDispatch();

  const openNewModal = useCallback(
    rowId => {
      const app = settings?.row?.modal?.app;
      const payload = { [tab]: { ...GetConfig(app, tab) } };

      dispatchRx({
        type: 'BSN_SET_CONFIG_MODAL',
        resource: 'modals',
        tab: app,
        payload
      });

      setOpenModal(true, rowId);
    },
    [settings?.row?.modal?.app, tab, setOpenModal]
  );
  const hiddenCell = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { pagination } = useConfig('system', 'table');
  const system = useSelector(rxState => rxState.bsn.system);
  const { tableRowSelected } = useTableRowSelected();
  if (!settings) return <h1>API does not exist</h1>;
  const { total: sTotal } = settings;
  const tableList = record;

  const hasFetched = !keepSpinner ? !isFetching && status === 'success' : false;
  const hasRecords = (tableList || []).length > 0;
  const newHideColumns =
    typeof hideColumnsCallback === 'function' ? hideColumnsCallback({ hasRecords, hideColumns }) : hideColumns ?? [];

  return (
    <>
      <Box mt={1}>{topToolbar}</Box>
    <PaperContent
      style={tableWrapperStyle}
    >
      {toolbarMobile || (
        <TableToolbar
          setToolbar={toolbar}
          tableActions={tableActions}
          toolbarExtra={toolbarExtra}
          centerToolbarItems={centerToolbarItems}
          // topToolbar={}
        />
      )}
      <TableResponsive>
        <TableContent isFetching={isFetching} aria-labelledby="tableTitle" size="medium">
          <TableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={record.length}
            tableSettings={settings}
            onSearch={onSearch}
            isFilter={false}
            showPassword={setPassword}
            record={tableList[0]}
            hideColumns={newHideColumns}
            responsive={responsive}
            isFetching={isFetching}
            style={tableHeadStyle}
          />
          {hasRecords ? (
            <TableBodyContent isFetching={isFetching}>
              {keepSpinner || (isFetching && showSpinnerOnFetching) ? (
                <TableListNoResults
                  fetched={hasFetched}
                  colNumber={settings?.cells?.length + (!hiddenCell && responsive ? 3 : 1)}
                />
              ) : (
                <TableRows
                  {...{
                    tableList,
                    tableRowSelected,
                    system,
                    settings,
                    hideColumns: newHideColumns,
                    hiddenCell,
                    responsive,
                    isSelected,
                    openNewModal,
                    isSelectedCallback
                  }}
                />
              )}
            </TableBodyContent>
          ) : (
            <TableBodyContent>
              <TableListNoResults
                fetched={hasFetched}
                colNumber={settings?.cells?.length + (!hiddenCell && responsive ? 3 : 1)}
                emptyView={renderNoDataContent}
                isSearchApplied={prefilledSearch}
                isDwFilterApplied={isDwFilterApplied}
              />
            </TableBodyContent>
          )}
        </TableContent>
      </TableResponsive>

      {tableList.length !== 0 && (
        <CustomTablePagination
          {...pagination}
          totalCount={total || sTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={setNewPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          isFetching={isFetching}
        />
      )}
      {app === 'clients' && tab === 'usersList' && mobileView ? (
        <>
          <UsersListActionsMobile selectedUsers={selected} setSelected={setSelected} setRefresh={setRefresh} />
          <UsersListNewUserMobile setRefresh={setRefresh} transformBeforeSubmit={transformUserPhoneNumberData} />
        </>
      ) : null}
    </PaperContent>
  </>);
};

TableListTable.defaultProps = {
  data: null,
  source: '',
  id: '',
  tableSettings: null,
  onSearch: e => e,
  cache: true
};

function GetConfig(app, tab) {
  return useConfig('modals', app, tab);
}

export default TableListTable;
