// @flow
import React, { type ComponentType } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ChartColorPercent, getESSScoreColor } from 'helpers';
import DashboardBoxTitle from '../dashboard/DashboardBoxTitle';

type SelectedBulletTypes = {
  viewBox: string,
  color: string
};

type ChartCircleTypes = {
  title: string,
  description?: string | null,
  chart: Object,
  labels?: boolean | null,
  showOf?: boolean | null,
  showLegends?: boolean | null,
  showTitle?: boolean | null,
  className?: string
};

const halfCircleAnimation = keyframes`
  from {
    stroke-dashoffset: var(--fill);
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const ContainerHalfCircle: ComponentType<*> = styled.div`
  width: 100%;
`;

const TextCurrentValueHalfCircle: ComponentType<*> = styled.text`
  font-size: calc(var(--fontSize) * 1.2);
  font-weight: var(--fontWeightMedium);
  fill: var(--colorDefault);
`;

const TextTotalValueHalfCircle: ComponentType<*> = styled.text`
  font-size: calc(var(--fontSize) / 3);
  font-weight: var(--fontWeightLight);
  fill: var(--colorDefault);
`;

const TextTotalValueActiveHalfCircle: ComponentType<*> = styled.text`
  fill: var(--colorDark);
`;

const GroupTextLegendsHalfCircle: ComponentType<*> = styled.g`
  font-size: calc(var(--fontSize) / 3.5);
  font-weight: var(--fontWeightLight);
  fill: var(--colorDefault);
`;

const TextLegendHalfCircle: ComponentType<*> = styled.text``;

const BulletLegendHalfCircle: ComponentType<*> = styled.circle`
  fill: ${({ color }) => `var(--colorSystem${color})`};
`;

const SvgBulletSelectedLegendHalfCircle: ComponentType<*> = styled.svg`
  fill: ${({ color }) => `var(--colorSystem${color})`};
`;

const BulletEmptyLegendHalfCircle: ComponentType<*> = styled.circle`
  fill: none !important;
`;

const BulletSelectedLegendHalfCircle: ComponentType<*> = styled.g``;

const PathBulletSelectedLegendHalfCircle: ComponentType<*> = styled.path``;

const SvgHalfCircle: ComponentType<*> = styled.svg`
  --path: 100%;
  --fill: ${({ fillValue }) => `${fillValue}%`};
  --none: transparent;
  transition: .2s;

  ${({isActive}) => isActive && css`
    filter: drop-shadow(0 0 8px #6392B1);
  `}
`;

const HalfCircleBackground: ComponentType<*> = styled.path`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 0.9);
  stroke-linecap: round;
  stroke: var(--colorGreyLight3);
  stroke-dasharray: calc(var(--path) * 2);
`;

const HalfCircleForeground: ComponentType<*> = styled.path`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 0.9);
  stroke-linecap: round;
  stroke: ${({ fillValue, invert, percentType }) => `var(--colorSystem${ChartColorPercent(fillValue, invert, "", percentType)})`};
  stroke-dasharray: calc(var(--fill) * 1.4) calc(var(--path) * 2);
  stroke-dashoffset: var(--fill) calc(var(--path) * 2);
  animation: ${halfCircleAnimation} 1s linear;
`;

const SelectedBullet = ({ viewBox, color }: SelectedBulletTypes) => {
  return (
    <SvgBulletSelectedLegendHalfCircle viewBox={viewBox} color={color}>
      <BulletSelectedLegendHalfCircle transform="translate(-492 -470)">
        <BulletLegendHalfCircle cx="501" cy="479" r="4" />
        <BulletSelectedLegendHalfCircle transform="translate(492 470)">
          <BulletEmptyLegendHalfCircle cx="9" cy="9" r="9" />
          <PathBulletSelectedLegendHalfCircle d="M9,0C4,0,0,4,0,9c0,5,4,9,9,9c5,0,9-4,9-9C18,4,14,0,9,0z M9,16.5c-4.1,0-7.5-3.4-7.5-7.5S4.9,1.5,9,1.5    s7.5,3.4,7.5,7.5S13.1,16.5,9,16.5z" />
        </BulletSelectedLegendHalfCircle>
      </BulletSelectedLegendHalfCircle>
    </SvgBulletSelectedLegendHalfCircle>
  );
};

const ChartHalfCircle = ({
    title,
    description,
    chart,
    labels,
    showOf = true,
    showLegends = true,
    showTitle = true,
    className,
    isActive = false,
    viewbox = "0 0 86 86",
    invert = false,
    percentType = false,
}: ChartCircleTypes) => {
  const { chartData } = chart;
  const SIZE = chartData[0].name ? chartData[0].name.toString().length : '0';
  return (
    <>
      {labels && showTitle && (
        <DashboardBoxTitle label={title} description={description} fontSize={13} icon iconSize={1.3} />
      )}
      <ContainerHalfCircle className={className}>
        <SvgHalfCircle isActive={isActive} viewBox={viewbox} fillValue={chartData[0].value} role="img">
          <HalfCircleBackground d="M5 50 A 20 20 10 0 1 80 50" />
          <HalfCircleForeground
            d="M5 50 A 20 20 10 0 1 80 50"
            className="chart-halfcircle-foregournd"
            fillValue={chartData[0].value}
            invert={invert}
            percentType={percentType}
          />
          {labels && (
            <>
              {/* eslint-disable-next-line no-nested-ternary */}
              <TextCurrentValueHalfCircle x={SIZE === 3 || SIZE === 4 ? `29` : SIZE === 2 ? `33` : `38`} y="52">
                {chartData[0].name}
              </TextCurrentValueHalfCircle>
              {showOf && (
                <TextTotalValueHalfCircle x="36" y="60">
                  of 800
                </TextTotalValueHalfCircle>
              )}
              {showLegends && (
                <GroupTextLegendsHalfCircle>
                  {getESSScoreColor(chartData[0].value) === 'Danger' ? (
                    <>
                      <SelectedBullet viewBox="-19 -192 250 250" color="Danger" />
                      <TextTotalValueActiveHalfCircle x="5" y="78">
                        0/499
                      </TextTotalValueActiveHalfCircle>
                    </>
                  ) : (
                    <>
                      <BulletLegendHalfCircle cx="10.5" cy="69" r="1.5" color="Danger" />
                      <TextLegendHalfCircle x="5" y="78">
                        0/499
                      </TextLegendHalfCircle>
                    </>
                  )}
                  <TextLegendHalfCircle x="6" y="85">
                    Poor
                  </TextLegendHalfCircle>
                  {getESSScoreColor(chartData[0].value) === 'Warning' ? (
                    <>
                      <SelectedBullet viewBox="-116 -192 250 250" color="Warning" />
                      <TextTotalValueActiveHalfCircle x="35" y="78">
                        500/629
                      </TextTotalValueActiveHalfCircle>
                    </>
                  ) : (
                    <>
                      <BulletLegendHalfCircle cx="42.5" cy="69" r="1.5" color="Warning" />
                      <TextLegendHalfCircle x="35" y="78">
                        500/629
                      </TextLegendHalfCircle>
                    </>
                  )}
                  <TextLegendHalfCircle x="39" y="85">
                    Fair
                  </TextLegendHalfCircle>
                  {getESSScoreColor(chartData[0].value) === 'Success' ? (
                    <>
                      <SelectedBullet viewBox="-208 -192 250 250" color="Success" />
                      <TextTotalValueActiveHalfCircle x="67" y="78">
                        630/800
                      </TextTotalValueActiveHalfCircle>
                    </>
                  ) : (
                    <>
                      <BulletLegendHalfCircle cx="74.5" cy="69" r="1.5" color="Success" />
                      <TextLegendHalfCircle x="67" y="78">
                        630/800
                      </TextLegendHalfCircle>
                    </>
                  )}
                  <TextLegendHalfCircle x="69" y="85">
                    Good
                  </TextLegendHalfCircle>
                </GroupTextLegendsHalfCircle>
              )}
            </>
          )}
        </SvgHalfCircle>
      </ContainerHalfCircle>
    </>
  );
};

ChartHalfCircle.defaultProps = {
  labels: false,
  showOf: true,
  showLegends: true,
  showTitle: true,
  className: '',
  description: null
};

export default ChartHalfCircle;
