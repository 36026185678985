import { CustomTooltip, TextWithIcon } from 'components';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import React, { useState } from 'react';

function RevertSettingsButton({ subscriptionId, refetchTable, setSubscriptionState, setActionLoading }) {
  const [revertSettingsLoading, setRevertSettingsLoading] = useState(false);

  const handleRevertSettings = async () => {
    if (!subscriptionId) return;

    setActionLoading(true);
    setRevertSettingsLoading(true);

    try {
      const res = await pushNotifications.revertSubscription(subscriptionId);
      // reset modal state
      setSubscriptionState(res.data);

      // disable subscription inside modal
      await refetchTable();

      enqueueAlertSnackbar('Settings has been reverted successfully', { props: { severity: 'success' } });
    } catch (err) {
      enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    } finally {
      setRevertSettingsLoading(false);
      setActionLoading(false);
    }
  };

  return (
    <div style={{ width: 'fit-content', marginTop: '20px' }}>
        <TextWithIcon
          text="Revert to Default Settings"
          icon="Refresh"
          isUnderlined
          onClick={handleRevertSettings}
          isLoading={revertSettingsLoading}
        />
    </div>
  );
}

export default RevertSettingsButton;
