// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { Grid, Typography, Paper } from '@material-ui/core';

export const GridContainer: ComponentType<*> = styled(Grid).attrs({
  container: true,
  spacing: 24,
  alignItems: 'stretch'
})`
  padding: 0;
  margin-top: 0;
`;

export const GridItem: ComponentType<*> = styled(Grid).attrs({
  item: true
})`
  margin-bottom: 0;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
`;

export const ContainerHeaderBox: ComponentType<*> = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  margin-bottom: ${({ mbbox }) => (mbbox ? '' : 'calc(var(--spacing) / 0.5)')};
  width: 100%;
  height: ${({ height }) => height || 'calc(var(--spacing) * 2.5)'};
  position: relative;
  ${({ pt }) => pt && `padding-top: calc(${pt} * var(--spacing))`};
  ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};
  ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};

  > h3 {
    margin: calc(var(--spacing) / var(--spacing));
  }
`;
