// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { Thumbnail, FilePreview } from '../common';
import { VideoPlayer } from 'components';
import { getStorage, decodeId, encodeId, trackingUtils } from 'helpers';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { updateFeed } from 'helpers/action';
import { getAssetUrl } from '../../newsfeedHelpers';
import { Typography } from '@trustsecurenow/components-library';
import { useLocation } from 'hooks';

const useStyles = makeStyles(theme => ({
  contentRoot: {
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px'
    }
  },
  image: {
    maxWidth: '100%',
    display: 'block',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  videoContainer: {
    width: '100%'
  },
  videoLoading: {
    textAlign: 'center',
    width: '100%',
    '& img': {
      maxWidth: 173,
      width: '100%',
      height: 'auto',
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 143
      }
    }
  }
}));

const sendGAVideoStartEvent = (videoData, app) => {
  trackingUtils.customEvent('video_started', {
    video_id: videoData.video_id,
    video_name: videoData.filename,
    app,
    platform: "portal"
  });
}

const FeedContent = (props) => {
  const { content, fileIsReady, postId, currPlayingVideoPostId, setCurrPlayingVideoPostId } = props;
  const classes = useStyles();
  const { app } = useLocation()
  const dispatch = useDispatch();
  const contentData = content?.data;
  const contentType = content?.type;
  const user = { id: decodeId(getStorage('userId')) };
  const [playVideo, setPlayVideo] = useState(false)

  const handlePlayVideo = () => {
    setCurrPlayingVideoPostId(postId);
    sendGAVideoStartEvent(contentData, app);
  }

  useEffect(() => {
    let interval;
    if (contentType === 'video' && !fileIsReady) {
      const post_id = encodeId(postId);
      let i = 0;
      interval = setInterval(() => {
        if (i < 15) {
          i++;
          newsFeed
            .getFeedSingle(user.id, post_id)
            .then(res => {
              const post = res?.data[0];
              if (post?.file_ready) {
                dispatch(updateFeed(post.id, { ...post }));
                clearInterval(interval);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      }, 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [fileIsReady]);

  useEffect(() => {
    if (currPlayingVideoPostId) {
      if (postId !== currPlayingVideoPostId) setPlayVideo(false);
      else setPlayVideo(true);
    }
  }, [currPlayingVideoPostId, postId]);


  return (
    <Grid container item xs={12} className={classes.contentRoot}>
      {{
        image: (
          <div>
            <img className={classes.image} src={getAssetUrl(contentData.url)} alt="post" />
          </div>
        ),
        video:
          fileIsReady ? (
            <div className={classes.videoContainer}>
              <VideoPlayer
                src={`${contentData.video_url}?clientBandwidthHint=4.9`}
                poster={contentData.video_thumbnail}
                controls={true}
                playing={playVideo}
                onPlay={handlePlayVideo}
                width="100%"
                height="auto"
              />
            </div>
          ) : (
            <Box className={classes.videoLoading} py={{ xs: 3, md: 7 }} px={{ xs: 2, md: 3 }}>
              <img src='/media/videoLoading.svg' alt='Video loading' />
              <Typography variant="subtitle1" component="p">Wait a moment to view, the video is loading...</Typography>
            </Box>
          ),
        thumbnail: <Thumbnail data={contentData} />
      }[contentType] || (
          <div>
            <Typography variant='button' mb={1.5}>
              Attachment List (1)
            </Typography>
            <FilePreview url={getAssetUrl(contentData.url)} filename={contentData.filename.replace(/.*?_/, '')} />
          </div>
        )}
    </Grid>
  );
};

export default FeedContent;