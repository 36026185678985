import styled from 'styled-components';
import { Button, TextField } from '../../../forms';
import { Container } from '../../../types';

export const ReferralCodeInput = styled(TextField)`
  && {
    & > div {
      background-color: var(--backgroundPaper);
    }

    & > .Mui_focused {
      color: #f3f5f6;
    }
  }
`

export const ReferralCodeModalWrapper = styled(Container.Grid)`
  padding: calc(var(--spacing) * 8) calc(var(--spacing) * 6);
  flex-direction: column;
`

export const ReferralCodeModalTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8125rem;
  color: ${({ isSuccess }) => isSuccess ? "var(--colorSystemSuccess)" : "var(--colorSystemDanger)"} ;
`

export const ReferralCodeModalText = styled.div`
  text-align: center;
  p {
    display: inline;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    color: var(--colorDefault);
  }
  a {
    color: var(--colorSystemInfo);
  }
`

export const ReferralCodeModalIconWrapper = styled.div`
  padding: 11px;
  border-radius: 50%;
  background-color: ${({ isSuccess }) => isSuccess ? "rgba(12, 206, 107, 0.2)" : "rgba(255, 90, 97, 0.2)"} ;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 48px;
    height: 48px;
  }
`


export const ReferralCodeButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 5px 10px 5px;
    margin: 5px 0;
    color: var(--colorCommonWhite);
    font-size: 1.2rem;
    letter-spacing: 1px;
    cursor: pointer;
    font-weight: 400;

    svg {
      font-size: 19px;
      margin-right: 13px;
      color: var(--colorCommonWhite);
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`

export const ReferralCodeSubmissionTitle = styled.h3`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    margin: 5px 0;
    color: var(--colorDarkGrey);
    letter-spacing: 1px;
    font-weight: 600;
  }
`
