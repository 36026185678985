// @flow
import React from 'react';
import { Container } from 'components';
import { SaveIcon, Button } from '@trustsecurenow/components-library';

type AccordionFooterProps = {
  onSubmit: Function,
  disabled?: boolean
};

const AccordionFooter = ({ onSubmit, disabled }: AccordionFooterProps) => (
  <>
    <Container.Grid item md={12} bb={1} />
    <Container.Grid item md={12} pt={2} justify="flex-end">
      <Button
          color="info"
          startIcon={<SaveIcon />}
          disabled={disabled}
          onClick={onSubmit}
      >
        Save
      </Button>
    </Container.Grid>
  </>
);

export default React.memo(AccordionFooter);
