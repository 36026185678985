import React from "react"
import { useContentAdminFormContext } from '../../context';
import { getContentAdminFormTitle } from 'helpers';
import { ContentAdminFormLayoutHeaderContainer } from '../../style';
import { Typography } from '@trustsecurenow/components-library';

const ContentAdminFormLayoutHeader = () => {

  const {method, page} = useContentAdminFormContext()

  const title = getContentAdminFormTitle(page, method);

  return (
    <ContentAdminFormLayoutHeaderContainer>
      <Typography variant='h4'>
        {title}
      </Typography>
    </ContentAdminFormLayoutHeaderContainer>
  )
}


export default ContentAdminFormLayoutHeader
