import React, { useState, useRef, useCallback, useEffect } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as sra from 'helpers/apis/services/sra';
import { DeleteConfirmationModal, LoadingStyled } from 'components';

import { Toolbar, Recommendation, AddEditRecommendation } from './components';
import { useClientInfo } from '../../hooks';
import { useSRA } from '../../context/sraContext';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  list: {
    minHeight: '320px',
    height: 'calc(100vh - 242px)',
    overflowY: 'auto',
    '& .infinite-scroll-component': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '-webkit-overflow-scrolling': 'auto !important'
    }
  },
  loading: {
    width: '100%'
  },
  infoText: {
    textAlign: 'center',
    width: '100%'
  }
}));

const DEFAULT_SORT = {
  sortname: 'section',
  order: 'asc'
};

const EditRecommendations = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState();
  const [filters, setFilters] = useState({});
  const [currentRecommendation, setCurrentRecommendation] = useState('');
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [recommendations, setRecommendations] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const recommendationsListRef = useRef(null);
  const infiniteScrollRef = useRef(null);
  const {
    state: { revisionId },
    clientId
  } = useSRA();
  const { isHSN, isLoadingClientInfo } = useClientInfo(clientId);

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentRecommendation(null);
    setTimeout(() => {
      setDeleteSuccess(false);
    }, 500);
  };

  const scrollToTop = () => {
    if (recommendationsListRef?.current) {
      recommendationsListRef.current.scrollTop = 0;
    }
  };

  // Load more recommendations
  const fetchData = useCallback(() => {
    const params = {
      pagination: {
        page: recommendations?.length / 4,
        perPage: 4
      },
      filter: filters,
      sort: { ...DEFAULT_SORT }
    };

    if (revisionId) {
      sra
        .getRecommendations(params, clientId, revisionId)
        .then(res => {
          if (res.data.length !== 0) {
            setRecommendations(prevState => [...prevState, ...res.data]);
          } else setHasMore(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [recommendations?.length, filters, clientId, revisionId]);

  // Get initial recommendations
  const getRecommendationsRequest = useCallback(() => {
    const params = {
      pagination: {
        page: 0,
        perPage: 4
      },
      filter: filters,
      sort: { ...DEFAULT_SORT }
    };

    if (revisionId) {
      sra
        .getRecommendations(params, clientId, revisionId)
        .then(res => {
          setRecommendations(res.data);
          scrollToTop();
          if (res.data.length % 4 !== 0 || res.data.length === 0) setHasMore(false);
          else setHasMore(true);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [filters, clientId, revisionId]);

  useEffect(() => {
    setFilters(prev => {
      if (searchValue) {
        return { ...prev, search: searchValue };
      }

      const newFilters = { ...prev };
      delete newFilters.search;
      return { ...newFilters };
    });
  }, [searchValue]);

  useEffect(() => {
    if (recommendations?.length < 4 && hasMore) {
      getRecommendationsRequest();
    }
  }, [recommendations, hasMore, getRecommendationsRequest]);

  useEffect(() => {
    getRecommendationsRequest();
  }, [filters, getRecommendationsRequest]);

  useEffect(() => {
    if (infiniteScrollRef?.current && recommendationsListRef?.current) {
      const listElement = infiniteScrollRef?.current?.el?.children?.[0];
      const listElementParent = recommendationsListRef?.current;
      const listElementHeight = listElement?.scrollHeight;
      const listElementParentHeight = listElementParent?.clientHeight;

      if (listElementHeight <= listElementParentHeight) {
        fetchData();
      }
    }
  }, [infiniteScrollRef?.current, recommendationsListRef?.current]);

  const closeAddEditModal = useCallback(
    refetch => {
      setOpenAddEditModal(false);
      setTimeout(() => {
        setCurrentRecommendation(null);
      }, 500);

      if (refetch) {
        setHasMore(true);
        getRecommendationsRequest();
      }
    },
    [getRecommendationsRequest]
  );

  const deleteRecommendation = async () => {
    setDeleteLoading(true);
    try {
      const response = await sra.deleteRecommendation(currentRecommendation, clientId, revisionId);
      const currentDeletedId = currentRecommendation.id;
      setCurrentRecommendation(null);
      setDeleteSuccess(true);
      setRecommendations(prevState => prevState.filter(item => item.id !== currentDeletedId));
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  if (!recommendations || isLoadingClientInfo) return <LoadingStyled />;

  return (
    <Box pt={4}>
      <Toolbar
        setSearchValue={setSearchValue}
        setFilters={setFilters}
        setOpenAddEditModal={setOpenAddEditModal}
        isHSN={isHSN}
      />

      <div ref={recommendationsListRef} id="scrollableDiv" className={classes.list}>
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={recommendations.length} // This is important field to render the next data
          next={fetchData}
          hasMore={hasMore}
          loader={
            <div className={classes.loading}>
              <LoadingStyled text="Loading more recommendations" />
            </div>
          }
          endMessage={
            <p className={classes.infoText}>
              <b>{recommendations?.length ? 'Yay! You have seen it all' : 'No results found'}</b>
            </p>
          }
          scrollableTarget="scrollableDiv"
        >
          {recommendations?.map(item => (
            <Recommendation
              key={item.id}
              item={item}
              setCurrentRecommendation={setCurrentRecommendation}
              setOpenAddEditModal={setOpenAddEditModal}
              setOpenDeleteModal={setOpenDeleteModal}
              isHSN={isHSN}
            />
          ))}
        </InfiniteScroll>
      </div>

      <AddEditRecommendation
        open={openAddEditModal}
        close={closeAddEditModal}
        recommendation={currentRecommendation}
        isHSN={isHSN}
      />

      <DeleteConfirmationModal
        open={openDeleteModal}
        close={closeDeleteModal}
        onDelete={deleteRecommendation}
        showSuccessDialog={deleteSuccess}
        successMessage="Recommendation Deleted!"
        title="Delete Finding and Recommendation"
        description="This information will be deleted and cannot be recovered."
        disabledSubmit={deleteLoading}
      />
    </Box>
  );
};

export default EditRecommendations;
