// @flow
import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';

const useStyles = makeStyles({
  video: {
    '& video': {
      outline: 'none !important'
    }
  }
});

const VideoPlayer = forwardRef(({ src, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <ReactPlayer
      url={src}
      className={classes.video}
      {...rest}
      ref={ref}
    />
  );
});

export default VideoPlayer;
