import React from 'react';
import { Button, Dialog, Typography } from 'components';

import { dataPost } from 'helpers';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const ActivateClientModal = ({ client, onActivation, open, handleClose }) => {

  const activateClient = React.useCallback(
    (id, withEmails) => {
      const app = 'clients';
      const tab = 'actions';
      const data = {
        type: 'activate',
      };
      if (withEmails !== undefined) {
        data.system_emails_status = Boolean(withEmails);
      }

      handleClose();
      dataPost({ app, tab, item: id, params: {}, data })
        .then(res => {
          onActivation();
          enqueueAlertSnackbar(res.data.message || res.data.description, {
            props: { severity: 'success' }
          });
        })
        .catch(err => {
          enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
            props: { severity: 'error' }
          });
        });
    },
    [onActivation]
  );

  return (
    <>
      <Dialog
        open={open || false}
        setOpen={handleClose}
        maxWidth="xs"
        closeButton
        onlyOk
        variant="standard"
      >
        <Typography.h3 mx={0} mt={0} textAlign="center" maxWidth={501}>
          You are about to activate {client.name.trim()}
        </Typography.h3>

        <Typography.h3 mx={0} mb={0} mt={2} textAlign="center" maxWidth={373}>
          Would you like to activate with system emails turned on?
        </Typography.h3>

        <Typography.p mx={0} mt={0} mb={5} textAlign="center">
          All Phishing Campaigns, Client Reminders, Automated Reports and other system emails will start sending
          if configured.
        </Typography.p>

        <Button
          backgroundColor="colorSystemSuccess"
          backgroundColorHover="colorSystemSuccessHover"
          backgroundDisabled="colorSystemSuccessDisable"
          height={36}
          width={300}
          variant="outlined"
          style={{ padding: '1.2rem 4.5rem' }}
          textTransform={false}
          onClick={() => {
            activateClient(client.id, true);
          }}
        >
          Activate and Send System Emails
        </Button>
        <Button
          backgroundColor="buttonSecondaryBackground"
          backgroundColorHover="buttonSecondaryBackground"
          bordercolor="colorSystemSuccess"
          labelcolor="var(--colorSystemSuccess)"
          height={36}
          onClick={() => {
            activateClient(client.id, false);
          }}
          variant="outlined"
          style={{ width: '302px', marginTop: '2rem' }}
          textTransform={false}
        >
          Activate and Do Not Send System Emails
        </Button>
      </Dialog>
    </>
  );
};

export default ActivateClientModal;
