// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { TableBody } from '@material-ui/core';
import { type partnerProfileInformationType } from 'conf';
import { Container, Table } from 'components';
import { HeartIcon, Typography } from '@trustsecurenow/components-library';
import PartnerInformationRow from './PartnerInformationRow';

export const TableBodyPartner: ComponentType<*> = styled(TableBody)`
  tr {
    height: calc(var(--spacing) * 8);
  }
`;

type PartnerInformationType = {
  record?: partnerProfileInformationType
};

const PartnerInformation = ({
  record = {
    brand_key: '',
    logo: '',
    branded_urls: {}
  }
}: PartnerInformationType) => {
  const { brand_key: brandKey, branded_urls: brandedUrls, logo } = record;
  // const handleCopy = (i, message) => {
  //   console.log(i);
  //   console.log(message);
  //   dispatch('snackState', {
  //     state: true,
  //     message
  //   });
  // };

  return (
    <>
      <Container.Paper px={2} mt={2} alignItems="center">
        <Typography my={2}>Branded Login URL</Typography>
        <HeartIcon sx={{ mr: 2.5, fontSize: 22 }} color="info" />
      </Container.Paper>
      <Container.Paper mt={2} direction="column">
        <Table.Container>
          <TableBodyPartner>
            <PartnerInformationRow name="Key" url={brandKey} icon="PartnerKey" isCopy={false} />
            {brandedUrls.map(({ name, url, icon }) => (
              <PartnerInformationRow name={name} url={url} icon={icon} isCopy />
            ))}
          </TableBodyPartner>
        </Table.Container>
        <Typography variant="body2" pl={2} pr={4}>
          Note: The landing page URL pre-populates the partner email address on file. This email address will receive
          form submissions when completed by an individual. To adjust where these form submissions are sent, update the
          email address with your preferred email address.
        </Typography>
      </Container.Paper>
    </>
  );
};

PartnerInformation.defaultProps = {
  record: {
    brand_key: '',
    logo: '',
    branded_urls: []
  }
};

export default PartnerInformation;
