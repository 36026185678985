import React, { useState } from 'react';
import { Button } from '@trustsecurenow/components-library';
import {
  PreassessmentPhoneContainer,
  PreassessmentPhoneNumberInput,
  PreassessmentPhoneNumberErrorMessage
} from '../../style';
import cognito from '../../../../helpers/apis/cognito';
import { Container } from 'components';
import { usePreassessmentContext } from '../../contexts';

const PreassessmentPhoneNumber = ({ setVerifyView, setVerificationDate, phoneNumber, setPhoneNumber }) => {
  const {
    client_id,
    dispatch,
    disabled,
    user: { email }
  } = usePreassessmentContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerify = async () => {
    dispatch.setDisabled(true);
    const filteredNumber = phoneNumber.replace(/[\s-()]/g, '');
    try {
      await cognito.changePhoneNumber({
        email,
        client_id,
        phone_number: filteredNumber
      });
      const verificationDate = await cognito.sendVerificationCode({
        email,
        client_id,
        method: 'sms'
      });

      setVerificationDate(verificationDate.data.valid_until);
      setVerifyView('verifySms');
      dispatch.setDisabled(false);
    } catch (e) {
      dispatch.setDisabled(false);
      setErrorMessage(e.response.data.message);
      console.log('error', e);
    }
  };

  return (
    <>
      <PreassessmentPhoneContainer>
        <Container.Grid sm={9}>
          <PreassessmentPhoneNumberInput
            variant="outlined"
            value={phoneNumber}
            name="phone_number"
            label="Phone number"
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            onChange={value => {
              setErrorMessage('');
              setPhoneNumber(value);
            }}
            defaultCountry="us"
            countryCodeEditable={false}
            fullWidth
          />
        </Container.Grid>
        <Container.Grid sm={3} justifyContent={'flex-end'}>
          <Button fullWidth color="success" onClick={handleVerify} disabled={disabled}>
            Next
          </Button>
        </Container.Grid>
      </PreassessmentPhoneContainer>
      {errorMessage && (
        <PreassessmentPhoneNumberErrorMessage m={12} sm={12}>
          <p>{errorMessage}</p>
        </PreassessmentPhoneNumberErrorMessage>
      )}
    </>
  );
};

export default PreassessmentPhoneNumber;
