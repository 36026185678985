import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import * as dashboard from 'helpers/apis/services/dashboard';
import { useLocation, useId, useConfig } from 'hooks';
import { LoadingStyled, Modal3 } from 'components';
import { LazyIcon } from 'components/icons';
import useHasInternet from 'hooks/useHasInternet';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import { AddIcon, Button, enqueueAlertSnackbar, Box } from '@trustsecurenow/components-library';
import { SelectField } from '../forms';
import TableListModal from '../tablelist/TableListModal';

function transform(data) {
  return data.map(({ label, value }) => {
    return {
      label,
      value
    };
  });
}

const PagePolicies = () => {
  const app = 'clients';
  const { app: appLoc, item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const [policyType, setPolicyType] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [policies, setPolicies] = useState([]);
  const settings = useConfig('tables', app, String(value));
  const hasInternet = useHasInternet();
  const cancelTokenSource = useRef();
  const preferences = useMemo(() => userPreferences.getTableSettings(app, value), [value]);
  const [error, setError] = useState({
    exists: false,
    msg: ''
  });
  const [tableControls, setTableControls] = useState({
    page: 0,
    perPage: preferences.pagination || 25,
    total: 0,
    order: preferences.order || 'asc',
    orderBy: preferences.orderBy || (value === 'policies' ? 'policy_id' : 'name'),
    isLoading: false
  });

  useEffect(() => {
    // getting new pereferences when user changes the tab
    // which is equal to the value
    setTableControls(prev => {
      return {
        ...prev,
        page: 0,
        perPage: preferences.pagination || 25,
        total: 0,
        order: preferences.order || 'asc',
        orderBy: preferences.orderBy || (value === 'policies' ? 'policy_id' : 'name')
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  const fetchPolicies = useCallback(
    // eslint-disable-next-line no-shadow
    async ({ policyType, app, id, sort, pagination, onSuccess, onError, cancelToken }) => {
      if (policyType === 'otherPolicies') {
        dashboard
          .getOtherPolicies({ app, id, sort, pagination, cancelToken })
          .then(response => onSuccess(response, policyType))
          .catch(onError);
      } else if (policyType === 'policies') {
        dashboard
          .getPolicies({ app, id, sort, pagination, cancelToken })
          .then(response => onSuccess(response, policyType))
          .catch(onError);
      }
    },
    []
  );

  /**
   * fetches policies and
   * handles the internal state of the component related to it
   */
  const fetchingPoliciesEffect = useCallback(() => {
    if (value !== null) {
      setTableControls(prev => {
        return { ...prev, isLoading: true };
      });
      setError({
        exists: false,
        msg: ''
      });
      // eslint-disable-next-line no-shadow
      const onSuccess = (response, policyType) => {
        hasInternet(() => {
          setError({
            exists: false,
            msg: ''
          });
          setTableControls(old => {
            return { ...old, total: response.headers['x-total-count'], isLoading: false };
          });
          if (response.data.length === 0) {
            setPolicies([]);
          } else {
            setPolicies(response.data);
          }
        });
      };

      // eslint-disable-next-line no-shadow
      const onError = error => {
        if (axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log('Request canceled', error.message);
        } else {
          setTableControls(prev => {
            return { ...prev, isLoading: false };
          });
          setError({
            exists: true,
            msg: error?.response?.data?.description || 'Something went wrong'
          });
          enqueueAlertSnackbar(error?.response?.data?.description || 'Something went wrong', {
            props: { severity: 'error' }
          });
        }
      };

      /** cancelling previous not answered requests */
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('cancelling an old api call');
      }

      cancelTokenSource.current = axios.CancelToken.source();

      fetchPolicies({
        policyType: value,
        app,
        id: setId,
        sort: { field: tableControls.orderBy, order: tableControls.order },
        pagination: {
          page: tableControls.page * tableControls.perPage + 1,
          perPage: (tableControls.page + 1) * tableControls.perPage + 1
        },
        onSuccess,
        onError,
        cancelToken: cancelTokenSource.current.token
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInternet, preferences, tableControls.order, tableControls.orderBy, tableControls.page, tableControls.perPage]);

  useEffect(() => {
    if (!policyType || policyType.length === 0) {
      dashboard.getPolicyTypes({ clientID: setId, app: appLoc }).then(response => {
        const tempPolicyType = transform([...response.data]);
        setPolicyType([...tempPolicyType]);
        if (value === null) {
          const policy_type = localStorage.getItem('policyType');
          if (policy_type) {
            setValue(policy_type);
            localStorage.removeItem('policyType');
            return;
          }
          setValue(tempPolicyType?.[0]?.value);
        }
      });
    }
  }, [appLoc, policyType, setId, value]);

  useEffect(() => {
    fetchingPoliciesEffect();
  }, [fetchingPoliciesEffect]);

  const onClick = e => {
    setOpen(!open);
  };

  const Toolbar = () => (
    <>
      {policyType ? (
        <>
          <SelectField
            value={value}
            name="policies"
            onChange={e => setValue(e.target.value)}
            emptyValue="Policy Type"
            choices={policyType}
            mr={2}
            variant
            GAclick
          />
          {value === 'policies' && (
            <Button onClick={e => onClick(e)} startIcon={<AddIcon />}>
              New Policy
            </Button>
          )}
          {value === 'otherPolicies' && (
            <Button onClick={e => onClick(e)} startIcon={<AddIcon />}>
              New Other Policy
            </Button>
          )}
        </>
      ) : (
        <LazyIcon component="Loading" mr={1} color="colorDefault" size={3} />
      )}
    </>
  );

  return (
    <>
      {value ? (
        <>
          <TableListModal
            tab={value}
            id={setId}
            app={app}
            refetchOnUpdate={() => fetchingPoliciesEffect()}
            tableOptions={{
              refetchOnWindowFocus: false
            }}
            tableSettings={settings}
            toolbar={<Toolbar />}
            resetTable={value}
            data={{
              data: policies,
              page: tableControls.page,
              prePage: tableControls.perPage,
              total: tableControls.total,
              isError: error.exists,
              isFetching: tableControls.isLoading,
              isLoading: tableControls.isLoading,
              // isPreviousData: false,
              order: tableControls.order,
              orderBy: tableControls.orderBy,
              status: error.exists ? 'error' : 'success',
              dispatch: {
                // empty methods for the table
                // to use when when some actions triggered
                // just to prevent errors
                refetch: () => {},
                // if provided, will be fired only when user deletes items from the table
                refetchOnDeletion: () => {
                  fetchingPoliciesEffect();
                },
                setOrder: order => {
                  setTableControls(old => {
                    return { ...old, order };
                  });
                },
                setOrderBy: orderBy => {
                  setTableControls(old => {
                    return { ...old, orderBy };
                  });
                },
                setPage: page => {
                  setTableControls(old => {
                    return { ...old, page };
                  });
                },
                setPrePage: perPage => {
                  setTableControls(old => {
                    return { ...old, perPage, page: 0 };
                  });
                }
              }
            }}
          />
          {open && (
            <Modal3
              open={open}
              setOpen={onClick}
              app={app}
              type={value === 'policies' ? 'policy' : 'other_policy'}
              tab={value}
              createNew
              refetchOnCreate={() => fetchingPoliciesEffect()}
            />
          )}
        </>
      ) : (
        <LoadingStyled centerAlign />
      )}
    </>
  );
};

export default PagePolicies;
