import apiClient from '../apiClient/apiClient';
import { dataParams } from '../index';
import { bsnClientServicesBaseURL } from './bsnClientServices';

const baseURL = bsnClientServicesBaseURL;

const training = {};

training.getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

/**
 * fetches training reports types and types fitlers
 * @param {string} clientID : ID of the current client
 * @returns training reports types' and types filters
 */
training.trainingFilters = clientID => {
  const requestOptions = {
    url: `${baseURL}/myCompany/trainingFilter/${clientID}`,
    headers: training.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches a list of a training reports of specific type
 * @param {string} clientID: ID of the current client,
 * @param {"trainingAnnual"| "trainingTrainings"| "trainingUsers"} reportType
 * @param {{pagination?:{page: number, perPage:number}, sort?:{sortname: string,order: 'asc'| 'desc'}, _filter?: string, _reporId?: string|null}} params: query params
 * @returns a list of a training reports
 */
training.trainingReport = (clientID, reportType, params) => {
  const requestOptions = {
    url: `${baseURL}/clients/${reportType}/${clientID}`,
    headers: training.getRequestHeaders(),
    params: dataParams({ ...params, startAtZero: true }),
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches a list of users that taken a micro training
 * or fetches a list micro trainings that are taken by a user
 * @param {"trainingTrainingsUsers"| "trainingUsersTrainings"} trainingType
 * @param {":clientID/:microtrainingID" | ":userID"} id
 * @param {{pagination?:{page: number, perPage:number}, sort?:{sortname: string,order: 'asc'| 'desc'}, _filter?: string, _reporId?: string|null}} params
 * @returns  a list of users that taken a micro training
 * or  a list of micro trainings that are taken by a user
 */
training.trainingModal = (trainingType, id, params) => {
  const requestOptions = {
    url: `${baseURL}/clients/${trainingType}/${id}`,
    headers: training.getRequestHeaders(),
    params: dataParams({ startAtZero: true, ...params })
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches the download url of micro training report for a specific client
 * @param {string} clientID : id of the current client
 * @param {string} filters : a string of begin and end dates matching the following format
 * begin_date:2023/07-09T10:08:00.000Z,end_date:2023-07-11T10:08:00.000Z
 * @returns download url of micro training report.
 */
training.downloadMicroReport = (clientID, filters) => {
  const requestOptions = {
    url: `${baseURL}/clients/microTrainingReport/${clientID}`,
    headers: training.getRequestHeaders(),
    params: {
      _filter: filters
    }
  };
  return apiClient.get(requestOptions);
};

export default training;
