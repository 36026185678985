import React from 'react';
import { CloseRounded } from '@material-ui/icons';
import { Typography } from '@trustsecurenow/components-library';

const ToastCloseButton = ({ closeToast }) => {
  return (
    <span onClick={closeToast} style={{ padding: '5px 5px 0 0' }}>
      <CloseRounded style={{ fontSize: 20, color: '#828282' }} />
    </span>
  );
};

export default ToastCloseButton;
