import { useCallback, useMemo } from 'react';
import clients from 'helpers/apis/clients';
import { dataProvider } from '../../../helpers';
import { ANNUAL, MICRO_TRAINING, FULL_TRAINING } from './constants';
import { useId, useLocation } from '../../../hooks';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useTrainingToolbar = ({ setLoading, state, filterString, setReportDownloaded }) => {
  const userId = useId();
  const { item } = useLocation();
  const clientId = item || userId;

  const downloadTrainingReport = useCallback(
    (type, trainingId, selected) => {
      setLoading(true);
      const trainingApi =
        type === FULL_TRAINING ? clients.getAssignedTrainingsReport : clients.getSelectedTrainingReport;
      const params = type === FULL_TRAINING ? { clientId, selected } : { clientId, trainingId, selected };
      trainingApi(params)
        .then(response => {
          window.open(response.data.url);
          setLoading(false);
        })
        .catch(error => enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } }))
        .finally(() => setLoading(false));
    },
    [state.subValue]
  );

  const downloadMicroReport = useCallback(() => {
    const filter = filterString ? `?_filter=${filterString}` : '';
    setLoading(true);
    dataProvider
      .getOne('clients', `microTrainingReport/${clientId}${filter}`)
      .then(response => {
        const { MicroTrainingAutomatedReport_url: pdf, MicroTraining_url: excel, url } = response?.data || {};
        [excel, pdf]
          .map((url, idx) => {
            if (!url) return false;
            const newWindow = window.open(url, '_blank');
            if (!newWindow) {
              // Opening the URL was blocked
              // You can display an error message or perform an alternative action

              enqueueAlertSnackbar(
                `${idx === 0 ? 'Excel' : 'PDF'} report is blocked. Please allow pop-ups on this page and try again.`,
                { props: { severity: 'error' } }
              );
              return false;
            }
            return true;
          })
          .every(d => d);

        enqueueAlertSnackbar('Micro Training PDF And Excel Report Have Been Downloaded', {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.message || err?.response?.data?.description, { props: { severity: 'error' } });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterString]);

  const downloadButtonData = useMemo(
    () => ({
      [ANNUAL]: {
        title: 'Download Full Training Report',
        loadingTitle: 'Loading Training Report...',
        method: (type, selected, trainingId) => downloadTrainingReport(type, trainingId, selected)
      },
      [MICRO_TRAINING]: {
        title: 'Download Micro Training Report',
        loadingTitle: 'Loading Micro Training Report...',
        method: () => downloadMicroReport()
      }
    }),
    [downloadTrainingReport, downloadMicroReport]
  );

  return {
    downloadButtonData
  };
};

export default useTrainingToolbar;
