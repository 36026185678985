import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@material-ui/icons';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  optionsSelect: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '22px 25px',
    color: 'var(--colorDefault)',
    borderBottom: '1px solid var(--backgroundPaper)',
    '& > svg': {
      color: 'var(--colorDefault)'
    },
    '&.selected': {
      color: 'var(--colorSystemInfo)',
      background: 'var(--backgroundDefault)'
    }
  },
  labelWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 40,
    marginRight: 16,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      verticalAlign: 'middle',
      fontSize: 24
    }
  },
  hoverStyle: {
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)',
      background: 'var(--backgroundDefault)'
    }
  }
}));

const OptionsSelect = ({ value, description, icon, onClick, isSelected }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.optionsSelect} ${classes.hoverStyle} ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <span className={classes.labelWrap}>
        <span className={classes.icon}>{icon}</span>
        <div>
          <Typography variant='h4' component='span' mb={0.5}>{value}</Typography>
          {description && <Typography variant='link1'>{description}</Typography>}
        </div>
      </span>
      <ArrowForwardIosRoundedIcon style={{ fontSize: 20 }} />
    </div>
  );
};

export default React.memo(OptionsSelect);
