// @flow
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { Box } from '@material-ui/core';
import { Container, LazyIcon } from 'components';
import styled from 'styled-components';
import { generateDropzoneAccept } from 'utils/generateDropzoneAccept';
import { Typography } from '@trustsecurenow/components-library';

const DragZoneContainer = styled(Box)`
  position: relative;
  border: 2px dashed var(--colorBox);
  border-radius: var(--borderRadius);
  width: 100%;
  height: 136px;
  outline: none;
  text-align: center;
  z-index: 2;
  padding: 40px 16px;
`;

type Props = {
  fileType: string,
  fileInfo: string | Object,
  disabled: boolean,
  dispatch: Function,
};

const UploadFile = ({
  fileType,
  fileInfo,
  disabled,
  dispatch,
}: Props) => {
  const fileName = fileInfo && (typeof fileInfo === 'object' ? fileInfo.name : fileInfo);

  const onDrop = useCallback(
    acceptedFiles => {
      const newFile = acceptedFiles[0];
      const fileValid = fileType.replace(/\s/g, '').split(',').some(t => newFile.name.toLowerCase().includes(t))

      if (!newFile || !fileValid) {
        enqueueAlertSnackbar(`This type of file is not allowed. Please use a ${fileType.toUpperCase()} file`, {
          props: { severity: 'error' }
        });
        return;
      }

      if (dispatch) {
        dispatch(newFile);
        enqueueAlertSnackbar('File Attached', { props: { severity: 'success' } });
      }

    },
    [dispatch, fileType]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: generateDropzoneAccept(fileType),
    disabled,
    onDrop
  });
  const inputProps = getInputProps();

  return (
    <Container.Grid item sm={12} xs={12} md={12}>
      <Container.Grid container>
        <Container.Grid item sm={12} xs={12} md={12}>
          <DragZoneContainer display="flex" alignItems="center" flexDirection="row" {...getRootProps()}>
            <input type="file" id="file" {...inputProps}  />
            <Container.Grid container direction="row" alignItems="center" justifyContent="center">
              <Container.Grid item mr={2} >
                <LazyIcon component="DragDrop" color="colorBox" size={3} />
              </Container.Grid>
              <Container.Grid item>
                <Container.Grid container direction="column" justifyContent="center">
                  <Typography variant='button2' bold m={0.5}>Upload File</Typography>
                  <Typography variant='body2' mt={0.5}>Drag &amp; Drop your files or
                    <Typography variant='body2' component='span' sx={{textDecoration: 'underline'}}>Browse</Typography>
                  </Typography>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>
          </DragZoneContainer>
        </Container.Grid>

        {fileName && (
          <Container.Grid item sm={12} xs={12} xl={12} md={12}>
            <Typography variant='button' color='info.main' fontWeight="fontWeightBold" pr={1.5}>Current attachment:</Typography>
            <Typography variant='button' sx={{textDecoration: 'underline'}}>{fileName}</Typography>
          </Container.Grid>
        )}

      </Container.Grid>
    </Container.Grid>
  );
};

export default UploadFile;
