import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const baseURL = `${process.env.REACT_NEW_BASE_URL}darkwebmonitoringservices`;

const dw = {};

dw.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

dw.updateDomainFields = ({ clientId, data }) => {
  const requestOptions = {
    url: `${baseURL}/clients/domainsinfo/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    data
  };
  return apiClient.put(requestOptions);
};

dw.getProhibitedDomains = () => {
  const requestOptions = {
    url: `${baseURL}/clients/blacklistedDomainsList`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

dw.getDarkwebReport = ({ clientId, params }) => {
  const requestOptions = {
    url: `${baseURL}/clients/DarkWebReport/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params
  };
  return apiClient.get(requestOptions);
};

dw.getFilters = (clientId, searchParams) => {
  const requestOptions = {
    url: `${baseURL}/darkweb/dark-web-filters/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params: searchParams ?? {}
  };
  return apiClient.get(requestOptions);
};

dw.getSettings = clientId => {
  const requestOptions = {
    url: `${baseURL}/darkweb/settings/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

dw.getRemediationTraining = () => {
  const requestOptions = {
    url: `${baseURL}/darkweb/remediation-training`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

dw.remediateDarkWebQuiz = () => {
  const requestOptions = {
    url: `${baseURL}/DarkWebQuiz/Remediate`,
    headers: dw.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches configuration for clients/notification page
 * @param {object} params
 * @param {string} params.id  generally the client id
 * @param {object} params.cancelToken axios cancel token
 * @returns configuration for clients/notificaiton page
 */
dw.getAlerts = ({ id, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}/clients/alerts/${id}`,
    headers: dw.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * updates configuration for clients/notification page
 * @param {object} params
 * @param {string} params.id  generally the client id
 * @param {object} params.data  new configuration data
 * @param {object} params.cancelToken axios cancel token
 * @returns updated configuration for clients/notificaiton page
 */
dw.setAlerts = ({ id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}/clients/alerts/${id}`,
    headers: dw.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.put(requestOptions);
};

/**
 *
 * @param {object} params
 * @param {id} params.id  generally the client id
 * @param {string} params.callId  generally the time at which the endpoint is called
 * @param {object} params.cancelToken  axios cancel token
 * @returns domains info for specific client
 */
dw.getDomainsInfo = ({ id, callId, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}/clients/domainsinfo/${id}`,
    headers: dw.getRequestHeaders(),
    params: {
      _callId: callId
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches alist of data breaches
 * @param {object} params
 * @param {id} params.id  generally the client id
 * @param {string} params.filter
 * @param {object} params.sort  {field: name of sorted field, order: sorting direction}
 * @param {object} params.pagination {page: starting page, perPage: number of records in each page}
 * @param {object} params.cancelToken  axios cancel token
 */
dw.getDarkWebMonitoring = ({ id, filter, dw_filter, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}/clients/darkWebMonitoring/${id}`,
    headers: dw.getRequestHeaders(),
    params: {
      _filter: filter,
      dw_filter,
      _sort: sort.field,
      _order: sort.order,
      _start: pagination.page,
      _end: pagination.perPage
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches alist of data breaches
 * @param {object} params
 * @param {string} params.filter
 * @param {object} params.sort  {field: name of sorted field, order: sorting direction}
 * @param {object} params.pagination {page: starting page, perPage: number of records in each page}
 * @param {object} params.cancelToken  axios cancel token
 */
dw.getDarkWeb = ({ filter, query, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}/admin/dashboard/darkWeb`,
    headers: dw.getRequestHeaders(),
    params: {
      _filter: filter,
      query,
      _sort: sort.field,
      _order: sort.order,
      _start: pagination.page,
      _end: pagination.perPage
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

dw.checkDomainValidity = (clientId, domain) => {
  const requestOptions = {
    url: `${baseURL}/darkweb/check/domain/${clientId}?domain=${domain}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

export default dw;
