import { buildQueryString } from 'helpers/utils';
import apiClient from '../apiClient/apiClient';
import { bsnClientServicesBaseURL } from './bsnClientServices';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const reportAPIGWID = process.env.REACT_APP_BASE_URL_18;
const reportBaseURL = `https://${reportAPIGWID}.${base}/${env}`;

const preAssessments = {};

const headers = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

preAssessments.getUrl = clientId => {
  return apiClient.get({
    url: `${bsnClientServicesBaseURL}/${clientId}/preassessments/`,
    headers: headers()
  });
};

preAssessments.fetchAssessmentData = (clientId, type) => {
  return apiClient.get({
    url: `${bsnClientServicesBaseURL}/${clientId}/preassessments/?type=${type}`,
    headers: headers()
  });
};

preAssessments.detectAssessment = (assessmentId, clientId) => {
  return apiClient.get({
    url: `${bsnClientServicesBaseURL}/preassessments/${assessmentId}/?client_id=${clientId}`,
    headers: headers()
  });
};

preAssessments.updateEmployeeCount = (clientId, data) => {
  return apiClient.put({
    url: `${bsnClientServicesBaseURL}/${clientId}/preassessments/`,
    headers: headers(),
    data
  });
};

preAssessments.getUsers = (clientId, queryParams, cancelToken) => {
  const queryString = buildQueryString(queryParams);
  return apiClient.get({
    url: `${bsnClientServicesBaseURL}/${clientId}/quiz-users/${queryString}`,
    headers: headers(),
    cancelToken
  });
};

preAssessments.getPreassessmentReport = (client_id, preassesment_id) => {
  return apiClient.get({
    url: `${reportBaseURL}/reports/${client_id}/preassessment_report/${preassesment_id}`,
    headers: headers()
  });
};

preAssessments.getPreemploymentReport = (user_id, preassesment_id) => {
  return apiClient.get({
    url: `${reportBaseURL}/reports/${user_id}/preemployment_report/${preassesment_id}`,
    headers: headers()
  });
};
preAssessments.getAiriaReport = (user_id, assesment_id) => {
  return apiClient.get({
    url: `${reportBaseURL}/reports/${user_id}/airia_report/${assesment_id}`,
    headers: headers()
  });
};
export default preAssessments;
