// @flow
import React from 'react';
import { makeStyles, Divider, IconButton, Dialog, DialogTitle, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { getAvatar } from '../../newsfeedHelpers';
import { CustomAvatar } from '../common';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  list: {
    padding: '15px',
    overflowY: 'auto',
    height: '30vh',
    [theme.breakpoints.down('sm')]: {
      height: '60vh'
    }
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const ClientsModal = ({ open, close, data }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogTitle style={{ position: 'relative' }} id="dialog-title">
          <Typography variant='h3'>Clients</Typography>
          <IconButton className={classes.closeBtn} id="ModalHeaderButtonClose" onClick={close}>
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <div className={classes.list}>
          {data.map(client => (
            <div key={client.id}>
              <Box display="flex" alignItems="center" px={{ xs: 0, md: 1 }} py={1}>
                <CustomAvatar>{getAvatar(client.name)}</CustomAvatar>
                <Box pl={{ xs: 1.2, md: 2 }} flexGrow={1}>
                  <Typography textAlign='left' component="p" sx={{wordBreak: 'break-word'}} variant="h4">
                    {client.name}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </div>
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default React.memo(ClientsModal);
