// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { updateNotifications, updateFeed, deleteComment } from 'helpers/action';
import { DeleteConfirmationModal } from 'components';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const DeleteCommentModal = ({ open, close, postId, commentId, onDeleteComment, commentsCount }) => {
  const dispatch = useDispatch();

  const handleDeleteComment = () => {
    dispatch(updateFeed(postId, { comments_count: commentsCount - 1 }));
    dispatch(deleteComment(postId, commentId));

    newsFeed
      .deleteComment(commentId, postId)
      .then(res => {
        if (res?.status && res.status === 200) dispatch(updateNotifications(true));
        onDeleteComment(commentId);
        enqueueAlertSnackbar(res?.data?.message);
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      });
    close();
  };

  return (
    <DeleteConfirmationModal
      open={open}
      close={close}
      onDelete={handleDeleteComment}
      title="Delete Comment?"
      description="This comment will be deleted and cannot be recovered."
    />
  );
};

export default DeleteCommentModal;
