import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useAdminAccess } from '../hooks';
import { ADMIN_MANAGE_PARTNERS, ADMIN_MICRO_TRAINING, ADMIN_NEWSLETTERS, ADMIN_POLICES, ADMIN_DARK_WEB, ADMIN_ROOT_PATH } from '../constants';

import { ManagePartners } from './ManagePartners';
import { Newsletters } from './Newsletters';
import { MicroTrainings } from './MicroTrainings';
import { Policies } from './Policies';
import { DarkWeb } from './DarkWeb';

const pages = {
  [ADMIN_MANAGE_PARTNERS]: <ManagePartners />,
  [ADMIN_NEWSLETTERS]: <Newsletters />,
  [ADMIN_POLICES]: <Policies />,
  [ADMIN_MICRO_TRAINING]: <MicroTrainings />
  // [ADMIN_DARK_WEB]: <DarkWeb />
};

const AdminPages = () => {
  const hasAccess = useAdminAccess();
  const { page } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!(page in pages)) {
      history.push(ADMIN_ROOT_PATH);
    }
  }, [history, page]);

  return hasAccess && <Box pt={2}>{pages[page]}</Box>;
};

export default AdminPages;
