import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Popover } from '@material-ui/core';
import { usePartnerChecklist } from 'apps/getstarted/hooks';
import { Button, PlayIcon, InfoIcon } from '@trustsecurenow/components-library';
import VideoModal from 'apps/getstarted/components/VideoModal';
import { TASKS } from '../constants';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  tooltip: {
    maxWidth: 400,
    padding: 16,
    color: 'var(--colorDefault)',
    '& p': {
      marginTop: 0
    }
  }
}));

const InfoButtons = ({ name }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const { loading, tasks } = usePartnerChecklist();
  const currentTask = TASKS.find(item => item.name === name);
  const hasNewSra = useSelector(({ bsn }) => bsn?.user?.access?.features?.new_sra);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Button
        onClick={() => setOpenVideoModal(true)}
        color="info"
        startIcon={<PlayIcon />}
        variant="outlined"
        sx={{ mr: 3.75 }}
      >
        Watch
      </Button>

      <Button onClick={e => setAnchorEl(e.currentTarget)} color="info" startIcon={<InfoIcon />} variant="outlined">
        Explain
      </Button>

      <Popover
        id={anchorEl ? 'simple-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.tooltip}>
          <p>{currentTask?.title || ''}</p>
          <p>{currentTask?.getDescription(hasNewSra) || ''}</p>
        </div>
      </Popover>

      <VideoModal
        open={openVideoModal}
        close={() => setOpenVideoModal(false)}
        title={currentTask?.title || ''}
        video={tasks[name]?.video_url || ''}
        loading={loading}
      />
    </Box>
  );
};

InfoButtons.propTypes = {
  name: PropTypes.string.isRequired
};

export default InfoButtons;
