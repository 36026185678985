// @flow
import React from 'react';
import { Grid, makeStyles, Divider, DialogActions } from '@material-ui/core';
import { Button } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  padded: {
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  dialogActions: {
    padding: '0 8px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 18px 20px'
    }
  }
}));

const ModalActions = ({ title, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Grid container className={classes.padded} justify="flex-end">
          <Grid item>
            <Button sx={{ width: 140 }} disabled={disabled} onClick={onClick}>
              {title}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default  React.memo(ModalActions);
