import React from 'react';
import { Box, Checkbox, Chip, FormControlLabel } from '@material-ui/core';
import { Container, Typography } from 'components';
import { Button } from '@trustsecurenow/components-library';
import { TYPES } from './helpers';

function IncludeReceivers({ state, setState, setModalState, isPartnerProfileApp }) {
  const revertIncludedUser = idx => {
    setState(st => {
      const arr = st.included_users;
      arr.splice(idx, 1);
      return {
        ...st,
        included_users: arr
      };
    });
  };

  return (
    <>
      {/* Enable push notification for */}
      {state.push_notification.allow_individual_targeting !== 0 && (
        <Container.Grid container>
          <Container.Grid item xs={Boolean(state.push_notification.show_receiving_groups) ? 12 : 10}>
            <Typography.p> {`Select users to receive ${state?.push_notification?.name}`} </Typography.p>
          </Container.Grid>
          {Boolean(state.push_notification.show_receiving_groups) &&
            state?.receiving_groups.map(group => (
              <Container.Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      label=""
                      value={group.checked}
                      checked={group.checked}
                      name="receiving_groups"
                      onClick={e => {
                        const newVal = e.target.checked ? 1 : 0;
                        const newReceivingGroup = state.receiving_groups.map(obj =>
                          obj.id === group.id ? Object.assign(obj, { checked: newVal }) : obj
                        );
                        setState(st => ({
                          ...st,
                          receiving_groups: newReceivingGroup
                        }));
                      }}
                    />
                  }
                  label={<p> {`Send to All ${group.name}s`} </p>}
                />
              </Container.Grid>
            ))}
          <Container.Grid item xs={2} justifyContent="flex-end" alignItems="center">
            <Button
              onClick={() =>
                setModalState({
                  // type: state?.push_notification?.individual_targeting_button_name.toLowerCase().includes('partner')
                  type: isPartnerProfileApp ? TYPES.includePartner : TYPES.includeUser,
                  opened: true
                })
              }
              size="x-small"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {state?.push_notification?.individual_targeting_button_name}
            </Button>
          </Container.Grid>
          <Container.Grid py={1.2} container>
            {isPartnerProfileApp && state?.included_users?.length > 0 && (
              <Container.Grid item xs={12}>
                <Typography.p my={0.5}>Partner Admins Selected</Typography.p>
              </Container.Grid>
            )}
            <Container.Grid flexWrap="wrap" justifyContent="flex-start" container xs={12}>
              {state.included_users.map((row, idx) => (
                <Box p={1}>
                  <Chip label={row.name} onDelete={() => revertIncludedUser(idx)} />
                </Box>
              ))}
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      )}
    </>
  );
}

export default IncludeReceivers;
