// @flow
import React, { type Element, useState } from 'react';
import { Modal3 } from 'components';
import { Info } from 'components/icons';
import { getId } from 'helpers';
import { useLocation } from 'hooks';
import { Checkbox, FormControlLabel, IconButton, Tooltip, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, PencilIcon } from '@trustsecurenow/components-library';
import WelcomeMessageStatusButton from 'apps/contentadmin/components/shared/WelcomeMessageStatusButton';

type DirectorySyncSwitchComponentTypes = {
  record: Object,
  enable: boolean,
  dispatch: Object
};

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'var(--colorDefault)',
    boxShadow: '0px 2px 16px 0px #0000001F',
    padding: 16,
    fontSize: 12,
    fontWeight: 400
  },
  arrow: {
    color: 'white'
  }
})(Tooltip);

const DirectorySyncSwitchComponent = ({
  record,
  enable: enableValue,
  dispatch
}: DirectorySyncSwitchComponentTypes): Element<*> => {
  const enable = record.type === 'csvBulkUpload' ? false : enableValue;
  const [open, setOpen] = useState(false);
  const [isWelcomeMsgDialogOpened, setIsWelcomeMsgDialogOpened] = useState(false);
  const [type, setType] = useState(null);
  const history = useHistory();
  const [paramIdValue, setParamIdValue] = useState(null);
  const { app, tab, item } = useLocation();
  const id = item || getId('clientId');
  const user_profile = useSelector(s => s?.bsn?.user?.profile);

  const onClick = (t, paramId) => {
    setOpen(true);
    setType(t);
    setParamIdValue(paramId);
  };

  return (
    <div
      style={{
        // backgroundColor: 'lightgreen',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      <WelcomeMessageStatusButton
        tooltipPlacement="left"
        isWelcomeMsgDialogOpened={isWelcomeMsgDialogOpened}
        setIsWelcomeMsgDialogOpened={setIsWelcomeMsgDialogOpened}
      />

      <Button
        onClick={e => {
          history.push({
            pathname: `/${app}/welcomeMessage/${id}`,
            search: `?${Object.entries({
              id,
              test_message_id: id,
              new_welcome_message_seen: '1',
              email: user_profile.email,
              location: `${history.location.pathname}`
            })
              .map(v => v.join('='))
              .join('&')}`
          });
        }}
        variant="outlined"
        startIcon={<PencilIcon />}
      >
        Edit Welcome Message
      </Button>
      {open && paramIdValue && (
        <Modal3
          open={open}
          type={type}
          paramId={paramIdValue}
          parentId={id}
          setOpen={setOpen}
          app="clients"
          tab="customMessage"
          apiApp="clients"
          apiTab="customMessage"
        />
      )}
    </div>
  );
};

export default DirectorySyncSwitchComponent;
