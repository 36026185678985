import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@trustsecurenow/components-library';
import { ObjectToCommaSeparatedString } from 'helpers/utils';
import { CustomTable, TableCellField } from '../../customTable';
import useTrainingModal from './useTrainingModal';
import { TextField } from '../../forms';
import { useDebounce } from '../../../hooks';
import { TRAINING_TRAININGS_USER, TRAINING_USERS_TRAININGS } from './constants';
import { useTrainingsContext } from './TrainingContext';

const TrainingModalButton = styled.div`
  text-align: right;
  padding: 10px 0;
`;

const TrainingModalContent = styled.div`
  padding: 24px 16px;
`;

const Toolbar = ({ onSearch }) => {
  const [value, setValue] = useState('');

  const [searchDebounceValue] = useDebounce({ value, delay: 500 });

  useEffect(() => {
    onSearch(searchDebounceValue.value);
  }, [searchDebounceValue.value]);

  return (
    <TextField
      placeholder={'Search'}
      value={value}
      onChange={({ target: { value } }) => {
        setValue(value);
      }}
    />
  );
};

const TrainingModalTable = ({ source, onClose, id }) => {
  const { params } = useTrainingsContext();
  const { name: _unused, ...rest } = params._filter;
  const { loading, data, isFetching, count, dispatch } = useTrainingModal({
    id,
    source,
    mainTableFilterString: ObjectToCommaSeparatedString(rest)
  });

  const trainingTrainingsUsers = useMemo(
    () => (
      <>
        <TableCellField width={'45%'} renderComponent={({ name }) => <span>{name}</span>} />
        <TableCellField width={'30%'} renderComponent={({ score }) => <span>{score}</span>} />
        <TableCellField width={'25%'} renderComponent={({ date_completed }) => <span>{date_completed}</span>} />
      </>
    ),
    []
  );

  const trainingUsersTrainings = useMemo(
    () => (
      <>
        <TableCellField width={'45%'} renderComponent={({ name }) => <span>{name}</span>} />
        <TableCellField width={'30%'} renderComponent={({ score }) => <span>{score}</span>} />
        <TableCellField width={'25%'} renderComponent={({ created }) => <span>{created}</span>} />
      </>
    ),
    []
  );

  const tableContent = { trainingTrainingsUsers, trainingUsersTrainings };

  return (
    <TrainingModalContent>
      <CustomTable
        source={`clients/${source}`}
        toolbar={source !== 'trainingUsersTrainings' ? <Toolbar onSearch={dispatch.onSearch} /> : null}
        total={count || 0}
        data={data || []}
        loading={loading}
        isFetching={isFetching}
        onLoad={dispatch.onLoadParams}
        onChangePage={dispatch.onChangePage}
        onChangePerPage={dispatch.onChangePerPage}
        onSort={dispatch.onChangeSort}
        preferences={{
          app: 'client',
          tab: source === 'trainingUsersTrainings' ? TRAINING_USERS_TRAININGS : TRAINING_TRAININGS_USER
        }}
      >
        {tableContent[source]}
      </CustomTable>
      <TrainingModalButton>
        <Link sx={{ mt: 1.5 }} onClick={onClose} component="button">
          Cancel
        </Link>
      </TrainingModalButton>
    </TrainingModalContent>
  );
};

export default TrainingModalTable;
