import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, TableList, TextField } from 'components';
import { isValidDomain, isValidEmail } from 'helpers';
import { useSelector } from 'react-redux';
import dwAPI from 'helpers/apis/darkWeb';
import styled from 'styled-components';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import useHasInternet from 'hooks/useHasInternet';
import axios from 'axios';
import { enqueueAlertSnackbar, Button, HelpIcon } from '@trustsecurenow/components-library';

const StyledTextField = styled(TextField)`
  width: 320px;
  .Mui-error {
    width: 320px;
  }
`;

const DarkWeb = () => {
  const [value, setValue] = useState('');
  const [query, setQuery] = useState('');
  const [validation, setValidation] = useState({ isValid: false, error: '' });
  const [prohibitedDomains, setProhibitedDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const hasInternet = useHasInternet();
  const [breaches, setBreaches] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const preferences = useMemo(() => userPreferences.getTableSettings('admin', 'darkweb'), []);
  const [error, setError] = useState({
    exists: false,
    msg: ''
  });
  const cancelTokenSource = useRef();
  const [tableControls, setTableControls] = useState({
    page: 0,
    perPage: preferences.pagination || 25,
    total: 0,
    order: preferences.order || 'asc',
    orderBy: preferences.orderBy || 'account',
    isLoading: false
  });

  const tableSettings = useSelector(state => state.bsn.tables['admin']['darkWeb']);

  useEffect(() => {
    // getting new pereferences when user changes the tab
    // which is equal to the value
    setTableControls(prev => {
      return {
        ...prev,
        page: 0,
        perPage: preferences.pagination || 25,
        total: 0,
        order: preferences.order || 'asc',
        orderBy: preferences.orderBy || 'account'
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  // eslint-disable-next-line no-shadow
  const fetchDWBreaches = useCallback(async ({ filter, query, sort, pagination, onSuccess, onError, cancelToken }) => {
    return dwAPI
      .getDarkWeb({ filter, query, sort, pagination, cancelToken })
      .then(onSuccess)
      .catch(onError);
  }, []);

  const fetchDWBreachesEffect = useCallback(() => {
    setTableControls(prev => {
      return { ...prev, isLoading: true };
    });
    setError({
      exists: false,
      msg: ''
    });
    // eslint-disable-next-line no-shadow
    const onSuccess = (response, policyType) => {
      hasInternet(() => {
        setError({
          exists: false,
          msg: ''
        });
        setTableControls(old => {
          return { ...old, total: response.headers['x-total-count'], isLoading: false };
        });
        if (response?.data?.data.length === 0) {
          setBreaches([]);
        } else {
          setBreaches(response?.data?.data);
        }
      });
    };

    // eslint-disable-next-line no-shadow
    const onError = error => {
      if (axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.log('Request canceled', error.message);
      } else {
        setTableControls(prev => {
          return { ...prev, isLoading: false };
        });
        setError({
          exists: true,
          msg: error?.response?.data?.description || 'Something went wrong'
        });
        enqueueAlertSnackbar(error?.response?.data?.description || 'Something went wrong', {
          props: { severity: 'error' }
        });
      }
    };
    /** cancelling previous not answered requests */
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('cancelling an old api call');
    }
    cancelTokenSource.current = axios.CancelToken.source();
    fetchDWBreaches({
      filter: searchTerm ? `account:${searchTerm},site_breached:${searchTerm}` : '',
      query,
      sort: { field: tableControls.orderBy, order: tableControls.order },
      pagination: {
        page: tableControls.page * tableControls.perPage + 1,
        perPage: (tableControls.page + 1) * tableControls.perPage + 1
      },
      onSuccess,
      onError,
      cacenlToken: cancelTokenSource.current.token
    });
  }, [
    fetchDWBreaches,
    searchTerm,
    query,
    tableControls.orderBy,
    tableControls.order,
    tableControls.page,
    tableControls.perPage,
    hasInternet
  ]);

  useEffect(() => {
    fetchDWBreachesEffect();
  }, [fetchDWBreachesEffect, query]);

  useEffect(() => {
    dwAPI
      .getProhibitedDomains()
      .then(res => {
        setProhibitedDomains(res?.data?.blacklisted_domains);
      })
      .catch(err => console.error(err));
  }, []);

  const validate = value => {
    setValidation(() => {
      // if (!value) return { isValid: false, error: '' };
      if (isValidEmail(value)) return { isValid: true, error: '' };
      else if (isValidDomain(value)) {
        const isProhibited = prohibitedDomains.includes(value) || prohibitedDomains.includes(value.toLowerCase());
        return !isProhibited
          ? { isValid: true, error: '' }
          : { isValid: false, error: 'The FQDN entered is on the Blacklisted domains list and cannot be used' };
      }
      // else if (value.length > 50) return 'Exceed in 50 Characters required fields';
      else return { isValid: false, error: '' };
    });
  };

  const handleValueChange = e => {
    const value = e.target.value;
    setValue(value);
    validate(value.trim());
  };

  const handleQueryNow = () => {
    setQuery(value);
  };

  const table = useMemo(
    () => (
      <TableList
        tableSettings={tableSettings}
        extraParams={{ query }}
        setLoading={setLoading}
        tableOptions={{
          refetchOnWindowFocus: false
        }}
        onSearch={setSearchTerm}
        hideColumns={['remediated']}
        data={{
          data: breaches,
          page: tableControls.page,
          prePage: tableControls.perPage,
          total: tableControls.total,
          isError: error.exists,
          isFetching: tableControls.isLoading,
          isLoading: tableControls.isLoading,
          order: tableControls.order,
          orderBy: tableControls.orderBy,
          status: error.exists ? 'error' : 'success',
          dispatch: {
            // empty methods for the table
            // to use when when some actions triggered
            // just to prevent errors
            refetch: () => {},
            // if provided, will be fired only when user deletes items from the table
            refetchOnDeletion: () => {
              fetchDWBreachesEffect();
            },
            setOrder: order => {
              setTableControls(old => {
                return { ...old, order };
              });
            },
            setOrderBy: orderBy => {
              setTableControls(old => {
                return { ...old, orderBy };
              });
            },
            setPage: page => {
              setTableControls(old => {
                return { ...old, page };
              });
            },
            setPrePage: perPage => {
              setTableControls(old => {
                return { ...old, perPage, page: 0 };
              });
            }
          }
        }}
      />
    ),
    [
      tableSettings,
      query,
      breaches,
      tableControls.page,
      tableControls.perPage,
      tableControls.total,
      tableControls.isLoading,
      tableControls.order,
      tableControls.orderBy,
      error.exists,
      fetchDWBreachesEffect
    ]
  );

  return (
    <>
      <Container.Paper px={2} mt={1} mb={1} alignItems="center">
        <Container.Grid mt={1} mb={1} container style={{ width: '100%' }}>
          <Container.Grid item pr={1} direction="column">
            <StyledTextField
              margin="dense"
              size="small"
              value={value}
              name="domain"
              label="Check"
              onChange={handleValueChange}
              error={!!validation.error}
              helperText={validation.error}
            />
          </Container.Grid>
          <Container.Grid item pl={1} pt={1}>
            <Button
              disabled={!validation.isValid || loading}
              onClick={handleQueryNow}
              startIcon={<HelpIcon />}
              color="info"
              sx={{ width: 203 }}
            >
              Query Now
            </Button>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      {table}
    </>
  );
};

export default DarkWeb;
