// @flow
import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import DashboardBoxTitle from 'components/dashboard/DashboardBoxTitle';
import { Hidden } from '@material-ui/core';
import ChartBar from '../charts/ChartBar';
import Container from '../types/Containers';

type DashboardChartBarGroupWidgetType = {
  chart: Array<Object> | null,
  type?: string
};

const SliderContainer = styled.div`
  .swiper-pagination {
    bottom: 0 !important;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .dashboard-chart-bar {
      h3 {
        font-size: 1.3rem;
        font-weight: 500;
        color: var(--colorDefault);
        padding-left: 30px;
      }
    }
  }
`;

const DesktopDashboardChartBarGroupWidget = ({ chart, type, title, componentDescription }) => {
  const isHorizontal = type === 'horizontal';
  return (
    <Hidden smDown>
      <>
        {title && (
          <DashboardBoxTitle
            icon
            label={title}
            mbbox
            fullWidth
            fontSize={13}
            iconSize={1.25}
            componentDescription={componentDescription}
            iconMl={1}
          />
        )}
        <Container.Grid spacing={0} pt={2}>
          {chart &&
            chart.map((chartBar, i) => {
              const {
                title,
                invert,
                colorType1,
                chart: { chartData }
              } = chartBar;
              return (
                <Container.Grid
                  item
                  pt={3}
                  pb={3}
                  bb={!isHorizontal ? (i === 0 || i === 1 ? 1 : 0) : 0}
                  br={!isHorizontal ? (i === 0 || i === 2 ? 1 : 0) : 0}
                  xs={!isHorizontal ? 6 : 12}
                  sm={!isHorizontal ? 6 : 12}
                  md={!isHorizontal ? 6 : 6}
                  lg={!isHorizontal ? 6 : 4}
                  xl={!isHorizontal ? 6 : 4}
                >
                  <ChartBar title={title} chart={chartData} invert={invert} colorType1={colorType1} labels />
                </Container.Grid>
              );
            })}
        </Container.Grid>
      </>
    </Hidden>
  );
};

const MobileDashboardChartBarGroupWidget = ({ chart }) => {
  if (!chart) return null;

  return (
    <Hidden mdUp>
      <SliderContainer>
        <Swiper autoHeight spaceBetween={50} pagination={true} modules={[Pagination]}>
          {chart.map((chartBar, i) => {
            const {
              title,
              invert,
              colorType1,
              chart: { chartData }
            } = chartBar;
            return (
              <SwiperSlide key={i}>
                <ChartBar
                  title={title}
                  chart={chartData}
                  invert={invert}
                  colorType1={colorType1}
                  labels
                  className={'dashboard-chart-bar'}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderContainer>
    </Hidden>
  );
};

const DashboardChartBarGroupWidget = ({ chart, type, title, description }: DashboardChartBarGroupWidgetType) => {
  return (
    <>
      <DesktopDashboardChartBarGroupWidget chart={chart} type={type} title={title} componentDescription={description} />
      <MobileDashboardChartBarGroupWidget chart={chart} />
    </>
  );
};

export default DashboardChartBarGroupWidget;
