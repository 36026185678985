import React from "react"
import { CardTitleAndTextContainer } from '../../style';
import { Typography } from '@trustsecurenow/components-library';

const CardTitleAndText = ({title, text}) => {
  return (
    <CardTitleAndTextContainer>
      <Typography variant = 'h4'>{ title }</Typography>
      <Typography variant = 'body2'>{ text }</Typography>
    </CardTitleAndTextContainer>
  )
}


export default CardTitleAndText
