import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@trustsecurenow/components-library';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import useDebounce from '../../../../hooks/useDebounce';

const filter = createFilterOptions();

function formatHashtags(value) {
    if (value) {
      const words = value.split(',') || value.split(' ');
      return words.filter(word => word.match(/#[\w_]+/));
    }
    return [];
  }
  
  const containsEmoji = (input) => {
    const emojiRegex = /[\p{Emoji}]/u;
    return emojiRegex.test(input);
  }

  
  const HashtagsField = ({ dispatch, record_hashtags }) => {
    const current_hashtags = formatHashtags(record_hashtags);
    const [hashtags, setHashtags] = useState({
      options: [],
      selecteds: current_hashtags,
      search: '',
      loading: false
    })
    const [searchHashTag] = useDebounce({ value: hashtags.search, delay: 500 });
    
    const fetchHashtags  = async (searchTerm = null)  => {
      setHashtags(prev => ({ ...prev, loading: true}));
      try {
        const res = await newsFeed.getHashtags(searchTerm)
        const options = res?.data && res.data.map(item => ({ label: `#${item.tag_name}` }))
        setHashtags(prev => ({
          ...prev,
          options: [...options]
        }))
      } catch (err) {
        setHashtags(prev => ({ ...prev, options: [] }));
      } finally {
        setHashtags(prev => ({ ...prev, loading: false}));
      }
    }
  
    useEffect(() => {
      const searchTerm = searchHashTag.value?.charAt(0) === '#' ? searchHashTag.value.substring(1) : searchHashTag.value
      fetchHashtags(searchTerm)
    }, [searchHashTag.value]);
  
    return (
      <Autocomplete
        multiple
        name="hash_tags"
        loading={hashtags.loading}
        onChange={(_e, val, reason) => {
          const seleteds = val.map(option => {
            if ( typeof option === 'string') {
              return option;
            }
            // case user selected to add new option
            if (option?.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.label;
          })
        
          setHashtags(prev => ({
            ...prev,
            selecteds: seleteds
          }))
  
          dispatch.onChange('hash_tags', seleteds.join(','))
          
          if (reason === 'clear') {
            setHashtags(prev => ({
              ...prev,
              search: ''
            }));
          }
        }}
        value={hashtags.selecteds}
        inputValue={hashtags.search}
        onInputChange={(e, value) => {
          if (!containsEmoji(value)) {
            setHashtags(prev => ({
              ...prev,
              search: value
            }));
          }
        }}
        freeSolo
        clearOnBlur
        getOptionLabel={option => typeof option === 'string' ? option : option?.label}
        options={hashtags.options}
        filterOptions={(options, params) => {
          if (containsEmoji(params.inputValue)) return [];
          const filtered = filter(options, params);
          const allowedHashtag = /#[\w_]+/.test(params.inputValue);
          // Suggest the creation of a new value
          const isExisting = !hashtags.loading && options.some((option) => {
            return params.inputValue?.toLocaleLowerCase() === option?.label?.toLocaleLowerCase();
          });
          if (allowedHashtag && !isExisting) {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        fullWidth
        renderInput={(params) =>
          <TextField
            {...params}
            label="Hashtags"
            multiline
            sx={{
              '& .MuiInputBase-root': {
                alignItems: 'flex-start',
                gap: 1
              }
            }}
            rows={6}
          />
        }
      />
    )
  };

  export default HashtagsField;