import { useCallback } from 'react';
import { hasInternetConnection } from '../helpers';

function useHasInternet() {
  return useCallback(
    (callback, callbackWithoutInternet) => hasInternetConnection(callback, callbackWithoutInternet),
    []
  );
}

export default useHasInternet;
