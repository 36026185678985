import React, { useState } from 'react';
import { RHFDragZone as DragZone, RHFDragDropLogo as DragDropLogo, RichTextEditorController } from 'components';
import Controller from 'components/newForms/Controller';
import { useFormContext, useWatch } from 'react-hook-form';
import contentAdmin from 'helpers/apis/contentAdmin';
import {
  Stack,
  Typography,
  enqueueAlertSnackbar,
  getRichTextEdiorConfig,
  useTheme,
  TextField,
  MailSearchIcon,
  Button
} from '@trustsecurenow/components-library';
import { updateMutable, updateNestedMutable } from 'utils/update';
import AttachmentList from '../shared/AttachmentList';
import { useContentAdminFormContext } from '../../context';
import { ContentAdminFormGrid, ContentAdminFormRow } from '../../style';

const defaultConfig = getRichTextEdiorConfig();
const customConfig = {
  uploader: {
    insertImageAsBase64URI: false
  },
  link: {
    openInNewTabCheckbox: false,
    noFollowCheckbox: false
  },
  enter: "br",
  addNewLineOnDBLClick: false,
  iframe: true,
  toolbarSticky: false,
  allowResizeY: true,
  maxHeight: undefined,
  maxWidth: '100%',
  buttons: [
    'fontsize',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'brush',
    'undo',
    'redo',
    'link',
    'image',
    'ul',
    'ol',
    'paragraph',
    'source'
  ]
};

const disabledRichTextEditorButtons = ['video', 'eraser', 'print', 'copyformat', 'hr', 'fullsize', 'font', 'table'];

const EmailTemplateForm = () => {
  const theme = useTheme();
  const { setValue, control } = useFormContext();
  const { dispatch, bindingParams } = useContentAdminFormContext();

  const [fileData, setFileData] = useState('');
  const [disabled, setDisabled] = useState(false);
  const attachments = useWatch({ control, name: 'attachments' });

  const readFile = e => {
    setFileData('');
    setDisabled(true);
    dispatch.disabled(true);

    const validExtensions = ['msg', 'eml'];
    const file = e.target.files[0];
    const fileName = file.name;
    const fileType = fileName.split('.').pop().toLowerCase();

    if (!validExtensions.includes(fileType)) {
      enqueueAlertSnackbar(
        `Not a valid choice`,
        { props: { severity: 'error' }}
      );
      setDisabled(false);
      dispatch.disabled(false);
      return;
    }

    const reader = new FileReader();
    let fileValue;

    reader.addEventListener(
      'load',
      async function() {
        fileValue = reader.result.split(',')[1];
        let templateData;
        try {
          templateData = await contentAdmin.parseEmailTemplate({
            file_type: fileType,
            template: fileValue
          });
        } catch (e) {
          if (e?.response?.data?.error) {
            enqueueAlertSnackbar(e?.response?.data.error.file_type, { props: { severity: 'error' } });
          } else {
            enqueueAlertSnackbar('Something went wrong', { props: { severity: 'error' } });
          }
        } finally {
          setDisabled(false);
          dispatch.disabled(false);
        }

        if (templateData) {
          const {
            data: { attachments, body, subject }
          } = templateData;

          setValue('email_subject', subject, { shouldValidate: true });
          setValue('email_html', body);

          setValue(
            'attachments',
            attachments.map(({ filename, attachment, ...params }) => {
              const blob = new Blob([attachment]);
              const file = new File([blob], filename, {
                type: 'text/plain'
              });
              return { file_display_name: filename, isNew: true, attachment: file, ...params };
            })
          );
          setDisabled(false);
          dispatch.disabled(false);
        }
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadLogo = ({ name, value, file }) => {
    setValue(
      name,
      value && {
        preview: value,
        file,
        isNew: true
      }
    );
  };

  const onFileUpload = files => {
    const uploadedFilesSize = files.reduce((sum, value) => {
      return sum + value.size;
    }, 0);

    const attachmentsFilesSize = attachments.reduce((sum, value) => {
      const file = value?.attachment ? value?.attachment : value;
      const fileSize = file?.size ? file.size : file.content_length;
      return sum + fileSize || 0;
    }, 0);

    if (uploadedFilesSize + attachmentsFilesSize > 20 * 1024 * 1024) {
      enqueueAlertSnackbar('File sizes must be no larger than 20 MB', { props: { severity: 'error' } });
      return false;
    }

    // Function to check if a filename exists in attachments
    const attachmentNameExists = (name, attachments) =>
      attachments.some(attachment => attachment.file_display_name === name);

    const newFilesData = [
      ...attachments,
      ...files.map(file => {
        let baseName = file.name;
        let extension = '';
    
        // Separate base name and extension
        const lastDotIndex = baseName.lastIndexOf('.');
        if (lastDotIndex !== -1) {
          baseName = file.name.slice(0, lastDotIndex);
          extension = file.name.slice(lastDotIndex);
        }
    
        // Check if baseName already exists in attachments
        let newName = baseName + extension;
        let count = 1;
        while (attachmentNameExists(newName, attachments)) {
          newName = `${baseName}-copy-${count}${extension}`;
          count++;
        }
    
        // Create a new File object with the updated name
        const updatedFile = new File([file], newName, { type: file.type });
    
        return {
          file_display_name: newName,
          attachment: updatedFile,
          isNew: true,
        };
      })
    ];
    setValue('attachments', newFilesData);

    return true;
  };

  const onRemoveFile = (name, index) => {
    if (attachments?.length) {
      const newValues = attachments.filter((item, attachmentIndex) => attachmentIndex !== index);
      setValue('attachments', newValues);
    }
  };

  const atValues = React.useMemo(
    () =>
      bindingParams.map(f => {
        return {
          title: f
            .replace(/_/g, ' ')
            .split(' ')
            .map(word => {
              return word[0].toUpperCase() + word.substring(1);
            })
            .join(' '),
          value: `{{${f}}}`
        };
      }),
    [bindingParams]
  );

  const attachmentsData = attachments.length
    ? attachments.map(item => item?.attachment?.name || item.file_display_name)
    : [];

  const richTextConfig = React.useMemo(() => {
    const config = updateMutable(
      defaultConfig,
      ['autocomplete', 'sources', 1],
      atValues ?? []
    );
    config.buttons = config.buttons.filter(v => !disabledRichTextEditorButtons.includes(v));
    config.style = { color: theme.palette.text.primary };

    return updateNestedMutable(config, customConfig)

  }, [theme.palette.text.primary, atValues]);

  return (
    <>
      <ContentAdminFormRow alignItems="flex-start">
        <ContentAdminFormGrid md={4}>
          <Controller
            name="name"
            required
            render={({ field }, error) => {
              return (
                <>
                  <TextField
                    name="name"
                    label="Template Name"
                    fullWidth
                    required
                    type="text"
                    inputProps={{ maxLength: 100 }}
                    value={field.value || ''}
                    onChange={field.onChange}
                    helperText={error?.message || ''}
                    error={error?.message}
                  />
                </>
              );
            }}
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <Controller
            name="email_subject"
            required
            render={({ field }, error) => {
              return (
                <>
                  <TextField
                    name="email_subject"
                    label="Email Subject"
                    fullWidth
                    required
                    type="text"
                    inputProps={{ maxLength: 100 }}
                    value={field.value || ''}
                    onChange={field.onChange}
                    helperText={error?.message || ''}
                    error={error?.message}
                  />
                </>
              );
            }}
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={2} py={0.8}>
          <Button
            component="label"
            sx={{ minWidth: 160 }}
            variant="outlined"
            disabled={disabled}
            startIcon={<MailSearchIcon />}
          >
            <input
              accept=".msg, .eml"
              id="import-email"
              type="file"
              onChange={readFile}
              value={fileData}
              hidden
              disabled={disabled}
            />
            Import Email
          </Button>
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid item lg={4} md={6} sm={12}>
          <DragZone
            title="Attachments"
            fileType=""
            name="attachments"
            size={12}
            type="file"
            multiple
            onChange={onFileUpload}
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid item lg={12} md={12} sm={12}>
          <AttachmentList data={attachmentsData} onRemoveFile={onRemoveFile} />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid lg={4} md={12} sm={12}>
          <DragDropLogo label="Update Logo" name="logo" onChange={uploadLogo} required />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid xs={12}>
          <Stack spacing={2.5} width="100%">
            <Typography variant="h4">Plain text</Typography>
              <RichTextEditorController
                name="email_html"
                required
                config={richTextConfig}
              />
          </Stack>
        </ContentAdminFormGrid>
      </ContentAdminFormRow>
    </>
  );
};

export default EmailTemplateForm;
