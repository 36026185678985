export const getHeaders = (contentType = 'application/json', includeAuthorization = true) => {
  return {
    'Content-Type': contentType,
    ...(includeAuthorization ? { Authorization: localStorage.getItem('idToken') } : {})
  };
};

export const getExternalHeaders = (itemKey = 'quizToken') => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});
