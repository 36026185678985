import styled from 'styled-components';
import { Container, Typography } from "components"
import { Card } from '@material-ui/core';

export const QuizWrapper = styled.div`
  max-width: 660px;
  width: 100%;
  margin: 0 calc(var(--spacing) * 2);
`

export const QuizHeaderContainer = styled(Card)`
  && {
    padding: 18px 32px;
    box-shadow: none !important;
    margin-bottom: var(--spacing);
    svg {
      color: var(--colorSystemInfoHover);
    }
  }
`

export const HeaderTimeTitle = styled(Typography.p)`
  color: var(--colorSystemInfoHover);
  margin: 0 0 0 var(--spacing);
  font-size: 14px;
  font-weight: 400;
`


export const QuizContentWrapper = styled(Card)``

export const QuizContentHeaderLayout = styled(Container.Grid)`
  padding: 20px 30px;

  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: column-reverse;
  }
`

export const QuizTitle = styled(Typography.h3)`
  font-size: 18px;
  line-height: 22px;
  color: var(--colorGreyLight1);
  margin: 0 0 3px 0;
`

export const QuizSubtitle = styled(Typography.p)`
  font-size: 14px;
  line-height: 25px;
  color: var(--colorGreyLight2);
  margin: 0;
`

export const QuizContentBodyLayout = styled.div`
  padding: 30px;
  border-top: 1px solid #F6F6F6;
  border-bottom: 1px solid #F6F6F6;
`

export const QuizContentFooterLayout = styled(Container.Grid)`
  padding: 20px 30px;
`