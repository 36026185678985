// @flow
import { Stack, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { Layout as AppLayout, Footer } from 'components';
import { setRedirectToFavorite } from 'helpers/action';
import React, { useCallback, useEffect, useState } from 'react';
import { useLogin } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isPublicUrl, isValidEmail, getStorage } from '../../helpers';
import cognito from '../../helpers/apis/cognito';
import useHasInternet from '../../hooks/useHasInternet';
import UserLoginForm from './UserLoginForm';

const LoginForm = ({ location }) => {
  const access_token = localStorage.getItem('accessToken');
  let history = useHistory();

  if (access_token) {
    let currentTab = sessionStorage.getItem('currentTab');
    if (currentTab) history.goBack();
    else {
      const isLoginPath = history.location.pathname === '/login';
      const favorite = getStorage('favorite', true);
      const redirectPath = isLoginPath && favorite ? favorite : 'myDashboard/dashboard';
      history.replace(`/${redirectPath}`);
    }
  }

  const hasInternetConnection = useHasInternet();

  const partnerLogosBaseURL = process.env.PARTNER_LOGOS_BASE_URL;
  const handleUserFederation = useCallback(userEmail => {
    cognito
      .checkFederatedLogin(userEmail)
      .then(response => {
        if (response.status === 200 && response.data.url !== null) {
          window.open(response.data.url, '_self');
          setLoading(false);
        } else {
          setShowPassword(true);
          setLoading(false);
        }
      })
      .catch(error => {
        hasInternetConnection(() => {
          setLoading(false);
          enqueueAlertSnackbar(error.response.data.description, { props: { severity: 'error' } });
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [email, setEmail] = useState('');
  const [brandKey, setBrandKey] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.clear();
    if (new URLSearchParams(location.search).get('microtraining_id')) {
      sessionStorage.setItem('microtraining_id', new URLSearchParams(location.search).get('microtraining_id'));
    }
  }, []);

  useEffect(() => {
    if (!access_token) {
      dispatch({
        type: 'BSN_CLEAR',
        payload: {
          myDashboard: {
            dashboard: null,
            leaderboard: null,
            leaderboardOverall: null,
            securityTraining: null,
            microTrainings: null,
            microTrainingsQuiz: null,
            microTrainingsStats: null,
            darkWeb: null,
            darkWebList: null,
            newsletters: null,
            securityPolicies: null,
            securityPolicy: null,
            otherPolicies: null,
            otherPolicy: null
          },
          myCompany: {
            dashboard: null,
            complianceDashboard: null,
            individualEssRanking: null,
            training: null,
            trainingFilter: null,
            microTrainings: null,
            microTrainingUsers: null,
            policies: null,
            otherPolicies: null,
            documents: null,
            documentsInfo: null,
            documentsServiceProvider: null,
            documentsTemplates: null,
            otherDocuments: null,
            sra: { id: 1 }, // TODO: Change to null later
            darkWebMonitoring: null,
            darkWebMonitoringBreaches: null,
            track: { id: 1 }, // TODO: Change to null later
            employees: null
          },
          clients: {
            list: null,
            dashboard: null,
            complianceDashboard: null,
            access: null,
            directorySync: null,
            information: null,
            notification: null,
            products: null,
            user: null,
            users: null,
            usersList: null,
            addUser: null,
            darkWebMonitoring: null,
            trainingReports: null,
            trainingAnnual: null,
            trainingTrainings: null,
            trainingTrainingsUsers: null,
            trainingUsers: null,
            trainingUsersTrainings: null,
            phishingReports: null,
            phishingReportsCampaign: null,
            phishingReportsRecipients: null,
            policies: null,
            policyInfo: null,
            otherPolicies: null,
            otherPolicyInfo: null,
            documents: null,
            documentsInfo: null,
            documentsServiceProvider: null,
            documentsTemplates: null,
            disasterRecovery: null,
            otherDocuments: null,
            track: null,
            trackSecurityIncidents: null,
            trackServerRoomAccess: null,
            phishingCampaignScenariosList: null,
            phishingCampaignRecipientsList: null,
            autophish: null,
            autoPhishCampaigns: null,
            scenarioPreview: ''
          },
          user: {
            profile: null,
            theme: null,
            access: null,
            contactUs: null
          },
          system: {
            systemHasData: false,
            initPosition: 0,
            item: null,
            hasSidebar: false,
            isApp: false,
            loading: false,
            loadingTab: false,
            loadingItem: false,
            location: 'login',
            locationPrevious: null,
            hasError: false,
            errorMessage: '',
            errorInfo: '',
            tabPrevious: null,
            tabCurrent: null,
            hasDarkMode: false,
            tableRowSelected: [],
            tableRowSelectedList: [],
            tableRowUnselected: [],
            tableFilter: {},
            disabledNext: true
          }
        }
      });
    }
    dispatch(setRedirectToFavorite(false));
  }, [dispatch]);

  useEffect(() => {
    let nextPath = location.state?.nextPathname;
    if (nextPath) {
      let isPublicPage = isPublicUrl(nextPath?.split('/')[1].split('?')[0]);
      const isNoRedirectPage = ['myDashboard', 'login', 'configmfaapp', 'mfavalidation'].some(name =>
        nextPath.includes(name)
      );

      if (isPublicPage || isNoRedirectPage) {
        dispatch(setRedirectToFavorite(true));
        sessionStorage.setItem('redirectToFavorite', 'true');
      } else {
        const searchVal = location.state?.nextSearch || '';
        sessionStorage.setItem('redirectToPath', nextPath + searchVal);
      }
    } else if (!sessionStorage.getItem('microtraining_id')) {
      dispatch(setRedirectToFavorite(true));
      sessionStorage.setItem('redirectToFavorite', 'true');
    }
  }, [dispatch, location.state?.nextPathname, location.state?.nextSearch]);

  useEffect(() => {
    let uEmail = new URLSearchParams(location.search).get('email');
    if (uEmail) uEmail = uEmail.replace(/ /g, '+');
    const uBrandKey = new URLSearchParams(location.search).get('brand_key');
    if (uEmail) {
      setEmail(uEmail);
      handleUserFederation(uEmail);
    }
    if (uBrandKey) {
      setBrandKey(uBrandKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleChangePassword = e => {
    setPassword(e.target.value);
  };

  const handleSubmitForm = e => {
    if (e) e.preventDefault();

    if (email === '') {
      enqueueAlertSnackbar(`Please enter an email address`, { props: { severity: 'error' } });
      return;
    }
    if (!isValidEmail(email)) {
      enqueueAlertSnackbar(`Please enter a valid email address`, { props: { severity: 'error' } });
      return;
    }

    if (showPassword && password === '') {
      enqueueAlertSnackbar('Please enter a Password');
      return;
    }

    setLoading(true);
    if (!password) {
      hasInternetConnection(
        () => {
          handleUserFederation(email);
        },
        () => {
          setLoading(false);
        }
      );
    }

    if (showPassword) {
      if (email === '' && password === '') {
        setLoading(false);
        enqueueAlertSnackbar('Email and Password are required', { props: { severity: 'error' } });
      }
      hasInternetConnection(
        () => {
          login({ loginPayload: { email, password }, dispatchRx: dispatch })
            .then(() => {
              setLoading(false);
            })
            .catch(e => {
              setLoading(false);
              if (e.statusCode === 405) {
                handleUserFederation(email);
              } else {
                e.message?.length > 0 && enqueueAlertSnackbar(e.message, { props: { severity: 'error' } });
              }
            });
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  return (
    <AppLayout appBar={null} sidebar={null} background="paper" centered footer={<Footer />}>
      <Stack maxWidth={610} p={2} margin="auto">
        {brandKey && (
          <Stack maxWidth={200} maxHeight={200} margin="auto">
            <img alt={brandKey} src={`${partnerLogosBaseURL}${brandKey}`} />
          </Stack>
        )}

        <UserLoginForm
          email={email}
          onChangeEmail={handleChangeEmail}
          password={password}
          onChangePassword={handleChangePassword}
          onSubmitForm={handleSubmitForm}
          loading={loading}
          showPassword={showPassword}
        />
      </Stack>
    </AppLayout>
  );
};

export default LoginForm;
