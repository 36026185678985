import React from 'react';
import { Dialog, makeStyles, IconButton } from '@material-ui/core';
import { Button, Link, CloseIcon } from '@trustsecurenow/components-library';
const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 0,
    fontSize: '24px'
  }
});

const GradientDialog = ({ opened, onClose, gradientSync }) => {
  const handleClose = () => {
    onClose();
  };
  const classes = useStyles();

  return (
    <Dialog style={{ 'background-color': '#082F49F5' }} onClose={handleClose} open={opened} fullWidth>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon sx={{fontSize:24}} />
      </IconButton>
      <div style={{ marginTop: '40px', textAlign: 'center', fontFamily: 'Roboto' }}>
        <h2> {'Gradient clients'} </h2>
        <p> Your clients information will be sent to gradient, as well as grant </p>
        <p> access to functionalities within your boards, </p>
        <p> this includes but is not limited to: </p>
        <br />
        <p> - Names of all of your client;</p>
        <p> - Access to all of your PSA boards</p>
        <br />
        <Button sx={{ width: 206 }} onClick={gradientSync} color="success">
          Yes, Sent To Gradient
        </Button>
        <br />
        <Link sx={{ mt: 3.5 }} component="button" onClick={handleClose}>
          Cancel
        </Link>
      </div>
    </Dialog>
  );
};

export default GradientDialog;
