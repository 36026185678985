// @flow
import React from 'react';
import { Grid, Typography, makeStyles, Link, Dialog } from '@material-ui/core';
import { DeleteOutlined as DeleteOutlinedIcon } from '@material-ui/icons';
import { Button } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  padded: {
    padding: '30px 20px'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  }
}));

const ClientsDeleteLogoModal = ({ open, close, deleteLogo }) => {
  const classes = useStyles();

  const deleteCurrLogo = () => {
    close();
    deleteLogo();
  };

  return (
    <>
      <Dialog open={open} onClose={close} style={{ padding: '50px,10px' }}>
        <Grid className={classes.padded} alignItems="center" style={{ textAlign: 'center' }} container>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <DeleteOutlinedIcon className={classes.deleteIcon} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" display="block" gutterBottom>
              Delete current logo?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography display="block" gutterBottom>
              This logo will be deleted and cannot be recovered.
            </Typography>
          </Grid>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <Typography display="block" gutterBottom>
              Are you sure about that?
            </Typography>
          </Grid>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <Button color="error" onClick={deleteCurrLogo}>
              Yes, I Want To Delete
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Link
              style={{ color: 'var(--colorDefault)', textDecoration: 'underline' }}
              href="#"
              onClick={e => {
                e.preventDefault();
                close();
              }}
              color="inherit"
            >
              Cancel
            </Link>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ClientsDeleteLogoModal;
