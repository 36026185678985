import { Box } from '@material-ui/core';
import { CustomTooltip } from 'components';
import { trackingUtils } from 'helpers';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import React, { useState } from 'react';
import { Button, enqueueAlertSnackbar } from '@trustsecurenow/components-library';

function EnableDisableButton({
  state,
  refetchSubscription,
  refetchTable,
  isEditedData,
  invalidData,
  setActionLoading,
  partnerId,
  clientId,
  trackGA
}) {
  const [enableLoading, setEnableLoading] = useState(false);

  const wasSubscriptionEnabled = state?.subscription?.enabled;

  const handleEnableDisable = async () => {
    setActionLoading(true);
    setEnableLoading(true);
    const saveBeforeEnable = !state.is_saved;

    try {
      if (saveBeforeEnable) {
        await pushNotifications.setSubscription(state?.subscription?.id, state);
      }

      await pushNotifications.enableDisableSubscription(partnerId, clientId, {
        push_notification_id: state.push_notification.id,
        subscription_id: +state.subscription.id,
        enabled: !!wasSubscriptionEnabled ? 0 : 1
      });

      await refetchSubscription(); // refetching modal data
      await refetchTable(); // refetching table data

      enqueueAlertSnackbar(
        `${state?.push_notification?.name} Notification has been ${wasSubscriptionEnabled ? 'disabled' : 'enabled'}`,
        {
          props: { severity: 'success' }
        }
      );

      if (trackGA && !wasSubscriptionEnabled) {
        trackingUtils.customEvent('completed_subtask', {
          sendUserName: true,
          sendPartnerName: true,
          label: state?.push_notification?.name
        });
      }
    } catch (err) {
      enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    } finally {
      setEnableLoading(false);
      setActionLoading(false);
    }
  };

  return (
    <Box ml={3}>
      <CustomTooltip
        title={
          isEditedData || invalidData
            ? `Must save first before you can ${wasSubscriptionEnabled ? 'disable' : 'enable'} the notification`
            : ''
        }
        placement="top"
        arrow
        light
        maxWidth={250}
      >
        <span>
          <Button
            onClick={handleEnableDisable}
            variant="outlined"
            disabled={isEditedData || invalidData}
            color={wasSubscriptionEnabled ? 'error' : 'primary'}
            sx={{ width: 95 }}
            loading={enableLoading}
          >
            {wasSubscriptionEnabled ? 'Disable' : 'Enable'}
          </Button>
        </span>
      </CustomTooltip>
    </Box>
  );
}

export default EnableDisableButton;
