import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { Container } from '../types';
import { Typography } from '@trustsecurenow/components-library';


const DashboardCardContainer = styled(Container.Grid)`
  margin-bottom: 30px;
`;
const DashboardCardWrapper = styled(Card)`
  width: calc(100% - 30px);
  padding: 40px calc(var(--spacing) * 2) 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`;
const DashboardCardIcon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorGreyLight3);
`;

const DashboardCard = ({ icon, title, description, path, handleClick }) => {
  const history = useHistory();

  const defaultHandleClick = () => {
    path && history.push(path);
  };

  return (
    <DashboardCardContainer md={4} sm={6} xs={12}>
      <DashboardCardWrapper onClick={handleClick || defaultHandleClick}>
        <DashboardCardIcon>{icon}</DashboardCardIcon>

        <Typography variant = 'h2'>{title}</Typography>

        {description && (
          <Typography variant="body2" textAlign="center" maxWidth={240}>{description}</Typography>
        )}

      </DashboardCardWrapper>
    </DashboardCardContainer>
  );
};

DashboardCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  path: PropTypes.string,
  handleClick: PropTypes.func
};

DashboardCard.defaultProps = {
  description: undefined,
  path: undefined,
  handleClick: undefined
};

export default DashboardCard;
