// @flow
import React from 'react';
import { Container } from 'components';
import UploadFile from '../UploadFile';
import ContainerStepContent from './ContainerStepContent';
import PropTypes from 'prop-types';

const NewsletterUploadStep = ({ stepNumber, dispatch, record, createMode }) => {
  return (
    <ContainerStepContent
      headerTitle={`${stepNumber}. Upload document`}
      onSubmit={dispatch.onNext}
      onBack={dispatch.onBack}
      disabled={!record.file}
      submitLabel={createMode ? 'Next' : 'Update'}
    >
      <Container.Grid item p={2} xs={12} sm={12} md={12} lg={6} xl={6}>
        <UploadFile
          title="Upload a file"
          fileType=".pdf"
          fileInfo={record.file}
          dispatch={dispatch.onFileUpload}
          type="file"
        />
      </Container.Grid>
    </ContainerStepContent>  
  );
};

NewsletterUploadStep.propTypes = {
  stepNumber: PropTypes.number,
  dispatch: PropTypes.func,
  record: PropTypes.object,
  createMode: PropTypes.bool
};

export default NewsletterUploadStep;