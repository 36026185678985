// @flow
import React, { useState } from 'react';
import { PagePushNotifications, Container } from 'components';
import { InfoButtons, ActionButtons } from './shared';
import { ContainerTitle } from './ComponentTypes';
import { Typography } from '@trustsecurenow/components-library';
import SendingTimesAccordion from 'components/pages/pushNotifications/SendingTimesAccordion';

const PartnerPushNotifications = () => {
const [isLoadingSendingTimes, setIsLoadingSendingTimes] = useState(true);
const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);

  return (
    <>
      <Container.Paper mt={2} mb={2}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle noMargin>
              <Typography variant='subtitle2'>
                Notifications
              </Typography>
              <InfoButtons name="automated_reminders_and_reports" />
            </ContainerTitle>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      <SendingTimesAccordion isOnPartnerProfile={true} onFinishLoading={() => setIsLoadingSendingTimes(false)} isLoading={isLoadingNotifications}/>
      {/* <Container.Paper> */}
      <PagePushNotifications clientId={null} trackGA onFinishLoading={() => setIsLoadingNotifications(false)} isLoading={isLoadingSendingTimes}/>
      {/* Action buttons */}
      <ActionButtons />
    </>
  );
};

export default PartnerPushNotifications;