import React from 'react';
import { Modal3, Container } from 'components';
import { useLocation } from 'hooks';
import { Button, AddIcon } from '@trustsecurenow/components-library';

const PartnerTaxExemptToolbar = ({ modalInfo, setModalInfo, setClose }) => {
  const { app } = useLocation();

  const { open, isNew, data, method } = modalInfo;

  const setOpen = val => {
    setModalInfo(prev => ({
      ...prev,
      open: val
    }));
  };

  return (
    <Container.Grid justifyContent={'flex-end'}>
      <Button onClick={() => setOpen(true)} startIcon={<AddIcon />} sx={{ width: 186 }}>
        Add certificate
      </Button>
      {open && (
        <Modal3
          open={open}
          setOpen={setClose}
          app={app}
          tab={'cellTax'}
          createNew={isNew}
          maxWidth={'sm'}
          data={{
            ...data,
            method
          }}
        />
      )}
    </Container.Grid>
  );
};

export default PartnerTaxExemptToolbar;
