// @flow
import React, { useState, useEffect } from 'react';
import { Button } from '@trustsecurenow/components-library';
import styled from 'styled-components';
import { Layout as AppLayout, Footer, LoadingStyled } from 'components';
import { Box } from '@material-ui/core';
import type { ComponentType } from 'react';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { Video as TrainingVideo } from '../dashboard/microTraining/components';
import cognito from '../../helpers/apis/cognito';

const Layout: ComponentType<*> = styled(AppLayout)`
  margin: 0 auto;
`;

const P: ComponentType<*> = styled.p`
  font-size: 18px;
  text-align: center;
`;

const Video = styled.div`
  ${props => props.theme.breakpoints.down('sm')} {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
`;

const MicroTrainingPublicView = ({ location }) => {
  const partnerLogosBaseURL = process.env.PARTNER_LOGOS_BASE_CF_URL;
  const logoPath = new URLSearchParams(location.search).get('logo_path');
  const [videoNumber, setVideoNumber] = useState(null);
  const [isValidParams, setValidParams] = useState(false);
  const loginRedirect = () => {
    window.location.href = `/#/login?microtraining_id=${new URLSearchParams(location.search).get('microtraining_id')}`;
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const microtraining_id = params.get('microtraining_id');
    const vidNum = params.get('video_number');
    const channel = params.get('channel');

    try {
      if (microtraining_id !== btoa(atob(microtraining_id))) {
        setValidParams(false);
        enqueueAlertSnackbar('Invalid Microtraining Id', { props: { severity: 'error' } });
        return;
      }
      setValidParams(true);
      sessionStorage.setItem('microtraining_id', microtraining_id);
    } catch {
      enqueueAlertSnackbar('Invalid Microtraining Id', { props: { severity: 'error' } });
      setValidParams(false);
    }

    cognito
      .authorize()
      .then(response => {
        sessionStorage.setItem('microtraining_id', microtraining_id);
        sessionStorage.setItem('microtraining_channel', channel || '');
        window.location.href = '/#/myDashboard/microTrainings';
      })
      .catch(err => {
        console.log('error=', err);
        setVideoNumber(vidNum);
      });

    return () => {
      setVideoNumber(null);
    };
  }, []);

  if (!videoNumber) return <LoadingStyled />;
  return (
    <Layout sidebar={null} background="paper" centered footer={<Footer />}>
      {logoPath && logoPath !== '' && (
        <div style={{ marginBottom: '20px' }}>
          {' '}
          <img
            style={{ maxWidth: '200px', maxHeight: '200px' }}
            alt=""
            src={`${partnerLogosBaseURL}${logoPath}`}
          />{' '}
        </div>
      )}

      <Video>
        <TrainingVideo src={`https://player.vimeo.com/video/${videoNumber}`} />
      </Video>

      <Box mt={3} mb={3}>
        {isValidParams ? <Button onClick={loginRedirect}>Sign in</Button> : null}
      </Box>
      {!isValidParams ? (
        <P>You have provided invalid MicroTraining ID</P>
      ) : (
        <P>To complete a quiz based on this training please login to the portal</P>
      )}
    </Layout>
  );
};

export default MicroTrainingPublicView;
