import React from 'react';
import { CustomAlert, LazyIcon } from 'components';
import { ArrowLeftIcon, Button } from '@trustsecurenow/components-library';
import { useMaskPartner } from '../../apps/admin/hooks';
import { useLocation } from 'hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const AlertContainer = styled.div`
  padding: 16px;
`;


const MaskModeAlert = () => {
  const { returnToAdmin } = useMaskPartner();
  const { tab } = useLocation();
  const history = useHistory();

  const handleClickToAdmin = () => {
    if (tab !== 'welcomeMessage') {
      returnToAdmin();
    } else {
      history.push('/admin/dashboard/manage-partners');
    }
  };

  return (
    <AlertContainer>
      <CustomAlert
        type="info"
        variant="outlined"
        action={
          <Button variant="outlined" color="info" startIcon={<ArrowLeftIcon />} onClick={handleClickToAdmin}>
            Return to admin
          </Button>
        }
        icon={
          <LazyIcon component="DarkWebMan" size={1.5} color="colorSystemInfo" />
        }
      >
        Mask mode enabled
      </CustomAlert>
    </AlertContainer>
  );
};

export default MaskModeAlert;
