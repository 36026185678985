import React, { useEffect, useState } from 'react';
import { Container, LoadingStyled, TextField } from 'components';
import cognito from 'helpers/apis/cognito';
import { Autocomplete } from '@material-ui/lab';
import { encodeId } from 'helpers';
import { Button, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { usePreassessmentContext } from '../../contexts';
import { PreassessmentBlueText, PreassessmentInfoContainer, PreassessmentInfoInput } from '../../style';

const PreassessmentInfo = () => {
  const {
    gotoQuizPage,
    dispatch,
    disabled,
    userState,
    user: {
      email
      // job_title, job_title_id, first_name, last_name
    },
    client_id
  } = usePreassessmentContext();
  const [loading, setLoading] = useState(true);
  const [jobRoleData, setJobRoleData] = useState(null);
  const [record, setRecord] = useState(null);
  const [error, setError] = useState({});

  const getJobRoleData = async () => {
    try {
      setLoading(true);
      const searchQuery = `?client_id=${client_id}&email=${encodeId(email)}`;
      const { data } = await cognito.getExternalUserData(searchQuery);

      const pickList = data.picklist.map(({ id, title }) => {
        return {
          value: id,
          label: title
        };
      });

      setJobRoleData(pickList);
      setRecord(data.user);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!record?.first_name || !record?.last_name || !record?.job_title_id) {
      dispatch.setDisabled(true);
      return;
    }
    [
      ['job_title_id', record?.job_title_id],
      ['first_name', record?.first_name],
      ['last_name', record?.last_name]
    ].forEach((v, i, arr) => {
      const [key, value] = v;
      if (value.toString().trim() === '' || !value) {
        setError(prev => {
          return { ...prev, [key]: true };
        });
      } else {
        setError(prev => {
          return { ...prev, [key]: false };
        });
      }
    });
    if ([record?.job_title_id, record?.first_name, record?.last_name].some(v => v.toString().trim() === '' || !v)) {
      dispatch.setDisabled(true);
      return;
    }
    dispatch.setDisabled(false);
  }, [record?.first_name, record?.last_name, record?.job_title_id]);

  useEffect(() => {
    getJobRoleData();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;

    setRecord(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const onChangeJobRole = (e, newValue) => {
    setRecord(prev => ({
      ...prev,
      ['job_title_id']: newValue?.value
    }));
  };

  const handleSubmit = async () => {
    if (disabled) return;

    const user = Object.fromEntries(Object.entries(record).map(([key, value]) => [key, value?.toString().trim()]));
    // console.log('updateExternalUser(user)', user)
    await cognito
      .updateExternalUser(user)
      .then(res => {
        if (res.status === 200) gotoQuizPage();
      })
      .catch(err => {
        if (err.response?.data?.code === 400) {
          enqueueAlertSnackbar('You have already taken this assessment', { props: { severity: 'error' } });
        }
        console.log('err', err);
        console.log('err.response', err.response);
      });
  };

  if (loading) return <LoadingStyled />;

  return (
    <>
      <PreassessmentInfoContainer>
        <PreassessmentBlueText>Enter your name and job title</PreassessmentBlueText>
        <PreassessmentInfoContainer>
          <Container.Grid sm={6}>
            <PreassessmentInfoInput
              value={record?.first_name}
              name="first_name"
              label="First name"
              onChange={handleChange}
              fullWidth
              error={error?.first_name}
              helperText={error?.first_name && `First Name is required field`}
            />
          </Container.Grid>

          <Container.Grid sm={6}>
            <PreassessmentInfoInput
              mr={0}
              value={record?.last_name}
              name="last_name"
              label="Last name"
              onChange={handleChange}
              fullWidth
              error={error?.last_name}
              helperText={error?.last_name && `Last Name is required field`}
            />
          </Container.Grid>

          <Container.Grid sm={12}>
            <Autocomplete
              fullWidth
              id="job_title_id"
              name="job_title_id"
              getOptionLabel={option => option.label}
              value={(jobRoleData || []).find(({ value }) => value === record?.job_title_id)}
              options={jobRoleData || []}
              onChange={onChangeJobRole}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Job Role"
                  error={error?.job_title_id}
                  helperText={error?.job_title_id && `Job title is required field`}
                />
              )}
            />
          </Container.Grid>

          <Container.Grid mt={3} lg={12} sm={12} justifyContent={'flex-end'}>
            <Button disabled={disabled} onClick={handleSubmit} color="success">
              {userState === 'start' ? 'Begin Assessment' : 'Continue Assessment'}
            </Button>
          </Container.Grid>
        </PreassessmentInfoContainer>
      </PreassessmentInfoContainer>
    </>
  );
};

export default PreassessmentInfo;
