// @flow

import { type ComponentType } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Paper,
  Tab,
  TableHead,
  TableRow,
  TableCell,
  ExpansionPanel,
  ExpansionPanelSummary,
} from '@material-ui/core';

export const TableHeadPartner: ComponentType<*> = styled(TableHead)`
  border: 1px solid var(--borderDefault);

  th {
    color: var(--colorDefault) !important;
    font-weight: var(--fontWeightMedium);
    border-bottom: initial;
  }
`;

export const TableRowPartner: ComponentType<*> = styled(TableRow)`
  &:hover {
    background-color: var(--backgroundDefault);
    td {
      color: var(--colorDefault);
    }
  }
`;

export const TableCellPartner: ComponentType<*> = styled(TableCell)`
  && {
    color: var(--colorDefault);
    padding: var(--spacing) calc(var(--spacing) * 1);
    border-bottom: 1px solid var(--borderDefault);
    font-size: calc(var(--fontSize) / 1);
    &:first-child {
      font-weight: var(--fontWeightBold);
    }
  }
`;

export const Title: ComponentType<*> = styled(Typography)`
  margin: 20px 0;
`;

export const ContainerMx: ComponentType<*> = styled(Paper)`
  padding: ${({ padding }) => (padding === false ? 0 : 12)}px;
`;

export const TabButton: ComponentType<*> = styled(Tab)`
  text-transform: none;
`;

export const AccordionSummary: ComponentType<*> = styled(ExpansionPanelSummary)`
  && {
    border-radius: var(--borderRadius);
  }
  > div:first-child {
    margin: 0 0;
    color: var(--colorDefault);
  }
  .Selected {
    color: blue;
  }
`;
// TODO: set width 100% on AccordionSummary, then align on right
// TODO: when selected change the color of AccordionSummary to blue (text and svg);
export const Accordion: ComponentType<*> = styled(ExpansionPanel)`
  margin: 6.77px 0;
  && {
    &:before {
      background: none;
    }
  }
`;

export const AccordionSummaryHeader: ComponentType<*> = styled(ExpansionPanelSummary)`
  && {
    border-radius: var(--borderRadius);
    justify-items: center;
  }
  > div:first-child {
    margin: 0 0;
    color: blue;
    display: flex;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : 'var(--borderSize) solid var(--borderDefault)')};
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 'calc(var(--spacing) * 3)')};
  padding: ${({ padding }) => padding || '20px'};
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: var(--borderSize) solid var(--borderDefault);
  padding: 20px;
  width: 100%;
`;
