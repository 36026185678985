import apiClient from '../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_2;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const microTrainingApi = {};

microTrainingApi.getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

/**
 * submits a micro-training, using the new api version
 * @param {object} params
 * @param {string} params.app  current working app
 * @param {object} params.data  of request body
 * @param {string} params.data.id  of the micro training
 * @param {string} params.data.channel  of the micro training
 * @param {Array} params.data.questions  of the micro training
 * @param {object} params.cancelToken  for axios request cancellation
 */
microTrainingApi.submit = ({ app, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}v2/${app}/microTrainingsQuiz`,
    headers: microTrainingApi.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

export default microTrainingApi;
