// @flow
import React from 'react';
import { Box } from '@material-ui/core';
import { FilePreview } from '../common';
import Parser from 'html-react-parser';
import { Typography } from '@trustsecurenow/components-library';

const FeedNewsletter = ({ newsletter }) => {
  const { name, topic_text, quick_tips, attachment_url } = newsletter;

  return (
    <>
      <Typography variant='h3'>{name}</Typography>
      {topic_text && <Typography variant='body2' mb={2.5}>{Parser(topic_text)}</Typography>}
      {quick_tips && (
        <>
          <Typography variant='h3'>Quick Tips</Typography>
          <Typography variant='body2' mb={2.5}>{Parser(quick_tips)}</Typography>
        </>
      )}
      <Box mb={1.5}>
        <Typography variant="button">Attachment List (1)</Typography>
      </Box>
      <FilePreview url={attachment_url} filename={decodeURIComponent(attachment_url.match(/[^\/]*$/))} />
    </>
  );
};

export default FeedNewsletter;
