import styled, { css } from 'styled-components';
import { Container } from 'components';
import MuiPhoneNumber from 'material-ui-phone-number';

export const PreassessmentPhoneContainer = styled(Container.Grid)``;

export const PreassessmentPhoneNumberInput = styled(MuiPhoneNumber)`
  && {
    max-width: 400px;
    margin: 0 calc(var(--spacing) * 2) 0 0 !important;

    ${props => props.theme.breakpoints.down('xs')} {
      margin: 0 0 calc(var(--spacing) * 2) 0 !important;
      max-width: 100%;
    }
  }
`;

export const PreassessmentPhoneNumberErrorMessage = styled(Container.Grid)`
  && {
    margin: 0;

    p {
      margin: 8px 0 0 0;
      color: var(--colorSystemDanger);
    }
  }
`;

export const ErrorMessage = styled.p`
  margin: var(--spacing) 0 0 0;
  font-size: 14px;
  color: var(--colorSystemDanger);
`;

export const PreassessmentLeftBox = styled(Container.Grid)`
  && {
    margin: 0 calc(var(--spacing) * 2) 0 0 !important;

    ${props => props.theme.breakpoints.down('xs')} {
      margin: 0 0 calc(var(--spacing) * 2) 0 !important;
    }
  }
`;
