import React, { useEffect, useMemo, useState } from 'react';
import { VerificationCodeInput, Container } from 'components';
import { ErrorMessage } from 'apps/preassessment/style';
import { usePreassessmentContext } from 'apps/preassessment/contexts';
import cognito from 'helpers/apis/cognito';
import { getTimeFromSeconds, setStorage } from 'helpers';
import { getAnalyzedUserState } from 'components/QuizViewer';
import { Button, Link } from '@trustsecurenow/components-library';

const PreassessmentVerificationCode = ({
  loading,
  verificationDate,
  resendCallBack,
  setVerifyView,
  setVerificationType,
  verificationType
}) => {
  const {
    type,
    client_id,
    quiz_id,
    gotoQuizPage,
    dispatch,
    user: { email, first_name, last_name, job_title_id }
  } = usePreassessmentContext();

  const [second, setSecond] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const remainingTimeSeconds = useMemo(() => {
    const currentDate = new Date();
    const date = new Date(verificationDate);
    const remainingTime = new Date(date.getTime() - currentDate.getTime());
    return 60 * remainingTime.getMinutes() + remainingTime.getSeconds();
  }, [verificationDate]);

  useEffect(() => {
    setSecond(remainingTimeSeconds);
  }, [verificationDate]);

  useEffect(() => {
    let interval = setInterval(() => {
      setSecond(prevState => (prevState >= 1 ? prevState - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [verificationDate]);

  const handleSubmit = async () => {
    try {
      dispatch.setDisabled(true);
      const loginData = await cognito.preassessmentLogin({
        email,
        client_id,
        password: verificationCode
      });
      const quiz_token = loginData.data.access_token;
      dispatch.onChange(quiz_token, 'quiz_token');
      sessionStorage.setItem('quizToken', quiz_token);

      const userState = await getAnalyzedUserState({ quiz_token, quiz_id });
      dispatch.setUserState(userState.type === 'resume' ? 'continue' : 'start');

      // user completed assessment? go to quiz page and display the final message
      if (userState.type === 'done') gotoQuizPage();

      dispatch.setDisabled(false);
      dispatch.changeStep(3);
    } catch (e) {
      dispatch.setDisabled(false);
      setErrorMessage(e.response.data.message);
      setVerificationCode('');
    }
  };

  const handleResendWithOtherMethod = () => {
    setVerificationCode(''); 

    if (verificationType === 'email') {
      setVerificationType('sms');
      setVerifyView('verifyPhoneNumber');
    } else {
      resendCallBack('email');
    }
    setErrorMessage('');
  };

  const verificationTypeText = verificationType === 'email' ? 'text message' : 'email';

  const resendCodeTimer = () => {
    const { sec, minute } = getTimeFromSeconds(second);
    const correctSecond = sec < 10 ? '0' + sec : sec;

    return `${minute}:${correctSecond}`;
  };

  return (
    <>
      <Container.Grid alignItems={'center'}>
        <Container.Grid sm={9}>
          <VerificationCodeInput
            error={errorMessage}
            verificationCode={verificationCode}
            onChange={value => {
              setErrorMessage('');
              setVerificationCode(value);
            }}
            digitsCount={6}
            placeholder={'*'}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container.Grid>
        <Container.Grid sm={3}>
          {errorMessage ? null : (
            <Button color="success" fullWidth onClick={handleSubmit} disabled={verificationCode.length !== 6}>
              Next
            </Button>
          )}
        </Container.Grid>
      </Container.Grid>
      <Container.Grid mt={2} alignItems={'center'}>
        <Link
          component="button"
          onClick={() => resendCallBack(verificationType)}
          disabled={second !== 0}
          sx={{ mr: 2.5 }}
          underline="none"
          variant="body2"
        >
          Resend code {second <= 0 ? null : `- ${resendCodeTimer()}`}
        </Link>
        <Link
          component="button"
          disabled={loading}
          variant="link1"
          onClick={handleResendWithOtherMethod}
          sx={{ ml: 4, mr: 2.5 }}
        >
          Didn’t receive a code? Try {verificationTypeText} instead
        </Link>
      </Container.Grid>
    </>
  );
};

export default PreassessmentVerificationCode;
