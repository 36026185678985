// @flow
import React, { useState } from 'react';
import { Box } from '@trustsecurenow/components-library';
import { Layout as AppLayout, Footer } from 'components';
import { isValidEmail } from 'helpers/utils';
import cognito from '../../helpers/apis/cognito';
import ForgetPasswordForm from './ForgetPasswordForm';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const UserForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (!email) return enqueueAlertSnackbar('Please enter an email', { props: { severity: 'error' } });
    if (!isValidEmail(email)) return enqueueAlertSnackbar('Email address is invalid', { props: { severity: 'error' } });

    const encodedEmail = encodeURIComponent(email);
    setLoading(true);
    cognito
      .sendForgotPasswordEmail(email)
      .then(response => {
        window.location.href = `/#login?email=${encodedEmail}`;
        enqueueAlertSnackbar(response.data.description);
        setLoading(false)
      })
      .catch(err => {
        enqueueAlertSnackbar(err.response?.data?.description ?? 'Check your connection and try again', {
          props: { severity: 'error' }
        });
        setLoading(false)
      })
    return null;
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  return (
    <AppLayout appBar={null} sidebar={null} background="paper" centered footer={<Footer />}>
      <Box maxWidth={600} margin="auto" p={2}>
        <ForgetPasswordForm email={email} onChangeEmail={handleChangeEmail} onSubmit={handleSubmit} loading={loading} />
      </Box>
    </AppLayout>
  );
};

export default UserForgotPassword;

