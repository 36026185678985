import React from 'react';
import { PARTNER_SETUP_BASE_PATH } from './constants';
// Old Page before TC 2.0
// import { default as PartnerTrainigConfiguration } from 'apps/shared/trainingConfiguration/Dashboard';
import PartnerCustomProfile from './PartnerCustomProfile';
import PartnerNotifConfiguration from './PartnerNotifConfiguration';
import PartnerWelcomeMessage from './PartnerWelcomeMessage';
import PartnerTMSConfiguration from './PartnerTMSConfiguration';
import PartnerPushNotifications from './PartnerPushNotifications';
import PartnerTaxExempt from './PartnerTaxExempt';
import PartnerIntegrations from './PartnerIntegrations';
import PartnerTrainingConfiguration from './PartnerTrainingConfiguration';


export const PAGES = {
  configure: {
    label: 'Setup Guide',
    path: PARTNER_SETUP_BASE_PATH
  },
  branding: {
    label: 'Branding',
    path: `${PARTNER_SETUP_BASE_PATH}/branding`,
    component: <PartnerCustomProfile />
  },
  partner_notifications: {
    label: 'Partner Notifications',
    path: `${PARTNER_SETUP_BASE_PATH}/partner_notifications`,
    component: <PartnerNotifConfiguration />
  },
  default_welcome_message: {
    label: 'Default Welcome Message',
    path: `${PARTNER_SETUP_BASE_PATH}/default_welcome_message`,
    component: <PartnerWelcomeMessage />
  },
  ticket_management_system: {
    label: 'Ticket Management System',
    path: `${PARTNER_SETUP_BASE_PATH}/ticket_management_system`,
    component: <PartnerTMSConfiguration />
  },
  automated_reminders_and_reports: {
    label: 'Automated Reminders & Reports',
    path: `${PARTNER_SETUP_BASE_PATH}/automated_reminders_and_reports`,
    component: <PartnerPushNotifications />
  },
  tax_exemption: {
    label: 'Tax Exemption',
    path: `${PARTNER_SETUP_BASE_PATH}/tax_exemption`,
    component: <PartnerTaxExempt />
  },
  integrations: {
    label: 'Integrations',
    path: `${PARTNER_SETUP_BASE_PATH}/integrations`,
    component: <PartnerIntegrations isCofigureTab />
  },
  training_configuration: {
    label: 'Training Configuration',
    path: `${PARTNER_SETUP_BASE_PATH}/training_configuration`,
    component: <PartnerTrainingConfiguration />
    // Old Page before TC 2.0
    // component: <PartnerTrainigConfiguration isCofigureTab />
  }
};
