import { Box } from '@material-ui/core';
import {
  Button,
  DoubtIcon,
  Link,
  MailSentIcon,
  SaveIcon,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import { RHFCheckbox as Checkbox, Container, CustomTooltip, RHFTextField as TextField } from 'components';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { redirectValidURL } from '../../../../helpers';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { CONTENT_ADMIN_LANDING_PAGES, CONTENT_ADMIN_ROOT_PATH, CONTENT_ADMIN_SENDING_PROFILES } from '../../constants';
import { useContentAdminFormContext } from '../../context';
import {
  ContentAdminFormButtonsContainer,
  ContentAdminFormLayoutFooterContainer,
  ContentAdminFormLayoutFooterContainerWithCard
} from '../../style';

const ContentAdminFormLayoutFooter = () => {
  const { record, page, method, dispatch } = useContentAdminFormContext();
  const [disabled, setDisabled] = useState(false);

  const isLandingPage = page === CONTENT_ADMIN_LANDING_PAGES;

  const Layout = isLandingPage ? ContentAdminFormLayoutFooterContainerWithCard : ContentAdminFormLayoutFooterContainer;

  const history = useHistory();

  const handleBack = useCallback(() => {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}/${page}`);
  }, [page]);

  const handleCheck = e => {
    const { name, checked } = e.target;
    dispatch.onChange(name, checked);
  };

  const handleSendTestEmail = useCallback(async () => {
    setDisabled(true);
    try {
      const response = await contentAdmin.sendTestEmail();
      enqueueAlertSnackbar(response.data.description);
    } catch (e) {
      enqueueAlertSnackbar(e?.response?.data?.description || 'Something went wrong', {
        props: { severity: 'error' }
      });
    } finally {
      setDisabled(false);
    }
  }, []);

  const _renderSubmitButton = useMemo(
    () => (
      <Button
        type="submit"
        disabled={Boolean(record.disabled)}
        startIcon={<SaveIcon />}
        color="success"
        sx={{ width: 160, whiteSpace: 'nowrap' }}
      >
        Save Template
      </Button>
    ),
    [record.disabled, method]
  );

  const _renderCancelButton = useMemo(
    () => (
      <Link sx={{ mr: 2.5 }} underline="hover" component="button" onClick={handleBack}>
        Cancel
      </Link>
    ),
    [handleBack]
  );

  const _renderSendingProfilesButton = useMemo(
    () =>
      page === CONTENT_ADMIN_SENDING_PROFILES && (
        <Button
          variant="outlined"
          sx={{ minWidth: 190 }}
          onClick={handleSendTestEmail}
          disabled={disabled}
          startIcon={<MailSentIcon />}
        >
          Send Test Email
        </Button>
      ),
    [page, disabled]
  );

  const _renderCaptureSubmittedDataSwitch = useMemo(
    () =>
      isLandingPage && (
        <Container.Grid container spacing={2} alignItems="flex-start">
          <Container.Grid item xs={6}>
            <TextField
              name="redirect_uri"
              label="Redirect URL"
              fullWidth
              required
              rules={{
                validate: value => (!redirectValidURL(value) ? 'Please enter a valid URL address' : null)
              }}
            />
          </Container.Grid>

          <Container.Grid item>
            <Box display="flex" alignItems="center" py={1}>
              <Checkbox label="Capture Submitted Data" name={'capture_submitted_data'} />
              <span style={{ cursor: 'pointer' }}>
                <CustomTooltip
                  light
                  title="Enabling capture submitted data, will track any users that enter information in the pre-landing page"
                  placement="top"
                >
                  <DoubtIcon  sx={{fontSize: 20, verticalAlign: 'middle'}} />
                </CustomTooltip>
              </span>
            </Box>
          </Container.Grid>
        </Container.Grid>
      ),
    [isLandingPage]
  );

  return (
    <Layout>
      {_renderSendingProfilesButton}
      {_renderCaptureSubmittedDataSwitch}

      <ContentAdminFormButtonsContainer justifyContent={'flex-end'}>
        {_renderCancelButton}
        {_renderSubmitButton}
      </ContentAdminFormButtonsContainer>
    </Layout>
  );
};

export default ContentAdminFormLayoutFooter;
