// @flow
import React from 'react';
import { Box, makeStyles, Dialog } from '@material-ui/core';
import { SyncAlt as SyncAltIcon } from '@material-ui/icons';
import { Button, Link, Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '30px 20px',
    textAlign: 'center',
    '& h3': {
      color: '#0069FF'
    }
  },
  deleteIcon: {
    backgroundColor: 'rgba(25, 148, 230, 0.2)',
    color: '#0069FF',
    borderRadius: '50%',
    padding: '15px',
    fontSize: '40px'
  }
}));

const OverridePinnedPostModal = ({ open, close, onConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth>
      <div className={classes.content}>
        <Box pb={2.5}>
          <SyncAltIcon className={classes.deleteIcon} />
        </Box>
        <Typography variant="h2" color='info.main' gutterBottom>
          Override?
        </Typography>
        <Box pb={2.5} pt={1.5}>
          <Typography maxWidth={350} variant="subtitle1" component="p" gutterBottom>
            A post is currently pinned, are you sure you want to override the current pinned post?
          </Typography>
        </Box>
        <Box pb={2.5}>
          <Button color="info" onClick={onConfirm}>
            Yes, I want to override
          </Button>
        </Box>
        <Box>
          <Link
            component="button"
            onClick={e => {
              e.preventDefault();
              close();
            }}
          >
            Cancel
          </Link>
        </Box>
      </div>
    </Dialog>
  );
};

export default OverridePinnedPostModal;
