import { Box } from '@material-ui/core'
import { Tooltip } from '@trustsecurenow/components-library'
import StatusButton from 'apps/shared/darkWebMonitoring/StatusButton'
import { useDirectorySync } from 'apps/shared/directorySync/DirectorySyncContext'
import EnableDisableWelcomeMessageDialog from 'apps/shared/users/modals/EnableDisableWelcomeMessageDialog'
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction'
import React from 'react'
import { useDispatch } from 'react-redux';

const WelcomeMessageStatusButton = ({
  isWelcomeMsgDialogOpened,
  setIsWelcomeMsgDialogOpened,
  tooltipPlacement = 'right'
}) => {
  const { state, dispatch } = useDirectorySync();
  const dispatchRx = useDispatch();

  const handleOpenWelcomeMsgDialog = () => setIsWelcomeMsgDialogOpened(true);
  const handleCloseWelcomeMsgDialog = () => setIsWelcomeMsgDialogOpened(false);

  const handleSendWelcomeMsgToggler = () => {
    dispatch.onSwitch('automatedWelcome', !state.automatedWelcome);
    dispatchRx(resetCreateUserMatchingEmailError());
    handleCloseWelcomeMsgDialog();
  };

    return (
        <>
            <Box>
              <Tooltip
                arrow
                title={
                  state.automatedWelcome
                  ? "Welcome Message enabled. New Users will receive welcome messages by default. Click here to disable welcome messages."
                  : "Click here to enable welcome messages."
                }
                placement={tooltipPlacement}
              >
                <span>
                  <StatusButton
                      style={{ marginRight: 5 }}
                      status={state.automatedWelcome ? 'active' : 'inactive'}
                      label="Welcome Message"
                      onClick={handleOpenWelcomeMsgDialog}
                      inactiveOnClick={handleOpenWelcomeMsgDialog}
                  />
                </span>
              </Tooltip>
            </Box>

            <EnableDisableWelcomeMessageDialog
                isWelcomeMsgEnabled={state.automatedWelcome}
                openDialog={isWelcomeMsgDialogOpened}
                enableDialogIsCustomWelcomeMsg={state?.customWelcome}
                enableDialogWelcomeMsgFrequency={state?.welMessFrequency}
                enableDialogWelcomeMsgHowMany={state?.welMessHowMany}
                enableDialogWelcomeMsgDeferred={state?.deferred_sending}
                onCloseDialog={handleCloseWelcomeMsgDialog}
                onSubmitEnable={handleSendWelcomeMsgToggler}
                onSubmitDisable={handleSendWelcomeMsgToggler}
            />
      </>
    )
}

export default WelcomeMessageStatusButton;
