import React, { cloneElement, createElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton, MenuItem, MenuList, Popover, Grid, Box, TextField } from '@material-ui/core';
import { Filter } from '../icons';
import Button from './Button';
import { Autocomplete } from '@material-ui/lab';

const AddFilterButton = styled(Button)``;

const AddFilter = ({ buttonFullWidth, onChange, values, config }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterChoices, setFilterChoices] = useState([]);
  const [fieldsArr, setFieldsArr] = useState([]);

  useEffect(() => {
    const filterChoicesData = config.map(({ props }) => props.label);
    setFilterChoices(filterChoicesData);
  }, []);

  const handleOpenPopover = e => {
    e.stopPropagation();
    const { target } = e;
    setAnchorEl(target);
  };

  const handleDeleteField = (title, name, deleteItemIndex) => {
    setFilterChoices(prevState => [...prevState, title]);
    setFieldsArr(prevState => prevState.filter((_, index) => index !== deleteItemIndex));
    onChange(name, null);
  };

  const filtersChoices = filterChoices.map((title, index) => {
    const handleSelect = () => {
      const field = config.find(({ props: { label } }) => label === title);

      const newField = createElement(
        field.component,
        {
          id: `add-filter-${title}`,
          onChange: (e, value) => {
            if (value !== undefined) {
              onChange(field.props.name, +value);
            } else {
              onChange(field.props.name, e.target.value);
            }
          },
          allowEmpty: false,
          fullWidth: true,
          style: { maxWidth: '90%' },
          ...field.props
        },
        null
      );

      setFieldsArr(prevState => [...prevState, newField]);

      setFilterChoices(prevState => prevState.filter(choice => choice !== title));
      setAnchorEl(null);
    };
    return (
      <MenuItem key={title} onClick={handleSelect}>
        {title}
      </MenuItem>
    );
  });

  return (
    <>
      {fieldsArr.map((Component, index) => {
        const { getValue, choices, options, label, name } = Component.props;
        return (
          <Grid item component={Box} maxWidth={216} display="flex" alignItems="center" key={name}>
            <IconButton size="small" onClick={() => handleDeleteField(label, name, index)} mr={2}>
              <CloseIcon />
            </IconButton>
            {cloneElement(Component, {
              checked: !!values?.[name] || false,
              value: getValue ? getValue(options || choices, values?.[name]) : values?.[name]
            })}
          </Grid>
        );
      })}
      {!!filtersChoices.length && (
        <Grid item>
          <AddFilterButton fullwidth={buttonFullWidth} onClick={handleOpenPopover}>
            <Filter mr={1} />
            Add Filter
          </AddFilterButton>
        </Grid>
      )}

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            minWidth: '135px'
          }
        }}
      >
        <MenuList>{filtersChoices}</MenuList>
      </Popover>
    </>
  );
};

export default AddFilter;
