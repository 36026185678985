import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { Container, TextField, Typography, SelectField, LoadingStyled, RadioField } from 'components';
import {
  ExpandMore as ExpandMoreIcon,
  PersonOutline as PersonOutlineIcon,
  MailOutline as MailOutlineIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Settings as SettingsIcon,
  Airplay as AirplayIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@material-ui/icons';
import { Button, CheckIcon, SaveIcon, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import * as sra from 'helpers/apis/services/sra';
import { isValidEmail, snakeToCamelTitle } from 'helpers';
import { OrganisationListItem, SystemAccordion, MuiPhoneNumberInput } from './style';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'var(--backgroundDefault) !important',
    boxShadow: 'none',
    color: 'var(--colorDarkGrey)'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const OrganizationProfile = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const clientId = props.id;
  const close = props.close;
  const hsn = props.hsn;
  const [expanded, setExpanded] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const handleChangePhones = (value, name) => {
    setState({ ...state, [name]: value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeEmrEhrState = event => {
    //if selected NO EMR/EHR then will reset addtional information about EMR/EHR
    setState({
      ...state,
      [event.target.name]: event.target.value,
      ...(event.target.value === 'No' ? {
        emr_ehr_vendor: '',
        emr_ehr_internal_name: '',
        emr_ehr_os: null,
        emr_ehr_os_other: '',
        emr_ehr_details: '',
        emr_ehr_location: null,
        emr_ehr_other_location: '',
        emr_ehr_description: '',
      } : {})
    });
  };
  const handleChangeEmailState = event => {
    //if selected NO email then will reset addtional information about email
    setState({
      ...state,
      [event.target.name]: event.target.value,
      ...(event.target.value === 'No' ? {
        [hsn ? 'phi_on_email' : 'pii_on_email']: null,
        email_vendor: '',
        email_vendor_other: '',
        email_vendor_details: '',
        email_server_location: null,
        email_details: '',
      } : {})
    });
  };
  const handleChangePortableMedia = event => {
    //if selected NO portable media then will reset addtional information about portable media
    setState({
      ...state,
      [event.target.name]: event.target.value,
      ...(event.target.value === 'No' ? {
        [hsn ? 'phi_on_portable_media' : 'pii_on_portable_media']: null,
        tablets: null,
        list_portable_devices: ''
      } : {})
    });
  };
  const handleChangeBackupMedia = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      ...(event.target.value === 'No' ? {
        backup_media_details: ''
      } : {})
    });
  };
  const handleChangeSmartphone = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      ...(event.target.value === 'No' ? {
        [hsn ? 'phi_on_smartphones' : 'pii_on_smartphones']: null,
        list_smartphone_vendors: ''
      } : {})
    });
  };
  const handleChangeState = event => {
    let newState = { ...state, [event.target.name]: event.target.value };

    if (event.target.name === 'administrator_email') {
      if (event.target.value && !isValidEmail(event.target.value)) setInvalidEmail(true);
      else setInvalidEmail(false);
    }
    if (event.target.name === 'emr_ehr_os' && event.target.value !== 'Other' && state.emr_ehr_os_other) {
      newState.emr_ehr_os_other = '';
    }
    if (event.target.name === 'industry' && event.target.value !== 'Other' && state.industry_other) {
      newState.industry_other = '';
    }
    if (
      event.target.name === 'email_vendor' &&
      event.target.value !== 'Other' &&
      state.email_vendor_other?.length !== 0
    ) {
      newState.email_vendor_other = '';
    }
    if (event.target.name === 'organization_type' && event.target.value !== 'Other' && state.organization_other_type) {
      newState.organization_other_type = '';
    }
    if (event.target.name === 'emr_ehr_location' && event.target.value !== 'Other' && state.emr_ehr_other_location) {
      newState.emr_ehr_other_location = '';
    }
    setState(newState);
  };
  const handleStateBooleanChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked ? 1 : 0 });
  };
  const handleStateYesNoState = event => {
    setState({ ...state, [event.target.name]: event.target.checked ? 'Yes' : 'No' });
  };
  const save = next => {
    if (invalidEmail) {
      enqueueAlertSnackbar('Please enter a valid email', { props: { severity: 'error' } });
      return;
    }
    setLoading(true);
    sra.setClientOrganizationProfile(clientId, state).then(res => {
      enqueueAlertSnackbar('Saved successfully.', { props: { severity: 'success' } });
      if (next === 'next') setValue(value + 1);
      if (next === 'close') close();
      setLoading(false);
    });
  };
  useEffect(() => {
    if (!state)
      sra
        .getClientOrganizationProfile(clientId)
        .then(res => {
          console.log(res);
          setState(res.data);
        })
        .catch(err => {
          console.clear();
          console.log(err);
        });
  }, [state]);

  if (!state || loading) return <LoadingStyled />;
  console.log(state);
  return (
    <>
      <AppBar position="static" className={classes.root}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Name and location" {...a11yProps(0)} />
          <Tab label="Network" {...a11yProps(1)} />
          {hsn && <Tab label="EMR/EHR" {...a11yProps(2)} />}
          <Tab label="Email" {...a11yProps(hsn ? 3 : 2)} />
          <Tab label="Media and Devices" {...a11yProps(hsn ? 4 : 3)} />
          <Tab label="System" {...a11yProps(hsn ? 5 : 4)} />
          <Tab label="Additional information" {...a11yProps(hsn ? 6 : 5)} />
        </Tabs>
      </AppBar>
      <TabPanel className={classes.root} value={value} index={0}>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container alignItems={'center'}>
                <Container.Grid item mb={3} bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        1.Organization info
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                {!hsn ? (
                  <>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <SelectField
                        fullWidth
                        // allowEmpty={false}
                        id="industry"
                        label="Provider"
                        choices={[
                          { label: 'Agriculture', value: 'Agriculture' },
                          { label: 'Apparel', value: 'Apparel' },
                          { label: 'Banking', value: 'Banking' },
                          { label: 'Biotechnology', value: 'Biotechnology' },
                          { label: 'Chemicals', value: 'Chemicals' },
                          { label: 'Communications', value: 'Communications' },
                          { label: 'Construction', value: 'Construction' },
                          { label: 'Consulting', value: 'Consulting' },
                          { label: 'Education', value: 'Education' },
                          { label: 'Electronics', value: 'Electronics' },
                          { label: 'Energy', value: 'Energy' },
                          { label: 'Engineering', value: 'Engineering' },
                          { label: 'Entertainment', value: 'Entertainment' },
                          { label: 'Environmental', value: 'Environmental' },
                          { label: 'Finance', value: 'Finance' },
                          { label: 'Food & Beverage', value: 'Food & Beverage' },
                          { label: 'Government', value: 'Government' },
                          { label: 'Healthcare', value: 'Healthcare' },
                          { label: 'Hospitality', value: 'Hospitality' },
                          { label: 'Insurance', value: 'Insurance' },
                          { label: 'Legal', value: 'Legal' },
                          { label: 'Machinery', value: 'Machinery' },
                          { label: 'Manufacturing', value: 'Manufacturing' },
                          { label: 'Media', value: 'Media' },
                          { label: 'Not For Profit', value: 'Not For Profit' },
                          { label: 'Other', value: 'Other' },
                          { label: 'Recreation', value: 'Recreation' },
                          { label: 'Retail', value: 'Retail' },
                          { label: 'Shipping', value: 'Shipping' },
                          { label: 'Technology', value: 'Technology' },
                          { label: 'Telecommunications', value: 'Telecommunications' },
                          { label: 'Transportation', value: 'Transportation' },
                          { label: 'Utilities', value: 'Utilities' }
                        ]}
                        name="industry"
                        value={state.industry}
                        onChange={handleChangeState}
                        // required
                      />
                    </Container.Grid>
                    {state.industry === 'Other' && (
                      <>
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                          <TextField
                            value={state.industry_other}
                            name="industry_other"
                            label="Other Industry"
                            fullWidth
                            onChange={handleChangeState}
                          />
                        </Container.Grid>
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <SelectField
                        fullWidth
                        allowEmpty={false}
                        id="organization_type"
                        label="Organization Type"
                        choices={[
                          {
                            label: 'Attorney/Legal supporting a Medical Organization',
                            value: 'Attorney/Legal supporting a Medical Organization'
                          },
                          { label: 'Billing, Claims or Coding Services', value: 'Billing, Claims or Coding Services' },
                          { label: 'Clearinghouse', value: 'Clearinghouse' },
                          {
                            label: 'CPA or Financial Entity supporting a Medical Organization',
                            value: 'CPA or Financial Entity supporting a Medical Organization'
                          },
                          { label: 'Consultant', value: 'Consultant' },
                          { label: 'Disposal or Shredding Services', value: 'Disposal or Shredding Services' },
                          {
                            label: 'EHR/EMR/Practice Management Application',
                            value: 'EHR/EMR/Practice Management Application'
                          },
                          { label: 'Health Information Exchange', value: 'Health Information Exchange' },
                          { label: 'Health Plan', value: 'Health Plan' },
                          { label: 'Healthcare Provider', value: 'Healthcare Provider' },
                          {
                            label: 'Information Technology (IT) Support',
                            value: 'Information Technology (IT) Support'
                          },
                          { label: 'Medical Equipment Vendor/Servicer', value: 'Medical Equipment Vendor/Servicer' },
                          { label: 'Medical Transcription', value: 'Medical Transcription' },
                          { label: 'Other Application that Stores ePHI', value: 'Other Application that Stores ePHI' },
                          { label: 'Other', value: 'Other' },
                          {
                            label: 'Transportation or Storage of Medical Information',
                            value: 'Transportation or Storage of Medical Information'
                          }
                        ]}
                        name="organization_type"
                        value={state.organization_type}
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    {state.organization_type === 'Other' && (
                      <>
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                          <TextField
                            value={state.organization_other_type}
                            name="organization_other_type"
                            label="Other Organization Type"
                            fullWidth
                            onChange={handleChangeState}
                          />
                        </Container.Grid>
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                      </>
                    )}
                  </>
                )}
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.number_employees}
                    name="number_employees"
                    label="How many employees (total)"
                    fullWidth
                    onChange={handleChangeState}
                    inputProps={{ min: 0, step: 1, maxLength: 7 }}
                    integerOnly
                  />
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item mb={3} bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        2.Administrator info
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.administrator_name}
                    name="administrator_name"
                    label="Organization’s administrator name"
                    fullWidth
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.administrator_email}
                    name="administrator_email"
                    label="Organization’s administrator email"
                    fullWidth
                    onChange={handleChangeState}
                    error={invalidEmail}
                    helperText={invalidEmail && 'Please enter a valid email address'}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.zip}
                    name="zip"
                    label="Zip code"
                    fullWidth
                    integerOnly
                    onChange={handleChangeState}
                    inputProps={{ maxLength: 8 }}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={8} lg={8} xl={8}></Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField value={state.city} name="city" label="City" fullWidth onChange={handleChangeState} />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField value={state.state} name="state" label="State" fullWidth onChange={handleChangeState} />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.address_1}
                    name="address_1"
                    label="Address 1"
                    fullWidth
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.address_2}
                    name="address_2"
                    label="Address 2"
                    fullWidth
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControlLabel
                    value={state.second_location === 'Yes'}
                    checked={state.second_location === 'Yes'}
                    control={<Checkbox color="primary" />}
                    name="second_location"
                    label="Do you have a second location?"
                    labelPlacement="end"
                    onChange={handleStateYesNoState}
                  />
                </Container.Grid>
                {state.second_location === 'Yes' && (
                  <>
                    <Container.Grid bt={1} pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.p>Second Location</Typography.p>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.second_zip}
                        name="second_zip"
                        label="Zip code"
                        fullWidth
                        integerOnly
                        onChange={handleChangeState}
                        inputProps={{ maxLength: 8 }}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={8} lg={8} xl={8}></Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.second_city}
                        name="second_city"
                        label="City"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.second_state}
                        name="second_state"
                        label="State"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.second_address_1}
                        name="second_address_1"
                        label="Address 1"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.second_address_2}
                        name="second_address_2"
                        label="Address 2"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        value={state.third_location === 'Yes'}
                        checked={state.third_location === 'Yes'}
                        control={<Checkbox color="primary" />}
                        name="third_location"
                        label="Do you have a third location?"
                        labelPlacement="end"
                        onChange={handleStateYesNoState}
                      />
                    </Container.Grid>
                  </>
                )}
                {state.second_location === 'Yes' && state.third_location === 'Yes' && (
                  <>
                    <Container.Grid bt={1} pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.p>Third Location</Typography.p>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.third_zip}
                        name="third_zip"
                        label="Zip code"
                        fullWidth
                        integerOnly
                        onChange={handleChangeState}
                        inputProps={{ maxLength: 8 }}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={8} lg={8} xl={8}></Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.third_city}
                        name="third_city"
                        label="City"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.third_state}
                        name="third_state"
                        label="State"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.third_address_1}
                        name="third_address_1"
                        label="Address 1"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.third_address_2}
                        name="third_address_2"
                        label="Address 2"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        value={state.fourth_location === 'Yes'}
                        checked={state.fourth_location === 'Yes'}
                        control={<Checkbox color="primary" />}
                        name="fourth_location"
                        label="Do you have a fourth location?"
                        labelPlacement="end"
                        onChange={handleStateYesNoState}
                      />
                    </Container.Grid>
                  </>
                )}
                {state.second_location === 'Yes' && state.third_location === 'Yes' && state.fourth_location === 'Yes' && (
                  <>
                    <Container.Grid bt={1} pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.p>Fourth Location</Typography.p>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.fourth_zip}
                        name="fourth_zip"
                        label="Zip code"
                        fullWidth
                        integerOnly
                        onChange={handleChangeState}
                        inputProps={{ maxLength: 8 }}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={8} lg={8} xl={8}></Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.fourth_city}
                        name="fourth_city"
                        label="City"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.fourth_state}
                        name="fourth_state"
                        label="State"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.fourth_address_1}
                        name="fourth_address_1"
                        label="Address 1"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.fourth_address_2}
                        name="fourth_address_2"
                        label="Address 2"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        value={state.fifth_location === 'Yes'}
                        checked={state.fifth_location === 'Yes'}
                        control={<Checkbox color="primary" />}
                        name="fifth_location"
                        label="Do you have a fifth location?"
                        labelPlacement="end"
                        onChange={handleStateYesNoState}
                      />
                    </Container.Grid>
                  </>
                )}
                {state.second_location === 'Yes' &&
                  state.third_location === 'Yes' &&
                  state.fourth_location === 'Yes' &&
                  state.fifth_location === 'Yes' && (
                    <>
                      <Container.Grid bt={1} pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography.p>Fifth Location</Typography.p>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.fifth_zip}
                          name="fifth_zip"
                          label="Zip code"
                          fullWidth
                          integerOnly
                          onChange={handleChangeState}
                          inputProps={{ maxLength: 8 }}
                        />
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={8} lg={8} xl={8}></Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.fifth_city}
                          name="fifth_city"
                          label="City"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.fifth_state}
                          name="fifth_state"
                          label="State"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}></Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.fifth_address_1}
                          name="fifth_address_1"
                          label="Address 1"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.fifth_address_2}
                          name="fifth_address_2"
                          label="Address 2"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                    </>
                  )}
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item mb={3} bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        3.Contact
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4} mt={1}>
                  <MuiPhoneNumberInput
                    variant="outlined"
                    value={
                      state.administrator_phone?.includes('+')
                        ? state.administrator_phone
                        : `+1 ${state.administrator_phone}`
                    }
                    name="administrator_phone"
                    label="Administrator phone (Primary)"
                    fullWidth
                    onChange={e => handleChangePhones(e, 'administrator_phone')}
                    defaultCountry="us"
                    countryCodeEditable={false}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={2} lg={2} xl={2}>
                  <TextField
                    value={state.administrator_phone_ext}
                    name="administrator_phone_ext"
                    label="Ext"
                    fullWidth
                    onChange={handleChangeState}
                    integerOnly
                    inputProps={{ maxLength: 5 }}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}></Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4} mt={1}>
                  <MuiPhoneNumberInput
                    variant="outlined"
                    value={
                      state.administrator_phone_alt?.includes('+')
                        ? state.administrator_phone_alt
                        : `+1 ${state.administrator_phone_alt}`
                    }
                    name="administrator_phone_alt"
                    label="Administrator phone (Alternative)"
                    fullWidth
                    onChange={e => handleChangePhones(e, 'administrator_phone_alt')}
                    defaultCountry="us"
                    countryCodeEditable={false}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={2} lg={2} xl={2}>
                  <TextField
                    value={state.administrator_phone_alt_ext}
                    name="administrator_phone_alt_ext"
                    label="Ext"
                    fullWidth
                    onChange={handleChangeState}
                    integerOnly
                    inputProps={{ maxLength: 5 }}
                  />
                </Container.Grid>
                <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="flex-end">
                    <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                        Save
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        color="success"
                        sx={{ ml: 1, width: 170 }}
                        onClick={() => save('next')}
                      >
                        Save and next
                      </Button>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
      </TabPanel>
      <TabPanel className={classes.root} value={value} index={1}>
        {/* <Accordion expanded={expanded === 'panelNetwork'} onChange={handleChangeAccordion('panelNetwork')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelNetworkbh-content"
            id="panelNetworkbh-header"
          >
            <Typography.p className={classes.heading}>
              <ErrorOutlineIcon color="secondary" /> Information about data
            </Typography.p>
          </AccordionSummary> */}
        {/* <AccordionDetails> */}
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2}>
              <Container.Grid container>
                <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography.p p={1} pl={2}>
                    In this section we will ask you questions about your network including your servers, desktops,
                    laptops, etc. This will help us get an overview of your network and assist with the Risk Assessment.
                  </Typography.p>
                </Container.Grid>
                {/* <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.p>
                        Personally Identifiable Information (PII) and Sensitive Data Use the below information to help
                        answer questions about PII and Sensitive Data. Keep in mind that PII might be clients,
                        customers, vendors, employees, etc. The following is PII and Sensitive Data.
                      </Typography.p>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <PersonOutlineIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Personal Identifiers"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="Name;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Social Security number;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Drivers’ license number;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Credit card numbers;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Other financial account numbers (bank, etc);" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Passport numbers;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Other Government ID # or unique identifiers;" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <MailOutlineIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Contact Information"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="E-mail address;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Phone number;" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Postal address;" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <InsertDriveFileIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Other personal data"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="User names, avatars, etc." />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Mother’s maiden name" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Birth date" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Sex" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Age" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Other physical descriptors (eye/hair color, height, etc.)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Sexual orientation" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Race/ethnicity" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Religion" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Education" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Employment" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Citizenship" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Health, insurance, treatment, or medical information" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Criminal history" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Other PII (e.g., in unstructured data fields completed by user)" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItemIcon></ListItemIcon>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary="Biometric identifiers and similar physical-based data"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="Signature" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Fingerprints, handprints" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Photo, scans (retinal, facial)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Voice" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Physical movements (e.g., finger swipes, keystrokes)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="DNA markers" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <SettingsIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Device-based or -related data"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="User names" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Passwords" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Unique device identifier" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Location/GPS data" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Camera controls (photo, video, videoconference)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Microphone controls" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Other hardware/software controls" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Photo data" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Audio/sound data" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Other device sensor controls or data" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="On/Off status and controls" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Cell tower records (e.g., logs, user location, time, date)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Data collected by apps (itemize)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Contact lists and directories" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Biometric data or related data (see above)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="SD card or other stored data" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Network status" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Network communications data" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Device settings or preferences (e.g., security, sharing, status, etc.)" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <AirplayIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Web site or platform-related data"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="Log data (e.g., IP address, time, date, referrer site, browser type)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Tracking data (e.g., single- or multi-session cookies, beacons)" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Forms data" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <InsertDriveFileIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Sensitive data"
                            secondary={
                              <List>
                                <ListItem>
                                  <ListItemText primary="Organization’s proprietary information" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Organizations plans or information that might be damaging if a competitor" />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="accessed the information" />
                                </ListItem>
                              </List>
                            }
                          />
                        </ListItem>
                      </List>
                    </Container.Grid>*/}
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
        {/* </AccordionDetails> */}
        {/* </Accordion> */}
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item mb={3} bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        1.About data
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.number_of_servers}
                    name="number_of_servers"
                    label="How many servers do you have?"
                    type="number"
                    fullWidth
                    onChange={handleChangeState}
                    inputProps={{ min: 0, step: 1, maxLength: 7 }}
                    integerOnly
                  />
                </Container.Grid>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        {hsn
                          ? '2.Do you have patient information on any servers?'
                          : '2.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any server?'}
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioField
                    label=""
                    name={hsn ? 'phi_on_servers' : 'pii_on_servers'}
                    choices={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    value={hsn ? state.phi_on_servers : state.pii_on_servers}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        3.What is the network operating system?
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item mb={1} pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title="This is the operating system used on your servers.">
                    <div style={{ width: '100%' }}>
                      <SelectField
                        fullWidth
                        // allowEmpty={false}
                        choices={[
                          { label: 'Windows', value: 'Windows' },
                          { label: 'UNIX', value: 'UNIX' },
                          { label: 'Linux', value: 'Linux' },
                          { label: 'Mac OS', value: 'Mac OS' },
                          { label: 'Other', value: 'Other' }
                        ]}
                        name="network_operating_system"
                        value={state.network_operating_system}
                        onChange={handleChangeState}
                        // required
                      />
                    </div>
                  </Tooltip>
                </Container.Grid>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        4.Please provide details of your network
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={state.network_details}
                    label="Network Details"
                    name="network_details"
                    placeholder="Ex: We have four different network at company. Both use Windows"
                    fullWidth
                    rows={4}
                    multiline
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}></Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={state.number_workstations}
                    // label="Workstations Number"
                    name="number_workstations"
                    label="How many workstations(desktops) do you have?"
                    type="number"
                    fullWidth
                    onChange={handleChangeState}
                    inputProps={{ min: 0, step: 1, maxLength: 7 }}
                    integerOnly
                  />
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    value={state.number_laptops}
                    // label="Laptops Number"
                    name="number_laptops"
                    label="How many laptops do you have?"
                    type="number"
                    fullWidth
                    onChange={handleChangeState}
                    inputProps={{ min: 0, step: 1, maxLength: 7 }}
                    integerOnly
                  />
                </Container.Grid>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        {hsn
                          ? '5.Do you have patient information on any workstations?'
                          : '5.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any workstations?'}
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title="Keep in mind, sensitive data may be in Documents, Spreadsheets, PDFs, Email Attachments, Screenshots, Audio/Videos, etc.">
                    <div>
                      <RadioField
                        label=""
                        name={hsn ? 'phi_on_workstations' : 'pii_on_workstations'}
                        choices={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                        ]}
                        value={hsn ? state.phi_on_workstations : state.pii_on_workstations}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </div>
                  </Tooltip>
                </Container.Grid>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        {hsn
                          ? '6.Do you have patient information on any laptops?'
                          : '6.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any Laptops?'}
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Keep in mind, sensitive data may be in Documents, Spreadsheets, PDFs, Email Attachments, Screenshots, Audio/Videos, etc.">
                    <div>
                      <RadioField
                        label=""
                        name={hsn ? 'phi_on_laptops' : 'pii_on_laptops'}
                        choices={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                        ]}
                        value={hsn ? state.phi_on_laptops : state.pii_on_laptops}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </div>
                  </Tooltip>
                </Container.Grid>

                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        7.Please check any operating systems your workstations and laptops are running
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value={state.os_win10 === 1}
                      checked={state.os_win10 === 1}
                      control={<Checkbox color="primary" />}
                      name="os_win10"
                      label="Windows 10"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                    <FormControlLabel
                      value={state.os_win8 === 1}
                      checked={state.os_win8 === 1}
                      control={<Checkbox color="primary" />}
                      name="os_win8"
                      label="Windows 8"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                    <FormControlLabel
                      value={state.os_win7 === 1}
                      checked={state.os_win7 === 1}
                      control={<Checkbox color="primary" />}
                      name="os_win7"
                      label="Windows 7"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                    <FormControlLabel
                      value={state.os_winvista === 1}
                      checked={state.os_winvista === 1}
                      control={<Checkbox color="primary" />}
                      name="os_winvista"
                      label="Windows vista"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                    <FormControlLabel
                      value={state.os_winxp === 1}
                      checked={state.os_winxp === 1}
                      control={<Checkbox color="primary" />}
                      name="os_winxp"
                      label="Windows xp"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                    <FormControlLabel
                      value={state.os_winold === 1}
                      checked={state.os_winold === 1}
                      control={<Checkbox color="primary" />}
                      name="os_winold"
                      label="Older Windows"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                    <FormControlLabel
                      value={state.os_mac === 1}
                      checked={state.os_mac === 1}
                      control={<Checkbox color="primary" />}
                      name="os_mac"
                      label="Apple/Mac"
                      labelPlacement="end"
                      onChange={handleStateBooleanChange}
                    />
                  </FormGroup>
                </Container.Grid>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        8.Do you perform vulnerability scans on a regular basis?
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="vulnerability_scans"
                    choices={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                      { value: 'N/A', label: 'N/A' }
                    ]}
                    value={state.vulnerability_scans}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                {state.vulnerability_scans === 'N/A' && (
                  <>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Please explain why you are not performing vulnerability scans?
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.vulnerability_scans_details}
                        name="vulnerability_scans_details"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </>
                )}
                <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="flex-end">
                    <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                        Save
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        color="success"
                        sx={{ ml: 1, width: 170 }}
                        onClick={e => save('next')}
                      >
                        Save and next
                      </Button>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
      </TabPanel>
      {hsn && (
        <TabPanel className={classes.root} value={value} index={2}>
          <Container.Grid container spacing={4} mt={2}>
            <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Paper fullWidth radius={2}>
                <Container.Grid container>
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography.p p={1} pl={2}>
                      In this section we will ask you questions about your Electronic Medical Records (EMR) or Electronic
                      Health Records (EHR).
                    </Typography.p>
                  </Container.Grid>
                </Container.Grid>
              </Container.Paper>
            </Container.Grid>
          </Container.Grid>

          <Container.Grid container spacing={4} mt={2}>
            <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Paper fullWidth radius={2} pb={2}>
                <Container.Grid container>
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          Do you have an EMR/EHR implemented?
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioField
                      label=""
                      name="emr_ehr_implemented"
                      choices={[
                        { value: 'Yes', label: 'Yes' },
                        { value: 'No', label: 'No' }
                      ]}
                      value={state.emr_ehr_implemented}
                      mt={2}
                      mb={1}
                      row
                      labelPlacement="End"
                      onChange={handleChangeEmrEhrState}
                    />
                  </Container.Grid>
                  {state.emr_ehr_implemented === 'Yes' && (
                    <>
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              What is the name of your EMR/EHR Vendor?
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          value={state.emr_ehr_vendor}
                          name="emr_ehr_vendor"
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              What is the internal name that you use to refer to your EMR/EHR?
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          value={state.emr_ehr_internal_name}
                          name="emr_ehr_internal_name"
                          fullWidth
                          inputProps={{ maxLength: 30 }}
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              What server operating system does your EMR/EHR run on?
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioField
                          label=""
                          name="emr_ehr_os"
                          choices={[
                            { label: 'Windows', value: 'Windows' },
                            { label: 'UNIX', value: 'UNIX' },
                            { label: 'Linux', value: 'Linux' },
                            { label: 'Mac OS', value: 'Mac OS' },
                            { label: 'Other', value: 'Other' }
                          ]}
                          value={state.emr_ehr_os}
                          mt={2}
                          mb={2}
                          row
                          labelPlacement="End"
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      {state.emr_ehr_os === 'Other' && (
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            value={state.emr_ehr_os_other}
                            name="emr_ehr_os_other"
                            label="Other EMR/EHR OS"
                            fullWidth
                            onChange={handleChangeState}
                          />
                        </Container.Grid>
                      )}
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              Please enter EMR/EHR Other operating system or provide more details:
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          value={state.emr_ehr_details}
                          name="emr_ehr_details"
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              Where is your EMR/EHR located?
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioField
                          label=""
                          name="emr_ehr_location"
                          choices={[
                            { label: 'Running on a server in office', value: 'Running on a server in office' },
                            { label: 'Hosted by vendor(Cloud/SaaS)', value: 'Hosted by vendor(Cloud/SaaS)' },
                            {
                              label: 'Hosted by third party(Not EMR vendor)',
                              value: 'Hosted by third party(Not EMR vendor)'
                            },
                            { label: 'Other', value: 'Other' }
                          ]}
                          value={state.emr_ehr_location}
                          mt={2}
                          mb={2}
                          row
                          labelPlacement="End"
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                      {state.emr_ehr_location === 'Other' && (
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            value={state.emr_ehr_other_location}
                            name="emr_ehr_other_location"
                            label="Other EMR/EHR Location"
                            fullWidth
                            onChange={handleChangeState}
                          />
                        </Container.Grid>
                      )}
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              Please describe where your EMR/EHR is located:
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          value={state.emr_ehr_description}
                          name="emr_ehr_description"
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                    </>
                  )}
                  <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="flex-end">
                      <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                          Save
                        </Button>
                        <Button
                          startIcon={<CheckIcon />}
                          color="success"
                          sx={{ ml: 1, width: 170 }}
                          onClick={e => save('next')}
                        >
                          Save and next
                        </Button>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
              </Container.Paper>
            </Container.Grid>
          </Container.Grid>
        </TabPanel>
      )}
      <TabPanel className={classes.root} value={value} index={hsn ? 3 : 2}>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        1.Do you utilize email in your organization?
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="email"
                    choices={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    value={state.email}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeEmailState}
                  />
                </Container.Grid>
                {state.email === 'Yes' && (
                  <>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? '2.Do you have patient information on any email accounts?'
                              : '2.Do you have PII or Sensitive Data in any email accounts?'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name={hsn ? 'phi_on_email' : 'pii_on_email'}
                        choices={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                        ]}
                        value={hsn ? state.phi_on_email : state.pii_on_email}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            3.What Email vendor and product do you use?
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="email_vendor"
                        choices={[
                          { value: 'Office365 (Exchange Online)', label: 'Office365 (Exchange Online)' },
                          { value: 'GSuite', label: 'GSuite' },
                          { value: 'Microsoft Exchange', label: 'Microsoft Exchange' },
                          { value: 'Google Gmail', label: 'Google Gmail' },
                          { value: 'Hotmail', label: 'Hotmail' },
                          { value: 'Yahoo! Mail', label: 'Yahoo! Mail' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.email_vendor}
                        mt={2}
                        mb={2}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    {state.email_vendor === 'Other' && (
                      <>
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            value={state.email_vendor_other}
                            name="email_vendor_other"
                            label="Other Email Vendor"
                            fullWidth
                            onChange={handleChangeState}
                            mb={1}
                          />
                        </Container.Grid>
                        <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}></Container.Grid>
                      </>
                    )}
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.email_vendor_details}
                        name="email_vendor_details"
                        label="Email Vendor Details"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                        mb={1}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            4.Where is your Email Server?
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="email_server_location"
                        choices={[
                          { value: 'Onsite', label: 'Onsite' },
                          { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
                          { value: 'Cloud(Google, Yahoo!, Hotmail)', label: 'Cloud(Google, Yahoo!, Hotmail)' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.email_server_location}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            5.Please provide us with any additional details regarding your email
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.email_details}
                        name="email_details"
                        placeholder="Ex: Office365(Exchange Online) is configured with Exchange Online
                    Protection(EOP) and Data Loss Prevention(DLP)"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </>
                )}
                <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="flex-end">
                    <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                        Save
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        color="success"
                        sx={{ ml: 1, width: 170 }}
                        onClick={() => save('next')}
                      >
                        Save and next
                      </Button>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
      </TabPanel>
      <TabPanel className={classes.root} value={value} index={hsn ? 4 : 3}>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        1. Portable media
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        Portable media includes: USB drives, CD-ROM, DVD-ROM, Floppy Drives, Tablet Computers (iPad),
                        etc. Do you use portable media devices?
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="portable_media_devices"
                    choices={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    value={state.portable_media_devices}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangePortableMedia}
                  />
                </Container.Grid>
                {state.portable_media_devices === 'Yes' && (
                  <>
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'Do you have patient information on any portable media (including USB drives, CDs, DVD, Tablets, etc.)?'
                              : 'Do you have PII or sensitive data on any portable media (including USB drives, CDs, DVD, tablets, etc)?'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name={hsn ? 'phi_on_portable_media' : 'pii_on_portable_media'}
                        choices={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                        ]}
                        value={hsn ? state.phi_on_portable_media : state.pii_on_portable_media}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Do you use tablets?
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="tablets"
                        choices={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                        ]}
                        value={state.tablets}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.list_portable_devices}
                        name="list_portable_devices"
                        label="List the portable media devices you are currently using"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </>
                )}
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        2.Backup Media
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        Do you utilize backup media?
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="backup_media"
                    choices={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    value={state.backup_media}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeBackupMedia}
                  />
                </Container.Grid>
                {state.backup_media === 'Yes' && (
                  <>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.backup_media_details}
                        name="backup_media_details"
                        label="Please provide us with any additional details regarding your
                    backup media"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </>
                )}
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        3.Smartphone
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        Do you utilize smartphones?
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="smartphones"
                    choices={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    value={state.smartphones}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeSmartphone}
                  />
                </Container.Grid>
                {state.smartphones === 'Yes' && (
                  <>
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'Do you have patient information on any smartphones (including emails, text messages, etc)?'
                              : 'Do you have PII or sensitive data on any smartphone (including emails, text messages, etc)?'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name={hsn ? 'phi_on_smartphones' : 'pii_on_smartphones'}
                        choices={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                        ]}
                        value={hsn ? state.phi_on_smartphones : state.pii_on_smartphones}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.list_smartphone_vendors}
                        name="list_smartphone_vendors"
                        label="List the smartphone vendors and/or phones"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </>
                )}
                <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="flex-end">
                    <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                        Save
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        color="success"
                        sx={{ ml: 1, width: 170 }}
                        onClick={e => save('next')}
                      >
                        Save and next
                      </Button>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
      </TabPanel>
      <TabPanel className={classes.root} value={value} index={hsn ? 5 : 4}>
        <SystemAccordion
          expanded={expanded === 'panelNetworkSystem'}
          onChange={handleChangeAccordion('panelNetworkSystem')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelNetworkSystembh-content"
            id="panelNetworkSystembh-header"
          >
            <Typography.p className={classes.heading}>
              <ErrorOutlineIcon color="secondary" /> Information about data
            </Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'pt-0'}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography.p className={'system-accordion-text'}>
                        Please list any systems that contain {hsn ? 'PHI' : 'PII'} or Sensitive Data. Keep in mind that{' '}
                        {hsn ? 'PHI' : 'PII'} might be clients, customers, vendors, employees, etc. Systems may include:
                        Billing Systems, Customer Relationship Management, Order Management Systems, Website(s), Network
                        File Shares, Collaborative Systems (Microsoft SharePoint, Google Docs, etc.), Multi-function
                        copy/print/scanners that contain hard drives, Database Systems, Employee / Human Resource
                        Management Systems, Vendor Management Systems, Accounting and Financial Systems, etc.
                      </Typography.p>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </SystemAccordion>

        <SystemAccordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography.p className={classes.heading}>System 1 (Main)</Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'pt-0'}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.system_1_name}
                        name="system_1_name"
                        label="System Name"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Operating System
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_1_os"
                        choices={[
                          { label: 'Windows', value: 'Windows' },
                          { label: 'UNIX', value: 'UNIX' },
                          { label: 'Linux', value: 'Linux' },
                          { label: 'Mac OS', value: 'Mac OS' },
                          { label: 'Other', value: 'Other' }
                        ]}
                        value={state.system_1_os}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'System Vendor and # of ePHI Records (estimate):'
                              : 'System Vendor and # of PII or sensitive data'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.system_1_vendor}
                        name="system_1_vendor"
                        label="System Vendor"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={hsn ? state.system_1_ephi : state.system_1_pii}
                        name={hsn ? 'system_1_ephi' : 'system_1_pii'}
                        label={hsn ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                        fullWidth
                        onChange={handleChangeState}
                        integerOnly
                        inputProps={{ min: 0, step: 1, maxLength: 7 }}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Location
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_1_location"
                        choices={[
                          { value: 'Onsite', label: 'Onsite' },
                          { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
                          { value: 'Vendor Hosted', label: 'Vendor Hosted' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.system_1_location}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Details
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.system_1_details}
                        name="system_1_details"
                        placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </SystemAccordion>
        <SystemAccordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <Typography.p className={classes.heading}>System 2</Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'pt-0'}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.system_2_name}
                        name="system_2_name"
                        label="System Name"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Operating System
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_2_os"
                        choices={[
                          { label: 'Windows', value: 'Windows' },
                          { label: 'UNIX', value: 'UNIX' },
                          { label: 'Linux', value: 'Linux' },
                          { label: 'Mac OS', value: 'Mac OS' },
                          { label: 'Other', value: 'Other' }
                        ]}
                        value={state.system_2_os}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'System Vendor and # of ePHI Records (estimate):'
                              : 'System Vendor and # of PII or sensitive data'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.system_2_vendor}
                        name="system_2_vendor"
                        label="System Vendor"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={hsn ? state.system_2_ephi : state.system_2_pii}
                        name={hsn ? 'system_2_ephi' : 'system_2_pii'}
                        label={hsn ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                        fullWidth
                        onChange={handleChangeState}
                        inputProps={{ min: 0, step: 1, maxLength: 7 }}
                        integerOnly
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Location
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_2_location"
                        choices={[
                          { value: 'Onsite', label: 'Onsite' },
                          { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
                          { value: 'Vendor Hosted', label: 'Vendor Hosted' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.system_2_location}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Details
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.system_2_details}
                        name="system_2_details"
                        placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </SystemAccordion>
        <SystemAccordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Typography.p className={classes.heading}>System 3</Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'pt-0'}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.system_3_name}
                        name="system_3_name"
                        label="System Name"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Operating System
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_3_os"
                        choices={[
                          { label: 'Windows', value: 'Windows' },
                          { label: 'UNIX', value: 'UNIX' },
                          { label: 'Linux', value: 'Linux' },
                          { label: 'Mac OS', value: 'Mac OS' },
                          { label: 'Other', value: 'Other' }
                        ]}
                        value={state.system_3_os}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'System Vendor and # of ePHI Records (estimate):'
                              : 'System Vendor and # of PII or sensitive data'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.system_3_vendor}
                        name="system_3_vendor"
                        label="System Vendor"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={hsn ? state.system_3_ephi : state.system_3_pii}
                        name={hsn ? 'system_3_ephi' : 'system_3_pii'}
                        label={hsn ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                        fullWidth
                        onChange={handleChangeState}
                        inputProps={{ min: 0, step: 1, maxLength: 7 }}
                        integerOnly
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Location
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_3_location"
                        choices={[
                          { value: 'Onsite', label: 'Onsite' },
                          { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
                          { value: 'Vendor Hosted', label: 'Vendor Hosted' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.system_3_location}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Details
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.system_3_details}
                        name="system_3_details"
                        placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </SystemAccordion>
        <SystemAccordion expanded={expanded === 'panel4'} onChange={handleChangeAccordion('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <Typography.p className={classes.heading}>System 4</Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'pt-0'}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.system_4_name}
                        name="system_4_name"
                        label="System Name"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Operating System
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_4_os"
                        choices={[
                          { label: 'Windows', value: 'Windows' },
                          { label: 'UNIX', value: 'UNIX' },
                          { label: 'Linux', value: 'Linux' },
                          { label: 'Mac OS', value: 'Mac OS' },
                          { label: 'Other', value: 'Other' }
                        ]}
                        value={state.system_4_os}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'System Vendor and # of ePHI Records (estimate):'
                              : 'System Vendor and # of PII or sensitive data'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.system_4_vendor}
                        name="system_4_vendor"
                        label="System Vendor"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={hsn ? state.system_4_ephi : state.system_4_pii}
                        name={hsn ? 'system_4_ephi' : 'system_4_pii'}
                        label={hsn ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                        fullWidth
                        onChange={handleChangeState}
                        inputProps={{ min: 0, step: 1, maxLength: 7 }}
                        integerOnly
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Location
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_4_location"
                        choices={[
                          { value: 'Onsite', label: 'Onsite' },
                          { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
                          { value: 'Vendor Hosted', label: 'Vendor Hosted' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.system_4_location}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Details
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.system_4_details}
                        name="system_4_details"
                        placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </SystemAccordion>
        <SystemAccordion expanded={expanded === 'panel5'} onChange={handleChangeAccordion('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <Typography.p className={classes.heading}>System 5</Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'pt-0'}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        value={state.system_5_name}
                        name="system_5_name"
                        label="System Name"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            Operating System
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_5_os"
                        choices={[
                          { label: 'Windows', value: 'Windows' },
                          { label: 'UNIX', value: 'UNIX' },
                          { label: 'Linux', value: 'Linux' },
                          { label: 'Mac OS', value: 'Mac OS' },
                          { label: 'Other', value: 'Other' }
                        ]}
                        value={state.system_5_os}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            {hsn
                              ? 'System Vendor and # of ePHI Records (estimate):'
                              : 'System Vendor and # of PII or sensitive data'}
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state.system_5_vendor}
                        name="system_5_vendor"
                        label="System Vendor"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={hsn ? state.system_5_ephi : state.system_5_pii}
                        name={hsn ? 'system_5_ephi' : 'system_5_pii'}
                        label={hsn ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                        fullWidth
                        onChange={handleChangeState}
                        inputProps={{ min: 0, step: 1, maxLength: 7 }}
                        integerOnly
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Location
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <RadioField
                        label=""
                        name="system_5_location"
                        choices={[
                          { value: 'Onsite', label: 'Onsite' },
                          { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
                          { value: 'Vendor Hosted', label: 'Vendor Hosted' },
                          { value: 'Other', label: 'Other' }
                        ]}
                        value={state.system_5_location}
                        mt={2}
                        mb={1}
                        row
                        labelPlacement="End"
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                    <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container.Grid container spacing={4} justify="space-between">
                        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography.h3 p={2} m={0.1} fontSize={16}>
                            System Details
                          </Typography.h3>
                        </Container.Grid>
                      </Container.Grid>
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.system_5_details}
                        name="system_5_details"
                        placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </SystemAccordion>
        <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container.Grid container spacing={4} justify="flex-end">
            <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                Save
              </Button>
              <Button startIcon={<CheckIcon />} color="success" sx={{ ml: 1, width: 170 }} onClick={e => save('next')}>
                Save and next
              </Button>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </TabPanel>
      <TabPanel className={classes.root} value={value} index={hsn ? 6 : 5}>
        <Accordion expanded={expanded === 'panelNetwork'} onChange={handleChangeAccordion('panelNetwork')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelNetworkbh-content"
            id="panelNetworkbh-header"
          >
            <Typography.p className={classes.heading}>
              <ErrorOutlineIcon color="secondary" /> Please provide additional information that will better help us
              understand your network/computer environment
            </Typography.p>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid container spacing={4} mt={2}>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Paper fullWidth radius={2} pb={2}>
                  <Container.Grid container>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List>
                        <OrganisationListItem alignItems="flex-start">
                          <ListItemIcon>
                            <InsertDriveFileIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              hsn
                                ? 'List any additional system that contain patient information or electronic protected health information (ePHI);'
                                : 'List any additional system that contain PII;'
                            }
                            secondary={
                              <List>
                                <OrganisationListItem>
                                  <ListItemText primary="List any known threats or issues that you have experienced in the past (floods, crimes, etc.);" />
                                </OrganisationListItem>
                                <OrganisationListItem>
                                  <ListItemText primary="Use this section to provide anything that you feel will help us with the Risk Assessment." />
                                </OrganisationListItem>
                              </List>
                            }
                          />
                        </OrganisationListItem>
                      </List>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </Container.Grid>
            </Container.Grid>
          </AccordionDetails>
        </Accordion>

        <Container.Grid container spacing={4} mt={2}>
          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Paper fullWidth radius={2} pb={2}>
              <Container.Grid container>
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={state.additional_info}
                    name="additional_info"
                    placeholder="Additional Information"
                    fullWidth
                    rows={4}
                    multiline
                    onChange={handleChangeState}
                  />
                </Container.Grid>
                <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="flex-end">
                    <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button startIcon={<SaveIcon />} sx={{ width: 108 }} color="info" onClick={() => save(false)}>
                        Save
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        color="success"
                        sx={{ ml: 1, width: 170, whiteSpace: 'nowrap' }}
                        onClick={e => save('close')}
                      >
                        Save and close
                      </Button>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </Container.Grid>
        </Container.Grid>
      </TabPanel>
    </>
  );
};
export default OrganizationProfile;
