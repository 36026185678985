import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { Container, Typography } from 'components';
import { TYPES } from './helpers';
import {Button, AddIcon } from '@trustsecurenow/components-library';

function ExcludeReceivers({ state, setState, setModalState }) {
  const revertExcludeClient = idx => {
    setState(st => {
      const arr = st.excluded_clients;
      arr.splice(idx, 1);
      return {
        ...st,
        excluded_clients: arr
      };
    });
  };

  return (
    <>
      {/* Disable push notification for */}
      {state.picklist.clients.length !== 0 && (
        <Container.Grid py={1.2} container>
          <Container.Grid container xs={4}>
            <Container.Grid item xs={12}>
              <Typography.p fontWeight="bold" m={0.1}>
                Disable push notification for
              </Typography.p>
            </Container.Grid>
            <Container.Grid item xs={12}>
              Your list has {state.excluded_clients.length} customers
            </Container.Grid>
          </Container.Grid>
          <Container.Grid flexWrap="wrap" container xs={5}>
            {state.excluded_clients.map((row, idx) => (
              <Box p={1}>
                <Chip label={row.name} onDelete={() => revertExcludeClient(idx)} />
              </Box>
            ))}
          </Container.Grid>
          <Container.Grid pl={2} item xs={3} justifyContent="flex-end">
            <div>
              <Button
                onClick={() => setModalState({ type: TYPES.excludeClient, opened: true })}
                startIcon={<AddIcon/>}
                >
                  Add client list
              </Button>
            </div>
          </Container.Grid>
        </Container.Grid>
      )}
    </>
  );
}

export default ExcludeReceivers;
