import apiClient from 'helpers/apiClient/apiClient';

export const bsnClientServicesBaseURL = `${process.env.REACT_NEW_BASE_URL}bsnclientservices`;

const bsnClientServices = {};

bsnClientServices.getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

/**
 * fetches the directory sync configuration for a specific client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 * @returns configuration for directory sync page
 */
bsnClientServices.getDirectorySyncConfig = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * updates configuaration for sending automatemd welcome message for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 * @param {boolean} Params.automatedWelcome - whether to send automated Welcome or not
 * @returns {Promise<AxiosResponse<string>>}
 */
bsnClientServices.sendAutomatedWelcomeMsg = ({ clientID, automatedWelcome }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/sendAutomated/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: { automatedWelcome }
  };
  return apiClient.post(requestOptions);
};

/**
 * updates configuaration for using custom welcome message for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 * @param {boolean} Params.customWelcome - whether to use custom Welcome or not
 * @returns {Promise<AxiosResponse<string>>}
 */
bsnClientServices.useCustomWelcomeMsg = ({ clientID, customWelcome }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/customMessageToggle/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: { customWelcome }
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches the current custom welcome message for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 * @param {"1001"|"1002"} Params.messageType - welcome message or welcome back message
 */
bsnClientServices.getWelcomeMsg = ({ clientID, messageType }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/customMessage/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    params: {
      message_type: messageType
    }
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches the current custom welcome message for a partner
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 * @param {"1001"|"1002"} Params.messageType - welcome message or welcome back message
 */
bsnClientServices.getPartnerWelcomeMsg = ({ clientID, messageType }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/partnerProfile/customMessages/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    params: {
      message_type: messageType
    }
  };
  return apiClient.get(requestOptions);
};

/**
 * updates the current custom welcome message draft for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to update the draft
 * @param {Object} Params.message - updated custom message object
 * @param {boolean} Params.message.deferred_sending
 * @param {string} Params.message.first_text
 * @param {string} Params.message.second_text
 * @param {"1001"|"1002"} Params.message.message_type - welcome message or welcome back message
 * @param {string} Params.message.add_notification_email
 * @param {number} [Params.message.hours]
 *
 */
bsnClientServices.updateWelcomeMsgDraft = ({ clientID, message }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/customMessage/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: message
  };
  return apiClient.post(requestOptions);
};

/**
 * updates the current custom welcome message draft for a partner
 * @param {Object} Params
 * @param {string} Params.clientID - for which to update the draft
 * @param {Object} Params.message - updated custom message object
 * @param {boolean} Params.message.deferred_sending
 * @param {string} Params.message.first_text
 * @param {string} Params.message.second_text
 * @param {"1001"|"1002"} Params.message.message_type - welcome message or welcome back message
 * @param {string} Params.message.add_notification_email
 * @param {number} [Params.message.hours]
 *
 */
bsnClientServices.updatePartnerWelcomeMsgDraft = ({ clientID, message }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/partnerProfile/customMessages/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: message
  };
  return apiClient.post(requestOptions);
};

/**
 * tests the current custom welcome message for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 * @param {Object} Params.message - updated custom message object
 * @param {string} Params.message.first_text
 * @param {string} Params.message.second_text
 * @param {"1"|"2"} Params.message.message_type - welcome message or welcome back message
 * @param {string} Params.message.email - to which the message is sent
 *
 */
bsnClientServices.testWelcomeMsg = ({ clientID, message }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/testcustomMessage/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: message
  };
  return apiClient.post(requestOptions);
};

/**
 * publishes the current custom welcome message draft for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to publish the config
 * @param {Object} Params.message - updated custom message object
 * @param {boolean} Params.message.deferred_sending
 * @param {string} Params.message.first_text
 * @param {string} Params.message.second_text
 * @param {1|2} Params.message.message_type - welcome message or welcome back message
 * @param {string} Params.message.add_notification_email
 * @param {1|2} Params.message.delete_draft
 * @param {number} [Params.message.hours]
 *
 */
bsnClientServices.publishWelcomeMsg = ({ clientID, message }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/customMessage/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: message
  };
  return apiClient.post(requestOptions);
};

/**
 * publishes the current custom welcome message draft for a partner
 * @param {Object} Params
 * @param {string} Params.clientID - for which to publish the config
 * @param {Object} Params.message - updated custom message object
 * @param {boolean} Params.message.deferred_sending
 * @param {string} Params.message.first_text
 * @param {string} Params.message.second_text
 * @param {1|2} Params.message.message_type - welcome message or welcome back message
 * @param {string} Params.message.add_notification_email
 * @param {1|2} Params.message.delete_draft
 * @param {number} [Params.message.hours]
 *
 */
bsnClientServices.publishParnterWelcomeMsg = ({ clientID, message }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/partnerProfile/customMessages/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: message
  };
  return apiClient.post(requestOptions);
};

/**
 * tests the current custom welcome message for a client
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the config
 */
bsnClientServices.downlaodCSVBulk = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/csvBulkDownload/${clientID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * updates the directory sync config for a specific client
 * @param {object} params
 * @param {string} Params.clientID - for which to update the config
 */
bsnClientServices.saveCSVBulkConfig = ({ clientID, data }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'csvbulkupload',
      automatedWelcome: data.automatedWelcome,
      welMessFrequency: data.welMessFrequency,
      welMessHowMany: data.welMessHowMany,
      customWelcome: data.customWelcome,
      welMessagePriorToPasswordReset: data.welMessagePriorToPasswordReset,
      welMessagePostToPasswordReset: data.welMessagePostToPasswordReset,
      welBackMessagePriorToPasswordReset: data.welBackMessagePriorToPasswordReset,
      welBackMessagePostToPasswordReset: data.welBackMessagePostToPasswordReset,
      file: data.file,
      base64: data.base64
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * updates the directory sync config for a specific client
 * @param {object} params
 * @param {string} Params.clientID - for which to update the config
 */
bsnClientServices.saveOnPremiseActiveDirectoryConfig = ({ clientID, data }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'onpremiseactivedirectory',
      automatedWelcome: data.automatedWelcome,
      customWelcome: data.customWelcome,
      welMessFrequency: data.welMessFrequency,
      welMessHowMany: data.welMessHowMany,
      welMessagePriorToPasswordReset: false,
      welMessagePostToPasswordReset: false,
      welBackMessagePriorToPasswordReset: false,
      welBackMessagePostToPasswordReset: false,
      rocketCyberClientId: data.rocketCyberClientId,
      rocketCyberUrl: data.rocketCyberUrl,
      useAsPortalLogonValue: data.useAsPortalLogonValue
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * updates the directory sync config for a specific client
 * @param {object} params
 * @param {string} Params.clientID - for which to update the config
 */
bsnClientServices.saveGoogleGSuite = ({ clientID, data }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'googlegsuite',
      automatedWelcome: data.automatedWelcome,
      customWelcome: data.customWelcome,
      adminEmail: data.adminEmail,
      fileName: data.file.name,
      file: data.file,
      base64: data.base64
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * updates the directory sync config for a specific client
 * @param {object} params
 * @param {string} Params.clientID - for which to update the config
 */
bsnClientServices.saveAzureActiveDirectory = ({ clientID, data }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'azureactivedirectorypowershell',
      automatedWelcome: data.automatedWelcome,
      customWelcome: data.customWelcome,
      adminEmail: data.adminEmail,
      fileName: data.file.name,
      file: data.file,
      base64: data.base64
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * toggles the status of AzureActiveDirectory
 * @param {object} params
 * @param {string} Params.clientID - for which to update the status
 * @param {boolean} Params.status - status value to update
 */
bsnClientServices.toggleAzureActiveDirectory = ({ clientID, status }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'azureActiveDirectory',
      status
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * toggles the status of onPremiseActiveDirectory
 * @param {object} params
 * @param {string} Params.clientID - for which to update the status
 * @param {boolean} Params.status - status value to update
 */
bsnClientServices.toggleOnPremiseActiveDirectory = ({ clientID, status }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'onPremiseActiveDirectory',
      status
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * toggles the status of GoogleGSuite
 * @param {object} params
 * @param {string} Params.clientID - for which to update the status
 * @param {boolean} Params.status - status value to update
 */
bsnClientServices.toggleGoogleGSuite = ({ clientID, status }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'googleGSuite',
      status
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches download link of DirectorySyncAgent
 * @param {object} params
 * @param {string} params.clientID - for which to download the agent
 * @returns the url to download DirectorySyncAgent
 */
bsnClientServices.downloadDirectorySyncAgent = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'onpremiseactivedirectorydownloadmsi'
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * creates and fetches a powershell script
 * @param {object} params
 * @param {string} params.clientID - for which to create a script
 * @returns the created a script
 */
bsnClientServices.createPowerShellScript = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'azureActiveDirectory'
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches download link of powershell script
 * @param {object} params
 * @param {string} params.clientID - for which to download the script
 * @returns the url to download powershell script
 */
bsnClientServices.downloadPowerShellScript = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/directorySync/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: 'azureActiveDirectory'
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches a client information for a specific partner
 * @param {Object} Params
 * @param {string} Params.partnerID - under which the client is listed
 * @param {string} Params.clientID - for which to fetch the profile information
 */
bsnClientServices.getClientInformation = ({ partnerID, clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/v1/list/${partnerID}`,
    headers: bsnClientServices.getRequestHeaders(),
    params: {
      _filter: `id:${String(clientID)}`
    }
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches track page configuration for a client.
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the track config
 */
bsnClientServices.getTrackConfig = ({ app, clientID, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/${app}/track/${clientID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetch a list of track security incidents of a specific clients
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the list
 * @param {string} Params.filters
 * @param {Object} Params.sort
 * @param {string} Params.sort.field
 * @param {string} Params.sort.order
 * @param {Object} Params.pagination
 * @param {number} Params.pagination.page
 * @param {number} Params.pagination.perPage
 * @param {string} Params.cancelToken
 * @returns a list of track security incidents
 */
bsnClientServices.getTrackSecurityIncidents = ({ clientID, filters, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackSecurityIncidents/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    params: {
      _filter: filters ? encodeURI(filters) : '',
      _sort: sort.field,
      _order: sort.order.toUpperCase(),
      _start: pagination.page,
      _end: pagination.perPage
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetch a list of trackSecurityIncidents of a specific clients
 * @param {Object} Params
 * @param {string} Params.clientID - for which to fetch the list
 * @param {string} Params.filters
 * @param {Object} Params.sort
 * @param {string} Params.sort.field
 * @param {string} Params.sort.order
 * @param {Object} Params.pagination
 * @param {number} Params.pagination.page
 * @param {number} Params.pagination.perPage
 * @param {string} Params.cancelToken
 * @returns a list of trackServerRoomAccess
 */
bsnClientServices.getTrackServerRoomAccess = ({ clientID, filters, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackServerRoomAccess/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    params: {
      _filter: filters ? encodeURI(filters) : '',
      _sort: sort.field,
      _order: sort.order.toUpperCase(),
      _start: pagination.page,
      _end: pagination.perPage
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * creates a new security Incident
 * @param {Object} params
 * @param {string} params.clientID - for which to create the Incident
 * @param {Object} params.securityIncident - the Incident to be created
 */
bsnClientServices.createSecurityIncident = ({ clientID, securityIncident }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackSecurityIncidents/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: securityIncident
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches info of a trackSecurityIncident
 * @param {Object} Params
 * @param {string} Params.trackID
 * @param {string} Params.cancelToken
 */
bsnClientServices.getSecurityIncidentInfo = ({ trackID, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackSecurityIncidentsInfo/${trackID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * updates info of a trackSecurityIncident
 * @param {Object} Params
 * @param {string} Params.trackID
 * @param {string} Params.securityIncident - data to update
 * @param {string} Params.cancelToken
 */
bsnClientServices.updateSecurityIncidentInfo = ({ trackID, securityIncident, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackSecurityIncidentsInfo/${trackID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: securityIncident
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.put(requestOptions);
};

/**
 * deletes a trackSecurityIncident
 * @param {Object} Params
 * @param {Object} Params.clientID
 * @param {string} Params.trackID
 * @param {string} Params.cancelToken
 */
bsnClientServices.deleteSecurityIncident = ({ clientID, tracksID, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackSecurityIncidents/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      id: tracksID
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * fetches info of a trackServerRoomAccessInfo
 * @param {Object} Params
 * @param {string} Params.trackID
 * @param {string} Params.cancelToken
 */
bsnClientServices.getTrackServerRoomAccessInfo = ({ trackID, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackServerRoomAccessInfo/${trackID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * updates info of a trackServerRoomAccessInfo
 * @param {Object} Params
 * @param {string} Params.trackID
 * @param {string} Params.serverRoomAccess - data to update
 * @param {string} Params.cancelToken
 */
bsnClientServices.updateServerRoomAccessInfo = ({ trackID, serverRoomAccess, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackServerRoomAccessInfo/${trackID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: serverRoomAccess
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.put(requestOptions);
};

/**
 * deletes a trackServerRoomAccess
 * @param {Object} Params
 * @param {Object} Params.clientID
 * @param {string} Params.trackID
 * @param {string} Params.cancelToken
 */
bsnClientServices.deleteServerRoomAccess = ({ clientID, tracksID, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackServerRoomAccess/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      id: tracksID
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * creates a trackServerRoomAccess
 * @param {Object} params
 * @param {string} params.clientID - for which to create the Track
 * @param {Object} params.serverRoomAccess - the Track to be created
 */
bsnClientServices.createServerRoomAccess = ({ clientID, serverRoomAccess }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/trackServerRoomAccess/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: serverRoomAccess
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches a list of all managed clients by a specific partner
 * @param {Object} Params
 * @param {string} Params.partnerID
 * @param {string} Params.filters
 * @param {Object} Params.sort
 * @param {string} Params.sort.field
 * @param {string} Params.sort.order
 * @param {Object} Params.pagination
 * @param {number} Params.pagination.page
 * @param {number} Params.pagination.perPage
 * @param {string} Params.cancelToken
 * @param {string} Params.cancelToken
 */
bsnClientServices.getClientsList = ({ partnerID, filters, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/v1/list/${partnerID}`,
    headers: bsnClientServices.getRequestHeaders(),
    params: {
      _filter: filters ? encodeURI(filters) : '',
      _sort: sort?.field,
      _order: sort?.order.toUpperCase(),
      _start: pagination?.page,
      _end: pagination?.perPage
    }
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches clients' products types
 * @param {Object} Params
 * @param {string} Params.partnerID
 * @param {string} Params.cancelToken
 */
bsnClientServices.getProductsTypes = ({ partnerID, cancelToken }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/productsType/${partnerID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * takes an action to a client
 * @param {Object} params
 * @param {string} params.clientID - for which to create the Track
 * @param {Object} params.action - to take for a client
 */
bsnClientServices.applyClientAction = ({ clientID, action, system_emails_status }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/actions/${clientID}`,
    headers: bsnClientServices.getRequestHeaders(),
    data: {
      type: action,
      system_emails_status
    }
  };
  return apiClient.post(requestOptions);
};

/**
 * deletes a client
 * @param {Object} params
 * @param {string} params.clientID - for which to create the Track
 */
bsnClientServices.deleteClient = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/actions/${clientID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

/**
 * fetches a client's company name
 * @param {Object} params
 * @param {string} params.clientID - for which to fetch the company name
 */
bsnClientServices.getCompanyName = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/company/${clientID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches a client's access page configuration
 * @param {Object} params
 * @param {string} params.clientID - for which to fetch the configuration
 */
bsnClientServices.getClientAccess = ({ clientID }) => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/access/${clientID}`,
    headers: bsnClientServices.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

export default bsnClientServices;
