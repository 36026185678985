/* eslint-disable no-console */
// @flow
import React, { type Element, type Context, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation, useUserProfile, useWindowSize } from 'hooks';
import {
  BSN_GET_LIST,
  BSN_GET_ONE,
  BSN_SET,
  BSN_SET_ITEM,
  BSN_SET_USER,
  BSN_SYSTEM_CHANGE_TAB,
  BSN_SYSTEM_CHANGE_APP,
  BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
  initialState,
  type initialStateType,
  mobilePagesWhiteList,
  GAbuttonExceptions,
  BSN_SET_ANY
} from 'conf';
import axios from 'axios';
import { differenceInMinutes } from 'date-fns';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { getStorage, setStorage, clearStorage, getStorageObject } from './storage';
import { checkSession, isSessionExpired } from './session';
import { getActiveTab, itemExist, validateVariable, isPublicUrl, USER_ROLES } from './utils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastCloseButton, NotificationToast } from 'apps/newsfeed/components/notifications';
import {
  setRedirectToFavorite,
  addNotification,
  setUnopenedNotificationsCount,
  trackingUtils,
  isNumber
} from 'helpers';
import useBreakpoint from '../hooks/useBreakpoint';
import { getMessaging, onMessage, isSupported } from 'firebase/messaging';
import { useMaskPartner } from '../apps/admin/hooks';
import { isMobile } from 'react-device-detect';
import { useTheme, useThemeContext } from '@trustsecurenow/components-library';

let messaging = null;
isSupported().then(res => {
  if (res) {
    import('firebaseInit').then(module => {
      messaging = getMessaging();
    });
  }
});

const valid_industry_user_roles = [USER_ROLES.MANAGER_ADMIN, USER_ROLES.PARTNER_ADMINISTRATOR, USER_ROLES.MANGER];

const AppContext: Context<{
  dispatch: Function,
  state: initialStateType,
  app: string,
  tab: string,
  item: number | string | null | typeof undefined,
  tempState: any
}> = createContext({
  dispatch: e => e,
  state: initialState,
  app: '',
  tab: '',
  item: 0,
  tempState: []
});

type AppProviderTypes = {
  children: any
};

export const AppProvider = ({ children }: AppProviderTypes): Element<*> => {
  useUserProfile();
  const history = useHistory();
  const state = useSelector(rxState => rxState.bsn);
  const user = useSelector(rxState => rxState.bsn.user.profile);
  const location = useSelector(rxState => rxState.bsn.system.location);
  const favoriteApp = useSelector(({ bsn }) => bsn?.user?.profile?.favorite);
  const redirectToFavorite = useSelector(state => state?.auth?.redirectToFavorite);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const accessApps = useSelector(({ bsn }) => bsn?.user?.access?.apps);
  const userRole = getStorage('userRole');
  const { app, tab: tabApp, item: itemApp } = useLocation();
  const isApp = validateVariable(app, state);
  const tab: string = isApp ? tabApp || 'dashboard' : '';
  const isTab = isApp && validateVariable(tab, state[app]);
  const [loading, setLoading] = useState(false);
  const isItem = isApp && validateVariable(itemApp);
  const item = isApp && isItem ? itemApp || state.system.item : null;
  const tabSettings = tab !== 'undefined' ? (isTab ? state.tabs[app][tab] : null) : null;
  const isList = itemExist(tabSettings, 'isList') && tabSettings && tabSettings.isList;
  const theme = state.partnerProfile.colorScheme;
  const dispatchRx = useDispatch();
  const { setPrimaryColor } = useThemeContext();
  const { palette } = useTheme();

  const dispatch = {};
  const mobileView = useBreakpoint('sm');
  const { cleanMaskModeStorage } = useMaskPartner();
  const isMaskMode = getStorage('maskMode', true);

  useEffect(() => {
    if (newsfeedAccess && messaging) {
      onMessage(messaging, payload => {
        console.log('Received foreground message ', payload);
        const notification = {
          ...payload.data,
          data: JSON.parse(payload.data.data)
        };
        dispatchRx(addNotification(notification));

        let allNewsfeedNotificationsSeen = sessionStorage.getItem('allNewsfeedNotificationsSeen');
        if (allNewsfeedNotificationsSeen === '1')
          sessionStorage.setItem('lastToastNotificationId', notification.notification_id);
        else sessionStorage.removeItem('lastToastNotificationId');

        dispatchRx(setUnopenedNotificationsCount(false, 1));
        dispatchRx({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: 0
        });
        toast(<NotificationToast notification={notification} />, {
          draggable: false,
          autoClose: 5000,
          style: { borderRadius: 6, boxShadow: 'none' }
        });
      });
    }
  }, [newsfeedAccess]);

  const UseLayout = () => {
    const tabs = useSelector(rxState => rxState.bsn.tabs) || {};
    return tabs[favoriteApp];
  };

  const layout = UseLayout();

  useEffect(() => {
    const favorite = getStorage('favorite', true);
    const access_token = getStorage('accessToken', true);
    const isAllowedPage = !mobileView || (mobileView && mobilePagesWhiteList[favoriteApp]);
    const redirectToPathStorage = sessionStorage.getItem('redirectToPath');

    if (redirectToPathStorage && access_token) {
      history.push(redirectToPathStorage);
      sessionStorage.removeItem('redirectToPath');
      return;
    }

    if (!favorite && favoriteApp && access_token && layout) {
      setStorage('favorite', favoriteApp, true);

      const access = accessApps[favoriteApp];
      const allowedLayout = Object.keys(layout).reduce((acc, tabname) => {
        if (access[tabname]) acc[tabname] = layout[tabname];
        return acc;
      }, {});

      const allowedTabs = Object.keys(allowedLayout);
      let mobileAllowedTabs;

      if (mobilePagesWhiteList[favoriteApp]) {
        mobileAllowedTabs = mobilePagesWhiteList[favoriteApp].filter(mobileTab => {
          return allowedTabs.includes(mobileTab);
        });
      } else {
        mobileAllowedTabs = [''];
      }

      const defaultTab = isMobile ? mobileAllowedTabs[0] : allowedTabs[0];

      let redirectToFavoriteStorage = sessionStorage.getItem('redirectToFavorite');
      if ((redirectToFavorite || redirectToFavoriteStorage) && isAllowedPage) {
        if (
          user.get_started_notify &&
          user.user_role === 'Partner Administrator' &&
          // make sure that get started is allowed to be shown in mobile
          (!isMobile || mobilePagesWhiteList['getStarted'])
        ) {
          localStorage.setItem('getStartedNotify', 'true');
          history.push('/getStarted/dashboard');
        } else {
          history.replace(`/${favoriteApp}${favoriteApp === 'clients' ? '' : '/' + defaultTab}`);
        }

        dispatchRx(setRedirectToFavorite(false));
        sessionStorage.removeItem('redirectToFavorite');
      }
    }
  }, [favoriteApp, redirectToFavorite, location, accessApps, layout, isMobile, mobileView, history, user]);

  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        const url = response.config.url;
        if ((!url.includes('authorize') && !isSessionExpired()) || !getStorage('lastRequestDate', true)) {
          const access_token = getStorage('accessToken', true);
          if (access_token) {
            setStorage('lastRequestDate', Date.now(), true);
            sessionStorage.setItem('sessionOpened', 'true');
          }
        }
        const token_updated = getStorage('tokenUpdated', true);
        const expire_time = getStorage('expireTime', true);
        if (expire_time) {
          const now = new Date();
          const expire_date = new Date(+expire_time);
          const diff = differenceInMinutes(expire_date, now);
          if (diff >= 0 && diff <= 15 && token_updated && token_updated === 'true') {
            setStorage('tokenUpdated', 'false', true);
            checkSession();
          }
        }
        return response;
      },
      error => {
        const status = error?.response?.status;
        const url = error?.response?.config?.url;
        if (status && (status === 401 || status === 403)) {
          if (url.includes('authorize')) {
            if (
              !window.location.hash.includes('expiredSession') &&
              !window.location.hash.includes('login') &&
              !isPublicUrl(window.location.hash.split('/')[1].split('?')[0])
            ) {
              const sessionOpened = sessionStorage.getItem('sessionOpened');
              clearStorage();
              if (sessionOpened) window.location.href = '/expiredSession/index.html';
              else window.location.href = '/#/login';
            }
          } else {
            checkSession();
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (!state.user.profile && !isPublicUrl(window.location.hash.split('/')[1].split('?')[0])) authProvider.checkAuth();
    const userData = getStorageObject('userData');
    if (userData) {
      const primary = userData?.theme?.base;
      if (palette.primary.main.toLowerCase() !== primary.base.toLowerCase()) {
        setPrimaryColor({
          main: primary.base,
          light: primary.light1,
          lighter: primary.light2,
          lightest: primary.light3,
          dark: primary.dark1,
          darker: primary.dark2,
          darkest: primary.dark3
        });
      }
    }
  }, [palette.primary.main, setPrimaryColor, state.user.profile]);

  useEffect(() => {
    if (
      // (user === 'null' || user === null) &&
      // location === 'myDashboard' &&
      !isPublicUrl(window.location.hash.split('/')[1].split('?')[0])
    ) {
      const id_token = getStorage('idToken', true);
      const refresh_token = getStorage('refreshToken', true);
      const access_token = getStorage('accessToken', true);
      console.log(`%cCHECK AUTH`, 'font-size: medium; color: #FF0000');
      // eslint-disable-next-line prefer-promise-reject-errors
      dataProvider
        .post('user', 'authorize2', null, { id_token, refresh_token, access_token })
        .then(({ status, statusText, data }) => {
          if (data.user?.user_role == USER_ROLES.MANGER && tab == 'csvBulkUpload') {
            history.push('/myDashboard/dashboard');
          }
          if (status < 200 || status >= 300) throw new Error(statusText);
          dispatchRx({
            type: BSN_SET_ANY,
            payload: {
              tabs: {
                user: {
                  profile: {
                    label: valid_industry_user_roles.includes(userRole) ? 'Profile' : 'My Profile'
                  }
                }
              }
            }
          });
        })
        .catch(error => {
          const status = error?.response?.status;
          if (status === 500) {
            window.location.href = '/#/login';
            clearStorage();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (app !== 'clients' && app !== 'aadAuth' && isMaskMode) {
      cleanMaskModeStorage();
    }
  }, [app, isMaskMode]);

  // Track clicking for Google Analytics
  const onGAbtnClick = e => {
    const target = e.target;
    const elementType = target.tagName.toLowerCase();
    const isGAbuttonClick = target?.dataset?.gaButton || target?.closest('[data-ga-button]')?.dataset?.gaButton;

    // Check if element has "data-ga-button" attribute, if not check if element type is "button" or "a"
    if (isGAbuttonClick) {
      trackingUtils.clickButton(isGAbuttonClick);
    } else if (elementType === 'button' || target?.closest('button') || elementType === 'a' || target?.closest('a')) {
      const el =
        elementType === 'button' || elementType === 'a' ? target : target?.closest('button') || target?.closest('a');
      const isAppOrTab = el?.dataset?.gaApp || el?.dataset?.gaTab;

      if (!isAppOrTab) {
        let btnText = el?.innerText?.trim();
        const allowTracking = !GAbuttonExceptions.includes(btnText?.toLowerCase());

        if (btnText && !isNumber(btnText) && allowTracking) {
          btnText = btnText === 'Go' ? `Go - ${el?.href?.split('/').slice(-1)[0]}` : btnText;
          trackingUtils.clickButton(btnText);
        }
      }
    }
  };

  useEffect(() => {
    if (user) window.addEventListener('click', onGAbtnClick);
    return () => {
      window.removeEventListener('click', onGAbtnClick);
    };
  }, [user]);

  dispatch.getData = (
    setLocation?: string,
    setTab?: string,
    setItem?: string,
    setIslist?: boolean,
    params?: Object = {}
  ) => {
    const resource = setLocation || app;
    const currentTab = setTab || tab;
    const currentItem = setItem || item;
    const newIsList = typeof setIslist === 'undefined' ? isList : setIslist;
    const setIsItem = validateVariable(currentItem);

    const dispatchSettings: {
      type: string,
      resource: string,
      tab: string,
      item?: string | number | false | null,
      payload: Object
    } = {
      type: newIsList ? BSN_GET_LIST : BSN_GET_ONE,
      resource,
      tab: currentTab,
      payload: null
    };
    // eslint-disable-next-line consistent-return
    if (!tab) return dispatchRx(dispatchSettings);
    return dataProvider[newIsList ? 'getList' : 'getOne'](
      resource,
      setIsItem ? `${currentTab}/${String(currentItem)}` : currentTab,
      params
    )
      .then(response => {
        if (!validateVariable(response) || !validateVariable('data', response)) return;
        const { data: resData } = response;
        // if (setIsItem) dispatchSettings.item = currentItem;
        dispatchSettings.payload = resData;
        dispatchRx(dispatchSettings);
        if (newIsList) {
          dispatchRx({
            type: BSN_SET_ITEM,
            resource: 'tables',
            tab: resource,
            item: currentTab,
            key: 'total',
            payload: Object.prototype.hasOwnProperty.call(response, 'total') ? response.total : null
          });
        }

        // eslint-disable-next-line consistent-return
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  };

  dispatch.postData = (setLocation?: string, setTab?: string, setItem?: string, setIslist) => {};

  dispatch.get = e => console.log(e);

  dispatch.set = (setApp: string, setTab: string, payload: Object) => {
    dispatchRx({
      type: BSN_SET,
      resource: setApp,
      tab: setTab,
      payload
    });
  };

  dispatch.setItem = (
    setApp: string,
    setTab: string,
    key: string,
    payload: Object,
    setItem?: number | string | null = null,
    atBeginning = false
  ) => {
    dispatchRx({
      type: BSN_SET_ITEM,
      resource: setApp,
      tab: setTab,
      key,
      item: setItem,
      atBeginning,
      payload
    });
  };

  dispatch.getOne = (setApp?: string, setTab?: string, setItem?: string, setParam?: Object = {}) => {
    console.log(`%cDISPATCH GET ONE`, 'font-size: medium; color: NavajoWhite');
    return dispatch.getData(setApp, setTab, setItem, false, setParam);
  };

  dispatch.getList = (setApp?: string, setTab?: string, setItem?: string, setParam?: Object = {}) => {
    console.log(`%cDISPATCH GET LIST`, 'font-size: medium; color: LightSeaGreen');
    return dispatch.getData(setApp, setTab, setItem, true, setParam);
  };

  dispatch.changeApp = (
    setApp: string,
    locationPrevious: string,
    setTab: string,
    tabPrevious: string,
    setItem?: number | string | false | null = null
  ) => {
    console.log(`%cDISPATCH CHANGE APP`, 'font-size: medium; color: fuchsia');
    dispatchRx({
      type: BSN_SYSTEM_CHANGE_APP,
      resource: 'system',
      payload: { ...state.system, location: setApp, locationPrevious }
    });
    dispatch.changeTab(setApp, setTab, tabPrevious, setItem);
  };

  dispatch.changeTab = (
    setApp: string,
    setTab: string,
    tabPrevious: string,
    setItem?: number | string | false | null = null,
    mutation?: boolean = false,
    activeTabType?: string = ''
  ) => {
    dispatch.set('system', 'tableFilter', {});
    dispatch.set('system', 'tableRowSelected', []);
    dispatch.set('system', 'tableRowSelectedList', []);
    dispatch.set('system', 'tableRowUnselected', []);
    dispatch.set('system', 'tableRowSelectedCount', 0);
    dispatch.set('system', 'activeTabType', activeTabType);
    console.log(`%cDISPATCH CHANGE TAB`, 'font-size: medium; color: lime');
    const previousTab = tabPrevious || state.system.tabCurrent || 'dashboard';
    const current = setTab || getActiveTab(tab);

    if (typeof current !== 'undefined') {
      dispatchRx({
        type: BSN_SYSTEM_CHANGE_TAB,
        resource: 'system',
        payload: { ...state.system, tabCurrent: '', tabPrevious: previousTab, setItem }
      });

      dispatchRx({
        type: 'BSN_CLEAR_CONFIG_MODAL',
        resource: 'modals'
      });
    }
  };

  dispatch.update = (setApp?: string, setTab?: string, setItem?: string, setData: any): Promise<any> => {
    const updateTab = setTab || tab;
    if (state[app][updateTab] === null) return Promise.reject();
    const updateApp = setApp || app;
    const updateItem = setItem || item;
    const hasItem = validateVariable(updateItem);
    const newTab = hasItem && updateItem !== null ? `${updateTab}/${updateItem}` : updateTab;
    const newState = hasItem ? state[updateApp][updateTab][updateItem] : state[updateApp][updateTab];

    return dataProvider.update(app, newTab, setData || newState);
  };

  dispatch.setLoading = (type, value) => {
    dispatch.set('system', type, value);
  };

  dispatch.resetPhishingItem = (id, items) => {
    const thisApp = 'clients';
    dataProvider.post(thisApp, 'phishingResetCampaign', id, {
      ids: items
    });

    dispatch.setItem(
      thisApp,
      'phishingReportsRecipients',
      id,
      state.clients.phishingReportsRecipients[id].map(v => (items.includes(v.id) ? { ...v, status: 'Email Sent' } : v))
    );
  };

  return (
    <>
      <ToastContainer
        position="bottom-left"
        newestOnTop={false}
        hideProgressBar
        closeButton={<ToastCloseButton />}
        style={{
          left: mobileView ? 0 : 75,
          bottom: 6,
          maxHeight: '80vh',
          overflow: 'hidden',
          overflowY: 'auto'
        }}
      />
      <AppContext.Provider
        value={{
          dispatch,
          state,
          tempState: { location: app, tab, item, theme },
          app,
          tab,
          item,
          loading,
          setLoading,
          theme
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
};

export const useApp = () => useContext(AppContext);
