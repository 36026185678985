// @flow
import React from 'react';
import { makeStyles, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  thumbnailLink: {
    textDecoration: 'inherit',
    color: 'inherit',
    border: '1px solid var(--borderBase)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    paddingRight: props => props.allowDelete ? '30px' : '',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px'
    }
  },
  thumbnailImageWrap: {
    width: '100px',
    minWidth: '100px',
    marginRight: '20px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '80px',
      minWidth: '80px',
      marginRight: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      minWidth: '60px',
      marginRight: '10px'
    }
  },
  image: {
    maxWidth: '100%',
    display: 'inline-block',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  closeBtn: {
    position: 'absolute',
    right: 1,
    top: 1,
    backgroundColor: 'var(--backgroundDefault) !important',
    zIndex: 1
  },
}));

const Thumbnail = ({ data, deleteThumbnail, allowDelete }) => {
  const classes = useStyles({ allowDelete });

  return (
    <Box position='relative'>
      <a className={classes.thumbnailLink} target="_blank" href={data.url}>
        <div className={classes.thumbnailImageWrap}>
          <img
            className={classes.image}
            src={data.image ? (data.image[0] === '/' ? data.url + data.image : data.image) : '/media/broken.png'}
            onError={e => {
              e.target.onerror = null;
              e.target.src = '/media/broken.png';
            }}
          />
        </div>
        <div>
          <Typography variant="button" component="p" mb={1.25}>
            {data.title}
          </Typography>
          <Typography variant="body2" >{data.description}</Typography>
        </div>
      </a>
      {allowDelete && (
        <IconButton
          className={classes.closeBtn}
          onClick={deleteThumbnail}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default Thumbnail;