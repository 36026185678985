import React, { useMemo } from 'react';
import { ContentAdminFormGrid, ContentAdminFormRow } from '../../style';
import {
  RHFTextField as TextField,
  RHFDynamicForm as DynamicForm,
} from 'components';
import { Button, CircleAddIcon } from '@trustsecurenow/components-library';
import { useContentAdminFormContext } from '../../context';
import { Typography } from '@trustsecurenow/components-library';

const SendingProfileForm = () => {

  const dynamicFormAddButton = (
    <Button color="info" startIcon={<CircleAddIcon />} sx={{ width: 285 }}>
      Add Custom Header
    </Button>
  );

  return (
    <>
      <ContentAdminFormRow>
        <ContentAdminFormGrid md={8} mb={2.5}>
          <TextField
            name="host"
            label="Host"
            fullWidth
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={8} mb={2.5}>
          <TextField
            type={"number"}
            name="port"
            label="Port"
            fullWidth
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={8} mb={2.5}>
          <TextField
            name="user"
            label="User"
            fullWidth
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={8}>
          <TextField
            type={"password"}
            name="password"
            label="Password"
            fullWidth
            required
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>
      <ContentAdminFormRow>
        <Typography variant='h4' mb = { 3.75 }>
          Email Headers:
        </Typography>

        <DynamicForm
          name={"smtp_headers"}
          addButton={dynamicFormAddButton}
        >
          <TextField
            name="header_name"
            label="X-Custom-Header"
            fullWidth
            required
            gridSize={{
              md: 6,
              sm: 12
            }}
          />
          <TextField
            name="header_value"
            label="{{.URL.}}-gophish"
            fullWidth
            required
            gridSize={{
              md: 6,
              sm: 12
            }}
          />
        </DynamicForm>

      </ContentAdminFormRow>
    </>
  )
}

export default SendingProfileForm
