import React, { useRef, useState } from 'react';
import Controller from './Controller';
import Cropper from 'react-cropper';
import { Box, Button, IconButton } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { throttle } from 'lodash';
// import { LazyIcon } from '../icons';
import { Container, LazyIcon } from 'components';
import type { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { enqueueAlertSnackbar, Typography } from '@trustsecurenow/components-library';

const MAX_IMG_SIZE = 5120000; // 5MB

export const DragZone: ComponentType<*> = styled(Box)`
  border: calc(var(--borderSize) * 2) dashed var(--borderDefault);
  border-radius: calc(var(--borderSize) * 10);
  cursor: pointer;
  width: 100%;
  min-width: 140px;
  ${({ error }) =>
    error &&
    css`
      border-color: var(--colorSystemDanger);
    `}
`;

export const BoxContainer: ComponentType<*> = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const BoxImage: ComponentType<*> = styled(Box)`
  overflow: hidden;
  & > img {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    max-width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`;

export const BoxDescription: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  h6,
  p {
    margin: 0;
    padding-bottom: 8px;
  }
`;

export const Figure: ComponentType<*> = styled.figure`
  margin: 0;
  & > img {
    max-height: 100px;
    max-width: 200px;
    width: auto;
    height: auto;
  }
`;

export const AttachmentInfo: ComponentType<*> = styled.span`
  color: var(--colorDefault);
  padding-left: var(--spacing);
`;

const dataURLtoFile = async (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];

  const res = await fetch(dataUrl);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mime });
};

const DragDropLogoWrapper = ({ name, logo, label, error, helperText, fileName, onChange, setValue }) => {
  const [cropper, setCropper] = useState({});
  const [preview, setPreview] = useState(false);
  const [f, setF] = useState([{ preview: null, name: fileName }]);
  const crop = useRef();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    disabled: (preview && f[0].preview !== null) || logo,
    onDrop: acceptedFiles => {
      const newFile = acceptedFiles[0];
      const fileSize = newFile?.size;
      if (!newFile) {
        return enqueueAlertSnackbar(`This type of file is not allowed. Please use only .png, .jpg, .jpeg files`,
          { props: { severity: 'error', sx: { backgroundColor: '#D32F2F' }}}
        );
      }
      if (fileSize > MAX_IMG_SIZE) {
        return enqueueAlertSnackbar(`This image exceeds the 5MB maximum image size. Please resize the image and try again`,
          { props: { severity: 'error', sx: { backgroundColor: '#D32F2F' }}}
        );
      }
      setF(
        acceptedFiles.map(file => {
          setPreview(true);
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
        })
      );
    }
  });

  const onClick = async () => {
    const canvas = crop.current.getCroppedCanvas({
      width: 200,
      height: 100,
    });

    const cropResult = canvas.toDataURL()

    const file = await dataURLtoFile(cropResult, 'cropped.jpg');

    if (onChange) {
      onChange({
        name,
        value: cropResult,
        file
      });
    } else {
      setValue && setValue(name);
    }

    setPreview(false);
  };

  const getLogoFileName = logoData => {
    if (logoData) {
      return logoData.substring(logoData.lastIndexOf('/') + 1).split('?')[0];
    }
  };

  return (
    <Container.Grid direction="column">
      {label && (
        <Typography variant = 'subtitle2'  color = { error && "error.main" } mb={2.5} >{label}</Typography>
      )}
      <DragZone error={error} display="flex" alignItems="center" p={3} {...getRootProps()}>
        {preview && f[0].preview !== null ? (
          f.map(file => (
            <BoxContainer key={file.name}>
              <BoxImage>
                <Cropper
                  ref={crop}
                  style={{ maxHeight: '300px', maxWidth: '400px', margin: '0 auto' }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  src={file.preview}
                  viewMode={2}
                  background={false}
                  dragMode="move"
                  responsive={true}
                  autoCropArea={0.8}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                  crop={throttle(setCropper, 500)}
                  value={cropper}
                />
                <Button color="primary" onClick={onClick}>
                  Crop and Save
                </Button>
              </BoxImage>
            </BoxContainer>
          ))
        ) : (
          <>
            {logo ? (
              <Figure>
                <img src={logo} alt={label} />
              </Figure>
            ) : (
              <>
                <input {...getInputProps()} />
                <LazyIcon
                  component="DragDrop"
                  mr={3}
                  size={2.3}
                  color={error ? 'colorSystemDanger' : 'colorSystemInfo'}
                />
                <BoxDescription>
                  <Typography color = { !error ? "info.main" :  "error.main" }>Update Logo</Typography>
                  <Typography color = { error && "error.main" } >
                    Drag drop here or{" "}
                    <Typography variant='subtitle2' component='span' textDecoration='underline'>
                      Browse
                    </Typography>
                  </Typography>
                </BoxDescription>
              </>
            )}
          </>
        )}
      </DragZone>
      {logo ? (
        <Container.Grid container>
          <Container.Grid item justify="space-between" direction="row" alignItems="center" pt={2}>
            <Typography variant = 'body2' mr={2}>
              <Typography variant='button' color = { "info.main" }>
                Current attachment:
              </Typography>
              <Typography variant='link2' pl={1} textDecoration = 'underline'>
                {f[0].name || getLogoFileName()}
              </Typography>
            </Typography>
            <IconButton onClick={() => onChange({ name, value: null })}>
              <LazyIcon component="Delete" color="colorDefault" />
            </IconButton>
          </Container.Grid>
        </Container.Grid>
      ) : (
        <>
          {error ? (
            <Typography my = {1} mb = {1.75} color = { "error.main" } >{helperText}</Typography>
          ) : (
            <>
              <Typography  mt={1} variant='caption'>
                No larger than 5MB <br />
                Logo is cropped to 200px x 100px
              </Typography>
              <Typography variant='caption'>
                Only .png, .jpg, .jpeg files will be accepted / Transparent or white background
              </Typography>
            </>
          )}
        </>
      )}
    </Container.Grid>
  );
};

const DragDropLogo = ({ onChange, rules, required, name, value, ...props }) => {
  let formContext = {};

  // when we use DragDropLogo outside of Form component useFormContext give null
  const formContextData = useFormContext();
  if (formContextData) formContext = formContextData;

  const { setValue, watch } = formContext;

  if (!formContextData) {
    const logo = value?.preview;
    const fileName = value?.fileName;
    return <DragDropLogoWrapper {...props} logo={logo} fileName={fileName} onChange={onChange} name={name} />;
  }

  const dragDropLogoValue = watch(name);

  const logo = dragDropLogoValue?.preview;
  const fileName = dragDropLogoValue?.fileName;

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => {
        const isError = error && !(dragDropLogoValue && dragDropLogoValue.file);
        return (
          <DragDropLogoWrapper
            {...props}
            logo={logo}
            fileName={fileName}
            error={isError}
            helperText={error?.message}
            onChange={onChange}
            name={name}
            setValue={setValue}
          />
        );
      }}
    />
  );
};

export default DragDropLogo;
