import React, { useEffect, useState } from 'react';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { Container, LoadingStyled } from 'components';
import { admin } from 'helpers';
import PartnerBillingReport from '../../../partner/PartnerBillingReport';
import { Box } from '@trustsecurenow/components-library';

const PartnersBillingReport = ({ partnerId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    
    admin.partners
      .getBilling(partnerId)
      .then((resp) => setData(resp.data))
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Failed to get Billing Information', {
          props: { severity: 'error' }
        });
      })
      .finally(() => setLoading(false));
  }, [partnerId]);
  
  if (loading) return <Box width={'100%'}><LoadingStyled /></Box>;

  return (
    <Container.Grid md={12}>
      <PartnerBillingReport record={data} partnerId={partnerId} />
    </Container.Grid>
  )
}

export default PartnersBillingReport;