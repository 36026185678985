// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeStyles,
  IconButton,
  Drawer,
  Divider,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { CustomTooltip } from 'components';
import { Button, FilterIcon } from '@trustsecurenow/components-library';
import ToastCloseButton from '../notifications/ToastCloseButton';
import { setFeedFilter } from 'helpers/action';
import { FilterDate } from './index';
import { FILTER_OPTIONS } from '../../constants';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  filterWrap: {
    position: 'relative',
    // height: '100%',
    display: 'inline-block'
    // '& [class*=ContainerButton]': {
    //   height: '100%'
    // }
  },
  closeBtnWrap: {
    position: 'absolute',
    right: -6,
    top: -6,
    zIndex: 1
  },
  closeBtn: {
    backgroundColor: 'var(--colorGreyLight2) !important',
    color: 'var(--colorCommonWhite)'
  },
  drawer: {
    width: 550,
    height: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      maxWidth: 550
    }
  },
  drawerHeader: {
    fontSize: '18px',
    fontWeight: '700',
    padding: '15px 20px'
  },
  closeDrawer: {
    float: 'right',
    cursor: 'pointer'
  },
  filterContent: {
    padding: '20px 20px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    },
    '& [class*=MuiFormControlLabel-label]': {
      userSelect: 'none',
      fontSize: 14
    }
  },
  filterfooter: {
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  },
  checkbox: {
    '& svg': {
      fontSize: 24
    }
  }
}));

const Filter = ({ isFilterOpened, toggleFilterDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const filter = useSelector(state => state.newsfeed.filter);
  const [postFilter, setPostFilter] = useState(filter);
  const hasFilter = filter && Object.keys(filter).length > 0;

  const onOpenFilters = () => {
    toggleFilterDrawer(true);
    setPostFilter(filter);
  };

  const onCloseFilters = () => {
    toggleFilterDrawer(false);
  };

  const onFilterChange = (name, value) => {
    let newFilter = { ...postFilter };
    if (value) newFilter = { ...newFilter, [name]: value };
    else delete newFilter[name];
    setPostFilter({ ...newFilter });
  };

  const filterFeeds = () => {
    dispatch(setFeedFilter(postFilter));
    onCloseFilters();
  };

  const clearFilter = () => {
    setPostFilter({});
    dispatch(setFeedFilter({}));
    onCloseFilters();
  };

  return (
    <div className={classes.filterWrap}>
      <Button size="large" onClick={onOpenFilters}>
        <FilterIcon sx={{ fontSize: '2.2rem' }} />
      </Button>
      {hasFilter && (
        <div className={classes.closeBtnWrap}>
          <CustomTooltip title="Clear Filter" placement="top">
            <IconButton size="small" className={classes.closeBtn} onClick={clearFilter}>
              <CloseIcon />
            </IconButton>
          </CustomTooltip>
        </div>
      )}

      <Drawer
        anchor="right"
        open={isFilterOpened}
        onClose={onCloseFilters}
        PaperProps={{
          classes: { root: classes.drawer }
        }}
      >
        <div role="presentation">
          <div className={classes.drawerHeader}>
            Advanced Filter
            <span onClick={onCloseFilters} className={classes.closeDrawer}>
              <ToastCloseButton />
            </span>
          </div>
          <Divider />

          <div>
            <div className={classes.filterContent}>
              <Typography variant='subtitle2' color='info.main' mb={1.25}>Content</Typography>
              {FILTER_OPTIONS.map(item => {
                const { label, value } = item;
                return (
                  <div key={value}>
                    <FormControlLabel
                      label={label}
                      control={
                        <Checkbox
                          value={postFilter?.[value] || false}
                          checked={postFilter?.[value] || false}
                          onChange={e => onFilterChange(e.target.name, e.target.checked)}
                          name={value}
                          color="primary"
                          className={classes.checkbox}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className={classes.filterContent}>
              <Typography variant='subtitle2' color='info.main' mb={1.25}>Dates</Typography>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Typography
                variant='body2'
                sx={{
                  [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    mb: 1.25
                  },
                }}
                mr={1.25}>
                  Post published from
                </Typography>
                <FilterDate
                  name="published_from"
                  postFilter={postFilter}
                  onFilterChange={onFilterChange}
                  maxDate={postFilter.published_to}
                />
                <Box component="span" mx={{ xs: 1, md: 2 }}>
                  to
                </Box>
                <FilterDate
                  name="published_to"
                  postFilter={postFilter}
                  onFilterChange={onFilterChange}
                  minDate={postFilter.published_from}
                />
              </Box>
            </div>

            <Divider />
            <div className={classes.filterfooter}>
              <Button onClick={filterFeeds} fullWidth>
                Filter Now
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Filter;
