// @flow
import React, { type Element, memo, type ComponentType } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { RadioButtonUnchecked as RadioButtonUncheckedIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { Button } from '@trustsecurenow/components-library';
import Table from '../types/Table';
import Container from '../types/Containers';
import { Typography } from '@trustsecurenow/components-library';


type DashboardToDoListTypes = {
  record: Object
};
export const TableResponsive: ComponentType<*> = styled.div`
  width: 100%;
  @media screen and (min-width: 1280px) and (max-width: 1584px) {
    overflow-x: auto;
  }
`;
const TableHeadContent: ComponentType<*> = styled(TableHead)`
  background: var(--backgroundTableHeadDark);
`;
const TableHeadCell: ComponentType<*> = styled(TableCell)`
  && {
    border-bottom: none;
    font-weight: var(--fontWeightRegular);
    font-size: var(--fontSize);
    color: var(--colorCommonWhite);
  }
  &:nth-child(1) {
    border-left: var(--borderSize) solid var(--borderDefault);
    border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
  }
  &:nth-last-child(1) {
    border-right: var(--borderSize) solid var(--borderDefault);
    border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
  }
  @media screen and (min-width: 1616px) {
    &:nth-child(2) {
      border-left: var(--borderSize) solid var(--borderDefault);
      border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    }
    &:nth-last-child(2) {
      border-right: var(--borderSize) solid var(--borderDefault);
      border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
    }
  }
`;
const TableBodyCell: ComponentType<*> = styled(TableCell)`
  && {
    font-size: var(--fontSize);
    color: ${({ active }) => (active ? `var(--colorSystemSuccess)` : `var(--colorDefault)`)};
    ${({ active }) => active && `color: var(--colorSystemSuccess)`};
    border-bottom: none;
    padding: calc(var(--spacing) / 2) calc(var(--spacing) * 2);
    &:nth-child(1) {
      vertical-align: bottom;
      padding-left: 0;
    }
    &:nth-last-child(1) {
      padding-right: 0;
    }
  }
  > svg {
    font-size: calc(var(--fontSize) * 1.2);
  }
`;
const CheckCircleIconGreen: ComponentType<*> = styled(CheckCircleIcon)`
  color: var(--colorSystemSuccess);
`;
const DashboardToDoList = ({ record }: DashboardToDoListTypes): Element<*> => {
  return (
    <>
      <Typography variant = "button">{record.title}</Typography>
      <Container.Paper>
        <TableResponsive>
          <Table.Container size="small" mt={1} radius={2}>
            <TableHeadContent>
              <TableRow>
                <TableHeadCell align="center">To Do</TableHeadCell>
                <TableHeadCell>Tasks</TableHeadCell>
                <TableHeadCell align="right">Status</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHeadContent>
            <TableBody>
              {record.data.map(row => (
                <TableRow key={row.id}>
                  <TableBodyCell align="center">
                    {row.completed ? <CheckCircleIconGreen /> : <RadioButtonUncheckedIcon />}
                  </TableBodyCell>
                  <TableBodyCell>{row.action}</TableBodyCell>
                  <TableBodyCell align="right" active={!record.todo}>
                    {row.status}
                  </TableBodyCell>
                  <TableBodyCell align="right">
                    <Button variant="outlined" to={`/${row.link}`} component={Link}>
                      Go
                    </Button>
                  </TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table.Container>
        </TableResponsive>
      </Container.Paper>
    </>
  );
};
export default memo<DashboardToDoListTypes>(DashboardToDoList);
