import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Paper, styled, Stack, Typography, Link } from '@trustsecurenow/components-library';
import { Link as RouterLink } from 'react-router-dom';

const FormContainer = styled(Paper)(({ theme }) => ({
    borderTop: `5px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(10),
    padding: theme.spacing(12, 2, 8.5, 2)
}));

const ForgetPasswordForm = ({ email, onChangeEmail, onSubmit, loading }) => {
    return (
        <FormContainer elevation={0}>
            <Stack margin="auto" spacing={5.75} maxWidth={350}>
                <Stack spacing={4.75}>
                    <Typography variant="h2" color="primary" textAlign="start">
                        Reset Password
                    </Typography>
                    <form onSubmit={onSubmit} noValidate>
                        <Stack spacing={4.5}>
                            <TextField
                                required
                                autoFocus
                                value={email}
                                type="email"
                                label="Your Email"
                                disabled={loading}
                                inputMode="email"
                                onChange={onChangeEmail}
                                fullWidth
                                size="large"
                            />

                            <Button type="submit" size="large" loading={loading} fullWidth>
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </Stack>
                <Link textAlign="center" component={RouterLink} to="login" variant="link2">
                    &lt; Back to login
                </Link>
            </Stack>
        </FormContainer>
    );
};

ForgetPasswordForm.propTypes = {
    email: PropTypes.string.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default ForgetPasswordForm;
