// @flow
import React, { type ComponentType, type Element, useState, useRef } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { TextField } from 'components';
import { Button, CheckIcon, CloseIcon, Link, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { dataProvider } from 'helpers';
import { useLocation, useFormData } from 'hooks';
import { useTableList } from './TableListContext';
import { useDispatch } from 'react-redux';
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction';

type UpdatingCellTypes = {
  cellId: string,
  cellValue: string,
  cellConfig: {
    app?: string,
    tab?: string,
    field: string,
    id?: boolean
  }
};

const TagFormData: ComponentType<*> = styled.form`
  display: flex;
`;

const UpdatingCell = ({ cellId, cellValue, cellConfig }: UpdatingCellTypes): Element<*> => {
  const { app: appLocation, tab: tabLocation, item } = useLocation();
  const { dispatch: dispatchTable } = useTableList();
  const { setFormData } = useFormData();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: ''
  });
  const formRef = useRef(null);
  const dispatchRx = useDispatch();

  const onKeyDown = e => {
    if (e.keyCode === 13) onSubmit(e);
  }

  const onSubmit = e => {
    e.preventDefault();

    const fData = setFormData(formRef?.current);
    const { field } = cellConfig;

    if (!fData?.[field]?.trim()) {
      setError({
        ...error,
        show: true,
        message: 'Please fill out this field.'
      });
    } else {
      setError({
        ...error,
        show: false,
        message: ''
      });

      const { app: appConfig, tab: tabConfig, id } = cellConfig;
      const app = appConfig || appLocation;
      const tab = tabConfig || tabLocation;
      const setId = id ? cellId : item;
      const data = {
        [field]: fData?.[field]
      };

      dataProvider.update(app, `${tab}/${setId}`, data)
      .then(res => {
        enqueueAlertSnackbar(res.data, {
          props: {
            severity: "success"
          }
        });
      })
      .catch(error => {
        enqueueAlertSnackbar(error?.response?.data?.description, {
          props: {
            severity: 'error'
          }
        })
      })
      .finally(() => {
        setEdit(false);
        dispatchTable.setRefresh(true);
        setTimeout(() => {
          dispatchTable.setRefresh(false);
        }, 1000);
        dispatchRx(resetCreateUserMatchingEmailError());
      })
    }
  };

  const onCancel = () => {
    setEdit(false);
    setError({
      ...error,
      show: false,
      message: ''
    });
  };

  return (
    <>
      {!edit ? (
        <Tooltip title="Click to edit" placement="right">
          <Link component="button" underline="none" onClick={() => setEdit(true)}>
            {cellValue}
          </Link>
        </Tooltip>
      ) : (
        <TagFormData onSubmit={e => e.preventDefault()} ref={formRef} style={{width:'350px'}}>
          <TextField
            value={cellValue}
            name={cellConfig.field}
            size="small"
            error={error.show}
            helperText={error.show && error.message}
            inputProps={{ maxlength: 25 }}
            onKeyDown={onKeyDown}
          />
          <Button
            onClick={e => onSubmit(e)}
            color="success"
            sx={{ ml: 2, mt: 1, mb: error.show ? 3.5 : 1 }}
            startIcon={<CheckIcon />}
          >
            Save
          </Button>
          <Button
            onClick={onCancel}
            color="error"
            sx={{ ml: 2, mt: 1, mb: error.show ? 3.5 : 1 }}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </TagFormData>
      )}
    </>
  );
};

export default UpdatingCell;
