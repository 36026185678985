import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DialogModal from './DialogModal';

const ConfirmLeavePageModal = () => {
  const history = useHistory();
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: ''
  });
  const [isVisibleDialog, setVisibleDialog] = useState(false);

  const handleGoToIntendedPage = useCallback(location => history.push(location), [history]);

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block(location => {
      if (location.pathname !== history.location.pathname) {
        setVisibleDialog(true);
      }
      setTriggerExit(obj => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [handleGoToIntendedPage, history, triggerExit.onOk, triggerExit.path]);

  return (
    <DialogModal
      title="Leave without saving?"
      message="Are you sure you want to leave the page without saving? 
                      Any unsaved information will be deleted"
      buttonSubmit="Leave Page"
      open={isVisibleDialog}
      handleClose={() => setVisibleDialog(false)}
      onSubmit={() => {
        setTriggerExit(obj => ({
          ...obj,
          onOk: true
        }));
        setVisibleDialog(false);
      }}
    />
  );
};

export default ConfirmLeavePageModal;
