import styled from 'styled-components';
import { Typography } from 'components';
import { 
  ExpansionPanel,
  ExpansionPanelSummary,
  Chip as MuiChip,
  Popper,
  Stepper as MuiStepper,
  StepConnector as MuiStepConnetor 
} from '@material-ui/core';

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableCellText = styled(Typography.p)`
  font-size: 14px;
  line-height: 25.2px;
  font-weight: 400;
  color: var(--colorDefault);
  margin: 0;
`;

export const ActionsContainer = styled.div`
  width: 176px;
`;

export const ContainerMenuActions = styled(Popper)`
  && {
    z-index: 1;
  }
`;

// Accordion styles
export const AccordionSummary = styled(ExpansionPanelSummary)`
  && {
    border-radius: var(--borderRadius);
    
    &.Mui-expanded > div > p {
      color: var(--colorSystemInfo);
      font-weight: 500;
    }
  }
  
  > div:first-child {
    margin: 0 0;
    color: var(--colorDefault);
  }
`;

export const Accordion = styled(ExpansionPanel)`
  margin: 6.77px 0;
  && {
    border-radius: var(--borderRadius);
    &:before {
      background: none;
    }
  }
`;

export const SimilarPartnersAccordion = styled(ExpansionPanel)`
  margin: 6.77px 0;
  && {
    border-radius: var(--borderRadius);
    
    & > div:first-child {
      border: 1px solid var(--colorSystemWarning);
      border-left-width: 4px; 
      background: rgba(255, 164, 87, 0.1);
      
      &.Mui-expanded > div > p {
        color: var(--colorSystemWarning)!important;
      }
    }
    
    &:before {
      background: rgba(255, 164, 87, 0.1);
    }
  }
`;

export const Chip = styled(MuiChip)`
  && {
    height: auto;
    border-color: ${({ color }) => `var(${color})`};
    background-color: ${({ variant, color }) => (variant === 'filled' ? `var(${color})` : '#fff')};
    border-radius: ${({ borderRadius }) => borderRadius ? `${borderRadius}px` : '16px'};
    span {
      padding: 2px 13px 2px 13px;
      font-size: 14px;
      color: ${({ color, variant }) => variant === 'outlined' ? `var(${color})` : '#fff'};
    }
  }
`;


export const Stepper = styled(MuiStepper)`
  && {
    background: transparent;
    width: 100%;
  }
`;
export const StepIconStyled = styled('div')`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border-color: ${({ active, completed }) => ((completed && 'var(--colorSystemSuccess)') || (active && 'var(--colorSystemInfo)')) || 'border-color: none'};
  border-width: ${({ active, completed }) => active || completed ? '1px' : 0};
  border-style:  ${({ active, completed }) => active || completed ? 'solid' : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CompleteStepIcon = styled('div')`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgba(12, 206, 107, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CurrentStepIcon = styled('div')`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  background-color: ${({ active }) => active ? 'rgba(25, 148, 230, 0.2)' : '#DBDDDF'};
  color: ${({ active }) => active ? 'var(--colorSystemInfo)' : 'var(--colorLight)'};
`;

export const StepConnector = styled(MuiStepConnetor)`
  && {
    border-color: var(--colorGreyLight2);
    margin-top: 8px;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

