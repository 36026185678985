const generateSortField = (name, direction) => ({ name, direction: direction === 'asc' ? 'ascending' : 'descending' });

const checkValueJson = value => typeof value === 'string' && value.startsWith('JSON:');
const getJsonFromValue = value => JSON.parse(value.split('JSON:')[1]);

const generateFilterField = (field, operator, value) => {
    if (checkValueJson(value)) {
    return { field, ...getJsonFromValue(value) };
  }
  const filter = { field };
  if (operator === 'is') {
    if (['ISNULL', 'ISNOTNULL'].includes(value)) filter.operator = value;
    else if (value === 'OVERDUE') {
      filter.operator = 'LESSTHAN';
      filter.value = new Date().toISOString();
    } else {
      filter.operator = 'EQUAL';
      filter.value = value;
    }
  } else if (operator === 'isAnyOf') {
    const isBetween = value.every(option => Array.isArray(option));
    filter.operator = isBetween ? 'BETWEEN' : 'IN';
    filter.value = isBetween ? value : value.map(option => option.trim());
  } else if (operator === '=') {
    filter.operator = 'EQUAL';
    filter.value = value;
  }
  return filter;
};

const generateApiTableParams = ({ pagination = null, sorting = [], search = {}, filter = null }) => {
  const params = {};

  if (pagination) params.limit = { skip: pagination.page * pagination.pageSize, take: pagination.pageSize };

  if (sorting.length) params.sorted = sorting.map(({ sort, field }) => generateSortField(field, sort));

  if (search.value) params.search = { fields: search.items, operator: 'CONTAINS', key: search.value };

  if (filter?.items.length) {
    params.where = {
      predicates: filter.items.map(item =>
        generateFilterField(filter.fields?.[item.field] || item.field, item.operator, item.value)
      )
    };
  }

  return params;
};

export default generateApiTableParams;
