import React from "react"
import { makeStyles } from '@material-ui/core';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 22
    }
  }
}));

const QuestionFeedback = ({ isCorrect, feedback }) => {
  const classes = useStyles({ isCorrect });
  const [firstWord, ...restSentence] = feedback.split(' ');

  return (
    <div className={classes.wrapper}>
      <Typography 
      color={isCorrect? 'success.main' : 'error.main'}
      >
        <Typography component='span' pr={0.75}>
          {firstWord}
        </Typography>
        {restSentence.join(' ')}
      </Typography>
    </div>
  )
}

export default React.memo(QuestionFeedback)
