// @flow
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import styled from 'styled-components';
import { IconButton, Popover } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import { trackingUtils } from 'helpers';
import { Typography, InfoIcon, CloseIcon } from '@trustsecurenow/components-library';
import { ContainerHeaderBox } from './ComponentTypes';
import { useDashboard } from './DashboardContext';

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? 'initial' : 'calc(var(--spacing) * 50)')};
  padding: calc(var(--spacing) * 2.5);
  white-space: pre-wrap;

  ${props => props.theme.breakpoints.down('sm')} {
    box-sizing: border-box;
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? 'initial' : 'calc(var(--spacing) * 50)')};
  }
`;

const PopoverContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(var(--spacing) * 0.5);
`;

const ContainerIcon = styled.div`
  margin-left: ${({ iconMl }) =>
    iconMl || iconMl === 0 ? `calc(var(--spacing) * ${iconMl})` : 'calc(var(--spacing) * 5)'};
  order: 1;
  display: flex;
  align-items: center;

  button {
    padding: ${({ iconPadding }) => iconPadding || '12px'};
  }
`;

const DashboardBoxTitle = ({
  label,
  title,
  description,
  componentDescription,
  fontSize = 13,
  icon = false,
  iconSize = 0,
  iconDescription,
  iconMl,
  pt = 0,
  pl = 0,
  pr = 0,
  justify,
  mbbox,
  fullWidth,
  hideTitle,
  height,
  iconPadding
}) => {
  const { dispatch } = useDashboard();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-dashboard' : undefined;

  const mobileView = useBreakpoint('sm');

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    dispatch.setBox({
      open: true,
      box: label
    });
    trackingUtils.clickButton(label);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch.setBox({
      open: false,
      box: ''
    });
  };

  useEffect(() => {
    if (!open) {
      dispatch.setBox({
        open: false,
        box: ''
      });
    }
  }, [open]);

  return (
    <>
      <ContainerHeaderBox mbbox={mbbox} pt={pt} pl={pl} pr={pr} justify={justify} height={height}>
        {!hideTitle && (
          // eslint-disable-next-line react/jsx-pascal-case
          <Typography variant="button">{title ?? label}</Typography>
        )}
        {icon && (
          <ContainerIcon iconMl={iconMl} iconPadding={iconPadding}>
            {iconDescription && iconDescription}
            <IconButton aria-describedby={id} onClick={handleClick}>
              <InfoIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </ContainerIcon>
        )}
      </ContainerHeaderBox>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={e => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            maxWidth: mobileView
              ? 'calc(375px - 32px)'
              : 'calc(100% - (var(--layoutSidebarWidth) + var(--spacing) * 4.5))',
            width: mobileView ? '100%' : null
          }
        }}
      >
        <PopoverContent fullWidth={fullWidth}>
          <PopoverContentTitle>
            <Typography variant="h4" mt={0.1} mr={2}>
              {title ?? label}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </PopoverContentTitle>
          <Typography mt={0.1} mb={0.1}>
            {componentDescription ?? Parser(`${description}`)}
          </Typography>
        </PopoverContent>
      </Popover>
    </>
  );
};

DashboardBoxTitle.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  fontSize: PropTypes.number,
  icon: PropTypes.bool,
  iconSize: PropTypes.number,
  iconDescription: PropTypes.string,
  pt: PropTypes.number,
  pl: PropTypes.number,
  pr: PropTypes.number,
  justify: PropTypes.string,
  mbbox: PropTypes.bool,
  fullWidth: PropTypes.bool,
  labelColor: PropTypes.string,
  hideTitle: PropTypes.bool,
  height: PropTypes.string,
  iconPadding: PropTypes.string
};

DashboardBoxTitle.defaultProps = {
  icon: false,
  iconSize: 0,
  fontSize: 13,
  description: null,
  iconDescription: null,
  pt: 0,
  pl: 0,
  pr: 0,
  justify: 'space-between',
  mbbox: false,
  fullWidth: false,
  labelColor: 'var(--colorDefault)',
  hideTitle: false,
  height: '',
  iconPadding: ''
};

export default DashboardBoxTitle;
