import React from 'react';
import { Dialog } from 'components/forms';
import { Container, Typography } from 'components/types';
import { Button, Link } from '@trustsecurenow/components-library';

const DialogModal = ({ open, handleClose, onSubmit, title, message, buttonSubmit, buttonColor }) => {
  return (
    <Dialog open={open || false} setOpen={handleClose} maxWidth="sm" onlyOk closeButton variant="standard">
      <Container.Grid container direction="column" alignItems="center" justify="center" p={3}>
        <Container.Grid item>
          <Typography.h3 fontSize={24}>{title}</Typography.h3>
        </Container.Grid>

        <Container.Grid item alignItems="center" pb={2} pl={2} pr={2}>
          <Typography.p style={{ textAlign: 'center' }}>{message}</Typography.p>
        </Container.Grid>

        <Container.Grid item pb={3.5} pt={2}>
          <Button onClick={onSubmit} color={buttonColor} sx={{ width: 170 }}>
            {buttonSubmit}
          </Button>
        </Container.Grid>

        <Link component="button" onClick={handleClose}>
          Cancel
        </Link>
      </Container.Grid>
    </Dialog>
  );
};

export default DialogModal;
