import styled, { css } from 'styled-components';
import { Card, FormControlLabel } from '@material-ui/core';
import { Container, Typography } from 'components';

export const ContentAdminFormLayoutContainer = styled(Card)`
  padding: 0;
`;

export const ContentAdminFormTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--colorDefault);
`;

export const ContentAdminFormLayoutHeaderContainer = styled(ContentAdminFormTitle)`
  padding: 20px 30px;
  border-bottom: 1px solid var(--borderDefault);
`;

export const ContentAdminFormLayoutFooterContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentAdminFormLayoutFooterContainerWithCard = styled(Card)`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentAdminFormButtonsContainer = styled(Container.Grid)`
  && {
    margin-left: auto;
    align-self: flex-start;
    padding: 4px 0;
  }
`;

export const ContentAdminFormRowWithCard = styled(Card)`
  margin-bottom: 20px;
`;

export const ContentAdminFormRow = styled(Container.Grid)`
  padding: 30px 30px 0;
  &:not(:first-of-type) {
    padding: calc(30px - var(--spacing)) 30px 0;
  }
  ${({ withoutBorder }) =>
    !withoutBorder &&
    css`
      border-bottom: 1px solid var(--borderDefault);
      padding: 30px !important;
    `};

  ${({ withoutPadding }) =>
    withoutPadding &&
    css`
      padding: 0 !important;
    `}
`;

export const ContentAdminFormGrid = styled(Container.Grid)`
  padding-right: 30px;
  align-items: center;

  ${({ withoutPadding }) =>
    withoutPadding &&
    css`
      padding: 0 !important;
    `}
`;

export const SubTitle = styled(Typography.h3)`
  margin-top: 0;
  margin-bottom: 30px;
`;

export const ContentAdminCheckbox = styled(FormControlLabel)`
  && {
    &.capture-checkbox {
      span:last-of-type {
        white-space: nowrap;
      }
    }
  }
`;

export const LandingPagesModalContainer = styled.div`
  padding: calc(var(--spacing) * 4) calc(var(--spacing) * 3);
  .modal-subtitle {
    margin-bottom: 15px;
  }
  .icon {
    font-size: 1.3em;
  }
`;

export const EmailPopoverContainer = styled.div``;

export const EmailPopoverTitle = styled(Typography.h3)`
  margin: 0 0 14px;
  font-weight: 400;
  font-size: 14px;
  color: #373d3f;
`;

export const EmailPopoverFromName = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 10px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--borderDefault);
`;

export const EmailPopoverFromNameIcon = styled.div`
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: var(--backgroundTableHead);
  margin-right: 8px;
`;

export const EmailPopoverFromNameTitle = styled(Typography.h3)`
  margin: 0;
  font-size: 14px;
  opacity: 0.2;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      color: var(--colorSystemInfo);
    `}
`;

export const EmailLocalPart = styled(Typography.p)`
  margin: 0;
  font-size: 14px;
  opacity: 0.2;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`;

export const EmailLocalPartTitle = styled.span`
  font-weight: 400;
  ${({ active }) =>
    active &&
    css`
      font-weight: 600;
      opacity: 1;
      color: var(--colorSystemInfo);
    `}
`;
