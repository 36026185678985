import { getStorage } from '../storage';
import apiClient from '../apiClient/apiClient';
import { bsnClientServicesBaseURL } from './bsnClientServices';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_21;
const baseURL = `https://${apiGWID}.${base}/${env}`;
const apiGWID8 = process.env.REACT_APP_BASE_URL_8;
const baseURL8 = `https://${apiGWID8}.${base}/${env}`;
const inboxURL = process.env.REACT_APP_INBOX_BASE_URL;
// const baseURL = `http://10.0.11.7:5000`;

const normalisedParams = (params = {}) => {
  const paramsCopy = JSON.parse(JSON.stringify(params));

  const newParams = {};

  Object.entries(paramsCopy).forEach(([key, value]) => {
    if (value !== 'none') {
      if (value === 'null') {
        newParams[key] = null;
      } else if (Array.isArray(value)) {
        newParams[key] = value.join(',');
      } else {
        newParams[key] = value;
      }
    }
  });

  return newParams;
};

const contentAdmin = {};

contentAdmin.getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: getStorage('idToken', true)
});

contentAdmin.getList = (path, params) => {
  const requestOptions = {
    url: `${baseURL}/${path}/`,
    params: normalisedParams(params),
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.deleteItem = (path, id) => {
  const requestOptions = {
    url: `${baseURL}/${path}/${id}/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

contentAdmin.getPickList = (path, params = null) => {
  const requestOptions = {
    url: `${baseURL}/picklist/${path}/`,
    headers: contentAdmin.getRequestHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

contentAdmin.generatePresignedUrl = (data, type) => {
  const requestOptions = {
    url: `${baseURL}/picklist/generate-presigned-url/?type=${type}`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

contentAdmin.getBindingParams = () => {
  const requestOptions = {
    url: `${baseURL}/templates/binding-params/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.parseEmailTemplate = data => {
  const requestOptions = {
    url: `${baseURL}/templates/parse-email-template/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

contentAdmin.createTemplate = (path, data) => {
  const requestOptions = {
    url: `${baseURL}/${path}/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

contentAdmin.editTemplate = (path, id, data) => {
  const requestOptions = {
    url: `${baseURL}/${path}/${id}/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

contentAdmin.updateTemplate = (path, data) => {
  const requestOptions = {
    url: `${baseURL}/${path}/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

contentAdmin.getSingleTemplate = (path, id) => {
  const requestOptions = {
    url: `${baseURL}/${path}/${id}/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.getSendingProfileTemplate = () => {
  const requestOptions = {
    url: `${baseURL}/smtp/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.sendTestEmail = () => {
  const requestOptions = {
    url: `${baseURL}/smtp/send-test-email/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

contentAdmin.readWebsite = url => {
  const requestOptions = {
    url: `${baseURL}/landing-pages/read-website/?url=${url}`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.stopCampaign = id => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${id}/stop-campaign/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

contentAdmin.createCampaign = (id, data) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${id}/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

contentAdmin.editCampaign = (clientId, data, campaignId) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${clientId}/${campaignId}/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

contentAdmin.deleteCampaign = (clientId, campaignId) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${clientId}/${campaignId}/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

contentAdmin.stopCampaign = (clientId, campaignId) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${clientId}/${campaignId}/end-campaign/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

contentAdmin.deleteLaunches = data => {
  const requestOptions = {
    url: `${baseURL}/campaigns/delete-campaign-launches/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.delete(requestOptions);
};

contentAdmin.stopLaunches = data => {
  const requestOptions = {
    url: `${baseURL}/campaigns/end-campaign-launches/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

contentAdmin.getSingleCampaign = (clientId, campaignId) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${clientId}/${campaignId}/`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.generateCampaignSummaryReport = (id, data) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${id}/generate-campaign-summary-report/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };

  return apiClient.post(requestOptions);
};

contentAdmin.getSummaryResult = launchId => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${launchId}/campaign-launches-summary/`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.getSummaryDetails = (launchId, params) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${launchId}/campaign-launches-details/`,
    headers: contentAdmin.getRequestHeaders(),
    params: normalisedParams(params)
  };

  return apiClient.get(requestOptions);
};

contentAdmin.resetClick = (launchId, data) => {
  const requestOptions = {
    url: `${baseURL}/campaigns/${launchId}/reset-click/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };

  return apiClient.post(requestOptions);
};

contentAdmin.getIpOrgName = launchId => {
  const requestOptions = {
    url: `${baseURL}/campaigns/ip-to-org/${launchId}/`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.testWhitelisting = data => {
  const requestOptions = {
    url: `${baseURL}/smtp/test-whitelisting/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };

  return apiClient.post(requestOptions);
};

contentAdmin.getWhitelistingInstructions = () => {
  const requestOptions = {
    url: `${baseURL8}/phishing/whitelisting-instructions`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.getWhitelistingAttachment = id => {
  const requestOptions = {
    url: `${baseURL8}/phishing/whitelisting-instructions/attachments/${id}`,
    headers: contentAdmin.getRequestHeaders(),
    responseType: 'blob'
  };

  return apiClient.get(requestOptions);
};

contentAdmin.getPowershellScript = () => {
  const requestOptions = {
    url: `${baseURL}/picklist/get-powershell-script/`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

// filters: { partner_name, client_name, partner_id, client_id, modified_date, sortingField, sortingDirection }
contentAdmin.getInboxEmailsList = async (start = 0, end = 25, filters) => {
  const truthyFilters = { ...filters };

  Object.keys(truthyFilters).forEach(key => {
    if (!truthyFilters[key]) {
      delete truthyFilters[key];
    }
    if (key === 'sortingField' || key === 'sortingDirection') {
      delete truthyFilters[key];
    }
  });

  const params = `filter=${encodeURIComponent(JSON.stringify(truthyFilters))}&sort=${encodeURIComponent(
    JSON.stringify({
      [filters.sortingField]: filters.sortingDirection
    })
  )}&limit=${encodeURIComponent(
    JSON.stringify({
      start,
      end
    })
  )}`;

  const requestOptions = {
    url: `${inboxURL}InboxOverview?${params}`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.getEmailContents = async ({ id }) => {
  const requestOptions = {
    url: `${inboxURL}/EmailContent?id=${id}`,
    headers: contentAdmin.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

contentAdmin.forwardEmail = async ({ email, recipients }) => {
  const requestOptions = {
    url: `${inboxURL}ResendEmail/${email}`,
    headers: contentAdmin.getRequestHeaders(),
    data: { emails: recipients }
  };
  return apiClient.post(requestOptions);
};

contentAdmin.aadAuth = data => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/dmd-auth/`,
    headers: contentAdmin.getRequestHeaders(),
    data
  };

  return apiClient.post(requestOptions);
};

contentAdmin.getDMDState = clientId => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/dmd/${clientId}/`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.deleteDMDState = clientId => {
  const requestOptions = {
    url: `${bsnClientServicesBaseURL}/clients/dmd/${clientId}/`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.delete(requestOptions);
};

contentAdmin.sendTestDMD = (clientId, data) => {
  const requestOptions = {
    url: `${baseURL}/direct-mail-delivery/test-email/${clientId}/`,
    headers: contentAdmin.getRequestHeaders(),
    data: {
      service: 'aad',
      ...data
    }
  };

  return apiClient.post(requestOptions);
};

contentAdmin.getTemplateContent = templateId => {
  const requestOptions = {
    url: `${inboxURL}/TemplateContent/${templateId}`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.getTemplateHeaderFooterContent = type => {
  const requestOptions = {
    url: `${inboxURL}/TemplateContent/HeaderFooter/${type}`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};


contentAdmin.postTemplateContent = (html_content, test_data) => {
  const requestOptions = {
    url: `${inboxURL}TemplateContent`,
    headers: contentAdmin.getRequestHeaders(),
    data: {
      html_content,
      test_data
    }
  };

  return apiClient.post(requestOptions);
};
contentAdmin.getTemplates = () => {
  const requestOptions = {
    url: `${inboxURL}NotificationTemplates`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.postTemplateSql = (template_name, html_content, test_data) => {
  const requestOptions = {
    url: `${inboxURL}TemplateSql`,
    headers: contentAdmin.getRequestHeaders(),
    data: {
      template_name: template_name ?? 'new_template',
      html_content
      // test_data
    }
  };

  return apiClient.post(requestOptions);
};

contentAdmin.getMultiCampaignPhisihingReport = campaign_id => {
  const requestOptions = {
    url: `${baseURL}/multi_campaign_phishing_report/${campaign_id}`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.getSingleCampaignPhisihingReport = campaign_id => {
  const requestOptions = {
    url: `${baseURL}/single_campaign_phishing_report/${campaign_id}`,
    headers: contentAdmin.getRequestHeaders()
  };

  return apiClient.get(requestOptions);
};

contentAdmin.testSendMail = (template_name, html_content, test_data) => {
  const requestOptions = {
    url: `${inboxURL}TestTemplate`,
    headers: contentAdmin.getRequestHeaders(),
    data: {
      ...(template_name && { template_name }),
      html_content,
      test_data
    }
  };
  return apiClient.post(requestOptions);
};

export default contentAdmin;
