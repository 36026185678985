// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { updateNotifications, deleteFeed, setHasPinnedPost } from 'helpers/action';
import { DeleteConfirmationModal } from 'components';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const DeletePostModal = ({ open, close, postId, isPinned }) => {
  const dispatch = useDispatch();

  const deletePost = () => {
    dispatch(deleteFeed(postId));
    if (isPinned) dispatch(setHasPinnedPost(false));

    newsFeed
      .deletePost(postId)
      .then(res => {
        if (res?.status && res.status === 200) dispatch(updateNotifications(true));
        enqueueAlertSnackbar(res?.data?.message, { props: { severity: 'success' } });
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      });
    close();
  };

  return (
    <DeleteConfirmationModal
      open={open}
      close={close}
      onDelete={deletePost}
      title="Delete Post?"
      description="This post will be deleted and cannot be recovered."
    />
  );
};

export default DeletePostModal;
