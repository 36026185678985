// @flow
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { 
  ArrowDown,
  Container,
  CustomTable,
  LazyIcon,
  Typography,
  TableCellField
} from 'components';
import { CancelButton as ActionButton, Chip, TableCellText } from '../../style/AdminCommon';
import PartnerTaxExemptViewModal from '../../../partner/PartnerTaxExemptViewModal';
import { useAdminList } from '../../hooks';
import { admin } from 'helpers';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography as TypographyCL,
  EyeIcon,
  Link,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';

type Props = {
  partnerId: string,
  accordionProps: {
    panelExpanded: boolean,
    handleChange: (value: string) => void
  }
}

type StatusChipProps = {
  name: string
};

const TaxExemptStatusChip = ({ name }: StatusChipProps) => {
  switch (name) {
    case 'COMPLETE':
      return <Chip label="Valid" variant="outlined" color="--colorSystemSuccess" size="small" />;
    case 'INVALID':
      return <Chip label="Invalid" variant="outlined" color="--colorSystemDanger" size="small" />;
    case 'EXPIRED':
      return <Chip label="Expired" variant="filled" color="--colorSystemDanger" size="small" />;
    default:
      return <Chip label="Pending" variant="outlined" color="--colorSystemWarning" size="small" />;
  }
};

const PartnersTaxExempt = ({ partnerId, accordionProps }: Props) => {
  const notify = useNotify();
  const { isLoading, data, dispatch, total, isSuccess, status } = useAdminList('partnersTaxExempt', partnerId);
  const [openCerfificate, setOpenCertificate] = useState(false);
  const [certificate, setCertificate] = useState(null);

  const viewCertificate = certificateId => {
    setOpenCertificate(true);
    admin.partnersTaxExempt
      .getCertificate(partnerId, certificateId)
      .then(resp => setCertificate(resp.data.url))
      .catch(err => enqueueAlertSnackbar(err?.response?.data?.description, { props: { severity: 'error' } }));
  };

  return (
    <>
      <PartnerTaxExemptViewModal
        open={openCerfificate}
        setOpen={setOpenCertificate}
        pdfData={certificate}
        setPdfData={setCertificate}
      />

      <Accordion {...accordionProps}>
        <AccordionSummary>
            <TypographyCL
              variant='subtitle1'
              color={accordionProps?.panelExpanded && 'info.main'}
            >
              Tax Exempt
            </TypographyCL>
          </AccordionSummary>
        <AccordionDetails>
          <CustomTable
            source={'admin/partnersTaxExempt'}
            total={total}
            data={data}
            loading={isLoading}
            onSort={sortValue => dispatch.setSort(sortValue)}
            onChangePage={page => dispatch.setPage(page)}
            onChangePerPage={perPage => dispatch.setPerPage(perPage)}
            onLoad={params => dispatch.onLoad(params)}
          >
            <TableCellField renderComponent={({ state }) => <TableCellText>{state}</TableCellText>} />
            <TableCellField renderComponent={({ signed_date }) => <TableCellText>{signed_date}</TableCellText>} />
            <TableCellField
              renderComponent={({ expiration_date }) => <TableCellText>{expiration_date}</TableCellText>}
            />
            <TableCellField renderComponent={({ status }) => <TaxExemptStatusChip name={status.name} />} />
            <TableCellField
              renderComponent={({ actions, id }) => (
                <Container.Grid alignItems="center">
                  {actions.view && (
                    <Link component="button" onClick={() => viewCertificate(id)} startIcon={<EyeIcon />}>
                      View
                    </Link>
                  )}
                </Container.Grid>
              )}
            />
          </CustomTable>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PartnersTaxExempt;
