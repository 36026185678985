// @flow
import UserList from './UserList';

export { default as UserLogin } from './UserLogin';
export { default as UserLogout } from './UserLogout';
export { default as UserProfile } from './UserProfile';
export { default as UserContactUs } from './UserContactUs';
export { default as UserFederated } from './UserFederated';
export { default as UserSetPassword } from './UserSetPassword';
export { default as UserForgotPassword } from './UserForgotPassword';
export { default as UserNew } from './UserNew';
export { default as MicroTrainingPublicView } from './MicroTrainingPublicView';

export default {
  name: 'user',
  list: UserList,
  icon: null,
  options: {
    label: 'My Profile'
  }
};
