// @flow
// export { default as api } from './api';

export const mobilePagesWhiteList = {
  myDashboard: ['dashboard', 'securityTraining', 'microTrainings', 'darkWeb', 'newsletters'],
  newsfeed: ['dashboard'],
  myCompany: ['dashboard', 'employees'],
  clients: ['dashboard', 'users'],
  reports: ['dashboard']
};

export const mobileBlockedPages = [
  'partnerProfile', 'user', 'getStarted', 'instantScan', 'admin', 'contentadmin', 'auditlog'
];

export const GAbuttonExceptions = ['cancel', 'next', 'save and next', 'back', 'close'];
