import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, CircularProgress, Box } from '@material-ui/core';
import * as sra from 'helpers/apis/services/sra';
import { Button, Typography } from 'components';
import { Download2 } from 'components/icons';
import RecommendationsTable from './RecommendationsTable';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  info: {
    background: 'var(--backgroundPaper)',
    borderRadius: 5,
    marginBottom: 10
  },
  infoTop: {
    padding: '10px 20px 15px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  infoBottom: {
    padding: '13px 20px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid var(--borderDefault)'
  },
  link: {
    fontWeight: 500,
    fontSize: 14,
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    color: 'var(--colorSystemInfo)',
    marginLeft: 20,
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const Recommendations = ({
  currentRecommendation,
  setCurrentRecommendation,
  revisionId,
  outstandingRecommendations
}) => {
  const classes = useStyles();
  const [loadingDownloadReport, setLoadingDownloadReport] = useState(false);

  const downloadReport = () => {
    setLoadingDownloadReport(true);
    sra
      .getWorkPlanReport(revisionId)
      .then(res => {
        const url = res?.data?.url;
        if (url) window.open(url);
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setLoadingDownloadReport(false);
      });
  };

  return (
    <>
      <div className={classes.info}>
        <div className={classes.infoTop}>
          <Box mr={1.5}>
            <Typography.h4 mt={2} mb={1}>
              Work Plan Recommendations
            </Typography.h4>
            <Typography.p mt={0.1} mb={0.1}>
              Address recommendations in your work plan to mitigate risks and improve your organization’s security
              posture.
            </Typography.p>
          </Box>

          <Button height={36} pr={3} pl={3} disabled={loadingDownloadReport} onClick={downloadReport}>
            {loadingDownloadReport ? (
              <>
                <CircularProgress size={18} thickness={2} />
                &nbsp;&nbsp;Loading...
              </>
            ) : (
              <>
                <Download2 color="colorCommonWhite" mr={1} size={1.5} />
                Download Work plan
              </>
            )}
          </Button>
        </div>

        <div className={classes.infoBottom}>
          <Typography.p mt={0.1} mb={0.1}>
            {outstandingRecommendations
              ? `Outstanding Recommendation${
                  outstandingRecommendations === 1 ? '' : 's'
                }: ${outstandingRecommendations}`
              : ''}
          </Typography.p>

          {currentRecommendation && (
            <button type="button" className={classes.link} onClick={() => setCurrentRecommendation(null)}>
              About your work plan
            </button>
          )}
        </div>
      </div>

      <RecommendationsTable
        setCurrentRecommendation={setCurrentRecommendation}
      />
    </>
  );
};

Recommendations.propTypes = {
  currentRecommendation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    priority: PropTypes.string,
    due_date: PropTypes.string,
    date_completed: PropTypes.string,
    notes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    finding: PropTypes.string,
    recommendation: PropTypes.string
  }),
  setCurrentRecommendation: PropTypes.func.isRequired,
  revisionId: PropTypes.string,
  outstandingRecommendations: PropTypes.number
};

Recommendations.defaultProps = {
  currentRecommendation: null,
  revisionId: '',
  outstandingRecommendations: null
};

export default Recommendations;
