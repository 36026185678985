import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Alert, AlertBellIcon } from '@trustsecurenow/components-library';

function WelcomeMessageAlert({ onClick }) {
  const disabledWelcomeMessagesCount = useSelector(({ bsn }) => bsn?.user?.profile?.disabledWelcomeMessagesCount);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);

  const isAdmin = userRole === 'Administrator';
  const isPartnerAdmin = userRole === 'Partner Administrator';
  const isAdminOrPartnerAdmin = isAdmin || isPartnerAdmin;

  if (!isAdminOrPartnerAdmin || !disabledWelcomeMessagesCount) return null;

  return (
    <Alert
      variant="filled"
      severity="error"
      icon={<AlertBellIcon />}
      sx={{ mb: 2, justifyContent: 'center', borderRadius: 0, textTransform: 'none' }}
    >
      {disabledWelcomeMessagesCount === 1
        ? 'You have one client who does not have their Welcome Messages enabled. '
        : ` You have ${disabledWelcomeMessagesCount} clients who do not have their Welcome Messages enabled. `}
        Click {' '}
      <Typography
        variant="link"
        color="error.contrastText"
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={onClick}
      >
      here
      </Typography>{' '}
      to learn how to enable.
    </Alert>
  );
}

export default WelcomeMessageAlert;
