/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Box, RichTextEditor, Typography } from '@trustsecurenow/components-library';
import { useFormContext } from 'react-hook-form';
import Controller from './Controller';

const RichTextEditorController = ({ name, required = false, rules, config }) => {
  const { getValues, setValue, formState } = useFormContext();
  const editorValue = getValues()[name];

   /**
   * State to track whether the editor has content or not
   * In order to display error message
   */
   const [editorHasContent, setEditorHasContent] = React.useState(false)

  const validateHTMLWithBodyContent = (text) => {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    const notVisibleTags = /<head[\s\S]*?<\/head>|<title[\s\S]*?<\/title>|<style[\s\S]*?<\/style>|<[^>]+>|&nbsp;/gi

    // Check if typed content is inside an HTML structure
    if(htmlRegex.test(text)) {
      //regular expression to capture text content inside <body>
      const match = text.match(/<body[^>]*>(.*?)<\/body>/is);
      // check if there's a match and remove nested child tags
      const content = match ? match[1]?.replace(/<[^>]+>|&nbsp;/g, "") : text?.replace(notVisibleTags, '')

      if (content && content.trim().length > 0){
        setEditorHasContent(true)
      } else {
        setEditorHasContent(false)
        return 'This field is required'
      }
    } else {
      setEditorHasContent(!!text)
    }
  };

  const RichTxtEditor = useMemo(() => {
    // https://github.com/jodit/jodit-react/issues/172
    const editedConfig = { ...config, enter: 'p' }
    return (
      <Box width="100%" key="editor1" /* do not remove this div */>
        <RichTextEditor
          value={editorValue}
          config={editedConfig}
          onBlur={value => setValue(name, value)}
          onChange={validateHTMLWithBodyContent}
        />
      </Box>
    );
  }, [config, editorValue, name, setValue]);

  return (
    <>
      <Controller
        required={required}
        name={name}
        rules={{
          ...rules,
          validate: validateHTMLWithBodyContent
        }}
        render={() => null} 
      />
      {RichTxtEditor}
      {!editorHasContent && formState?.errors?.[name] && (
        <Typography display="block" variant="caption" color="error.main">
          {formState?.errors?.[name].message}
        </Typography>
      )}
    </>
  );
};

export default RichTextEditorController;
