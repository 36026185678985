import { Tab, Tabs } from '@trustsecurenow/components-library';
import { Suspense, useState } from 'react';
import { LoadingStyled } from 'components';
import { useHistory, useLocation } from 'react-router-dom';
import { lazy } from 'react';
const PartnerAnnualCourse = lazy(() =>
  import('apps/shared/trainingConfiguration/annualCourseSettings/PartnerAnnualCourse')
);

export const TABS_CONFIG = [
  {
    name: 'annualCourseSettings',
    component: PartnerAnnualCourse,
    label: 'Annual Course Settings'
  }
];

export default function PartnerTrainingConfiguration() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const activeTab = TABS_CONFIG.find(({ name }) => name === searchParams.get('activeTab'))?.name || TABS_CONFIG[0].name;
  const [currentTab, setCurrentTab] = useState(activeTab);

  const handleChangeTab = newValue => {
    setCurrentTab(newValue);
    searchParams.set('activeTab', newValue);
    history.push(`${pathname}?${searchParams.toString()}`);
  };

  const ActiveTabComponent = TABS_CONFIG.find(({ name }) => name === currentTab).component;

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(_, newValue) => handleChangeTab(newValue)}
        sx={{ mt: 3.5, mb: 2.5, width: 'fit-content' }}
      >
        {TABS_CONFIG.map(({ label, name }) => (
          <Tab label={label} value={name} key={name} />
        ))}
      </Tabs>
      <Suspense fallback={<LoadingStyled centerAlign />}>
        <ActiveTabComponent />
      </Suspense>
    </>
  );
}
