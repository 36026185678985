import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@trustsecurenow/components-library';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import mfa from '../../helpers/apis/mfa';

const useStyles = makeStyles({
  numberInput: {
    '& ::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '-moz-appearance': 'textfield',
    '& fieldset': {
      borderColor: 'var(--borderDefault)'
    }
  }
});

const OtpVerification = props => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const inputsRefs = useRef([null, null, null, null, null, null]);
  const [otp, setOtp] = useState([null, null, null, null, null, null]);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false
  });
  const classes = useStyles();

  /**
   * raises error state for the form components
   * @param {String} message - error message to be displayed
   */
  const raiseErrorState = message => {
    setError(true);
    setSnackbar({
      ...snackbar,
      open: true,
      message: message
    });
  };

  /**
   * cancels error state for all form components
   */
  const cancelErrorState = () => {
    setError(false);
    setSnackbar({
      ...snackbar,
      open: false,
      message: ''
    });
  };

  /**
   * focuses and selects an input element with provided ref index
   * @param {number} index - of the input ref to focus and select
   */
  const focusInput = index => {
    if (inputsRefs.current[index]) {
      const input = inputsRefs.current[index];
      input.focus();
      input.select();
    }
  };

  /**
   * fired when a keyboard button is pressed
   * disallowing ['e', 'E', '.', '-', '+'] as valid inputs,
   * and changing the default behaviour of the Backspace button
   * @param e - keydown event
   * @param {number} index - of the input ref to focus and select
   */
  const handleKeyPress = (e, index) => {
    if (['e', 'E', '.', '-', '+'].includes(e.key)) {
      e.preventDefault();
    }
    if (e.key === 'Backspace') {
      if (otp[index] === null || otp[index] === '') {
        e.preventDefault();
        // focusing the preceding input
        focusInput(index > 0 ? index - 1 : 0);
      } else {
        const newOtp = [...otp];
        newOtp[index] = null;
        setOtp([...newOtp]);
        cancelErrorState();
      }
    }
  };

  /**
   * fired with each input event,\
   *  even if it doesn't change the current value of the input element
   * @param {event object} e - Input Event
   * @param {number} index - index of the input element
   */
  const handleInput = (e, index) => {
    let value = e.target.value;
    // in case a user entered non number value,\
    // while the input has a number in it
    if (value.length === 0 || value.length > 2) return;
    // limiting the input value to numbers from 0 to 9
    if (value.length === 2) {
      value = value[1];
    }
    cancelErrorState();
    // updating the otp with the new value
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp([...newOtp]);

    // focusing the next input
    focusInput(Number(index) + 1);
  };

  const TextFields = otp.map((number, index) => {
    return (
      <Grid item xs={matches ? 4 : 1} key={index}>
        <TextField
          style={error ? { backgroundColor: 'rgba(255, 90, 97, 0.2)' } : {}}
          className={classes.numberInput}
          inputRef={element => (inputsRefs.current[index] = element)}
          error={error}
          variant="outlined"
          type="number"
          value={number === null ? '' : number}
          // using the onInput instead of onChange makes us catch any input the user does
          // even if this input will not change the current value
          onInput={e => handleInput(e, index)}
          autoFocus={index === 0}
          onKeyDownCapture={e => handleKeyPress(e, index)}
          onPaste={e => {
            e.preventDefault();
            const value = e.clipboardData.getData('text/plain');
            if (value.length === 6 && Number(value)) {
              const newOtp = value.split('').map(number => Number(number));
              setOtp([...newOtp]);
            }
          }}
        />
      </Grid>
    );
  });

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      let otpString = '';
      for (const element of otp) {
        if (element === null || isNaN(element) || element < 0) {
          throw new Error('The code is invalid!');
        }
        otpString += element;
      }
      if (otpString.length < 6) throw new Error('The code is invalid!');
      const response = await mfa.verify(otpString, props.type);
      props.handleSubmit(response);
    } catch (error) {
      const message = error.response?.data.message || error.message;
      raiseErrorState(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid container component="form" spacing={1} justifyContent="space-around">
        {TextFields}
        <Grid item xs={matches ? 12 : 2}>
          <Button
            sx={{ width: 140 }}
            color="success"
            loading={isSubmitting}
            disabled={!otp.every(v => v !== null && v !== '')}
            fullWidth
            onClick={handleSubmit}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          /**
           * didn't use cancelErroState here,
           * bcz what is needed is to hide snackbar
           * without changing input boxes error state
           */
          setSnackbar({
            ...snackbar,
            open: false,
            message: ''
          });
        }}
        autoHideDuration={2000}
      >
        <MuiAlert severity="error" elevation={6} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default OtpVerification;
