import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, TableList } from 'components';
import { ArrowReloadIcon, ArrowSyncIcon, EyeIcon, Link, Typography } from '@trustsecurenow/components-library';
import { useId } from 'hooks';
import { hasKey } from '../../helpers';
import taxExempt from '../../helpers/apis/taxExempt';
import PartnerTaxExemptViewModal from './PartnerTaxExemptViewModal';
import PartnerTaxExemptToolbar from '../shared/taxExempt/PartnerTaxExemptToolbar';
import { InfoButtons, ActionButtons } from './shared';
import { ContainerTitle } from './ComponentTypes';

const PartnerTaxExempt = props => {
  const { id: propsId } = props;

  const initialState = {
    open: false,
    isNew: true,
    data: null,
    method: 'addCertificate'
  };

  const [modalInfo, setModalInfo] = useState(initialState);
  const [refresh, setRefresh] = useState(false);
  const partner_id = useId({ key: 'partnerId' });
  const app = 'partnerProfile';
  const tab = 'taxExempt';
  const id = propsId || partner_id;
  const [openDialog, setOpenDialog] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [err, setErr] = useState(null);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const isPA = userRole === 'Partner Administrator';

  const viewCertificate = async certificateId => {
    setOpenDialog(true);
    const data = await taxExempt.getCertificate(certificateId);
    setPdfData(data.data.url);
  };

  useEffect(() => {
    if (!modalInfo.open) {
      setRefresh(true);
      setTimeout(() => {
        setRefresh(false);
      }, 300);
    }
  }, [modalInfo.open]);

  const setClose = () => {
    setModalInfo(initialState);
  };

  return (
    <>
      {isPA && (
        <Container.Paper mt={2} radius={1}>
          <Container.Grid spacing={4}>
            <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerTitle noMargin noBorder>
                <Typography variant="subtitle2">Sales Tax Exemptions</Typography>
                <InfoButtons name="tax_exemption" />
              </ContainerTitle>
            </Container.Grid>
          </Container.Grid>
        </Container.Paper>
      )}

      {/* View modal */}
      <PartnerTaxExemptViewModal open={openDialog} setOpen={setOpenDialog} pdfData={pdfData} setPdfData={setPdfData} />

      {/* Table */}
      <Container.Paper mt={2} radius={1}>
        {err ? (
          <Typography variant="h2" maxWidth={500} textAlign="center" my={12.5} color="error.main">
            {err}
          </Typography>
        ) : (
          <TableList
            id={id}
            setApp={app}
            source={tab}
            toolbar={<PartnerTaxExemptToolbar setModalInfo={setModalInfo} modalInfo={modalInfo} setClose={setClose} />}
            transform={data => transformData(data, viewCertificate, setModalInfo)}
            refresh={refresh}
            tableOptions={{ retry: false, refetchOnWindowFocus: false }}
            setErr={setErr}
          />
        )}
      </Container.Paper>

      {/* Action buttons */}
      {isPA && <ActionButtons />}
    </>
  );
};

function transformData(data, viewCertificate, setModalInfo) {
  const taxData = hasKey(data, 'data') ? data.data : data;
  return taxData.map(row => {
    const rowCopy = JSON.parse(JSON.stringify(row));
    const handleOpenModal = method => {
      setModalInfo({
        open: true,
        isNew: false,
        method,
        data: {
          exposure_zones: rowCopy.exposure_zones
        }
      });
    };

    rowCopy.actions = (
      <Container.Grid direction="row" alignItems="center">
        {row.actions.view && (
          <Link sx={{ mr: 5 }} component="button" onClick={() => viewCertificate(rowCopy.id)} startIcon={<EyeIcon />}>
            View
          </Link>
        )}
        {row.actions.renew && (
          <Link
            color="info.main"
            component="button"
            onClick={() => handleOpenModal('postCertificate')}
            icon={<ArrowReloadIcon />}
            sx={{ mr: 5 }}
          >
            Renew
          </Link>
        )}
        {row.actions.update && (
          <Link
            component="button"
            color="info.main"
            onClick={() => handleOpenModal('putCertificate')}
            startIcon={<ArrowSyncIcon />}
            sx={{ mr: 5 }}
          >
            Update
          </Link>
        )}
      </Container.Grid>
    );
    return rowCopy;
  });
}

export default PartnerTaxExempt;
