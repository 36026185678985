// @flow
import React, { memo, type Element, useState, useEffect } from 'react';
import { Container } from 'components';
import { LazyIcon } from 'components/icons';
import { ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { AccordionSummary, Accordion } from './ComponentTypes';
import useHasInternet from '../../hooks/useHasInternet';
import { dataProvider } from 'helpers';
import { useId, useLocation } from 'hooks';
import { Button, ArrowDownloadIcon, ExcelIcon, Typography } from '@trustsecurenow/components-library';

type PartnerBillingReportTypes = {
  record: Object
};

const PartnerBillingReport = ({ record, partnerId }: PartnerBillingReportTypes): Element<*> => {
  const { app } = useLocation();
  const [open, setOpen] = useState(null);
  const userId = useId({ key: 'partnerId' });
  const item = partnerId || userId;
  const [loadingReport, setLoadingReport] = useState({
    pdf: false,
    xls: false
  });
  const hasInternet = useHasInternet();

  useEffect(() => {
    if (record && record.data.length > 0) {
      setOpen(record.data[0].id);
    }
  }, []);

  const downloadBillingReport = ({ statement_id, url }, type) => {
    hasInternet(() => {
      setLoadingReport(prevState => ({ ...prevState, [type]: true }));
      dataProvider
        .getOne(app, `billingStatement/${item}`, { type, statement_id })
        .then(response => window.open(response.data))
        .finally(() => setLoadingReport(prevState => ({ ...prevState, [type]: false })));
      return;
    });
  };

  return (
    <Container.Grid block spacing={24}>
      {record && record.data.length > 0 ? (
        <>
          <Container.Paper fullWidth mb={open === record.data[0].id && 1} pl={3}>
            <Typography variant='button' color="primary.main">
              Changes can take up to 24 hours
            </Typography>
          </Container.Paper>
          {record.data.map((column, i) => {
            return (
              <Container.Grid key={column.id} item block direction="column" xs={12}>
                <Accordion expanded={open === column.id} onChange={e => setOpen(column.id)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="access2-content"
                    id="access2-body"
                    classes={{
                      root: `accessAccordionSummary`,
                      selected: `Selected`
                    }}
                  >
                    <LazyIcon component="Calendar" color="grey" fontSize="default" mt={2.5} mb={2} mr={2} />
                    <Container.Grid item direction="row" sm={12} xs={12} xl={12} md={12} justify="flex">
                      <Container.Grid item sm={6} xs={6} xl={6} md={6} justify="flex-start">
                        <Typography variant="subtitle2" my={2}>
                          {column.month} {column.closed_date.split('-')[2]},{column.year}
                          {i === 0 && (
                            <Typography variant="subtitle1" component="span" color="info.main">
                              {' '}
                              (Current)
                            </Typography>
                          )}
                        </Typography>
                      </Container.Grid>
                      <Container.Grid item sm={6} xs={6} xl={6} md={6} justify="flex-end">
                        <Typography variant="subtitle3" my={2}>
                          $ {column.amount}
                        </Typography>
                      </Container.Grid>
                    </Container.Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container.Grid
                      item
                      direction="column"
                      sm={12}
                      xs={12}
                      xl={12}
                      md={12}
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <Button
                        onClick={() => downloadBillingReport(column, 'pdf')}
                        loading={loadingReport.pdf}
                        startIcon={<ArrowDownloadIcon />}
                        sx={{ width: 180, whiteSpace: 'nowrap' }}
                        size={i === 0 ? 'small' : 'x-small'}
                      >
                        {loadingReport.pdf ? 'Loading Statement...' : 'Download Statement'}
                      </Button>

                      <Button
                        sx={{ mt: 2, width: 180, whiteSpace: 'nowrap' }}
                        onClick={() => downloadBillingReport(column, 'xls')}
                        loading={loadingReport.xls}
                        startIcon={<ExcelIcon />}
                        size={i === 0 ? 'small' : 'x-small'}
                      >
                        {loadingReport.xls ? 'Loading Statement...' : 'Download XLS file'}
                      </Button>
                    </Container.Grid>
                  </AccordionDetails>
                </Accordion>
              </Container.Grid>
            );
          })}
        </>
      ) : (
        <h1>No reports for this period</h1>
      )}
    </Container.Grid>
  );
};

export default memo<PartnerBillingReportTypes>(PartnerBillingReport);
