// @flow
import React, { useState, type Element } from 'react';
import { Dialog, DialogContentText } from '@material-ui/core';
import { Link, Button } from '@trustsecurenow/components-library';
import Typography from '../types/Typography';
import { DialogTitleStyled, DialogContentStyled, DialogActionsStyled } from './ComponentTypes';

type ModalTypes = {
  action: {
    variant?: string,
    color?: string,
    icon?: Element<*>,
    label?: string,
    onClick?: Function
  },
  title?: {
    label: string,
    content: string,
    link?: string
  },
  description?: {
    label: string,
    content: string
  },
  btnCancel?: {
    show: boolean,
    label: string,
    onClick?: Function
  },
  btnSubmit?: {
    show: boolean,
    label?: string,
    onClick: Function
  },
  btnOptional?: {
    show: boolean,
    label: string,
    onClick: Function
  },
  component?: Element<*> | null,
  content?: {
    label: string | null,
    body: string | null
  } | null,
  opened?: boolean,
  showDismiss?: boolean,
  handleModal: Function
};

const Modal2 = ({
  action,
  title,
  description,
  btnCancel = true,
  btnSubmit = true,
  btnOptional = true,
  content,
  component,
  opened = false,
  showDismiss = true,
  handleModal,
  ...rest
}: ModalTypes): Element<*> => {
  const [open, setOpen] = useState(opened);

  const onClick = () => {
    setOpen(true);
    handleModal();
  };

  return (
    <div>
      <Button variant={action.variant} color={action.color} onClick={onClick}>
        {action.icon && <>{action.icon}&nbsp;</>}
        {action.label}
      </Button>

      <Dialog
        {...rest}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        scroll="paper"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitleStyled id="dialog-title">
          {title && (
            <Typography.h3>
              {title.label} &nbsp;&nbsp; {title.content}
            </Typography.h3>
          )}
        </DialogTitleStyled>
        <DialogContentStyled dividers>
          {description && (
            <>
              <Typography.h3>{description.label}</Typography.h3>
              <Typography.p>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: description.content }} />
              </Typography.p>
            </>
          )}
          {component || (
            <>
              {content && content.label && <Typography.h3>{content.label}</Typography.h3>}
              <DialogContentText id="dialog-description">
                {content && content.body && (
                  <>
                    {/* eslint-disable-next-line react/no-danger */}
                    <Typography.p>
                      <div dangerouslySetInnerHTML={{ __html: content.body }} style={{ margin: 20 }} />
                    </Typography.p>
                  </>
                )}
              </DialogContentText>
            </>
          )}
        </DialogContentStyled>
        <DialogActionsStyled>
          {btnOptional && (
            <Button color="info" onClick={btnOptional.onClick}>
              {btnOptional.label}
            </Button>
          )}
          <div>
            {btnCancel && (
              <Link sx={{ mr: 2.5 }} component="button" onClick={() => setOpen(false)}>
                {btnCancel.label}
              </Link>
            )}
            {btnSubmit && (
              <Button color="success" /* disabled={disableSubmit}  onClick={() => dispatch.modalAgree(true, name)} */>
                {btnSubmit.label} {/* TODO: A green line is rendered even with the btnSubmit false */}
              </Button>
            )}
          </div>
        </DialogActionsStyled>
      </Dialog>
    </div>
  );
};

Modal2.defaultProps = {
  opened: false,
  title: {
    label: '',
    content: '',
    link: ''
  },
  description: {
    label: '',
    content: ''
  },
  btnCancel: {
    show: false,
    label: '',
    onClick: () => {}
  },
  btnSubmit: {
    show: false,
    label: '',
    onClick: () => {}
  },
  btnOptional: {
    show: false,
    label: '',
    onClick: () => {}
  },
  component: null,
  content: {
    label: '',
    body: ''
  },
  showDismiss: true
};

export default Modal2;
