import { Button, TextField, Paper, styled, Link, Stack, Box, closeSnackbar } from '@trustsecurenow/components-library';
import React, { useLayoutEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const FormContainer = styled(Paper)(({ theme }) => ({
  borderTop: `5px solid ${theme.palette.primary.main}`,
  marginTop: theme.spacing(10),
  padding: theme.spacing(10.75, 2)
}));

const UserLoginForm = ({ email, onChangeEmail, password, onChangePassword, onSubmitForm, loading, showPassword }) => {
  const inputRef = useRef();

  const handleCloseNotifications = () => {
    closeSnackbar();
  };

  useLayoutEffect(() => {
    if (showPassword) {
      setTimeout(() => {
        inputRef.current.focus();
      });
    }
  }, [showPassword]);

  return (
    <FormContainer elevation={0}>
      <Box maxWidth={350} margin="auto">
        <form onSubmit={onSubmitForm} noValidate>
          <Stack spacing={4.5}>
            <Stack spacing={5.25}>
              <TextField
                autoFocus
                value={email}
                label="Email Address"
                inputMode="email"
                name="email"
                disabled={loading}
                onChange={onChangeEmail}
                fullWidth
                required
                size="large"
              />
              {showPassword && (
                <TextField
                  inputRef={inputRef}
                  value={password}
                  label="Password"
                  type="password"
                  name="password"
                  disabled={loading}
                  onChange={onChangePassword}
                  fullWidth
                  required
                  size="large"
                />
              )}
            </Stack>

            <Button type="submit" size="large" loading={loading} fullWidth>
              {showPassword ? 'SIGN IN' : 'NEXT'}
            </Button>

            <Stack>
              <Link
                component={RouterLink}
                underline="hover"
                color="primary.main"
                onClick={handleCloseNotifications}
                variant="button2"
                to="/newUser"
                textAlign="center"
              >
                New User?
              </Link>
              {showPassword && (
                <Link
                  component={RouterLink}
                  underline="hover"
                  color="primary.main"
                  onClick={handleCloseNotifications}
                  variant="button2"
                  to="/forgotPassword"
                  textAlign="center"
                >
                  Forgot your password?
                </Link>
              )}
            </Stack>
          </Stack>
        </form>
      </Box>
    </FormContainer>
  );
};

UserLoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default UserLoginForm;
