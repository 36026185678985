import React from 'react';
import styled from 'styled-components';
import { AddIcon, Button } from '@trustsecurenow/components-library';
import ClientsUsersAddButton from '../ClientsUsersAddButton';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const UsersListNewUserMobile = ({ setRefresh ,...rest}) => {
  const RenderedButton = ({ children, ...props }) => {
    return (
      <Container>
        <Button sx={{ width: '90%' }} startIcon={<AddIcon />} {...props}>
          {children}
        </Button>
      </Container>
    );
  };
  return (
    <ClientsUsersAddButton
      {...rest}
      tab="addUser"
      label="New User"
      setRefresh={value => {
        setRefresh(false);
        setRefresh(true);
      }}
      RenderedButton={RenderedButton}
    />
  );
};

export default UsersListNewUserMobile;
