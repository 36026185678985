import React, { useEffect, useState } from 'react';
import { Container, FilterActive as SelectPolicyType } from 'components';
import { Button, AddIcon } from '@trustsecurenow/components-library';

const PoliciesToolbar = ({ navigateToCreate, setParam }) => {
  const [policyTypeValue, setPolicyTypeValue] = useState('0'); // if 1 is hipaa policies

  useEffect(() => {
    setParam('hsn', policyTypeValue);
  }, []);

  const onChangeParam = ({ target: { name, value } }) => {
    setParam(name, value);
    setPolicyTypeValue(value);
  };

  return (
    <Container.Grid alignItems="flex-start" justifyContent="space-between" >
      <Container.Grid item md={4} lg={3} xl={2}>
        <SelectPolicyType
          name="hsn"
          value={policyTypeValue}
          state={{
            label: 'Policy Type',
            choices:  [
              { value: '0', label: 'Security Policies' },
              { value: '1', label: 'HIPAA Policies' },
            ]
          }}
          onChange={onChangeParam}
          isFixed
          noLabel
        />
      </Container.Grid>

      <Container.Grid item md={4} lg={2} xl={2} justify="flex-end" pt={1}>
        <Button onClick={navigateToCreate} startIcon={<AddIcon />} sx={{ width: 188 }}>
          New Policy
        </Button>
      </Container.Grid>
    </Container.Grid>
  );
};

export default PoliciesToolbar;
