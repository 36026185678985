import React, { useState, useEffect, useCallback, useMemo } from 'react';
import contentAdmin from 'helpers/apis/contentAdmin';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import { convertToAmPmFormat, convert12HourTo24HourFormat } from 'helpers';
import { useLocation, useId } from 'hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  MenuItem,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Stack,
  TextField,
  Autocomplete,
  enqueueAlertSnackbar,
  Link,
  ArrowReloadIcon,
  InternetIcon,
  ArrowDownIcon,
  ArrowUpIcon
} from '@trustsecurenow/components-library';

const HOURS = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

const SENDING_TIMES = {
  start_time: null,
  end_time: null,
  timezone_id: null
};

const TIME_DIRECTION_CONSTANTS = {
  UP: 'up',
  START: 'start',
  START_TIME: 'start_time',
  END_TIME: 'end_time',
  DOWN: 'down',
  END: 'end'
};

const SendingTimesAccordion = ({ isOnPartnerProfile = false, partner_Id = null, onFinishLoading = () => {}, isLoading  }) => {
  const partnerIdFromUser = useId({ key: 'partnerId' });
  const partnerId = partner_Id || partnerIdFromUser;
  const { item: clientId } = useLocation('clientId');
  const [isOpen, setIsOpen] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [initialSendingTimes, setInitialSendingTimes] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isReverting, setIsReverting] = useState(false);
  const [selectedSendingTimes, setSelectedSendingTimes] = useState(SENDING_TIMES);
  const [selectedField, setSelectedField] = useState(null);
  const isTimeError = useMemo(() => {
    const { start_time, end_time } = selectedSendingTimes;
    if (!start_time || !end_time) {
      return false;
    }
    const startTime24 = convert12HourTo24HourFormat(start_time);
    const endTime24 = convert12HourTo24HourFormat(end_time);

    return startTime24.localeCompare(endTime24) >= 0;

  }, [selectedSendingTimes]);

  const isStartTimeError = useMemo(
    () => isTimeError && selectedField === TIME_DIRECTION_CONSTANTS.START_TIME
    , [isTimeError, selectedField]);
  const isEndTimeError = useMemo(
    () => isTimeError && selectedField === TIME_DIRECTION_CONSTANTS.END_TIME
    , [isTimeError, selectedField]);

  const isSavedDisabled = useMemo(() => {
    if (!initialSendingTimes || isSaving || isTimeError) return true;

    const isSameTimezone =
      initialSendingTimes.timezone_id === selectedSendingTimes.timezone_id;

    const isSameStartTime =
      convertToAmPmFormat(initialSendingTimes.start_sending_time) ===
      selectedSendingTimes.start_time;

    const isSameEndTime =
      convertToAmPmFormat(initialSendingTimes.end_sending_time) ===
      selectedSendingTimes.end_time;

    return (isSameStartTime && isSameEndTime && isSameTimezone);
  }, [initialSendingTimes, selectedSendingTimes, isTimeError, isSaving]);

  const fetchData = useCallback(async () => {
    try {
      const initialSendingTimes = await pushNotifications.getSendingTimes(
        partnerId,
        !isOnPartnerProfile ? clientId : null
        );
      const timeZones = await contentAdmin.getPickList('timezones');
      setTimeZoneList(timeZones.data.timezones);
      onFinishLoading();
      if (!initialSendingTimes.data?.show_sending_times_section) return;
      setSelectedSendingTimes(prevState => ({
        ...prevState,
        start_time: convertToAmPmFormat(initialSendingTimes.data.start_sending_time),
        end_time: convertToAmPmFormat(initialSendingTimes.data.end_sending_time),
        timezone_id: initialSendingTimes.data.timezone_id
      }));
      setInitialSendingTimes(initialSendingTimes.data);
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    }
  }, [partnerId, clientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTimeChange = (type, direction) => {
    const isStartTime = type === TIME_DIRECTION_CONSTANTS.START;
    const currentKey = isStartTime ? TIME_DIRECTION_CONSTANTS.START_TIME : TIME_DIRECTION_CONSTANTS.END_TIME;
    const currentIndex = HOURS.indexOf(selectedSendingTimes[currentKey]);

    setSelectedSendingTimes(prevState => {
      let newIndex;
      if (direction === TIME_DIRECTION_CONSTANTS.UP) {
        newIndex = currentIndex === HOURS.length - 1 ? 0 : currentIndex + 1;
      } else {
        newIndex = currentIndex === 0 ? HOURS.length - 1 : currentIndex - 1;
      }

      return {
        ...prevState,
        [currentKey]: HOURS[newIndex]
      };
    });
    setSelectedField(currentKey);
  };

  const handleSelectTime = (newTime, currentKey) => {
    setSelectedSendingTimes(prevState => ({
      ...prevState,
      [currentKey]: newTime,
    }));
    setSelectedField(currentKey);
  };

  const handlePostSendingTimes = async (sendingTimes) => {
    try {
      const response = await pushNotifications.postSendingTimes(
        partnerId,
        !isOnPartnerProfile ? clientId : null,
        sendingTimes
      );

      enqueueAlertSnackbar(response.data.description, {
        props: { severity: 'success' }
      });
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    }
  };

  const handleSave = async () => {
    const modifiedSendingTimes = { ...selectedSendingTimes,
      start_time: convert12HourTo24HourFormat(selectedSendingTimes.start_time),
      end_time: convert12HourTo24HourFormat(selectedSendingTimes.end_time)
    };
    setIsSaving(true);
    await handlePostSendingTimes(modifiedSendingTimes);
    await fetchData();
    setIsSaving(false);
  };

  const handleRevertSettings = async () => {
    setIsReverting(true);
    await handlePostSendingTimes(SENDING_TIMES);
    await fetchData();
    setIsReverting(false);
  };

  const handleTimezoneChange = (event, newValue) => {
    setSelectedSendingTimes(prevState => ({
        ...prevState,
        timezone_id: newValue.id
    }));
};

  if (!initialSendingTimes?.show_sending_times_section || isLoading) return null;

  return (
    <Box mb={2}>
        <Accordion style={{ marginBottom: 20 }} expanded={isOpen} onChange={() => setIsOpen(prev => !prev)}>
          <AccordionSummary>
          <Typography color={isOpen && 'info.main'} my={1}>Configure Sending Times</Typography>
          </AccordionSummary>
          <AccordionDetails>
                <Typography variant="body2">
                  Customize the notification schedule for your platform. Tailor the timing for Micro Training and
                  Newsletter emails, Manager Notifications, <br />
                  Employee Reminders and Automated Reports to suit your preferences.
                </Typography>
              <Stack spacing={3.75} mt={4.25} width="fit-content">
              <Stack direction="row" alignItems="center" spacing={3.75} flexWrap="wrap" useFlexGap>
                  <Typography variant="subtitle2">Choose Time Zone</Typography>
                <Stack direction="row" alignItems="baseline" spacing={1}>
                  <Autocomplete
                    value={timeZoneList.find(item => item.id === selectedSendingTimes.timezone_id) || null}
                    onChange={handleTimezoneChange}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disableCloseOnSelect={false}
                    disableClearable
                    options={timeZoneList}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InternetIcon sx={{ pr: 1 }}/>
                          ),
                        }}
                        sx={{
                          width: 330,
                          '& .Mui-focused svg': {
                            color: 'info.main'
                          },
                          '& div': {
                            pr: '0 !important'
                          }
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem
                        {...props}
                        sx={{
                          color: option.id === selectedSendingTimes.timezone_id && 'info.main',
                          whiteSpace: 'normal'
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    )}
                  />
                </Stack>
                {!isOnPartnerProfile &&
                  !initialSendingTimes.is_system_config &&
                  !initialSendingTimes.is_partner_config && (
                    <Stack flexDirection="row" alignItems="center" gap={0.25}>
                      <Link component="button" onClick={handleRevertSettings} disabled={isReverting} startIcon={<ArrowReloadIcon/>}>
                          Revert to Default Settings
                      </Link>
                    </Stack>
                  )}
              </Stack>
              <Stack direction="row" alignItems="baseline" spacing={3.75} justifyContent="space-between" maxWidth={424} flexWrap="wrap" useFlexGap>
                <Typography variant="subtitle2">Send Between</Typography>
                <Stack direction="row" alignItems="baseline" spacing={1}>
                  <TextField
                    select
                    label="Start"
                    SelectProps={{
                      IconComponent: () => <></>
                    }}
                    sx={{ width: 110 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Stack pr={0.5}>
                            <IconButton sx={{ p: 0 }}>
                              <ArrowUpIcon sx={{ fontSize: 10 }} onClick={() => handleTimeChange(TIME_DIRECTION_CONSTANTS.START, TIME_DIRECTION_CONSTANTS.UP)} />
                            </IconButton>
                            <IconButton sx={{ p: 0 }}>
                              <ArrowDownIcon sx={{ fontSize: 10 }} onClick={() => handleTimeChange(TIME_DIRECTION_CONSTANTS.START, TIME_DIRECTION_CONSTANTS.DOWN)} />
                            </IconButton>
                          </Stack>
                        </InputAdornment>
                      )
                    }}
                    defaultValue={selectedSendingTimes.start_time}
                    value={selectedSendingTimes.start_time}
                    onChange={(e) => handleSelectTime(e.target.value, TIME_DIRECTION_CONSTANTS.START_TIME)}
                    fullWidth
                    error={isStartTimeError }
                    helperText={ isStartTimeError && 'Start time must be less than End time' }
                  >
                    {HOURS.map(hour => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="body2">and</Typography>
                  <TextField
                    select
                    label="Ends"
                    SelectProps={{
                      IconComponent: () => <></>
                    }}
                    sx={{ width: 110 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Stack pr={0.5}>
                            <IconButton sx={{ p: 0 }} onClick={() => handleTimeChange(TIME_DIRECTION_CONSTANTS.END, TIME_DIRECTION_CONSTANTS.UP)}>
                              <ArrowUpIcon sx={{ fontSize: 10 }} />
                            </IconButton>
                            <IconButton sx={{ p: 0 }} onClick={() => handleTimeChange(TIME_DIRECTION_CONSTANTS.END, TIME_DIRECTION_CONSTANTS.DOWN)}>
                              <ArrowDownIcon sx={{ fontSize: 10 }} />
                            </IconButton>
                          </Stack>
                        </InputAdornment>
                      )
                    }}
                    defaultValue={selectedSendingTimes.end_time}
                    value={selectedSendingTimes.end_time}
                    onChange={(e) => handleSelectTime(e.target.value, "end_time")}
                    fullWidth
                    error={isEndTimeError }
                    helperText={ isEndTimeError  && 'End time must be greater than Start time' }
                  >
                    {HOURS.map(hour => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>
              </Stack>
              <Stack alignItems="flex-end" mt={1}>
                <Button disabled={isSavedDisabled} onClick={handleSave}>
                  Save
                </Button>
              </Stack>

          </AccordionDetails>
        </Accordion>
    </Box>
  );
};

export default SendingTimesAccordion;
