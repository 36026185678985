/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, {
  type Element,
  type Context,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dataProvider, hasKey, useApp, isObjectEmpty } from 'helpers';
import { useConfig, useTableData, useTableProps, useTableRowSelected } from 'hooks';
import userPreferences from '../../helpers/apis/UsersAPI/userPreferences';
import * as dashboard from 'helpers/apis/services/dashboard';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const TableList2Context: Context<{
  dispatch: Object,
  filter: string | boolean,
  settings: Object,
  record: Object | Array<*>,
  order: 'asc' | 'desc',
  selected: Array<*>,
  orderBy: string,
  page: number,
  rowsPerPage: number,
  open: boolean,
  password: string,
  app: string,
  tab: string,
  item: string | number | boolean,
  paramKeyForModal: string,
  toolbar: any,
  total: number | null,
  isFetching: boolean,
  isPreviousData: boolean,
  isLoading: boolean,
  status: string,
  keyCheckToHideIcon?: string,
  openDialog?: boolean
}> = createContext({
  dispatch: {},
  filter: false,
  settings: {},
  record: null,
  order: 'asc',
  selected: [],
  orderBy: '',
  paramKeyForModal: '',
  keyCheckToHideIcon: '',
  page: 0,
  rowsPerPage: 25,
  open: false,
  password: '',
  app: '',
  tab: '',
  item: 0,
  toolbar: null,
  total: 0,
  isFetching: false,
  isPreviousData: false,
  isLoading: false,
  status: ''
});

export type TableListProviderTypes = {
  tableSettings?: Object,
  paramKeyForModal?: string,
  keyCheckToHideIcon?: string,
  data?: {
    data: Array<*> | Object,
    total: number,
    status: string,
    dispatch: Function,
    isFetching: boolean,
    isPreviousData: boolean,
    isLoading: boolean
  } | null,
  source?: string,
  id?: string,
  onSearch?: Function,
  cache?: boolean,
  children?: any,
  toolbar?: any,
  newItemSource?: any,
  refresh?: boolean,
  setApp?: string,
  transform?: Function,
  tableParams?: Object,
  tableOptions?: Object,
  resetTable?: string,
  refetchOnDeletion: Function
};

export const TableListProvider = ({
  tableSettings,
  paramKeyForModal,
  keyCheckToHideIcon,
  data,
  source: tableTab,
  id,
  onSearch,
  cache = true,
  children,
  toolbar,
  newItemSource,
  refresh = false,
  setApp: tableApp,
  transform = e => e,
  tableParams = {},
  tableOptions = {},
  resetTable,
  handleSelectAllRows,
  transformBeforeSubmit
}: TableListProviderTypes): Element<*> => {
  const dispatchRx = useDispatch();
  const state = useSelector(rxState => rxState.bsn);
  const { dispatch: dispatchApp } = useApp();
  const { app, tab, item, settings: settingsProps } = useTableProps({
    tableApp,
    tableTab,
    tableItem: id,
    tableSettings
  });
  const { getFilterFlat, tableRowSelected: selected, clearSelect } = useTableRowSelected();
  let settings = settingsProps;

  const preferences = userPreferences.getTableSettings(app, tab);
  const defaultOrder = hasKey(settings, 'order') ? settings.order : 'asc';
  const defaultOrderBy = hasKey(settings, 'orderBy') ? settings.orderBy : 'name';
  const defaultPagination = 25;

  const selectFilters = getFilterFlat();
  const { pagination } = useConfig('system', 'table');
  const initialState = {
    open: false,
    searchTerm: '',
    page: 0,
    params:
      settings && hasKey(settings, 'params')
        ? settings.params
        : {
            pagination: {
              page: 0,
              perPage: defaultPagination
            }
          }
  };
  const [reduce, setState] = useReducer(reducer, initialState);
  const [openDialog, setOpenDialog] = useState(false);

  const [order, setOrder] = useState(preferences.order || defaultOrder);
  const [orderBy, setOrderBy] = useState(preferences.orderBy || defaultOrderBy);
  const [rowsPerPage, setRowsPerPage] = useState(preferences.pagination || pagination.rowsPerPage);

  const [filter, setFilter] = useState(false);
  const [password, setPassword] = useState('none');
  const [, setTotal] = useState(null);
  const [newRefresh, setRefresh] = useState(refresh);
  const UseTableData = () => useTableData({ app, tab, item, transform, params: getParams(), options: tableOptions });
  const { data: record, total, status, dispatch: dispatchUtd, isFetching, isPreviousData, isLoading } = (d => {
    if (d && hasKey(d, 'data') && d.data !== null) return d;
    return UseTableData();
  })(data);

  if (record) {
    const [rowTable] = record;
    const rowKeys = rowTable && Object.keys(rowTable);
    const keysHidden = rowTable && rowKeys.filter(row => rowTable[row] === 'hidden');
    const newCells = rowTable ? settings.cells.filter(row => !keysHidden.includes(row.id)) : settings.cells;
    settings = { ...settings, cells: newCells };
  }

  useEffect(() => {
    if (refresh) setRefresh(true);
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 300);
    };
  }, [refresh]);

  useEffect(() => {
    if (newRefresh) dispatchUtd.refetch();
  }, [newRefresh]);

  useEffect(() => {
    if (resetTable) {
      setOrder(preferences.order || defaultOrder);
      setOrderBy(preferences.orderBy || defaultOrderBy);
      setPage(0);
      setRowsPerPage(preferences.pagination || defaultPagination);

      dispatchUtd.setPage(0);
      clearSelect();
      dispatchApp.set('system', 'tableRowSelected', []);
      dispatchApp.set('system', 'tableRowUnselected', []);
      dispatchApp.set('system', 'tableRowSelectedCount', 0);
    }
  }, [resetTable]);

  useEffect(() => {
    if (!isLoading) search(true);
  }, [selectFilters, reduce.searchTerm, orderBy, order, isLoading]);

  useEffect(
    () => () =>
      dispatchRx({
        type: 'BSN_TABLE_CLEANUP',
        resource: 'system',
        payload: {
          tableRowSelected: [],
          tableRowUnselected: [],
          tableFilter: {}
        }
      }),
    [tableTab]
  );

  const dispatch = {};

  dispatch.handleRequestSort = useCallback(
    (event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      const newOrder = isDesc ? 'asc' : 'desc';
      const newParams = {
        ...reduce.params,
        _sort: property || orderBy,
        sort: {
          field: property,
          order: newOrder
        }
      };
      setOrder(newOrder);
      setOrderBy(property);
      setParams(newParams);
      userPreferences.setTableSettings(app, tab, { order: newOrder, orderBy: property });
    },
    [order, orderBy, app, tab]
  );

  dispatch.handleChangeRowsPerPage = useCallback(
    event => {
      const { value } = event.target;
      dispatchUtd.setPrePage(+value);
      setRowsPerPage(+value);
      setPage(0);
      userPreferences.setTableSettings(app, tab, { pagination: value });
    },
    [app, tab]
  );

  dispatch.setNewPage = (e, p) => {
    dispatchUtd.setPage(p);
    setPage(p);
  };

  dispatch.setOpen = e => {
    if (!e) dispatchUtd.refetch();
    if (settings?.row?.modal?.external) dispatchApp.set('system', 'tableRowModal', e);
    else setState({ type: 'SETOPEN', payload: e });
  };

  dispatch.setOpenDialog = useCallback(e => setOpenDialog(e));

  dispatch.setFilter = useCallback(e => setFilter(e), []);

  dispatch.setPassword = useCallback(e => setPassword(e), []);

  dispatch.setTotal = useCallback(e => setTotal(e), []);

  dispatch.setRefresh = setRefresh;

  dispatch.search = useCallback((term: string) => {
    setState({ type: 'SETSEARCH', payload: term });
  }, []);

  function search(reset = true) {
    const newParams = getParams(reset);
    if (!data) dispatchUtd.setFilter(newParams);
    const type = reset ? 'SETSEARCHPARAMS' : 'SETPARAMS';
    setState({ type, payload: newParams });
  }

  function getParams(reset) {
    return {
      ...tableParams,
      ...reduce.params,
      _filter: getFielterFields(),
      sort: {
        field: orderBy,
        order
      },
      pagination: {
        page: reset ? 0 : reduce?.params?.pagination?.page || 0,
        perPage: rowsPerPage
      }
    };
  }

  function getFielterFields() {
    if (!settings?.searchFields && selectFilters === '' && isObjectEmpty(tableParams)) return '';
    const fields =
      reduce.searchTerm.length > 0 && settings?.searchFields.map(f => `${f}:${reduce.searchTerm}`).join(',');
    const filterFields = [];
    if (fields) filterFields.push(fields);
    if (selectFilters) filterFields.push(selectFilters);
    Object.keys(tableParams).forEach(e => filterFields.push(tableParams[e]));
    return filterFields.join(',');
  }

  dispatch.delete = useCallback(() => {
    const rowList = state.system.tableRowSelected;
    const onSuccess = res => {
      setPage(0);
      dispatchUtd.setPage(0);
      clearSelect();
      dispatchApp.set('system', 'tableRowSelected', []);
      enqueueAlertSnackbar(
        // eslint-disable-next-line no-nested-ternary
        res.data?.status
          ? `${res.data.status}`
          : res.data?.description
          ? `${res.data.description}`
          : `${rowList.length} ${rowList.length === 1 ? 'item was' : 'items were'} deleted`,
        { props: { severity: 'error' } }
      );
      data?.dispatch?.refetchOnDeletion && data.dispatch.refetchOnDeletion();
    };

    const onError = error => {
      enqueueAlertSnackbar(`${error.response.data.description}` || `${error.response.status}`, {
        props: { severity: 'error' }
      });
    };

    const onFinished = () => dispatchUtd.refetch();
    if (['clients', 'myCompany'].includes(app) && tab === 'workPlanFinding') {
      dashboard
        .deleteWorkPlanFinding({ app, id, data: { id: rowList } })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    } else {
      dataProvider
        .delete(app, `${String(tab)}/${String(id)}`, null, { id: rowList })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinished);
    }
  }, [app, tab]);

  dispatch.resetPhishingItem = useCallback(() => {
    const rowList = state.system.tableRowSelected;
    dataProvider
      .post(app, `phishingResetCampaign/${id}`, null, { ids: rowList })
      .then(res =>
        enqueueAlertSnackbar(
          `Campaign has been reset successfully for ${rowList.length} ${rowList.length === 1 ? 'User' : 'Users'}`,
          { props: { severity: 'error' } }
        )
      )
      .catch(error =>
        enqueueAlertSnackbar(`${error.response.data.code}: ${error.response.data.description}`, {
          props: { severity: 'error' }
        })
      )
      .finally(() => dispatchUtd.refetch());
  }, [app, tab]);

  dispatch.endCampaign = useCallback(
    selections => {
      dataProvider
        .post(app, `phishingReportsCampaign/${String(id)}`, null, { type: 'endCampaign', ids: selections })
        .then(res =>
          enqueueAlertSnackbar(
            res?.data?.description ? res.data.description : `${selections.length} Campaign(s) have been ended`,
            { props: { severity: 'error' } }
          )
        )
        .catch(error => {
          enqueueAlertSnackbar(`It was not possible end campaign due to: ${error.response.data.description}`);
        })
        .finally(() => {
          setRefresh(true);
          dispatchUtd.refetch();
        });
    },
    [app, tab]
  );

  function setPage(p) {
    setState({ type: 'SETPAGE', payload: p });
  }

  function setParams(params) {
    setState({ type: 'SETPARAMS', payload: params });
  }

  return (
    <TableList2Context.Provider
      value={{
        app,
        tab,
        item,
        dispatch,
        filter,
        settings,
        record,
        order,
        selected,
        orderBy,
        page: reduce.page,
        rowsPerPage,
        open: reduce.open,
        openDialog,
        password,
        toolbar,
        total,
        status,
        paramKeyForModal,
        keyCheckToHideIcon,
        isFetching,
        isPreviousData,
        isLoading,
        handleSelectAllRows,
        transformBeforeSubmit
      }}
    >
      {children}
    </TableList2Context.Provider>
  );
};

function reducer(previousState, { type, payload }) {
  switch (type) {
    case 'SETOPEN': {
      return { ...previousState, open: payload };
    }

    case 'SETSEARCH': {
      return { ...previousState, searchTerm: payload };
    }

    case 'SETPAGE': {
      return { ...previousState, page: payload };
    }

    case 'SETPARAMS': {
      return { ...previousState, params: payload };
    }

    case 'SETSEARCHPARAMS': {
      const newState = previousState;
      newState.params = payload;
      newState.page = 0;
      return newState;
    }

    default: {
      return { ...previousState, ...payload };
    }
  }
}

TableListProvider.defaultProps = {
  data: null,
  source: null,
  id: null,
  tableSettings: null,
  onSearch: e => e,
  cache: true,
  children: null,
  toolbar: null,
  newItemSource: null,
  refresh: false,
  paramKeyForModal: '',
  keyCheckToHideIcon: '',
  setApp: null,
  transform: e => e,
  tableParams: {},
  tableOptions: {},
  resetTable: null
};

export const useTableList2 = () => useContext(TableList2Context);
