// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, DialogContent, Box, RadioGroup } from '@material-ui/core';
import { LoadingStyled } from 'components';
import { RadioWithIcon } from '../../common';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { ModalTitle, ModalActions, ModalSearch } from './index';
import { CONTENT_OPTIONS } from '../../../constants';
import { enqueueAlertSnackbar, Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    minHeight: 300,
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    maxHeight: 300,
    minHeight: 150,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'initial'
    }
  }
}));

const SelectContent = ({ type, setView, postContent, onChangeContent }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(postContent[type]);

  const getOptions = useCallback(
    (searchValue) => {
      setLoading(true);
      const param = CONTENT_OPTIONS[type].param;
      newsFeed
        .getFeedPicklist(param, { name: searchValue })
        .then(res => {
          setOptions(res?.data?.[param] || null);
        })
        .catch(err => {
          enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
            props: { severity: 'error' }
          });
        })
        .finally(() => setLoading(false));
    },
    [type]
  );

  useEffect(() => {
    // Send empty string as a search value to get latest items (to display list by default befor user searches something)
    getOptions('');
  }, [getOptions]);

  const onChangeOption = e => {
    const newOption = options.find(item => item.id === Number(e.target.value));
    setSelectedOption(newOption);
  };

  const handleViewChange = useCallback(view => setView(view), []);

  return (
    <>
      <ModalTitle title={CONTENT_OPTIONS[type].title} onClick={() => handleViewChange('main')} />

      <DialogContent className={classes.dialogContent}>
        <ModalSearch placeholder="Search" onSearch={getOptions} />

        {loading ? (
          <LoadingStyled />
        ) : (
          options &&
          (options.length > 0 ? (
            <div className={classes.list}>
              <RadioGroup name={type} value={selectedOption?.id || ''} onChange={onChangeOption}>
                {options.map(item => (
                  <RadioWithIcon
                    key={item.id}
                    value={item.id}
                    label={item.name}
                    labelPlacement="start"
                    icon={null}
                    selectedVal={selectedOption?.id}
                  />
                ))}
              </RadioGroup>
            </div>
          ) : (
            <Typography variant="h4" component="p" py={3} px={2} textAlign="center">
              No results found
            </Typography>
          ))
        )}
      </DialogContent>

      <ModalActions
        title="Select"
        onClick={() => onChangeContent(type, selectedOption)}
        disabled={!selectedOption?.id}
      />
    </>
  );
};

export default SelectContent;
