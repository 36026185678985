import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SelectField, RadioButtonWithTitle } from 'components';
import QuestionFeedback from './QuestionFeedback';
import { Typography } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '-webkit-text-size-adjust': '100%',
    '& .question-item': {
      marginTop: 40,
      width: '48%'
    },
    [theme.breakpoints.down('sm')]: {
      '& .question-item': {
        marginTop: 10,
        width: '100%',
        background: 'var(--backgroundPaper)',
        borderRadius: 'var(--borderRadius)',
        padding: '24px 20px 6px'
      },
    }
  },
  selectWrap: {
    width: 120,
    '& [class*=MuiSelect-icon]': {
      display: ({ isCompleted }) => isCompleted ? 'none' : 'initial'
    },
    '& [class*=Mui-disabled]': {
      color: 'var(--colorDarkGrey) !important',
      opacity: '1 !important'
    }
  }
}));

const options = [
  { label: 'True', value: true },
  { label: 'False', value: false }
];

const Questions = ({ questions, isCompleted, handleChangeTraining, isMobile }) => {
  const classes = useStyles({ isCompleted });

  return (
    <div className={classes.container}>
      {questions.map((question, index) => (
        <div key={index} className="question-item">
          <Typography variant="h4">
           {question.label}
          </Typography>
          <Typography my={2}>
            {question.description}
          </Typography>
          {isMobile ? (
            options.map(option => (
              <RadioButtonWithTitle
                id={`${option.label}-${index}`}
                name={`question-${index}`}
                label={option.label}
                onChange={(e) => handleChangeTraining(e, index, option.value)}
                hide={isCompleted && question.user_answer !== option.value}
                isRightAnswer={isCompleted ? question.right_answer : undefined}
                checked={question.user_answer === option.value}
              />
            ))
          ) : (
            <div className={classes.selectWrap}>
              <SelectField
                label={isCompleted ? "" : "Answer"}
                name={`answer_${index}`}
                onChange={(e) => handleChangeTraining(e, index)}
                value={question.user_answer}
                choices={options}
                disabled={isCompleted}
                fullWidth
                size="small"
              />
            </div>
          )}

          {question.user_answer !== null && isCompleted && (
            <QuestionFeedback isCorrect={question.right_answer} feedback={question.feedback} />
          )}
        </div>
      ))}
    </div>
  )
}

export default Questions;
