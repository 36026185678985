import { Box } from '@material-ui/core';
import { getStorage } from 'helpers';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import React, { useState } from 'react';
import { Button, enqueueAlertSnackbar } from '@trustsecurenow/components-library';

function TestMessageButton({ state, setActionLoading }) {
  const [testLoading, setTestLoading] = useState(false);

  const handleTestMessage = async () => {
    const subscriptionId = state?.subscription?.id;
    if (!subscriptionId) return;

    setActionLoading(true);
    setTestLoading(true);

    pushNotifications
      .testPushNotificationsMessage(subscriptionId, {
        partner_id: getStorage('partnerId', true),
        user_id: getStorage('userId', true),
        client_id: getStorage('clientId', true),
        user_email: getStorage('email', true),
        use_custom_message: state.subscription.use_custom_message,
        custom_message_subject: state.subscription.custom_message_subject,
        custom_message_body: state.subscription.custom_message_body,
        subscription_id: subscriptionId
      })
      .then(res => {
        enqueueAlertSnackbar('The test message has been sent', { props: { severity: 'success' } });
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setTestLoading(false);
        setActionLoading(false);
      });
  };

  return (
    <Box ml={3}>
      <Button
        onClick={handleTestMessage}
        disabled={
          state.subscription.use_custom_message &&
          (!state.subscription.custom_message_body ||
            !state.subscription.custom_message_subject ||
            state.subscription.custom_message_body.trim() === ('' || '<p><br></p>') ||
            state.subscription.custom_message_subject.trim() === '')
        }
        color="success"
        sx={{ width: 140 }}
        loading={testLoading}
      >
        Test Message
      </Button>
    </Box>
  );
}

export default TestMessageButton;
