import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, DragZone, LoadingStyled } from 'components';
import { TextField, Grid } from '@trustsecurenow/components-library';
import { dataProvider } from 'helpers';
import { useLocation, useId } from 'hooks';
import { Step, StepLabel } from '@material-ui/core';
import * as dashboard from 'helpers/apis/services/dashboard';
import { enqueueAlertSnackbar, Button } from '@trustsecurenow/components-library';
import { Stepper } from './ComponentTypes';

const initialState = {
  data: {
    description: '',
    details: '',
    file: { name: '' },
    name: '',
    id: ''
  },
  step: 0,
  disabled: true,
  loading: false
};

let statusMessage = null;
let showCreatedMsg = false;

const PagePoliciesModal = ({ tab, dispatch, refetchOnCreate }) => {
  const app = 'clients';
  const [paste, setPaste] = useState(false);
  const modalType = tab === 'policies' ? 'policy' : 'other_policy';
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const [state, setState] = useReducer(reducer, initialState);
  const setId = item || clientId;
  const steps = ['Information', 'Attachment'];
  const loadingMsg = { 0: 'Please Wait', 1: `Uploading File\nPlease Wait` };

  useEffect(() => {
    return () => {
      if (showCreatedMsg) {
        enqueueAlertSnackbar(statusMessage);
        showCreatedMsg = false;
        statusMessage = null;
      }
    };
  }, []);

  const onChange = ({ target: { name, value } }, type) => {
    if (paste) {
      setState({ type, payload: { [name]: value } });
      setPaste(false);
    }
    setState({ type, payload: { [name]: value } });
  };

  const handleNext = e => {
    e.preventDefault();
    setState({ type: 'NEXTSTEP', payload: null });
  };

  const handleBack = e => {
    e.preventDefault();
    setState({ type: 'PREVSTEP', payload: null });
  };

  const onSave = async e => {
    try {
      e.preventDefault();
      setState({ type: 'SETLOADING', payload: true });

      let createdPolicy = null;

      const onError = error => {
        throw new Error(error?.response?.data?.message || error?.response?.data?.status || 'Something went wrong');
      };

      if (tab === 'policies') {
        createdPolicy = await dashboard
          .createPolicy({
            app,
            id: setId,
            data: {
              description: state.data.description,
              details: state.data.details,
              name: state.data.name
            }
          })
          .catch(onError);
      } else if (tab === 'otherPolicies') {
        createdPolicy = await dashboard
          .createOtherPolicy({
            app,
            id: setId,
            data: {
              description: state.data.description,
              details: state.data.details,
              name: state.data.name
            }
          })
          .catch(onError);
      }

      const preSignedUpload = await dashboard
        .presignedUpload({
          app,
          id: createdPolicy.data?.data?.id,
          data: {
            filename: state.data.file.name,
            type: modalType
          }
        })
        .catch(err => {
          throw new Error((err && err.response && err.response.data.message) || 'Failed presigned upload');
        });

      const formData = new FormData();
      formData.append('AWSAccessKeyId', preSignedUpload.data.fields.AWSAccessKeyId);
      formData.append('key', preSignedUpload.data.fields.key);
      formData.append('policy', preSignedUpload.data.fields.policy);
      formData.append('signature', preSignedUpload.data.fields.signature);
      formData.append('x-amz-security-token', preSignedUpload.data.fields['x-amz-security-token']);
      formData.append('file', state.data.file);

      await dataProvider
        .postUrl_propagatesError(preSignedUpload.data.url, formData, 'multipart/form-data')
        .catch(err => {
          throw new Error('Failed File Upload');
        });

      statusMessage = createdPolicy.data?.status || `New Policy ${state.data.name} was successfully created`;
      enqueueAlertSnackbar(statusMessage, { props: { severity: 'success' } });
      refetchOnCreate && refetchOnCreate();
      dispatch.onClose();
      setState({ type: 'SETLOADING', payload: false });
    } catch (error) {
      enqueueAlertSnackbar(error.message || 'Someting went wrong!', { props: { severity: 'error' } });
      setState({ type: 'SETLOADING', payload: false });
    }
  };

  const onFileUpload = file => {
    setState({ type: 'STEP2', payload: file });
  };

  return state.loading === true ? (
    <LoadingStyled text={loadingMsg[state.step]} marginTopPercentage={5} />
  ) : (
    <Container.Grid direction="column">
      <Stepper activeStep={state.step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container.Grid item alignItems="center" sm={12} xs={12} xl={12} md={12}>
        {state.step === 0 ? (
          <Grid container direction="column" mb={3} ml={3} mr={3} spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                source={state.data.name}
                value={state.data.name}
                id="name"
                label="Name"
                name="name"
                ml={3}
                required
                inputProps={{
                  onPaste: e => setPaste(true),
                  onChange: e => onChange(e, 'STEP1'),
                  maxLength: 200
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                source={state.data.description}
                value={state.data.description}
                id="description"
                label="Description"
                name="description"
                multiline
                rows={4}
                ml={3}
                required
                inputProps={{
                  onPaste: e => setPaste(true),
                  onChange: e => onChange(e, 'STEP1')
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                source={state.data.details}
                value={state.data.details}
                id="details"
                label="Details"
                name="details"
                multiline
                rows={4}
                ml={3}
                required
                inputProps={{
                  onPaste: e => setPaste(true),
                  onChange: e => onChange(e, 'STEP1')
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Container.Grid item direction="column" ml={3} pb={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid direction="row" alignItems="center" sm={4} xs={4} xl={4} md={4}>
              <DragZone
                title="Upload a file*"
                fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls, .xlt, .jpeg, .tiff, .png"
                size={12}
                record={state.data}
                dispatch={onFileUpload}
                type="file"
              />
            </Container.Grid>
          </Container.Grid>
        )}
      </Container.Grid>
      <Container.Grid item justify="flex-end" alignItems="center" mr={3} sm={12} xs={12} xl={12} md={12}>
        {state.step > 0 ? (
          <>
            <Button onClick={e => handleBack(e)}>Back</Button>
            <Button sx={{ ml: 2 }} disabled={state.loading || state.disabled} onClick={onSave}>
              Save
            </Button>
          </>
        ) : (
          <Button type="submit" onClick={handleNext} disabled={state.loading || state.disabled}>
            Next
          </Button>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'STEP1': {
      const newData = { ...prevState.data, ...payload };
      const isDisable =
        newData.name.trim().length > 0 && newData.description.trim().length > 0 && newData.details.trim().length > 0;
      return { ...prevState, data: newData, disabled: !isDisable };
    }

    case 'STEP2': {
      return { ...prevState, data: { ...prevState.data, file: payload }, disabled: false };
    }

    case 'SETDATA': {
      return { ...prevState, data: { ...prevState.data, ...payload } };
    }

    case 'NEXTSTEP': {
      return {
        ...prevState,
        step: prevState.step + 1,
        disabled: !prevState.data.file.name
      };
    }

    case 'PREVSTEP': {
      const { name, description, details } = prevState.data;
      const isDisable = name.trim().length > 0 && description.trim().length > 0 && details.trim().length > 0;
      return { ...prevState, step: prevState.step - 1, disabled: !isDisable, loading: false };
    }

    case 'SETLOADING': {
      return { ...prevState, loading: payload };
    }

    default: {
      return prevState;
    }
  }
}

PagePoliciesModal.propTypes = {
  tab: PropTypes.string.isRequired,
  dispatch: PropTypes.shape({
    onClose: PropTypes.func.isRequired
  }).isRequired,
  refetchOnCreate: PropTypes.func.isRequired
};

export default PagePoliciesModal;
