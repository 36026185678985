import React, { useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import { Info } from '../icons';
import { Typography } from '@trustsecurenow/components-library';

const InfoButtonWithPopover = ({ tooltip, Icon, name, className, iconCustomStyle = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? name : undefined;

  const IconComponent = Icon ? Icon : Info;

  return (
    <>
      <IconButton style={{ ...iconCustomStyle }} onClick={handleClick} className={className}>
        <IconComponent color={open ? 'colorSystemInfo' : 'colorDefault'} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            maxWidth: '280px',
            width: '100%',
            whiteSpace: 'pre-line'
          }
        }}
      >
        <Typography variant='body2' p = {2}>{tooltip}</Typography>
      </Popover>
    </>
  );
};

export default InfoButtonWithPopover;
