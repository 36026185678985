import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import StatusButton from 'apps/shared/darkWebMonitoring/StatusButton';
import { DialogContent, DialogContentText, IconButton, makeStyles } from '@material-ui/core';
import CustomTooltip from 'components/common/CustomTooltip';
import SingleSignOnForm, { PROVIDER_ID } from './SingleSignOnForm';
import { useRedirectionTarget } from 'hooks';
import { SINGLE_SIGNON_MODALS } from './constants';
import { Link, Stack, GoogleLogo, MicrosoftLogo, OktaLogo } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const PROVIDERS_CONFIG = [
  {
    id: PROVIDER_ID.O365,
    logo: MicrosoftLogo
  },
  {
    id: PROVIDER_ID.GOOGLE,
    logo: GoogleLogo
  },
  {
    id: PROVIDER_ID.OKTA,
    logo: OktaLogo
  }
];

const SingleSignOnSettingsModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [provider, setProvider] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [placeholder, setPlaceholder] = React.useState(false);
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);

  const redirectionTarget = useRedirectionTarget();

  React.useEffect(() => {
    if (!redirectionTarget) return;
    if (!Object.values(SINGLE_SIGNON_MODALS).includes(redirectionTarget)) return;
    switch (redirectionTarget) {
      case SINGLE_SIGNON_MODALS.O365:
        setProvider(PROVIDER_ID.O365);
        break;
      case SINGLE_SIGNON_MODALS.OKTA:
        setProvider(PROVIDER_ID.OKTA);
        break;
      case SINGLE_SIGNON_MODALS.GOOGLE:
        setProvider(PROVIDER_ID.GOOGLE);
        break;
    }
    setOpen(true);
  }, [redirectionTarget]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setPlaceholder(false);
    setProvider(null);
    setOpen(false);
  }, []);

  const handleIconHover = iconName => {
    setHoveredIcon(iconName);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };

  const handleClick = id => {
    setProvider(id);
    handleIconLeave();
  };

  const SyncTypesButtons = useMemo(
    () => (
      <Stack
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        mt={2}
        mb={3.75}
        mx="auto"
      >
        {PROVIDERS_CONFIG.map(({ id, logo: Logo }) => (
          <Link
            key={id}
            component="button"
            underline="none"
            onClick={() => handleClick(id)}
            onMouseEnter={() => handleIconHover(id)}
            onMouseLeave={handleIconLeave}
            onFocus={() => handleIconHover(id)}
            onBlur={handleIconLeave}
          >
            <Logo colored={hoveredIcon === id} />
          </Link>
        ))}
      </Stack>
    ),
    [hoveredIcon]
  );

  return (
    <>
      {buttonsState?.mfa_enabled ? (
        <CustomTooltip
          light
          title={
            buttonsState?.mfa_enabled
              ? 'Single Sign-on cannot be enabled if MFA is enabled. \nPlease disable MFA to enable Single Sign-on.'
              : ''
          }
          placement="bottom"
        >
          <>
            <StatusButton
              style={{ marginRight: 5 }}
              status={buttonsState?.federation_enabled ? 'active' : 'inactive'}
              disabled={buttonsState?.mfa_enabled}
              label="Single Sign-On"
              onClick={handleOpen}
              inactiveOnClick={handleOpen}
            />
          </>
        </CustomTooltip>
      ) : (
        <StatusButton
          style={{ marginRight: 5 }}
          status={buttonsState?.federation_enabled ? 'active' : 'inactive'}
          disabled={buttonsState?.mfa_enabled}
          label="Single Sign-On"
          onClick={handleOpen}
          inactiveOnClick={handleOpen}
        />
      )}

      <Dialog
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">
          Single Sign-On
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpen(false);
              setProvider(null);
              setPlaceholder(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {provider == null && !buttonsState?.federation_enabled && !placeholder ? (
            <>
              <DialogContentText id="alert-dialog-description">
                You can utilize Single Sign-On login to allow users to login using their 0365, Google or Okta
                credentials.
              </DialogContentText>
              {SyncTypesButtons}
            </>
          ) : (
            <SingleSignOnForm
              onCancel={() => {
                handleClose();
              }}
              onDisconnect={() => {
                setPlaceholder(true);
              }}
              onUpdateProvider={(newProvider) => {
                setProvider(newProvider)
              }}
              provider={provider}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SingleSignOnSettingsModal;
