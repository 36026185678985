/* eslint-disable no-console */
// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { LoadingStyled, PageError } from 'components';
import { Box } from '@trustsecurenow/components-library'

export { default as clients } from './clients';
export { default as newsfeed } from './newsfeed';
export { default as contentadmin } from './contentadmin';
export { default as auditlog } from './auditlog';
export { default as getstarted } from './getstarted';
export { default as dashboard } from './dashboard';
export { default as company } from './company';
export { default as partner } from './partner';
export { default as user } from './user';
export { default as Preassessment } from './preassessment';
export { default as admin } from './admin';
export { default as instantscan } from './instantscan';
export { default as reports } from './reports';
export * from './teamsAbout';
export * from './user';
export * from './newsfeed';

export const LazyApp = ({ component, ...rest }) => {
  const Component = component
    .split('/')
    .map((x, i) => (i !== 0 || x === '..' ? x : `./${x.replace('my', '').toLowerCase()}`))
    .join('/');

  const LoadableTab = React.useMemo(
    () =>
      Loadable({
        loader: () => import(`${Component}`),
        loading: props => {
          if (props.error) {
            console.group(`%cEROR: LAZY APP`, 'font-size: medium; color: red');
            console.log(props.error);
            console.groupEnd();
            return (
              <PageError
                title="Oops!"
                subtitile="There was a problem with the page?"
                description="Try and refresh the page, if the problem still exists then Contact Us"
                linkText="Contact Us"
                linkTo="/user/contactUs"
              />
            );
          }
          return <Box width={'100%'}><LoadingStyled centerAlign /></Box>;
        },
        timeout: 10000
      }),
    [component]
  );

  return <LoadableTab {...rest} />;
};
