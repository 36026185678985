import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles, Dialog, FormControlLabel, Checkbox } from '@material-ui/core';
import { SentimentSatisfiedOutlined as SentimentSatisfiedOutlinedIcon } from '@material-ui/icons';
import { trackingUtils } from 'helpers';
import { Button, Link } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '60px 20px',
    textAlign: 'center',
    '& h3': {
      color: 'var(--colorSystemSuccess)',
      marginBottom: 15
    }
  },
  icon: {
    backgroundColor: 'rgba(12, 206, 107, 0.2)',
    color: 'var(--colorSystemSuccess)',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '50px'
  },
  checkbox: {
    '& svg': {
      fontSize: 24
    },
    '& ~ span': {
      color: 'var(--colorDefault)',
      fontSize: 14
    }
  }
}));

const WelcomeModal = ({ open, close, onConfirm, onCancel }) => {
  const classes = useStyles();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    localStorage.removeItem('getStartedNotify');
  }, []);

  useEffect(() => {
    if (open) {
      trackingUtils.customEvent('click_setup_popup', {
        sendUserName: true,
        sendPartnerName: true,
        label: 'View popup'
      });
    }
  }, [open]);

  const onChangeShowMwssage = e => {
    setShowMessage(e.target.checked);
  };

  const onCancelClick = e => {
    e.preventDefault();
    onCancel(showMessage);
  };

  const onConfirmClick = () => {
    onConfirm(showMessage);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(8, 47, 73, 0.96)'
        }
      }}
    >
      <div className={classes.content}>
        <Box pb={1.5}>
          <SentimentSatisfiedOutlinedIcon className={classes.icon} />
        </Box>
        <Typography variant="h3" display="block" gutterBottom>
          Welcome to your Breach Secure Now Account!
        </Typography>
        <Typography display="block">Do you want to learn how to use the Platform?</Typography>
        <Box mt={1.5} mb={2.5}>
          <FormControlLabel
            label="Do not show this message again"
            control={
              <Checkbox
                value={showMessage}
                checked={showMessage}
                onChange={onChangeShowMwssage}
                name="show-message"
                color="primary"
                className={classes.checkbox}
              />
            }
          />
        </Box>
        <Box pb={3.5}>
          <Button color="success" sx={{ width: 130 }} onClick={onConfirmClick}>
            Yes
          </Button>
        </Box>
        <Box>
          <Link component="button" sx={{ display: 'inline-flex' }} onClick={onCancelClick}>
            No
          </Link>
        </Box>
      </div>
    </Dialog>
  );
};

WelcomeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.string.isRequired,
  onCancel: PropTypes.string.isRequired
};

export default WelcomeModal;
