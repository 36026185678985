// @flow

const usersList = {
  useQueryHook: true,
  noQuery: true,
  type: 'clientsUsersTable',
  selecting: true,
  selectAll: true,
  search: true,
  searchFields: ['first_name', 'last_name', 'email'],
  searchLabel: 'Search',
  orderBy: 'name',
  actions: [
    {
      icon: 'Lock',
      title: 'Unlock',
      method: 'unlock',
      name: 'unlock'
    },
    {
      icon: 'Enable',
      title: 'Active',
      method: 'activate',
      name: 'active'
    },
    {
      icon: 'Disable',
      title: 'Inactive',
      method: 'deactivate',
      name: 'inactive'
    },
    {
      icon: 'Policy',
      title: 'Reset Policies',
      method: 'policyAcknowledgement',
      name: 'reset_policies'
    },
    {
      icon: 'OtherPolicy',
      title: 'Reset Other Policies',
      method: 'otherPolicyAcknowledgement',
      name: 'reset_other_policies'
    },
    {
      icon: 'WelcomeMessage',
      title: 'Welcome Message',
      method: 'sendWelcomeMessages',
      name: 'welcome_message'
    },
    {
      icon: 'WelcomeMessage',
      title: 'New Partner Message',
      method: 'sendPartnerWelcomeMessages',
      name: 'partner_welcome_message'
    },
    {
      icon: 'Clear',
      title: 'Clear Bounce Email',
      method: 'removeBounceEmail',
      name: 'clear_bounce_email'
    },
    {
      icon: 'Refresh',
      title: 'Reset Password',
      method: 'sendResetPasswordMessages',
      name: 'reset_password'
    },
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete',
      name: 'delete'
    },
    {
      icon: 'Refresh',
      title: 'Reset Deleted User',
      method: 'resetDeletedUser',
      name: 'reset_deleted'
    },
    {
      icon: 'UserMove',
      title: 'Move User',
      method: 'moveUser',
      name: 'move_users'
    },
    {
      icon: 'UserChecked',
      title: 'Reset MFA',
      method: 'resetMFA',
      name: 'reset_mfa'
    },
    {
      icon: 'Delete',
      title: 'Permanent Delete',
      color: 'colorSystemDanger',
      method: 'permDeleteUser',
      name: 'permanent_delete'
    }
  ],
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'clients',
      tab: 'user'
    }
  },

  cells: [
    {
      id: 'name',
      label: 'Name',
      align: 'left',

      filter: {
        type: 'text',
        placeholder: 'e.g: 3,10-15,>10',
        help: 'Input Methods: Range (x-y), Grater then ....'
      }
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left',
      filter: {
        type: 'select',
        placeholder: 'Search',
        options: [
          {
            id: 'value 1',
            name: 'value 1'
          },
          {
            id: 'value 2',
            name: 'value 2'
          },
          {
            id: 'value 3',
            name: 'value 3'
          }
        ],
        help: 'Input Methods: Select, Grater then ....'
      }
    },
    { id: 'group_role', label: 'Group Role', align: 'left', tag: true },
    {
      id: 'last_login',
      label: 'Days Since Last Login',
      labelAlign: 'center',
      align: 'center'
      // isDate: true
    },
    {
      id: 'welcome_email_received',
      label: 'Welcome Email Status',
      labelAlign: 'center',
      align: 'center',
      hideOrderBy: true
    },
    {
      id: 'tag',
      label: 'Tag',
      align: 'left',
      tag: true
    },
    {
      id: 'ess',
      label: 'ESS',
      align: 'left'
    },
    {
      id: 'data_breaches',
      label: 'Data Breaches',
      labelAlign: 'center',
      align: 'center'
    },
    {
      id: 'dwr_training_completed',
      label: 'Dark Web Training',
      align: 'center'
    },
    {
      id: 'dwr_remediated_count',
      label: 'Remediated Breaches',
      align: 'center'
    },
    {
      id: 'policies',
      label: 'Policies',
      align: 'left',
      policesTooltip: true,
      hideOrderBy: true
    },
    {
      id: 'access_level',
      label: 'Access Level',
      labelPaddingLeft: 5,
      subLabel: ['Mktg', 'Pay', 'Bill', 'Tax', 'Dir Sync'],
      align: 'left',
      accessLevel: true,
      // hideWhenNoResult: true,
      hideOrderBy: true
      // conditional: true
    },
    {
      id: 'active',
      label: 'Status',
      align: 'left',
      renderActiveIcon: true
    },
    {
      id: 'removes_bounce_email',
      label: 'Email Bounce',
      align: 'left',
      renderEmailIcon: true
    },
    {
      id: 'locked',
      label: 'Locked',
      align: 'left',
      renderActiveIcon: true,
      renderIconLabel: 'PartnerRemote'
    }
  ]
};

export default usersList;
