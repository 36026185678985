import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Paper } from '@material-ui/core';
import { Typography } from '@trustsecurenow/components-library';
import styled from 'styled-components';
import OtpVerification from '../../components/forms/OtpVerification';
import { Footer } from 'components';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { clearMfaConfigData } from 'helpers';
import { setData } from '../../helpers/authProvider';
import { useDispatch } from 'react-redux';

const RootContainer = styled(Container)`
  background-color: var(--backgroundDefault);
  padding-top: 50px;
  min-height: 100vh;
`;

const ContentBox = styled(Container)(({ theme }) => ({
  borderTop: '5px solid var(--colorBaseBase)',
  padding: 10,
  paddingTop: 25,
  background: 'var(--backgroundPaper)',
  minHeight: 'calc(100vh - 120px)',
  marginBottom: 10
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'var(--colorDefault)'
}));

const ConfigMfaApp = props => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [firstRender, setFirstRender] = useState(true);
  const [passwordConfig, setpasswordConfig] = React.useState({
    qr_code_image: '',
    secret_code: ''
  });

  useEffect(() => {
    const qr_code_image = new URLSearchParams(search).get('qr_code_image');
    const secret_code = localStorage.getItem('secret_code');
    setpasswordConfig({
      ...passwordConfig,
      qr_code_image,
      secret_code
    });
    // preventing the page from showing an error
    // that qr image changed in the first render
    if (!firstRender) {
      enqueueAlertSnackbar('The QR code changed!, you need to scan it again.', {
        props: { severity: 'error' }
      });
    } else {
      setFirstRender(false);
    }
  }, [search]);

  const handleSubmit = async response => {
    try {
      setData(response.data, dispatch);
      localStorage.setItem('accessToken', localStorage.getItem('configToken'));
      localStorage.removeItem('configToken');
      clearMfaConfigData();
      window.location.href = '#/';
    } catch (error) {
      enqueueAlertSnackbar(error.message, {
        props: { severity: 'error' }
      });
    }
  };

  return (
    <RootContainer>
      <Container maxWidth="md">
        <ContentBox>
          <Grid container spacing={2}>
            {/* Header and Stepper */}
            <Grid item xs={12}>
              <Item elevation={0}>
                <Typography textAlign="left" variant="h2" mb={4.5}>
                  Verify
                </Typography>
              </Item>
            </Grid>
            {/* Configuration Steps */}
            <Grid item xs={12}>
              <Item elevation={0}>
                <Typography textAlign="left" variant="button" mb={3}>
                  Setup your Authenticator app
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item elevation={0}>
                <Typography textAlign="left" variant="body2" mb={4}>
                  1. Launch Authenticator, tap the “+” icon
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item elevation={0}>
                <Typography textAlign="left" variant="body2" mb={1.5}>
                  2. Choose Scan a QR code
                </Typography>
              </Item>
            </Grid>
            {/* The qrcode image */}
            <Grid item xs={12}>
              <Item elevation={0}>
                <StyledTypography align="left" variant="body2" gutterBottom component="div">
                  <img id="qr_code_image" src={passwordConfig.qr_code_image} />
                </StyledTypography>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item elevation={0}>
                <Typography
                  variant="button"
                  textAlign="left"
                  fontWeight={theme => theme.typography.fontWeightBold}
                  component="p"
                >
                  3. Enter the 6-digit code generated by Authenticator app below
                </Typography>
              </Item>
            </Grid>

            {/* Those two empty girds are kept for vertical spacing */}
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>

            {/* OTP Verification Form */}
            <Grid item xs={12}>
              <Item elevation={0}>
                <OtpVerification type="mfa_configure" handleSubmit={handleSubmit} />
              </Item>
            </Grid>
          </Grid>
        </ContentBox>
      </Container>
      <Footer sidebar={false} />
    </RootContainer>
  );
};

export default ConfigMfaApp;
