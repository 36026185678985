import React from 'react';
import { useQuiz } from 'components';
import { ArrowRightIcon, Button } from '@trustsecurenow/components-library';
import { QuizContentFooterLayout } from './quizStyle';

const QuizContentFooter = () => {
  const { selectedAnswerId, question, dispatch, onFinishQuiz, quizId, disabled, redirect } = useQuiz();

  const handleSubmit = () => {
    dispatch.submitAnswer(quizId, question.id, selectedAnswerId, onFinishQuiz, redirect);
  };

  return (
    <QuizContentFooterLayout justifyContent={'flex-end'}>
      <Button onClick={handleSubmit} disabled={disabled} endIcon={<ArrowRightIcon />}>
        {question?.is_last ? 'Submit' : 'Next question'}
      </Button>
    </QuizContentFooterLayout>
  );
};

export default QuizContentFooter;
