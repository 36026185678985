/* eslint-disable no-console */
// @flow
import React, { type StatelessFunctionalComponent } from 'react';
import { AdminContext, AdminUI } from 'react-admin';
import { AppProvider, ThemeProvider } from 'helpers';

const Admin: StatelessFunctionalComponent = ({
  appLayout,
  authProvider,
  catchAll,
  children,
  customReducers,
  customRoutes = [],
  customSagas,
  dashboard,
  dataProvider,
  history,
  i18nProvider,
  initialState,
  layout,
  loading,
  locale,
  loginPage,
  logoutButton,
  menu, // deprecated, use a custom layout instead
  theme,
  title = null
}: AdminProps) => {
  appLayout &&
    console.warn(
      'You are using deprecated prop "appLayout", it was replaced by "layout", see https://github.com/marmelab/react-admin/issues/2918'
    );
  loginPage === true &&
    process.env.NODE_ENV !== 'production' &&
    console.warn(
      'You passed true to the loginPage prop. You must either pass false to disable it or a component class to customize it'
    );
  locale &&
    console.warn('You are using deprecated prop "locale". You must now pass the initial locale to your i18nProvider');

  return (
    <AdminContext
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      history={history}
      customReducers={customReducers}
      customSagas={customSagas}
      initialState={initialState}
    >
      <ThemeProvider>
        <AppProvider>
          <AdminUI
            layout={appLayout || layout}
            customRoutes={customRoutes}
            dashboard={dashboard}
            menu={menu}
            catchAll={catchAll}
            theme={theme}
            title={title}
            loading={loading}
            loginPage={loginPage}
            logout={authProvider ? logoutButton : undefined}
          >
            {children}
          </AdminUI>
        </AppProvider>
      </ThemeProvider>
    </AdminContext>
  );
};

export default Admin;
