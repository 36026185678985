import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AddFilter } from 'components';
import { AddIcon, Button, Typography, SearchIcon } from '@trustsecurenow/components-library';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDebounce } from 'hooks';
import { SearchField } from '../../style';
import { useCardPageContext } from '../../context';
import { CONTENT_ADMIN_ROOT_PATH } from '../../constants';
import { getButtonName } from '../../helpers';
import { useContentAdminPageFilter } from '../../hooks';

const CardPageLayoutHeader = () => {
  const history = useHistory();
  const { pagination, dispatch, search, pagePath, filters } = useCardPageContext();

  const [searchValue, setSearchValue] = useState(search);

  const { limit } = pagination;

  const [searchValueDebounce] = useDebounce({ value: searchValue, delay: 500 });

  const handleChangeSearch = value => {
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch.onChange('search', searchValueDebounce.value);

    dispatch.onChange('page', 1);
  }, [searchValueDebounce]);

  useEffect(() => {
    if (localStorage.getItem('contentAdminPagination')) {
      dispatch.onChange('limit', limit);
    }
  }, [limit]);

  const handleCreate = () => {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}/${pagePath}/create`);
  };

  const handleChangeLimit = value => {
    localStorage.setItem('contentAdminPagination', value);
    dispatch.onChange('limit', value);
    dispatch.onChange('page', 1);
  };

  const handleChangeFilter = (name, value) => {
    dispatch.changeFilter(name, value);
  };

  const buttonName = getButtonName(pagePath);
  const { filterItems } = useContentAdminPageFilter();

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item component={Box} flexGrow={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <SearchField
              id="search"
              placeholder="Search"
              onChange={e => handleChangeSearch(e.target.value)}
              value={searchValue}
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon sx={{ fontSize: 16 }} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <AddFilter values={filters} buttonFullWidth onChange={handleChangeFilter} config={filterItems} />

          <Grid item component={Box} display="flex" alignItems="center">
            <Typography id="showItem" mr={0.5} variant="body2">
              Show:
            </Typography>
            <span>
              <Select
                labelId="showItem"
                id="show-item-select"
                value={limit}
                onChange={e => handleChangeLimit(e.target.value)}
                fullWidth
                style={{ fontSize: 'var(--fonSize)' }}
              >
                <MenuItem value={9}>9 entries</MenuItem>
                <MenuItem value={12}>12 entries</MenuItem>
                <MenuItem value={15}>15 entries</MenuItem>
                <MenuItem value={21}>21 entries</MenuItem>
              </Select>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button onClick={handleCreate} startIcon={<AddIcon />} sx={{ minWidth: 190 }} size="medium">
          New {buttonName}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CardPageLayoutHeader;
