/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Stack,
  Select,
  MenuItem,
  Divider,
  Button,
  AddIcon,
  GridToolbarQuickFilter,
  Tooltip,
  Box
} from '@trustsecurenow/components-library';
import { TABLE_VIEWS } from './constants';
import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro';
import { Link as RouterLink } from 'react-router-dom';
import BulkActionsMenu from './BulkActionsMenu';
import { useRecord } from 'hooks';

const Toolbar = ({
  tableView,
  visibleBulkActions,
  selectedRowsObj,
  setModalState,
  partnerId,
  isEBPP,
  isFetching,
  onChangeTableView
}) => {
  const [open, setOpen] = useState(false);
  const profile = useRecord('user', 'profile');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Box>
      <Stack p={2.5} direction="row" gap={1} flexWrap="wrap">
        <Tooltip open={open} title="Click here to switch columns and explore different statistics below." placement="top">
          <Box component='span' onMouseEnter={handleOpen} onMouseLeave={handleClose}>
            <Select
              value={tableView}
              onChange={onChangeTableView}
              sx={{
                width: 305
              }}
              disabled={isFetching}
              onClose={handleClose}
            >
              {Object.entries(TABLE_VIEWS).map(([viewKey, viewSettings]) => (
                <MenuItem key={viewKey} value={viewKey}>
                  {viewSettings.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Tooltip>
        <GridToolbarColumnsButton />
        {Boolean(isEBPP) && (
          <BulkActionsMenu
            visibleBulkActions={visibleBulkActions}
            selectedRowsObj={selectedRowsObj}
            setModalState={setModalState}
            partnerId={partnerId}
          />
        )}
        <Button disabled={isFetching || !profile} component={RouterLink} to="/clients/create" startIcon={<AddIcon />}>
          New Client
        </Button>
        <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
      </Stack>
      <Divider />
    </Box>
  );
};

export default React.memo(Toolbar);
