import React, { useEffect, useState } from 'react'
import { CircularProgress, IconButton, Link, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, Container, Dialog, LazyIcon, Typography, SearchIcon } from 'components';
import { Save } from 'components/icons';
import { ConfirmModal } from '../../../apps/admin/components';
import { useDebounce } from 'hooks';
import { admin as adminApi } from 'helpers';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useStyles = makeStyles({
  field: {
    backgroundColor: 'var(--backgroundDefault)',
    borderRadius: '5px',
    paddingBottom: '8px',
    '& div': {
      padding: '10px'
    }
  },
  moveDataField: {
    backgroundColor: 'var(--backgroundDefault)',
    borderRadius: '5px',
    padding: '8px 12px'
  },
  clearSearch: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'var(--colorDefault)'
  }
});

const AdminMoveClient = ({ clientName, onMoveClient }) => {
  const classes = useStyles();

  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openFindPartner, setOpenFindPartner] = useState(false)
  const [partners, setPartners] = useState({
    options: [],
    selected: null,
    search: null,
    loading: false
  })
  
  const [searchDebounce] = useDebounce({ value: partners.search, delay: 500 })

  useEffect(() => {
    if (openFindPartner) getPartners();
  }, [openFindPartner]);

  useEffect(() => {
    (partners.search || partners.search === '') && getPartners(searchDebounce.value);
  }, [searchDebounce.value]);

  const getPartners = (partnerName = null) => {
    setPartners(prev => ({
      ...prev,
      loading: true
    }));
    
    adminApi.partners
      .getList({ 
        filters: { ...(partnerName ? { name: partnerName } : {})}
      })
      .then(res => {
        setPartners(prev => ({
          ...prev,
          options: res?.data?.data || []
        }));
      })
      .catch(e => {
        enqueueAlertSnackbar('Failed to load partners', { props: { severity: 'error' } });
      })
      .finally(() =>
        setPartners(prev => ({
          ...prev,
          loading: false
        }))
      );
  };

  const resetPartners = () => {
    setPartners({
      options: [],
      selected: null,
      search: null,
      loading: false
    })
  }

  const onClose = () => {
    resetPartners()
    setOpenFindPartner(false)
  }

  const moveClient = () => {
    setOpenConfirmation(false)
    setOpenFindPartner(false)
    
    onMoveClient({ 
      partnerId: partners.selected?.id,
      partnerName: partners.selected?.name
    })
  }

  return (
    <div>
      <Button
        backgroundColor="buttonSecondaryBackground"
        backgroundColorHover="buttonSecondaryBackground"
        bordercolor="buttonSecondaryBorderFont"
        labelcolor="buttonSecondaryBorderFont"
        mt={1}
        onClick={() => setOpenFindPartner(true)}
      >
        <LazyIcon component="UserMove" size={1.5} color="buttonSecondaryBorderFont" mr={1} />
        Move Client
      </Button>

      <Dialog
        open={openFindPartner}
        setOpen={onClose}
        onlyOk
        maxWidth="sm"
        variant="standard"
        style={{ minHeight: '298px'}}
      >
        <Container.Grid direction="row" justify="space-between" alignItems="center" pb={1} bb={1}>
          <Typography.p bold m={0.2} fontSize={14}>
            {clientName}
          </Typography.p>
          
          <IconButton onClick={onClose}>
            <LazyIcon component="Close" color="colorDefault" size={1} />
          </IconButton>
        </Container.Grid>

        <Container.Grid container pt={3} pb={3}>
          <Container.Grid item md={12} pb={4} fullWidth>
            <Autocomplete
              popupIcon={<KeyboardArrowDownIcon size={40} />}
              noOptionsText={partners.loading ? 'Loading ...' : 'No results'}
              options={partners.options}
              getOptionLabel={option => option.name}
              fullWidth
              loadingText={
                <>
                  <CircularProgress color="inherit" size={20} />
                  <span>&nbsp;Loading...</span>
                </>
              }
              loading={partners.loading}
              onChange={(_e, val, reason) => {
                setPartners(prev => ({
                  ...prev,
                  selected: val
                }));
                if (reason === 'clear') {
                  setPartners(prev => ({
                    ...prev,
                    search: ''
                  }));
                }
              }}
              value={partners.selected}
              inputValue={partners.search}
              onInputChange={(e, value) => {
                setPartners(prev => ({
                  ...prev,
                  search: value
                }));
              }}
              disableClearable
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.field}
                  fullWidth
                  placeholder="Search/Find a Partner"
                  variant='standard'
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                      <SearchIcon />
                    ),
                    endAdornment: (
                      <div
                        className={classes.clearSearch}
                        onClick={() => {
                          setPartners(prev => ({
                            ...prev,
                            search: '',
                            selected: null
                          }));
                        }}
                      >
                        Clear Search
                      </div>
                    )
                  }}
                />
              )}
            />
          </Container.Grid>

          <Container.Grid item md={12}>
            <TextField
              variant='standard'
              className={classes.moveDataField}
              disabled
              fullWidth
              value="Move all client/user data"
              InputProps={{
                disableUnderline: true
              }}
            />
          </Container.Grid>
        </Container.Grid>

        <Container.Grid container justify="flex-end" alignItems="center" pt={1}>
          <Link
            href="#"
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
            color="inherit"
            style={{
              color: 'var(--colorDefault)',
              textDecoration: 'underline'
            }}
          >
            Cancel
          </Link> 
          <Button
            backgroundColor="colorSystemInfo"
            backgroundColorHover="colorSystemInfoHover"
            backgroundDisabled="colorSystemInfoDisable"
            disabled={!partners.selected}
            onClick={() => setOpenConfirmation(true)}
            ml={4}
          >
            <Save />
            &nbsp;&nbsp;Save
          </Button>
        </Container.Grid>
      </Dialog>

      <ConfirmModal
        onClose={() => setOpenConfirmation(false)}
        onSubmit={moveClient}
        config={{
          open: openConfirmation,
          title: 'Move Client',
          message: `Are you sure want to move this client/user data to ${partners.selected?.name}?`
        }}
        disableBackdropClick
        disableEscapeKeyDown
      />
    </div>
  )
}

export default AdminMoveClient