import {
  IconButton,
  SpreadsheetIcon,
  Tooltip,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import clients from 'helpers/apis/clients';
import { downloadFile } from 'helpers';
import { isNA } from '../helpers';

const ReportCell = ({ enabled, clientId, clientReport , onToggleDownloadingReport}) => {
  if (isNA(clientReport)) return <Typography variant="body2">N/A</Typography>;

  if (!enabled) return null;

  const handleDownloadReport = async e => {
    e.stopPropagation();
    onToggleDownloadingReport(true);
    try {
      const res = await clients.getClientReport(clientId);
      downloadFile(res.data.url);
    } catch (error) {
      enqueueAlertSnackbar(`${error.response}`, { props: { severity: 'error' } });
    } finally {
      onToggleDownloadingReport(false);
    }
  };

  return (
    <Tooltip title="Download Client Report" placement="top">
      <IconButton onClick={handleDownloadReport}>
        <SpreadsheetIcon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

ReportCell.propTypes = {
  clientId: PropTypes.string.isRequired,
  clientReport: PropTypes.string.isRequired
};

export default ReportCell;
