import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { ArrowReloadIcon , CloseIcon} from '@trustsecurenow/components-library';

const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #eee;
  background-color: var(--backgroundDefault);
`;

const Header = styled.div`
  height: 50px;
  border-bottom: 2px solid #eee;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  height: 90%;
  overflow: scroll;
`;

const Drawer = ({ open, onClose, onRefresh, children, onReachingScrollEnd }) => {
  const bodyRef = useRef(null);

  const handleInfiniteScroll = () => {
    const { scrollTop, offsetHeight, scrollHeight } = bodyRef.current;

    if (scrollTop + offsetHeight >= scrollHeight) {
      onReachingScrollEnd();
    }
  };

  useEffect(() => {
    const body = bodyRef.current;
    body.addEventListener('scroll', handleInfiniteScroll);
    return () => {
      body.removeEventListener('scroll', handleInfiniteScroll);
    };
  }, []);

  return (
    <Container>
      <Header>
        <h3>Inbox</h3>
        <span>
          <IconButton onClick={onRefresh} color="action">
            <ArrowReloadIcon sx={{ fontSize: 16 }} />
          </IconButton>
          <IconButton onClick={onClose} color="action">
            <CloseIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </span>
      </Header>
      <Body ref={bodyRef}>{children}</Body>
    </Container>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  onReachingScrollEnd: PropTypes.func.isRequired
};

export default Drawer;
