import React from 'react';
import { CheckboxInput, Grid, TextField, DatePicker } from '@trustsecurenow/components-library';
import ContainerStepContent from './ContainerStepContent';
import { ADMIN_MICRO_TRAINING } from '../../constants';
import HashtagsField from './HashtagsField';
import PublishDatePicker from './PublishDatePicker';


const InfoStep = ({ stepNumber, dispatch, record, page, createMode }) => {
  const isMicroTraining = page === ADMIN_MICRO_TRAINING;

  const disabled = !record.name?.trim() || (isMicroTraining && !record.video_number?.trim()) || !record.publish_date;

  return (
    <ContainerStepContent
      headerTitle={`${stepNumber}. Information`}
      onSubmit={dispatch.onNext}
      disableSubmit={disabled}
      submitLabel={createMode ? 'Next' : 'Update'}
      isFirstStep
    >
      <Grid container spacing={2} p={2} pt={4} pb={4}>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            value={record.name}
            name="name"
            label="Name"
            onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
            required
            fullWidth
          />
        </Grid>


        <Grid item xs={12} md={3} lg={2}>
          <TextField
            value={record.created_date}
            name="created_date"
            label="Created Date"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} lg={2}>
          <PublishDatePicker dispatch={dispatch} record={record} page={page} />
        </Grid>

        {isMicroTraining && (
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              value={record.video_number}
              name="video_number"
              label="Video Number"
              onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
              fullWidth
              required
            />
          </Grid>
        )}

        <Grid item xs={12} lg={isMicroTraining ? 2 : 5}>
          <CheckboxInput
            label="Publicly Accessible"
            value={record.publicly_accessible}
            checked={Boolean(record.publicly_accessible)}
            onChange={(e) => dispatch.onChange(e.target.name, e.target.checked)}
            name="publicly_accessible"
            color="default"
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <TextField
            name="description"
            label="Description"
            value={record.description}
            onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
            fullWidth
            multiline
            rows={6}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <HashtagsField dispatch={dispatch} record_hashtags={record.hash_tags} />
        </Grid>
      </Grid>
    </ContainerStepContent>
  );
};

export default InfoStep;
