import {
  IconButton,
  MenuItem,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import * as testAuthoringSystem from 'helpers/apis/services/testAuthoringSystem';
import notifications from 'helpers/apis/ContactUsers/notifications';
import { LoadingStyled } from 'components';
import { decodeId, isValidEmail } from 'helpers';
import {
  Button,
  CircleAlertIcon,
  enqueueAlertSnackbar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Stack,
  TextField
} from '@trustsecurenow/components-library';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import bsnPartnersApi from 'helpers/apis/BSNPartnersAPI/bsnpartnersapi';
import { useAccess, useDebounce } from 'hooks';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import { CONTENT_ADMIN, CONTENT_ADMIN_MT_NL, CONTENT_ADMIN_ROOT_PATH, TEST_MT_NL_OPTIONS } from '../constants';
import TestMTNLPartnerTestingCard from '../components/shared/TestMTNLPartnerTestingCard';

const useStyles = makeStyles(theme => ({
  noMaxWidth: {
    maxWidth: 'none'
  }
}));

const Container = styled.div`
  background-color: var(--backgroundPaper);
  margin: 20px 20px;
  padding: 30px;
  border-radius: 8px;
`;

const Element = styled.div`
  margin: 0 30px 30px 0;
  display: inline-block;
`;

const emailInfoText = `Portal email address to receive notifications`;
const howToSendText = `To Successfully receive notifications please make sure that:
- The emails entered belong to active partners
- The emails entered belong to active/real clients
- The product type of the client is not HIPAA Compliance
\u00A0\u00A0 or Unlimited Cybersecurity Training
- The user is active and not deleted
- The user is not external
- The user has logged in at least once or received the welcome message email
- Send Weekly Micro Trainings and Monthly Newsletters toggled on.`;

const TYPES = {
  mt: 'micro_trainings',
  nl: 'newsletters'
};

const TestMTNL = () => {
  const accessApps = useAccess({ type: 'app' });
  const hasAccess = accessApps.hasOwnProperty(CONTENT_ADMIN);
  const muiClasses = useStyles();

  const {
    goBack,
    location: { pathname }
  } = useHistory();

  const [type, setType] = useState(TEST_MT_NL_OPTIONS[0].value);
  const [names, setNames] = useState({
    options: [],
    selected: null
  });
  const [loadingNames, setLoadingNames] = useState(false);
  const [email, setEmail] = useState('');
  const [disableOnSend, setDisableOnSend] = useState(false);
  const [partnerTesting, setPartnerTesting] = useState({
    type: TEST_MT_NL_OPTIONS[0].value,
    isLoadingItems: true,
    isLoadingPartners: true,
    itemsOptions: [],
    partnersOptions: [],
    item: null,
    partner: null,
    partnerInputValue: '',
    isSendingNotification: false
  });
  const [searchPartnerDebounce] = useDebounce({ value: partnerTesting.partnerInputValue, delay: 500 });

  const isEmailError = email && !isValidEmail(email);

  const validateForm = () => {
    return !isValidEmail(email) || names.selected === null || !type;
  };

  const handleSend = () => {
    const payload = {
      type: type === TYPES.mt ? 'MicroTraining' : 'MonthlyBSN',
      email: [email],
      ...(type === TYPES.mt ? { QUIZCODE: decodeId(names.selected) } : { NEWSLETTERCODE: decodeId(names.selected) })
    };
    setDisableOnSend(true);
    notifications
      .testMTNL(payload)
      .then(res => {
        enqueueAlertSnackbar('Sent Successfully', { props: { severity: 'success' } });
      })
      .catch(err => {
        enqueueAlertSnackbar('Something went wrong while sending notifications', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setDisableOnSend(false);
      });
  };
  const reset = () => {
    setNames({
      options: [],
      selected: null
    });
  };

  const fetchPartners = useCallback(async () => {
    try {
      setPartnerTesting(prevState => ({
        ...prevState,
        isLoadingPartners: true
      }));
      const filters = { _limit: 20 };
      if (searchPartnerDebounce.value) filters.name = searchPartnerDebounce.value;
      const response = await bsnPartnersApi.audiLogsSearch({ search: 'partners', filters });
      setPartnerTesting(prevState => ({
        ...prevState,
        partnersOptions: response?.data?.data || [],
        isLoadingPartners: false
      }));
    } catch {
      setPartnerTesting(prevState => ({
        ...prevState,
        isLoadingPartners: false
      }));
    }
  }, [searchPartnerDebounce.value]);

  useEffect(() => {
    if (!hasAccess) return;
    fetchPartners();
  }, [fetchPartners, hasAccess]);

  useEffect(() => {
    if (!hasAccess) return;
    setPartnerTesting(prevState => ({ ...prevState, isLoadingItems: true, itemsOptions: [], item: null }));
    testAuthoringSystem
      .getMTNL(partnerTesting.type)
      .then(res => {
        setPartnerTesting(prevState => ({
          ...prevState,
          itemsOptions: res?.data?.quiz_data || [],
          isLoadingItems: false
        }));
      })
      .catch(() => {
        setPartnerTesting(prevState => ({
          ...prevState,
          isLoadingItems: false
        }));
      });
  }, [partnerTesting.type, hasAccess]);

  useEffect(() => {
    if (!hasAccess) return;
    if (type) {
      setLoadingNames(true);
      testAuthoringSystem
        .getMTNL(type)
        .then(res => {
          setNames(prev => ({
            ...prev,
            options: res?.data?.quiz_data
          }));
        })
        .finally(() => {
          setLoadingNames(false);
        });
    }
  }, [type, hasAccess]);

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleChangePartnerTestingType = e => {
    const { value } = e.target;
    setPartnerTesting(prevState => ({ ...prevState, type: value }));
  };

  const handleChangePartnerTestingName = (e, name) => {
    setPartnerTesting(prevState => ({ ...prevState, item: name }));
  };

  const handleChangePartnerTestingPartner = (e, partner) => {
    setPartnerTesting(prevState => ({ ...prevState, partner }));
  };

  const handleChangePartnerTestingPartnerInput = (e, partnerInputValue) => {
    setPartnerTesting(prevState => ({ ...prevState, partnerInputValue }));
  };

  const handleSendNotification = async () => {
    if (!partnerTesting.partner || !partnerTesting.item) return;
    try {
      setPartnerTesting(prevState => ({ ...prevState, isSendingNotification: true }));
      const isMicrotraining = partnerTesting.type === TYPES.mt;
      const name = isMicrotraining ? 'MicroTraining' : 'MonthlyNewsletter';
      await pushNotifications.reactionaryTestNotification(
        name,
        decodeId(partnerTesting.partner.id),
        decodeId(partnerTesting.item.id)
      );
      enqueueAlertSnackbar(
        'We are currently processing your request and will promptly send emails to partner users once the process is completed.',
        { props: { severity: 'success' } }
      );
    } catch {
      enqueueAlertSnackbar('Failed to send notification.', { props: { severity: 'error' } });
    } finally {
      setPartnerTesting(prevState => ({ ...prevState, isSendingNotification: false }));
    }
  };

  if (`${CONTENT_ADMIN_ROOT_PATH}/${CONTENT_ADMIN_MT_NL}` !== pathname | !hasAccess) {
    goBack();
  }

  if (hasAccess === null) return <LoadingStyled />;

  if (!hasAccess) return <div>You don't have access for this page</div>;

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title="User Testing" />
        <Divider />
        <CardContent>
        <Element>
          <FormControl variant="outlined" size="small" style={{ width: 180 }}>
            <TextField
              id="type"
              label="Type"
              value={type}
              onChange={e => {
                reset();
                setType(e.target.value);
              }}
              labelId="mt-nl-type-label"
              select
              fullWidth
            >
              {TEST_MT_NL_OPTIONS.map(t => (
                <MenuItem value={t.value}>{t.label}</MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Element>
        <Element>
          <FormControl variant="outlined" size="small" style={{ width: 500 }}>
            <TextField
              id="name"
              width="500px"
              label="Name - Created"
              value={names?.selected}
              disabled={type ? false : true}
              onChange={e => {
                setNames(prev => ({
                  ...prev,
                  selected: e.target.value
                }));
              }}
              labelId="mt-nl-name-label"
              select
              fullWidth
            >
              {loadingNames ? (
                <MenuItem value="" disabled>
                  {`Loading...`}
                </MenuItem>
              ) : (
                names?.options?.map(item => <MenuItem value={item?.id}>{`${item?.name} - ${item?.created}`}</MenuItem>)
              )}
            </TextField>
          </FormControl>
        </Element>
        <div style={{ marginBottom: '30px' }}>
          <div style={{ width: '90%', display: 'inline-block' }}>
              <TextField
                error={isEmailError}
                helperText={isEmailError && 'Please enter valid email address'}
                value={email}
                onChange={handleChangeEmail}
                type="email"
                label="Email address*"
                fullWidth
              />
          </div>
          <Tooltip style={{ margin: '0 10px', verticalAlign: 'text-top' }} title={emailInfoText} placement="top">
            <IconButton>
              <CircleAlertIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            title={validateForm() ? 'you must choose a type, name and email to send notification' : ''}
            placement="top"
          >
            <span>
              <Button color="success" onClick={handleSend} disabled={validateForm() || disableOnSend}>
                {disableOnSend && (
                  <CircularProgress size={14} thickness={2} style={{ color: `black`, marginRight: 10 }} />
                )}
                Send Notification
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            style={{ margin: '0 10px' }}
            classes={{ tooltip: muiClasses.noMaxWidth }}
            title={<span style={{ whiteSpace: 'pre-line' }}> {howToSendText} </span>}
            placement="right"
          >
            <IconButton>
              <CircleAlertIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        </div>
        </CardContent>
      </Card>
      <TestMTNLPartnerTestingCard
        type={partnerTesting.type}
        item={partnerTesting.item}
        partner={partnerTesting.partner}
        itemsOptions={partnerTesting.itemsOptions}
        partnersOptions={partnerTesting.partnersOptions}
        isLoadingItemsOptions={partnerTesting.isLoadingItems}
        isLoadingPartnersOptions={partnerTesting.isLoadingPartners}
        isSendDisabled={!partnerTesting.item || !partnerTesting.partner}
        isSendLoading={partnerTesting.isSendingNotification}
        onChangeType={handleChangePartnerTestingType}
        onChangeName={handleChangePartnerTestingName}
        onChangePartner={handleChangePartnerTestingPartner}
        onChangePartnerInput={handleChangePartnerTestingPartnerInput}
        onSendNotification={handleSendNotification}
      />
    </Stack>
  );
};

export default TestMTNL;
