// @flow
import {SettingsIcon } from '@trustsecurenow/components-library';
import AdminList from './AdminList';

export default {
  name: 'admin',
  list: AdminList,
  edit: AdminList,
  icon: SettingsIcon,
  options: {
    label: 'Admin',
    hasMobileSublist: false
  }
};