import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { enqueueAlertSnackbar, Button } from '@trustsecurenow/components-library';
import { Link, RHFTextField as TextField } from 'components';
import { useFormContext } from 'react-hook-form';
import { LandingPagesModalContainer } from '../../style';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { validSecureURL } from '../../../../helpers';
import { Typography } from '@trustsecurenow/components-library';


const LandingPagesModal = ({ onClose = () => {}, onDisabled = () => {}, disabled = false }) => {
  const { setValue } = useFormContext();
  const [url, setUrl] = useState('');

  const handleChange = e => {
    const { value } = e.target;

    if (!value || !validSecureURL(value)) {
      onDisabled(true);
    } else {
      onDisabled(false);
    }

    setUrl(value);
  };

  const handleSubmit = async () => {
    if (!url) return;

    onDisabled(true);
    try {
      const readWebSiteData = await contentAdmin.readWebsite(url);
      const { content } = readWebSiteData.data;
      setValue('html', content.replaceAll('<form ', `<form onsubmit="event.preventDefault();"`));
      onClose();
      onDisabled(false);
    } catch (e) {
      enqueueAlertSnackbar(e?.response?.data?.message, { props: { severity: 'error' } });
      onClose();
      onDisabled(false);
    }
  };

  return (
    <LandingPagesModalContainer>
      <Typography variant='body2' mb={2}>Enter a URL with "https" and Import the link</Typography>
      <TextField
        name="url"
        placeholder="URL"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link className="icon" />
            </InputAdornment>
          )
        }}
        fullWidth
        required
        onChange={handleChange}
        customLabel={{
          label: 'Required',
          position: 'right bottom'
        }}
      />
      <Button disabled={disabled || !url} fullWidth onClick={handleSubmit} color="success" size="medium" sx={{ mt: 5 }}>
        Import
      </Button>
    </LandingPagesModalContainer>
  );
};

export default LandingPagesModal;
