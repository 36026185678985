// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { theme } from 'conf';
import { Button } from '@material-ui/core';

export const ButtonLink: ComponentType<*> = styled(Button)`
  text-transform: capitalize;
  color: ${theme.palette.secondary.main};
`;

export const ContainerHeaderBox: ComponentType<*> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: calc(var(--spacing) / 0.5);

  > p {
    margin: calc(var(--spacing) / var(--spacing));
  }
`;

export const BoxDashboardAlign: ComponentType<*> = styled.div`
  @media (min-width: 0px) and (max-width: 1279px) {
    display: flex;
    flex-direction: column;
  }
  > svg {
    @media (min-width: 0px) and (max-width: 1279px) {
      margin-top: calc(var(--spacing) / 1);
    }
    font-size: calc(3 * var(--fontSize));
    color: var(--colorSystemInfo);
  }
`;

export const DashboardDarkWebContainer = styled.div`
  ${props => props.theme.breakpoints.down('sm')} {
    .root-container {
      width: 100%;
      margin: 0;
    }

    .email-video-container {
      justify-content: center;

      & > div {
        max-width: 576px;
        width: 100%;
      }
    }
  }
`;
