import { Box } from '@material-ui/core';
import { CustomTooltip } from 'components';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { Button, SaveIcon, enqueueAlertSnackbar } from '@trustsecurenow/components-library';

function SaveButton({
  state,
  setInitialData,
  refetchSubscription,
  refetchTable,
  noUsersSelected,
  invalidData,
  isEditedData,
  setActionLoading
}) {
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSave = async () => {
    setActionLoading(true);
    setSaveLoading(true);
    try {
      await pushNotifications.setSubscription(state?.subscription?.id, state);

      // Note: In case of a client is customizing default settings and pressed Save,
      // his custom settings will not be effective unless he presses Enable,
      // therefore, we can't refetch subscription data here since it will fall back to the default settings again.
      setInitialData(cloneDeep(state));
      await refetchSubscription();
      await refetchTable(); // refetching table data

      enqueueAlertSnackbar('Settings have been saved', { props: { severity: 'success' } });
    } catch (err) {
      enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    } finally {
      setSaveLoading(false);
      setActionLoading(false);
    }
  };

  const tooltip = noUsersSelected ? `Select users to receive ${state?.push_notification?.name} notifications` : '';

  return (
    <Box ml={3}>
      <CustomTooltip title={tooltip} placement="top" arrow light maxWidth={250}>
        <span>
          <Button
            disabled={invalidData || !isEditedData}
            onClick={handleSave}
            color="info"
            startIcon={<SaveIcon />}
            sx={{ width: 110 }}
            loading={saveLoading}
          >
            Save
          </Button>
        </span>
      </CustomTooltip>
    </Box>
  );
}

export default SaveButton;
