import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card as MUICard, CardActions, CardContent, Typography, Popover, Box, makeStyles } from '@material-ui/core';
import { InfoButtonWithPopover } from 'components';
import LineProgress from 'components/loaders/LineProgress';
import { Button } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 285,
    minHeight: 370,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  media: {
    padding: '20px 20px 0'
  },
  img: {
    width: '100%',
    height: 'auto'
  },
  content: {
    padding: '20px 20px 0'
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    '& .info': {
      padding: 8
    },
    '& svg': {
      fontSize: 16
    }
  },
  description: {
    fontSize: 14
  },
  progressVal: {
    fontSize: 14,
    textAlign: 'right',
    marginTop: 8
  },
  actions: {
    padding: 20,
    marginTop: 'auto'
  },
  tooltip: {
    maxWidth: 230,
    padding: 16,
    color: 'var(--colorDefault)',
    fontWeight: 500,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 12,
      height: 12,
      top: 20,
      transform: 'rotate(45deg)',
      left: -5,
      backgroundColor: 'var(--backgroundPaper)',
      display: 'block'
    }
  }
}));

const getColor = val => {
  if (val === 100) return 'var(--colorSystemSuccess)';
  if (val >= 50) return 'var(--colorSystemWarning)';
  if (val > 0) return 'var(--colorSystemDanger)';
  return 'var(--backgroundDefault)';
};

const Card = props => {
  const { imgSrc, imgAlt, title, infoTooltip, description, progress, btnTitle, btnTooltip, onClick } = props;
  const classes = useStyles();
  const btnRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (btnTooltip) setAnchorEl(btnRef.current);
  }, [btnTooltip]);

  const closePopover = () => {
    setAnchorEl(null);
  };

  return (
    <MUICard className={classes.root} elevation={0}>
      <div className={classes.media}>
        <img className={classes.img} src={imgSrc} alt={imgAlt} />
      </div>
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" component="h5" className={classes.title}>
          {title}
          <InfoButtonWithPopover name="learn-more" tooltip={infoTooltip} className="info" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
          {description}
        </Typography>
        {progress && (
          <Box mt={1}>
            <LineProgress
              backgroundColor="var(--backgroundDefault)"
              barColor={getColor(progress.value)}
              value={progress.value}
              variant="determinate"
            />
            <Typography variant="body2" color="textSecondary" component="p" className={classes.progressVal}>
              {progress.value}%
            </Typography>
          </Box>
        )}
      </CardContent>
      <CardActions className={classes.actions}>
        <Box width="100%" ref={btnRef}>
          <Button onClick={onClick} fullWidth>
            {btnTitle}
          </Button>
        </Box>
        <Popover
          className={classes.popover}
          id={anchorEl ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          PaperProps={{
            style: { overflowX: 'unset', overflowY: 'unset', marginLeft: 3 }
          }}
        >
          <div className={classes.tooltip}>{btnTooltip}</div>
        </Popover>
      </CardActions>
    </MUICard>
  );
};

Card.defaultProps = {
  imgAlt: '',
  progress: null,
  btnTooltip: ''
};

Card.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  title: PropTypes.string.isRequired,
  infoTooltip: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  progress: PropTypes.number,
  btnTitle: PropTypes.string.isRequired,
  btnTooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default React.memo(Card);
