import React from 'react'
import { CloseIcon, TextField, SearchIcon } from '@trustsecurenow/components-library';

const CustomSearchField = ({
  label,
  value,
  onChange,
  ...props
}) => {
  return (
    <TextField
      placeholder={label || 'Search'}
      variant="filled"
      value={value}
      inputBase
      InputProps={{
        startAdornment: value ? (
          <CloseIcon sx={{ cursor: 'pointer', fontSize: 12 }} onClick={() => onChange('')} />
        ) : <SearchIcon sx={{ fontSize: 12 }} />
      }}
      onChange={({ target: { value: val } }) => onChange(val)}
      fullWidth
      {...props}
    />
  );
};

export default CustomSearchField;